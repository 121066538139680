export const WEIGHT = 'weight';
export const HEIGHT = 'height';
export const HAS_RELATIVE_INTESTIONAL_CANCER = 'hasRelativeIntestinalCancerId';
export const RELATIVE_INTESTIONAL_CANCER_WHO =
  'relativeWithIntestinalCancerIds';
export const HAS_MEDICINE_SENSITIVITY = 'hasMedicineSensitivity';
export const MEDICINE_SENSITIVITY = 'medicineSensitivityDetails';
export const HAS_SMOKED_DAILY = 'hasSmokedDaily';
export const SMOKED_DAILY_YEARS_ID = 'smokedDailyYears';
export const SMOKED_DAILY_AMOUNT_ID = 'smokedDailyAmount';
export const DOES_SMOKE = 'doesSmoke';
export const DRINKING_FREQUENCY_ID = 'drinkingFrequencyId';
export const DRINKING_AMOUNT_ID = 'drinkingAmountId';
export const DRINKING_SIX_FREQUENCY_ID = 'drinkingSixOrMoreFrequencyId';
export const LONG_TERM_ILNESS_IDS = 'longTermIllnessIds';
export const REGULAR_MEDICINE_IDS = 'regularMedicationIds';
export const PRELIMINARY_ID = 'preliminaryInfoId';
export const CONFIDENCE = 'confidence';
