import { useField } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IOption } from '../../models/IOption';
import styles from './radioSelect.module.scss';

interface RadioSelectProps {
  name: string;
  optionsList: IOption[];
  disabled?: boolean;
  showErrors?: boolean;
  onChangeWarning?: () => boolean;
}

const RadioSelect: React.FC<RadioSelectProps> = ({
  name,
  optionsList,
  disabled,
  showErrors,
  onChangeWarning,
}) => {
  const { t } = useTranslation();

  const [field, form, meta] = useField(name);

  return (
    <div className={styles.container}>
      {optionsList.map((el) => (
        <div
          key={el.langValue}
          className={
            disabled
              ? `
        ${styles.disabled} grid-4-1st`
              : 'grid-4-1st'
          }
        >
          <input
            className={styles.input}
            type="radio"
            name={name}
            checked={+el.value === field.value}
            onChange={() => {
              if (onChangeWarning) {
                if (onChangeWarning()) {
                  meta.setValue(+el.value);
                }
              } else {
                meta.setValue(+el.value);
              }
            }}
            disabled={disabled}
          />
          <label htmlFor={name}>{t(`${name}.${el.langValue}`)}</label>
        </div>
      ))}
      {form.error && showErrors ? (
        <div className={styles.errors}>{form.error}</div>
      ) : null}
    </div>
  );
};

RadioSelect.defaultProps = {
  disabled: false,
  showErrors: true,
};

export default RadioSelect;
