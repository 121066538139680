import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import logo from '../../assets/images/HUS_logo.png';
import avatar from '../../assets/images/login.svg';
import Header from '../../components/header/header';
import MenuNav from '../../components/menu/menu';
import ScreeningSearch from '../../components/screeningSearch/screeningSearch';
import { ROLE_IDS } from '../../constants/adminTabel';
import { FIRSTNAME, LASTNAME, ORGANIZATION } from '../../constants/commonKeys';
import { LOCKED_BY_CURRENT_USER } from '../../constants/lockKeys';
import { rolesAllowedPages } from '../../constantsLists/lists';
import { RootState } from '../../state/rootReducer';
import { loginUser, LogoutUser } from '../../state/user/actions';
import AnnouncementBox from '../announcementBox/announcementBox';
import ChangeLanguageButtons from '../changeLangButton/changeLangButtons';
import styles from './layout.module.scss';

const Layout: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, screening } = useSelector((state: RootState) => state);
  const isEditing = screening.screeningLock[LOCKED_BY_CURRENT_USER];

  useEffect(() => {
    if (!user.isLoggedIn) {
      dispatch(loginUser(t));
    }
  }, []);

  return (
    <div className={`container--fluid ${styles.wrapper}`}>
      <div className={`${styles.upperContent} container__row`}>
        <img
          className={styles.logo}
          onClick={() => {
            if (isEditing) return;
            history.push('/');
          }}
          src={logo}
          alt={t('images.logo_alt')}
        />
        <Route
          path={['/', '/screening/:id']}
          exact
          component={ScreeningSearch}
        />
        <div className={styles.logContainer}>
          <img className={styles.userAvatar} src={avatar} alt="user" />

          <div className={styles.userInfoLogout}>
            <div>{`${user[FIRSTNAME] || ''}  ${user[LASTNAME] || ''}`}</div>
            <div>{user[ORGANIZATION]}</div>
            <button
              className="link-button-alike"
              onClick={() => {
                if (isEditing) return;
                dispatch(LogoutUser());
              }}
            >
              {t('links.logout')}
            </button>
          </div>
        </div>
      </div>
      <Header>
        <>
          <ChangeLanguageButtons />
          {user[ROLE_IDS] &&
            user[ROLE_IDS]?.filter((el) => el !== 5).length! > 1 && <MenuNav />}
        </>
      </Header>
      <AnnouncementBox />
      {children}
    </div>
  );
};

export default Layout;
