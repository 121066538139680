import { ActionTypes, TabActionTypes } from './actions';
import { Tab, Tabs } from './state';

export const initialTabState: Tab = {
  currentTab: Tabs.Preliminary,
};

export const TabReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: Tab = initialTabState,
  action: TabActionTypes
): Tab => {
  switch (action.type) {
    case ActionTypes.setTab:
      return {
        ...state,
        currentTab: action.payload,
      };
    default:
      return state;
  }
};
