import {
  DIAGNOSES_DIAMETER_MM_ID,
  DIAGNOSES_LOCATION_ID,
  DIAGNOSES_MORPHOLOGY_ID,
  DIAGNOSES_RESULT_ID,
} from '../constants/disgnosesTabel';

export interface IDiagnoses {
  [DIAGNOSES_MORPHOLOGY_ID]: number | null;
  [DIAGNOSES_LOCATION_ID]: number | null;
  [DIAGNOSES_DIAMETER_MM_ID]: number | null;
  [DIAGNOSES_RESULT_ID]: number;
}

export const DiagnosesInitial: IDiagnoses = {
  [DIAGNOSES_MORPHOLOGY_ID]: null,
  [DIAGNOSES_LOCATION_ID]: null,
  [DIAGNOSES_DIAMETER_MM_ID]: null,
  [DIAGNOSES_RESULT_ID]: 0,
};
