export const ROLE_IDS = 'roleIds';
export const MODIFIED_AT = 'modifiedAt';
export const MODIFIED_BY = 'modifiedBy';
export const MUNICIPALITY_GROUP_IDS = 'municipalityGroupIds';
export const MUNICIPALITY_IDS = 'municipalityIds';
export const NAME_ID_HAG = 'nameIdHag';
export const PERMISSION_STATUS = 'permissionStatus';
export const START_DATE = 'startDate';
export const END_DATE = 'endDate';
export const USER_ID = 'userId';
export const COMMENT = 'comment';
export const EMAIL = 'email';
export const CREATED_AT = 'createdAt';
export const IS_DELETED = 'isDeleted';
export const IS_DISABLED = 'isDisabled';
export const PHONE_NUMBER = 'phonenumber';
export const ORGANIZATION = 'organization';
export const EXPIRED_TEXT = 'expiredText';
