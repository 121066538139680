import moment from 'moment';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ROLE_IDS, USER_ID } from '../../constants/adminTabel';
import { rolesAllowedPages } from '../../constantsLists/lists';
import { RolesId } from '../../models/IUser';
import LoggedInUser from '../../models/LoggedInUser';
import Loading from '../../pages/loading/loading';
import { RootState } from '../../state/rootReducer';
import { loginUser, LogoutUser } from '../../state/user/actions';
import { langPath } from '../../utils/pathUtils';
import userManager from '../../utils/userManager';

export interface IAuthGuardProps extends RouteProps {
  allowedRoles: RolesId[];
  component: React.FC<any>;
}

const AuthGuard: React.FC<IAuthGuardProps> = ({
  allowedRoles,
  component: Component,
  ...rest
}) => {
  const [loggedIn, setLoggedIn] = useState(LoggedInUser.UNKNOWN);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { screening, user } = useSelector((state: RootState) => state);

  useEffect(() => {
    let subscribe = true;
    async function fetchData() {
      const userDetails = await userManager.getUser();
      if (!subscribe) return;
      if (userDetails === null) {
        return userManager.signinRedirect({
          resource: process.env.REACT_APP_OIDC_RESOURCE,
          state: {
            redirectPath: window.location.pathname,
          },
        });
      }
      if (moment(userDetails.expires_at * 1000).diff(moment()) < 0) {
        toast.error(t('errors.userAuthExp'));
        return dispatch(LogoutUser());
      } else {
        setLoggedIn(LoggedInUser.YES);
        dispatch(loginUser(t));
      }
    }
    fetchData();
    return () => {
      subscribe = false;
    };
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (loggedIn === LoggedInUser.UNKNOWN) return <div />;
        if (
          user[USER_ID] &&
          (!user[ROLE_IDS] || user[ROLE_IDS]?.length === 0)
        ) {
          toast.error(t('errors.noRoles'));
          return <div />;
        }

        if (
          loggedIn === LoggedInUser.YES &&
          user[USER_ID] &&
          !screening.hasLocations
        )
          return <Loading />;
        if (
          user[USER_ID] &&
          !allowedRoles?.every((el) => user[ROLE_IDS]?.includes(el))
        ) {
          const firstAllowedRole = user[ROLE_IDS]?.[0];
          return (
            <Redirect
              to={langPath(
                firstAllowedRole ? rolesAllowedPages[firstAllowedRole] : '/'
              )}
            />
          );
        }
        if (loggedIn === LoggedInUser.YES && screening.hasLocations)
          return <Component {...props} />;
        if (loggedIn === LoggedInUser.NO)
          return <Redirect to={langPath('/signin-oidc')} />;
      }}
    />
  );
};

export default AuthGuard;
