import { UserManagerSettings } from 'oidc-client';
import { createUserManager } from 'redux-oidc';
import getServerName from './getServerName';

const userManagerSettings: UserManagerSettings = {
  authority: process.env.REACT_APP_OIDC_AUTHORITY,
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
  client_secret: process.env.REACT_APP_CLIENT_SECRET,
  redirect_uri: `https://${getServerName()}/signin-oidc`,
  post_logout_redirect_uri: `https://${getServerName()}/signout-unauthorized`,
  response_type: 'code',
  scope: process.env.REACT_APP_OIDC_CLIENT_SCOPE,
  filterProtocolClaims: false,
  loadUserInfo: false,
  monitorSession: false,
};

const userManager = createUserManager(userManagerSettings);

export default userManager;
