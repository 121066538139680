import { initialUserForm } from '../../models/IUser';
import { ActionTypes, UsersActionTypes } from './actions';
import { CurrentUser } from './state';

export const initialUsersState: CurrentUser = {
  isLoggedIn: false,

  ...initialUserForm,
};

export const UserReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: CurrentUser = initialUsersState,
  action: UsersActionTypes
): CurrentUser => {
  switch (action.type) {
    case ActionTypes.LogoutUser:
    case ActionTypes.LoginUser:
      return {
        ...state,
        ...action.payLoad,
      };

    default:
      return state;
  }
};
