import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  ASSAY_ABOVE,
  INTERPRETABILITY_BLOCKERS,
  RESULT_PLACE,
  SAMPLE_INTERPRETABILITY,
  TEST_RESULT_POSITIVE,
  TEST_VALUE,
} from '../../constants/analysisResultFromKeys';
import { ANALYSIS_DATE } from '../../constants/commonKeys';
import { RootState } from '../../state/rootReducer';
import { Showresult } from '../../utils/resultHelper';
import AsyncDropdown from '../asyncDropdown/asyncDropdown';
import DatePickerLabel from '../formInputs/datePickerLabel';
import DropdownFormik from '../formInputs/dropdownFormik';
import TextInputLabel from '../formInputs/textInputLabel';
import styles from '../personTabContainer/tabs.module.scss';

const Analysis: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { screening } = useSelector((state: RootState) => state);

  return (
    <Formik
      initialValues={screening.analysisAndResult}
      enableReinitialize={true}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={() => {}}
    >
      {({ values }) => (
        <Form>
          {/* ROW 1 */}
          <div className="container__row">
            <div className="grid-4-1st">
              <DatePickerLabel
                src={`analysisResultForm.${ANALYSIS_DATE}`}
                className="width-inherit"
                disabled
              />
            </div>
            <div className="grid-4-2nd">
              <AsyncDropdown
                defaultOptions={[]}
                src={`analysisResultForm.${RESULT_PLACE}`}
                store={1}
                disabled
              />
            </div>
          </div>

          {/* ROW 2 */}
          <div className="container__row">
            <div className="grid-4-1st">
              <DropdownFormik
                src={`analysisResultForm.${SAMPLE_INTERPRETABILITY}`}
                dropDown={screening[SAMPLE_INTERPRETABILITY]}
                disabled
              />
            </div>
            <div className="grid-2-2nd">
              <DropdownFormik
                src={`analysisResultForm.${INTERPRETABILITY_BLOCKERS}`}
                dropDown={screening[INTERPRETABILITY_BLOCKERS]}
                disabled
                isMulti={true}
              />
            </div>
            {/* ROW 3 */}
            <div className="container__row">
              <div className="grid-4-1st">
                <TextInputLabel
                  src={`analysisResultForm.${TEST_VALUE}`}
                  disabled
                  value={values[TEST_VALUE] || ''}
                />
              </div>
              <div className={`grid-4-2nd ${styles.checkBoxContainer}`}>
                <input
                  type="checkbox"
                  name={ASSAY_ABOVE}
                  disabled
                  checked={values[ASSAY_ABOVE]}
                />
                <label htmlFor={ASSAY_ABOVE}>
                  {t(`analysisResultForm.${ASSAY_ABOVE}`)}
                </label>
              </div>
              <div className="grid-4-3rd">
                <TextInputLabel
                  src={`analysisResultForm.${TEST_RESULT_POSITIVE}`}
                  disabled
                  value={Showresult(values[TEST_RESULT_POSITIVE], t)}
                />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Analysis;
