import {
  INTERPRETABILITY_BLOCKERS,
  SAMPLE_INTERPRETABILITY,
} from '../../constants/analysisResultFromKeys';
import {
  CLASSIFOCATION_ID,
  TTGRAPHY_FINDING_IDS,
  MUTAIION_LOCATION_ID,
  OTHER_CLASSIFOCATION_ID,
} from '../../constants/ttgraphyFromKeys';
import {
  BOWEL_EMPTY_SUCCESS_ID,
  SCOPY_FINDING_IDS,
  SCOPY_LIMITATION_IDS,
  SCOPY_NOT_PERFORMED_REASON,
  SCOPY_PROCEDURE_IDS,
  SCOPY_REACHED_ID,
  COMPLICATION_IDS,
} from '../../constants/scopyForm';
import {
  ALLOWED_HANDLER_LIST,
  HANDLER_LIST,
  LARGEST_POLY_DIAMETER_ID,
  MUNICIPALITY_ID,
  NUMBER_POLYP_ID,
  SAMPLE_DATE,
  SCREENING_CODE,
  SCREENING_ID,
  SCREENING_YEAR,
  STATUS_LIST,
} from '../../constants/commonKeys';
import {
  DIAGNOSES_DIAMETER_MM_ID,
  DIAGNOSES_LOCATION_ID,
  DIAGNOSES_MORPHOLOGY_ID,
} from '../../constants/disgnosesTabel';
import { FOLLOW_UP_DECISION_ID } from '../../constants/followUp';
import {
  DRINKING_AMOUNT_ID,
  DRINKING_FREQUENCY_ID,
  DRINKING_SIX_FREQUENCY_ID,
  HAS_RELATIVE_INTESTIONAL_CANCER,
  LONG_TERM_ILNESS_IDS,
  REGULAR_MEDICINE_IDS,
  RELATIVE_INTESTIONAL_CANCER_WHO,
} from '../../constants/preliminaryFormKeys';
import {
  CANCER_PTNM_GRADUS_ID,
  CANCER_PTNM_M_ID,
  CANCER_PTNM_N_ID,
  CANCER_PTNM_T_ID,
  SURGICAL_PROCEDURE_PRIMARY_ID,
} from '../../constants/surgicalProcedureFormKeys';
import { Forms, ScreeningState } from '../../state/screening/state';
import { Tabs } from '../../state/tabs/state';
import { needDownload } from '../../utils/isTableReady';
import { formsQueryList } from './formsQueryHelper';
import Analysis from '../../features/analysisResultTab/analysis';

export const formQuery = (
  screeningstate: ScreeningState,
  screeningId: number,
  tab: Tabs
) => {
  const analysis = tab === Tabs.AnalysisResult,
    preliminary = tab === Tabs.Preliminary,
    colonoscopy = tab === Tabs.Scopy,
    colonography = tab === Tabs.TTGraphy,
    surgical = tab === Tabs.SurgicalProcedure,
    person = screeningstate.person.screeningCode === 0;

  return `
query Fetch${tab}Data {
  screening(screeningId: ${screeningId}) {

    ${
      preliminary
        ? `preliminaryInfoModifiedBy
        preliminaryInfoModifiedAt`
        : ''
    }
    ${
      analysis
        ? `analysisAndResultModifiedBy
        analysisAndResultModifiedAt`
        : ''
    }
    ${
      colonoscopy
        ? `colonoscopyModifiedBy
        colonoscopyModifiedAt`
        : ''
    }
    ${
      colonography
        ? `ttColonographyModifiedBy
        ttColonographyModifiedAt`
        : ''
    }
    ${
      surgical
        ? `surgicalProcedureModifiedBy
        surgicalProcedureModifiedAt`
        : ''
    }
    
    
    ${needDownload(
      screeningstate,
      'personScreenings',
      `personScreenings {
      ${SCREENING_CODE}
      ${SCREENING_ID}
    }`,
      true
    )}
    
    ${`${Forms.SCREENING_LOCK}:lockInfo {
      ${formsQueryList[Forms.SCREENING_LOCK]}
    }`}

    ${`${Forms.PERSON_LOCK} {
      ${formsQueryList[Forms.PERSON_LOCK]}
    }`}

    ${Tabs.TTGraphy}:isTtColonographyEnabled,
    ${Tabs.SurgicalProcedure}:isSurgeryOrProcedureEnabled,
    ${Tabs.Scopy}:isColonoscopyEnabled,
    isLatestScreening

    ${
      person
        ? `${Forms.PERSON} {
      ${formsQueryList[Forms.PERSON]}
    }
    ${SCREENING_CODE}
    `
        : ''
    }

    ${
      analysis
        ? `${Forms.ANALYSIS_RESULT} {
      ${formsQueryList[Forms.ANALYSIS_RESULT]}
    }
    ${Forms.SCOPY} {
      ${formsQueryList.followUp}
    }    
    `
        : ''
    }

    ${
      preliminary
        ? `${Forms.PRELIMINARY} {
      ${formsQueryList[Forms.PRELIMINARY]}
    }
    ${SCREENING_YEAR}: year
    ${SAMPLE_DATE}
    `
        : ''
    }
    ${
      colonoscopy
        ? `${Forms.SCOPY} {
      ${formsQueryList[Forms.SCOPY]}
      
    }
    `
        : ''
    }
    ${
      colonography
        ? `${Forms.TTGRAPHY} {
      ${formsQueryList[Forms.TTGRAPHY]}
    }`
        : ''
    }
    ${
      surgical
        ? `${Forms.SURGICAL_PROCEDURE} {
        ${formsQueryList[Forms.SURGICAL_PROCEDURE]}
      }`
        : ''
    }
    
    ${Forms.STATUS}
    ${Forms.HANDLER}
  }
  ${needDownload(screeningstate, STATUS_LIST, 'SCREENING_PROCESS_STATUS')} 

  
  ${ALLOWED_HANDLER_LIST}: allowedScreeningHandlers (screeningId:${screeningId}){
      langValue: firstName
      label:lastName
      value: userId
  }



  ${
    preliminary
      ? `${needDownload(
          screeningstate,
          MUNICIPALITY_ID,
          `municipalities {
    value: municipalityId
    langValue: code
  }`,
          true
        )} 
  ${needDownload(screeningstate, LONG_TERM_ILNESS_IDS, 'LONG_TERM_ILLNESS')} 
  ${needDownload(
    screeningstate,
    HAS_RELATIVE_INTESTIONAL_CANCER,
    'HAS_RELATIVE_INTESTINAL_CANCER'
  )} 
  ${needDownload(
    screeningstate,
    RELATIVE_INTESTIONAL_CANCER_WHO,
    'RELATIVE_WITH_INTESTINAL_CANCER'
  )} 
  ${needDownload(screeningstate, REGULAR_MEDICINE_IDS, 'REGULAR_MEDICATION')} 
  ${needDownload(screeningstate, DRINKING_AMOUNT_ID, 'DRINKING_AMOUNT')} 
  ${needDownload(screeningstate, DRINKING_FREQUENCY_ID, 'DRINKING_FREQUENCY')} 
  ${needDownload(
    screeningstate,
    DRINKING_SIX_FREQUENCY_ID,
    'DRINKING_FREQUENCY_SIX_OR_MORE'
  )}`
      : ''
  }

  ${
    analysis
      ? `${needDownload(
          screeningstate,
          INTERPRETABILITY_BLOCKERS,
          'SAMPLE_INTERPRETABILITY_BLOCKER'
        )} 
      ${needDownload(
        screeningstate,
        SAMPLE_INTERPRETABILITY,
        'SAMPLE_INTERPRETABILITY'
      )}
      ${needDownload(
        screeningstate,
        SCOPY_NOT_PERFORMED_REASON,
        'COLONOSCOPY_NOT_DONE_REASON'
      )}       
      `
      : ''
  }
      
      
      ${
        colonoscopy
          ? `
        ${needDownload(
          screeningstate,
          SCOPY_NOT_PERFORMED_REASON,
          'COLONOSCOPY_NOT_DONE_REASON'
        )} 
        ${needDownload(
          screeningstate,
          BOWEL_EMPTY_SUCCESS_ID,
          'BOWEL_EMPTYING_SUCCESS'
        )} 
        ${needDownload(screeningstate, SCOPY_REACHED_ID, 'COLONOSCOPY_REACH')} 
        ${needDownload(
          screeningstate,
          SCOPY_LIMITATION_IDS,
          'COLONOSCOPY_LIMITATION'
        )} 
        ${needDownload(
          screeningstate,
          SCOPY_FINDING_IDS,
          'COLONOSCOPY_FINDING'
        )} 
        ${needDownload(screeningstate, NUMBER_POLYP_ID, 'NUMBER_OF_POLYPS')} 
        ${needDownload(
          screeningstate,
          COMPLICATION_IDS,
          'COLONOSCOPY_COMPLICATION'
        )} 
        
        ${needDownload(
          screeningstate,
          FOLLOW_UP_DECISION_ID,
          'FOLLOW_UP_PROCEDURE'
        )} 
        ${needDownload(
          screeningstate,
          SCOPY_PROCEDURE_IDS,
          'COLONOSCOPY_PROCEDURE'
        )} 
        ${needDownload(screeningstate, DIAGNOSES_LOCATION_ID, 'TOPOLOGY')} 
        ${needDownload(
          screeningstate,
          DIAGNOSES_MORPHOLOGY_ID,
          'DIAGNOSIS',
          false,
          'databaseId'
        )} 
        ${needDownload(
          screeningstate,
          DIAGNOSES_DIAMETER_MM_ID,
          'LARGEST_POLYP_DIAMETER_MM'
        )} 
          
          `
          : ''
      }
        
        ${
          colonography
            ? ` 
          ${needDownload(screeningstate, NUMBER_POLYP_ID, 'NUMBER_OF_POLYPS')} 
          ${needDownload(
            screeningstate,
            FOLLOW_UP_DECISION_ID,
            'FOLLOW_UP_PROCEDURE'
          )}
          ${needDownload(
            screeningstate,
            LARGEST_POLY_DIAMETER_ID,
            'LARGEST_POLYP_DIAMETER_TT_COLONOGRAPHY_MM'
          )}            
          ${needDownload(
            screeningstate,
            MUTAIION_LOCATION_ID,
            'MUTATION_LOCATION'
          )} 
          ${needDownload(
            screeningstate,
            TTGRAPHY_FINDING_IDS,
            'TT_COLONOGRAPHY_FINDING'
          )} 
          ${needDownload(
            screeningstate,
            CLASSIFOCATION_ID,
            'COLON_FINDINGS_CLASSIFICATION'
          )} 
          ${needDownload(
            screeningstate,
            OTHER_CLASSIFOCATION_ID,
            'OTHER_FINDINGS_CLASSIFICATION'
          )} 
          `
            : ''
        }
        
        ${
          surgical
            ? `
            ${needDownload(
              screeningstate,
              SURGICAL_PROCEDURE_PRIMARY_ID,
              'SURGICAL_PROCEDURE_AS_PRIMARY_TREATMENT'
            )} 
            ${needDownload(screeningstate, CANCER_PTNM_M_ID, 'CANCER_PTNM_M')} 
            ${needDownload(screeningstate, CANCER_PTNM_N_ID, 'CANCER_PTNM_N')} 
            ${needDownload(screeningstate, CANCER_PTNM_T_ID, 'CANCER_PTNM_T')} 
            ${needDownload(
              screeningstate,
              CANCER_PTNM_GRADUS_ID,
              'CANCER_PTNM_GRADUS'
            )} 
            ${needDownload(screeningstate, DIAGNOSES_LOCATION_ID, 'TOPOLOGY')} 
            ${needDownload(
              screeningstate,
              DIAGNOSES_MORPHOLOGY_ID,
              'DIAGNOSIS',
              false,
              'databaseId'
            )} 
            ${needDownload(
              screeningstate,
              DIAGNOSES_DIAMETER_MM_ID,
              'LARGEST_POLYP_DIAMETER_MM'
            )}
            `
            : ''
        }
  
}`;
};
