import React from 'react';
import Layout from '../../features/layout/layout';
import PersonForm from '../../features/personForm/personForm';
import TabSelector from '../../features/personTabContainer/tabsComponents';
import TabSelectorControl from '../../features/tabSelectorControl/tabSelectorControl';
import styles from './personInfo.module.scss';

const PersonInfo: React.FC = () => {
  return (
    <Layout>
      <PersonForm />
      <TabSelectorControl />
      <div className={styles.tabContainer}>
        <TabSelector />
      </div>
    </Layout>
  );
};

export default PersonInfo;
