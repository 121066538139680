import React from 'react';
import styles from './tooltipLabel.module.scss';
import { useTranslation } from 'react-i18next';
import TooltipIconComponent from '../tooltipComponent/tooltipIconComponent';

interface ILabelProps {
  label: string;
  name: string;
  labelClassName?: string;
  required?: boolean;
}

const TooltipLabel: React.FC<ILabelProps> = ({
  label,
  required,
  name,

  labelClassName,
}) => {
  const { t } = useTranslation();

  return (
    <label
      id={`${name}-label`}
      className={`label_text ${labelClassName ?? ''} ${
        required ? styles.required : ''
      }`}
      htmlFor={name}
    >
      {t(`${label}.${name}`)} <TooltipIconComponent src={label} name={name} />
    </label>
  );
};
export default TooltipLabel;
