import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hasTranslation } from '../../utils/hasTranzlation';
import ComponentLocationService from '../../services/componentLocationService';
import styles from './tooltipIconComponent.module.scss';

interface ITooltipIconComponentProps {
  src: string;
  name: string;
}

const TooltipIconComponent: React.FC<ITooltipIconComponentProps> = ({
  src,
  name,
}) => {
  const { t } = useTranslation();

  const [side, setSide] = useState(styles.leftBox);
  useEffect(() => {
    if (hasTranslation(`${src}.info.${name}`)) {
      const locationService = ComponentLocationService(`#${name}-label`);
      setSide(locationService.isLeft() ? styles.leftBox : styles.rightBox);
    }
  }, []);

  return hasTranslation(`${src}.info.${name}`) ? (
    <div className={styles.tooltip}>
      <FontAwesomeIcon className={styles.info} icon={faInfoCircle} />
      <div
        className={`${styles.tooltiptext} ${side}`}
        dangerouslySetInnerHTML={{
          __html: t(`${src}.info.${name}`),
        }}
      />
    </div>
  ) : null;
};

export default TooltipIconComponent;
