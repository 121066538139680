import { TFunction } from 'i18next';
import {
  NEGATIVE,
  NO_REPLY,
  POSITIVE,
  TEST_RESULT_POSITIVE,
} from '../constants/analysisResultFromKeys';

export const Showresult = (result: boolean | null, t: TFunction) => {
  if (result === true) {
    return t(`${TEST_RESULT_POSITIVE}.POSITIVE`);
  }
  if (result === false) {
    return t(`${TEST_RESULT_POSITIVE}.NEGATIVE`);
  }
  return t(`${TEST_RESULT_POSITIVE}.NO_REPLY`);
};

type Result = typeof POSITIVE | typeof NEGATIVE | typeof NO_REPLY | 'DEFAULT';

export const findResultQueryKey = (v: number) => {
  if (v === 0) {
    return 'DEFAULT';
  }
  if (v === 1) {
    return NO_REPLY;
  }
  if (v === 2) {
    return POSITIVE;
  }
  if (v === 3) {
    return NEGATIVE;
  }
};
