import { IOptionWithSub } from '../components/municipalityselect/selectTasksCheckboxes';

export const municipalitiesGroupHelper = (
  // selected
  value: IOptionWithSub[],
  // allOptions
  munGroup: IOptionWithSub[]
) => {
  let newSelectedOptions: IOptionWithSub[] = munGroup,
    newSelectedValues: IOptionWithSub[] = value.filter(
      (id, index, self) => index === self.findIndex((t) => t.value === id.value)
    );
  const newSelectedValueIds: number[] = value.map((el) => +el.value);

  munGroup
    .filter((grou) => grou.hasSub && !!grou.subOption)
    .forEach((group) => {
      const valueNum = group.subOption?.map((sub) => +sub.value);
      if (valueNum?.every((num) => newSelectedValueIds.includes(num))) {
        newSelectedValues = [
          group,
          ...newSelectedValues.filter((el) => !valueNum.includes(+el.value)),
        ];
      } else {
        newSelectedOptions = newSelectedOptions.filter(
          (opti) => +opti.value !== +group.value
        );
        newSelectedValues = newSelectedValues.filter(
          (el) => +el !== +group.value
        );
      }
    });

  return {
    newSelectedOptions,
    newSelectedValues,
  };
};

export const changeToValue = (
  option: IOptionWithSub[],
  value: number[] | null
) => {
  if (!value) return [];
  let newVal: number[] = [];
  const groups = option.filter((el) => el.hasSub);
  const groupIds = groups.map((el) => +el.value);
  value.forEach((el) => {
    if (groupIds.includes(el)) {
      const theOne = groups
        .find((grou) => +grou.value === el)
        ?.subOption?.map((sub) => +sub.value);
      if (!theOne) return;
      newVal = [...theOne, ...newVal];
    } else {
      newVal.push(el);
    }
  });
  return newVal;
};
