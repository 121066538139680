export const flattenObj = (
  ob: any,
  excludes: string[] = [],
  result: any = {}
) => {
  for (const i in ob) {
    if (
      typeof ob[i] === 'object' &&
      !excludes.includes(i) &&
      !Array.isArray(ob[i])
    ) {
      flattenObj(ob[i], excludes, result);
    } else if (excludes.includes(i) && !ob[i]) {
      result[i] = {};
    } else {
      result[i] = ob[i];
    }
  }
  return result;
};
