import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import closeIcon from '../../assets/images/close_red.svg';
import saveIcon from '../../assets/images/save.svg';
import Dropdown from '../../components/dropdown/dropdown';
import { ScreeningHandler } from '../../components/ScreeningHandler/screeningHandler';
import { ScreeningStatus } from '../../components/screeningStatus/screeningStatus';
import {
  COMMENT,
  COULD_NOT_BE_REACHED_AT,
  FIRST_NAMES,
  SCREENING_CODE,
  SCREENING_ID,
  SSN,
  SURNAME,
} from '../../constants/commonKeys';
import { MOTHER_LANG, PERSON_ID, PHONE } from '../../constants/userInfoKeys';
import { languageOptions } from '../../constantsLists/lists';
import { validationNumberValues } from '../../constantsLists/validationLists';
import { personTableInitial } from '../../models/IPersonTable';
import { IPersonForm } from '../../models/IUSerInfo';
import { formsMutationList } from '../../services/queries/formsQueryHelper';
import {
  userLockQuery,
  userUnlockQuery,
} from '../../services/queries/userFormquery';
import { RootState } from '../../state/rootReducer';
import {
  setDefaultScreening,
  setScreeningId,
  setSubmitForm,
} from '../../state/screening/actions';
import { Forms } from '../../state/screening/state';
import DatePickerLabel from '../formInputs/datePickerLabel';
import TextInputLabel from '../formInputs/textInputLabel';
import styles from './personForm.module.scss';
import { personFormSchema, submitPersonForm } from './personHelper';

const PersonForm: React.FC = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { screening } = useSelector((state: RootState) => state);
  const history = useHistory();
  const { screeningId } = useParams<{ screeningId: string }>();

  const personSchema = personFormSchema(t);

  useEffect(() => {
    if (!+screeningId) {
      toast.error(t('errors.screeningId'));
      history.push('/');
    } else dispatch(setScreeningId(+screeningId));
    return () => {
      dispatch(setDefaultScreening());
    };
  }, [window.location.pathname]);

  const editTab = async () => {
    if (screening[Forms.PERSON][PERSON_ID]) {
      dispatch(
        setSubmitForm(
          Forms.PERSON_LOCK,
          userLockQuery(screening[Forms.PERSON][PERSON_ID]!),
          t
        )
      );
    }
  };

  const options = screening.personScreenings.map((el) => ({
    label: el.screeningCode,
    value: '' + el.screeningId,
  }));

  return (
    <Formik
      enableReinitialize={true}
      initialValues={screening[Forms.PERSON]}
      validateOnBlur={false}
      validationSchema={personSchema}
      validateOnChange={true}
      validateOnMount={false}
      onSubmit={(v, { resetForm }) =>
        submitPersonForm(v, resetForm, dispatch, t)
      }
    >
      {({ submitForm, resetForm, values }) => {
        const edit = screening[Forms.PERSON_LOCK].isLockedByCurrentUser;
        return (
          <Form>
            <div>
              <div className={`${styles.headTitle}`}>
                {t('header.screenedPerson')}
              </div>
              <div className="space-between container__row col-9">
                <div className="grid-4-1st">
                  <TextInputLabel
                    inputClassName={styles.ssn}
                    src={`personFrom.${SSN}`}
                    disabled
                  />
                </div>
                <div className="grid-4-2nd">
                  <label className="label_text ">
                    {t(`personFrom.${SCREENING_CODE}`)}
                  </label>
                  <Dropdown
                    value={options.find(
                      (el) => el.value === '' + screening[SCREENING_ID]
                    )}
                    onChange={(val) => {
                      if (
                        Array.isArray(val) ||
                        val.value === '' + screening[SCREENING_ID]
                      ) {
                        return;
                      }
                      history.push(`/screening/${val.value}`);
                    }}
                    clearOption={false}
                    options={options}
                    name={SCREENING_CODE}
                  />
                </div>
                <div className="grid-4-3rd">
                  <ScreeningStatus />
                </div>
                <div className="grid-4-4th">
                  <ScreeningHandler />
                </div>
              </div>
            </div>
            <div className="col-9 inline-flex">
              <div className="space-between container__row ">
                <div className="grid-4-1st">
                  <TextInputLabel
                    src={`personFrom.${FIRST_NAMES}`}
                    disabled={!edit}
                    required
                  />
                </div>
                <div className="grid-4-2nd">
                  <TextInputLabel
                    src={`personFrom.${SURNAME}`}
                    disabled={!edit}
                    required
                  />
                </div>

                <div className="grid-4-3rd">
                  <label className="label_text ">
                    {t(`personFrom.${MOTHER_LANG}`)}
                  </label>
                  <Dropdown
                    name={MOTHER_LANG}
                    options={languageOptions(t)}
                    value={languageOptions(t).find(
                      (x) =>
                        x.value.toLocaleLowerCase() ===
                        values[MOTHER_LANG]?.toLocaleLowerCase()
                    )}
                    onChange={(val) => (!Array.isArray(val) ? val.value : '')}
                    disabled={!edit}
                  />
                </div>
                <div
                  className={`grid-4-4th ${styles.phoneNumberAndNotReachedBlock}`}
                >
                  <div>
                    <TextInputLabel
                      src={`personFrom.${PHONE}`}
                      disabled={!edit}
                    />
                  </div>
                  <div>
                    <DatePickerLabel
                      src={`personFrom.${COULD_NOT_BE_REACHED_AT}`}
                      disabled={!edit}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-9 inline-flex">
              <div className="space-between container__row ">
                <div className="container__col-12">
                  <TextInputLabel
                    src={`personFrom.${COMMENT}`}
                    disabled={!edit}
                  />
                </div>
              </div>
            </div>
            <div className="inline-flex col-1">
              {edit ? (
                <div className={styles.control}>
                  <div
                    onClick={() => {
                      if (screening[Forms.PERSON][PERSON_ID]) {
                        dispatch(
                          setSubmitForm(
                            Forms.PERSON_LOCK,
                            userUnlockQuery(
                              screening[Forms.PERSON][PERSON_ID]!
                            ),
                            t
                          )
                        );
                        resetForm({ values: screening[Forms.PERSON] });
                      }
                    }}
                  >
                    <img src={closeIcon} alt="close" />
                  </div>
                  <div onClick={submitForm}>
                    <img src={saveIcon} alt="submit" />
                  </div>
                </div>
              ) : (
                <div onClick={() => editTab()} className={styles.editIcom}>
                  <FontAwesomeIcon icon={faPencilAlt} />
                </div>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PersonForm;
