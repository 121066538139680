import { Action } from 'redux';
import { SearchKeys } from './state';

export enum ActionTypes {
  setSearchKeys = 'SET_SEARCH_KEYS',
}

interface SetSearchAction extends Action {
  type: ActionTypes.setSearchKeys;
  payload: SearchKeys;
}

export type TabActionTypes = SetSearchAction;

export const setSearchKeys = (value: Partial<SearchKeys>) => ({
  type: ActionTypes.setSearchKeys,
  payload: value,
});
