import React from 'react';
import Layout from '../../features/layout/layout';
import ReportContent from '../../features/report/report';

const ScreeningTable: React.FC = () => {
  return (
    <Layout>
      <ReportContent />
    </Layout>
  );
};

export default ScreeningTable;
