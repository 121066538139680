import { useField } from 'formik';
import React from 'react';
import TooltipLabel from '../tooltipLabel/tooltipLabel';
import styles from './formInput.module.scss';

interface IInputWithTitleProps {
  name: string;
  inputClassName?: string;
  disabled?: boolean;
  maxLength?: number;
  inputMode?:
    | 'text'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
    | undefined;
  errorClassName?: string;
  hideErros?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  value?: any | null;
}

const Inputformik: React.FC<IInputWithTitleProps> = ({
  name,
  inputClassName,
  disabled,
  maxLength,
  inputMode,
  errorClassName,
  hideErros,
  onChange,
  onBlur,
  value,
}) => {
  const [field, meta] = useField(name);
  field.onChange = onChange || field.onChange;
  field.onBlur = onBlur || field.onBlur;

  return (
    <>
      <input
        data-testid={name}
        id={name}
        className={`${styles.title_input} ${inputClassName ?? ''}`}
        type="text"
        checked={field.checked}
        onChange={field.onChange}
        value={value ?? `${field.value ?? ''}`}
        onBlur={field.onBlur}
        name={name}
        disabled={disabled}
        maxLength={maxLength}
        inputMode={inputMode}
      />
      {meta.error && !hideErros ? (
        <div className={`${styles.error} ${errorClassName}`}>{meta.error}</div>
      ) : null}
    </>
  );
};

Inputformik.defaultProps = {
  inputClassName: '',
  disabled: false,
  maxLength: undefined,
  inputMode: undefined,
  errorClassName: '',
  hideErros: false,
  onChange: undefined,
  onBlur: undefined,
  value: null,
};

export default Inputformik;
