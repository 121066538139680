import React from 'react';
import RouterComponent from './routes';
import './assets/styles/common.scss';
import './App.css';
import { toast } from 'react-toastify';
import IdleTimer from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { LogoutUser } from './state/user/actions';
import { useTranslation } from 'react-i18next';

const App: React.FC = () => {
  const timeoutLogOut = 1000 * 60 * 30;
  const timeoutWarning = timeoutLogOut - 1000 * 60 * 10;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  toast.configure({
    autoClose: 4000,
    draggable: false,
  });

  return (
    <div className="App">
      <IdleTimer
        timeout={timeoutLogOut}
        onIdle={() => dispatch(LogoutUser())}
      />
      <IdleTimer
        timeout={timeoutWarning}
        onIdle={() => toast.warning(t('warnings.inactiveLogout'))}
      />
      <RouterComponent />
    </div>
  );
};

export default App;
