import React from 'react';
import Select from 'react-select';
import { useField } from 'formik';
import styles from './dropdown.module.scss';
import { useTranslation } from 'react-i18next';
import { IOption } from '../../models/IOption';
import TooltipLabel from '../tooltipLabel/tooltipLabel';

interface IDropdownProps {
  name: string;
  options: IOption[];
  placeholder?: string;
  classNamePrefix?: string;
  onChange?: (val: IOption | IOption[]) => void;
  value?: IOption | IOption[];
  manualChange?: boolean;
  disabled?: boolean;
  isMulti?: boolean;
  clearOption?: boolean;
  hideErros?: boolean;
  errorClassName?: string;
}

const Dropdown: React.FC<IDropdownProps> = ({
  name,
  options,
  placeholder,
  disabled,
  errorClassName,
  classNamePrefix,
  clearOption,
  hideErros,
  onChange,
  value,
  isMulti,
}) => {
  const [field, form, meta] = useField(name);
  const { t } = useTranslation();
  return (
    <>
      <Select
        styles={{
          control: (style) => ({ ...style, borderRadius: 'none' }),
        }}
        name={name}
        id={name}
        data-testid={name}
        options={options}
        onChange={(val: IOption | IOption[] | null) => {
          if (!val) {
            return meta.setValue(null);
          }
          if (onChange) {
            return meta.setValue(onChange(val));
          }
          return meta.setValue(val);
        }}
        onBlur={field.onBlur}
        value={value || field.value}
        isDisabled={disabled}
        isMulti={isMulti}
        isClearable={clearOption}
        placeholder={placeholder}
        classNamePrefix={classNamePrefix}
      />
      {form.error && !hideErros ? (
        <div className={`${styles.error} ${errorClassName}`}>{form.error}</div>
      ) : null}
    </>
  );
};

Dropdown.defaultProps = {
  classNamePrefix: '',
  placeholder: '',
  clearOption: true,
  onChange: undefined,
  manualChange: false,
  value: undefined,
};

export default Dropdown;
