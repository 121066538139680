import {
  IS_LOCKED,
  LOCKED_BY_CURRENT_USER,
  LOCKED_BY_NAME,
  LOCKED_UNTIL,
} from '../constants/lockKeys';

export interface ILock {
  [IS_LOCKED]: boolean;
  [LOCKED_BY_NAME]: string | null;
  [LOCKED_BY_CURRENT_USER]: boolean;
  [LOCKED_UNTIL]: string | null;
}

export const lockInitial: ILock = {
  [IS_LOCKED]: false,
  [LOCKED_BY_NAME]: null,
  [LOCKED_BY_CURRENT_USER]: false,
  [LOCKED_UNTIL]: null,
};
