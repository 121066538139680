import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { MUNICIPALITY_ID } from '../../constants/commonKeys';
import {
  DOES_SMOKE,
  DRINKING_AMOUNT_ID,
  DRINKING_SIX_FREQUENCY_ID,
  HAS_MEDICINE_SENSITIVITY,
  HAS_RELATIVE_INTESTIONAL_CANCER,
  HAS_SMOKED_DAILY,
  HEIGHT,
  MEDICINE_SENSITIVITY,
  RELATIVE_INTESTIONAL_CANCER_WHO,
  SMOKED_DAILY_AMOUNT_ID,
  SMOKED_DAILY_YEARS_ID,
  WEIGHT,
} from '../../constants/preliminaryFormKeys';
import { validationNumberValues } from '../../constantsLists/validationLists';

export const CreatePreConstants = () => {
  const { t } = useTranslation();

  const validation = Yup.object().shape({
    [HEIGHT]: Yup.number()
      .nullable()
      .max(
        validationNumberValues[HEIGHT].max,
        t('forms.mustBeNumberMax', {
          maxNumber: validationNumberValues[HEIGHT].max,
        })
      )
      .typeError(t('forms.mustBeNumber')),
    [WEIGHT]: Yup.number()
      .nullable()
      .max(
        validationNumberValues[WEIGHT].max,
        t('forms.mustBeNumberMax', {
          maxNumber: validationNumberValues[WEIGHT].max,
        })
      )
      .typeError(t('forms.mustBeNumber')),
    [MUNICIPALITY_ID]: Yup.number()
      .nullable()
      .typeError(t('forms.mustBeNumber')),
    [HAS_MEDICINE_SENSITIVITY]: Yup.boolean().nullable(),
    [MEDICINE_SENSITIVITY]: Yup.string()
      .max(
        validationNumberValues[MEDICINE_SENSITIVITY].max,
        t('forms.mustBeStringMax', {
          maxCharacter: validationNumberValues[MEDICINE_SENSITIVITY].max,
        })
      )
      .nullable(),
    [HAS_RELATIVE_INTESTIONAL_CANCER]: Yup.number().nullable(),
    [RELATIVE_INTESTIONAL_CANCER_WHO]: Yup.array().of(Yup.number()).nullable(),
    [DOES_SMOKE]: Yup.boolean().nullable(),
    [SMOKED_DAILY_YEARS_ID]: Yup.number()
      .nullable()
      .max(
        validationNumberValues[SMOKED_DAILY_YEARS_ID].max,
        t('forms.mustBeNumberMax', {
          maxNumber: validationNumberValues[SMOKED_DAILY_YEARS_ID].max,
        })
      )
      .typeError(t('forms.mustBeNumber')),

    [SMOKED_DAILY_AMOUNT_ID]: Yup.number()
      .nullable()
      .max(
        validationNumberValues[SMOKED_DAILY_AMOUNT_ID].max,
        t('forms.mustBeNumberMax', {
          maxNumber: validationNumberValues[SMOKED_DAILY_AMOUNT_ID].max,
        })
      )
      .typeError(t('forms.mustBeNumber')),

    [HAS_SMOKED_DAILY]: Yup.boolean().nullable(),
    [DRINKING_AMOUNT_ID]: Yup.number()
      .typeError(t('forms.mustBeNumber'))
      .nullable(),
    [DRINKING_SIX_FREQUENCY_ID]: Yup.number().nullable(),
  });
  return {
    validation,
  };
};
