import { TFunction } from 'react-i18next';
import icon_1 from '../assets/images/icon_start_process.svg';
import icon_2 from '../assets/images/icon_positive_lab_result.svg';
import icon_3 from '../assets/images/icon_prosecced_stop.svg';
import icon_4 from '../assets/images/icon_kolonoskopia.svg';
import icon_5 from '../assets/images/icon_tt_kolongrafia.svg';
import icon_6 from '../assets/images/icon_surgical.svg';
import icon_7 from '../assets/images/icon_success.svg';
import { IOption } from '../models/IOption';
import {
  CONTROL_ID,
  SAMPLE_DATE,
  SAMPLE_ID,
  SCREENING_CODE,
  SCREENING_ID,
  SCREENING_YEAR,
} from '../constants/commonKeys';
import { RolesId } from '../models/IUser';

export const languageOptions = (t: TFunction) => [
  // values should be low-case
  { label: t('languages.fi'), value: 'fi' },
  { label: t('languages.sv'), value: 'sv' },
  { label: t('languages.en'), value: 'en' },
  { label: t('languages.ru'), value: 'ru' },
  { label: t('languages.ku'), value: 'ku' },
  { label: t('languages.so'), value: 'so' },
  { label: t('languages.ar'), value: 'ar' },
];

export const yesOrNo: IOption[] = [
  { label: 'common.no', value: 'false', langValue: 'false' },
  { label: 'common.yes', value: 'true', langValue: 'true' },
];

export const iconsList: { [key: string]: string } = {
  INT1: icon_1,
  INT2: icon_1,
  INT3: icon_2,
  INT4: icon_7,
  INT6: icon_1,
  INT5: icon_3,
  INT7: icon_4,
  INT8: icon_4,
  INT9: icon_4,
  INT10: icon_4,
  INT11: icon_4,
  INT12: icon_5,
  INT13: icon_5,
  INT14: icon_5,
  INT15: icon_5,
  INT16: icon_5,
  INT17: icon_6,
  INT18: icon_6,
  INT19: icon_6,
  '0': icon_1,
  '2': icon_7,
  '4': icon_3,
  '5': icon_3,
  '6': icon_3,
  '7': icon_3,
};

// TODO GET RID OF THIS LISTS
export const decisionList: IOption[] = [
  { value: '6', langValue: 'doScopy' },
  { value: '3', langValue: 'doColonosgraphy' },
  { value: '1', langValue: 'noContinuation' },
];

export const excludeValues = [
  SCREENING_ID,
  SAMPLE_ID,
  SCREENING_YEAR,
  SCREENING_CODE,
  SAMPLE_DATE,
  CONTROL_ID,
];

export const rolesAllowedPages: { [key in RolesId]: string } = {
  1: '/',
  5: '/',
  2: '/orderKit',
  3: '/admin',
  4: '/report',
  6: '/pdfImport',
  7: '/pdfImport',
  8: '/pdfImport',
};
