import { IGetRowsParams } from 'ag-grid-community';
import axios from 'axios';
import { TFunction } from 'react-i18next';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { MUNICIPALITY_GROUP_IDS, ROLE_IDS } from '../constants/adminTabel';
import {
  HANDLER_LIST,
  MUNICIPALITY_ID,
  STATUS_LIST,
} from '../constants/commonKeys';
import { IOption } from '../models/IOption';
import { IUser } from '../models/IUser';
import { setScreeningData } from '../state/screening/actions';
import { ScreeningState } from '../state/screening/state';
import { redirectAuthFail } from '../utils/redirectAuthFail';
import userManager from '../utils/userManager';
import { searchUserQuery } from './queries/searchQueries';

export const FetchUsersList = async (
  t: TFunction,
  state: ScreeningState,
  maxPageNo: number,
  params: IGetRowsParams,
  dispatch: Dispatch<any>
): Promise<ISearchUsersData | null> => {
  const user = await userManager.getUser();
  if (!user) {
    redirectAuthFail(t);
    return null;
  }
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/graph`,
      {
        query: searchUserQuery(params, maxPageNo, state),
        operationName: 'FetchUsersList',
      },
      { headers: { Authorization: `${user.token_type} ${user.access_token}` } }
    );

    if (Array.isArray(res.data.errors)) {
      toast.error(t('errors.userSearch'));
      return null;
    }

    let screeningLists = {};
    if (res?.data?.data?.[ROLE_IDS]) {
      screeningLists = {
        ...screeningLists,
        [ROLE_IDS]: res?.data?.data?.[ROLE_IDS],
      };
    }
    if (res?.data?.data?.[MUNICIPALITY_ID]) {
      screeningLists = {
        ...screeningLists,
        [MUNICIPALITY_ID]: res?.data?.data?.[MUNICIPALITY_ID],
      };
    }
    if (res?.data?.data?.[MUNICIPALITY_GROUP_IDS]) {
      screeningLists = {
        ...screeningLists,
        [MUNICIPALITY_GROUP_IDS]: res?.data?.data?.[MUNICIPALITY_GROUP_IDS],
      };
    }
    if (Object.keys(screeningLists).length) {
      dispatch(setScreeningData(screeningLists));
    }

    return res?.data?.data;
  } catch (er) {
    toast.error(t('errors.userSearch'));
    return null;
  }
};

export interface ISearchUsersData {
  userSearch: { results: IUser[]; totalCount: number };
  [STATUS_LIST]: IOption[];
  [HANDLER_LIST]: IOption[];
}
