import { ISetFilterParams } from 'ag-grid-community';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import styles from './dateFilter.module.scss';

interface ITextProps extends ISetFilterParams {
  defaultVal: string;
  title: string;
}

export default forwardRef((props: ITextProps, ref) => {
  const { defaultVal, title } = props;
  const [keyWord, setKeyWord] = useState(defaultVal || '');
  const [keyWordFinal, setKeyWordFinal] = useState(defaultVal || '');
  const { t } = useTranslation();
  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return keyWord != null && keyWord !== '';
      },
      getModel() {
        if (keyWord === '') {
          return keyWord;
        }
        return moment(keyWord, 'DD.MM.YYYY').format('YYYY-MM-DD');
      },

      getModelAsString() {
        if (keyWord != null && keyWord !== '') {
          return keyWord;
        }
        return '';
      },
    };
  });

  useEffect(() => {
    props.filterChangedCallback();
  }, [keyWordFinal]);

  return (
    <div>
      {title ? <p>{title}</p> : null}
      <ReactDatePicker
        inline
        preventOpenOnFocus={false}
        dateFormat={'dd.mm.yyyy'}
        value={keyWord}
        onChange={(val: Date) => {
          setKeyWord(moment(val).format('DD.MM.YYYY'));
          setKeyWordFinal(moment(val).format('DD.MM.YYYY'));
        }}
      />
      <div className={styles.actionsContainer}>
        <button
          onClick={() => {
            setKeyWord('');
            setKeyWordFinal('');
          }}
        >
          {t('agGrid.remove')}
        </button>
      </div>
    </div>
  );
});
