export const CANCER_PTNM_GRADUS_ID = 'cancerPtnmGradusId';
export const CANCER_PTNM_M_ID = 'cancerPtnmMId';
export const CANCER_PTNM_N_ID = 'cancerPtnmNId';
export const CANCER_PTNM_T_ID = 'cancerPtnmTId';
export const SURGICAL_PROCEDURE_DIAGNOSES_DATE = 'diagnosisDate';
export const SURGICAL_PROCEDURE_DIAGNOSES_LOCATION = 'diagnosisLocationId';
export const SURGICAL_PROCEDURE_DIAGNOSES = 'surgicalProcedureDiagnoses';
export const SURGICAL_PROCEDURE_DATE = 'surgicalProcedureDate';
export const SURGICAL_PROCEDURE_BY_ID = 'surgicalProcedureDoneById';
export const SURGICAL_PROCEDURE_BY_NAME = 'surgicalProcedureDoneByName';
export const SURGICAL_PROCEDURE_PRIMARY_ID =
  'surgicalProcedureAsPrimaryTreatmentId';
export const SURGICAL_PROCEDURE_CODE_ID = 'surgicalProcedureCodeId';
export const SURGICAL_PROCEDURE_LOCATION = 'surgicalProcedureLocationId';
