import { MUNICIPALITY_GROUP_IDS, ROLE_IDS } from '../../constants/adminTabel';
import {
  ALLOWED_HANDLER_LIST,
  HANDLER_LIST,
  SAMPLE_DATE,
  SCREENING_CODE,
  SCREENING_ID,
  SCREENING_YEAR,
  STATUS_LIST,
} from '../../constants/commonKeys';
import { SAMPLE_KIT_STATUS_ID } from '../../constants/orderKitTable';
import {
  analysisDataInitial,
  analysisResultFromInitial,
} from '../../models/IAnalysisResultFrom';
import { lockInitial } from '../../models/ILock';
import {
  preliminaryDropdownInitial,
  preliminaryFormStateInitial,
} from '../../models/IPreliminaryForm';
import { scopyDataInitial, scopyFormInitial } from '../../models/IScopyForm';
import {
  colonGraphDataInitial,
  colonographyFromInitial,
} from '../../models/ITTGaphyFrom';
import { personFormInitial } from '../../models/IUSerInfo';
import {
  surgicalDropdonwInitial,
  surgicalProcedureFormInitial,
} from '../../models/surgicalProcedureFrom';
import { Tabs } from '../tabs/state';
import { ActionTypes, ScreeningActionTypes } from './actions';
import { Forms, Screening } from './state';

export const initialScreeningState: Screening = {
  screeningId: 0,
  hasLocations: false,
  isLoading: false,

  [STATUS_LIST]: null,
  [HANDLER_LIST]: null,
  [ALLOWED_HANDLER_LIST]: [],
  [SAMPLE_KIT_STATUS_ID]: null,
  [ROLE_IDS]: null,
  [Forms.STATUS]: -1,
  [Forms.HANDLER]: -1,
  isLatestScreening: true,
  [SCREENING_CODE]: -1,
  [Forms.SCOPY]: scopyFormInitial,
  [Forms.TTGRAPHY]: colonographyFromInitial,
  [Forms.ANALYSIS_RESULT]: analysisResultFromInitial,
  [Forms.SCREENING_LOCK]: lockInitial,
  [Forms.PERSON_LOCK]: lockInitial,
  [Forms.PRELIMINARY]: preliminaryFormStateInitial,
  [Forms.SURGICAL_PROCEDURE]: surgicalProcedureFormInitial,
  [Forms.PERSON]: personFormInitial,
  ...preliminaryDropdownInitial,
  ...analysisDataInitial,
  ...scopyDataInitial,
  ...colonGraphDataInitial,
  ...surgicalDropdonwInitial,
  [Tabs.Scopy]: false,
  [Tabs.TTGraphy]: false,
  [Tabs.SurgicalProcedure]: false,
  [Tabs.AnalysisResult]: true,
  [Tabs.Preliminary]: true,
  [MUNICIPALITY_GROUP_IDS]: [],
  personScreenings: [],
  [SCREENING_YEAR]: '',
  [SAMPLE_DATE]: '',
};

export const ScreeningReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: Screening = initialScreeningState,
  action: ScreeningActionTypes
): Screening => {
  switch (action.type) {
    case ActionTypes.registerId:
      return {
        ...state,
        screeningId: action.payload,
      };
    case ActionTypes.setLocationsSuccess:
      return {
        ...state,
        hasLocations: true,
      };

    case ActionTypes.setLoading:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ActionTypes.setDefault:
      return {
        ...state,
        [SCREENING_ID]: 0,
        [SCREENING_CODE]: -1,
        [Forms.HANDLER]: -1,
        [Forms.STATUS]: -1,
        [Forms.PRELIMINARY]: preliminaryFormStateInitial,
        [Forms.SCOPY]: scopyFormInitial,
        [Forms.TTGRAPHY]: colonographyFromInitial,
        [Forms.ANALYSIS_RESULT]: analysisResultFromInitial,
        [Forms.SCREENING_LOCK]: lockInitial,
        [Forms.PRELIMINARY]: preliminaryFormStateInitial,
        [Forms.SURGICAL_PROCEDURE]: surgicalProcedureFormInitial,
        [Forms.PERSON]: personFormInitial,
        [Tabs.Scopy]: false,
        [Tabs.TTGraphy]: false,
        [Tabs.SurgicalProcedure]: false,
        isLatestScreening: true,
        personScreenings: [],
        [ALLOWED_HANDLER_LIST]:[]
      };
    case ActionTypes.setFormLock:
    case ActionTypes.setTabAvailability:
      return {
        ...state,
        ...action.payload,
      };
    case ActionTypes.setForm:
      if (action.payload[HANDLER_LIST]) {
        action.payload[HANDLER_LIST] = action.payload[HANDLER_LIST]?.map(
          (el) => ({
            langValue: el.label ? `${el.label} ${el.langValue}` : el.langValue,
            value: el.value,
          })
        );
      }
      if (action.payload[ALLOWED_HANDLER_LIST]) {
        action.payload[ALLOWED_HANDLER_LIST] = action.payload[ALLOWED_HANDLER_LIST]?.map(
          (el) => ({
            langValue: el.label ? `${el.label} ${el.langValue}` : el.langValue,
            value: el.value,
          })
        );
      }
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
