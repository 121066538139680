import { components } from 'react-select';
import { IOptionWithSub } from './selectTasksCheckboxes';
import styles from './selectTasksCheckboxes.module.scss';

export const CustomOption = (props: any) => {
  const { Option } = components;
  const valueIds: number[] = props
    .getValue()
    .map((el: IOptionWithSub) => +el.value);

  return (
    <Option {...props}>
      <div className={styles.container}>
        <input
          className={props?.data?.parentId ? styles.sub : ''}
          type="checkbox"
          checked={
            props?.data?.parentId
              ? valueIds.includes(props?.data?.parentId) || props.isSelected
              : props.isSelected
          }
          onChange={(v) => {
            const option1: IOptionWithSub = props.data;
            const value: IOptionWithSub[] = props.getValue();
            if (v.target.checked) {
              if (option1?.hasSub && option1?.subOption) {
                const groupOptionsIds = option1?.subOption?.map(
                  (mun) => +mun.value
                );
                props.setValue([
                  ...value.filter(
                    (el) => !groupOptionsIds?.includes(+el.value)
                  ),
                  option1,
                ]);
                return;
              } else {
                if (option1.parentId) {
                  const parent = props.options?.find(
                    (el: IOptionWithSub) =>
                      '' + el.value === '' + option1.parentId
                  );
                  const futureSelected = [...value, option1].map(
                    (el) => +el.value
                  );

                  if (
                    parent.subOption
                      .map((el: IOptionWithSub) => +el.value)
                      .every((el: number) => futureSelected.includes(el))
                  ) {
                    props.setValue([
                      ...value.filter(
                        (op) =>
                          !parent.subOption
                            .map((el: IOptionWithSub) => +el.value)
                            .includes(+op.value)
                      ),
                      parent,
                    ]);
                    return;
                  } else {
                    props.setValue([...value, option1]);
                    return;
                  }
                } else {
                  props.setValue([...value, option1]);
                  return;
                }
              }
              // unCheck Part
            } else {
              if (option1.parentId && valueIds.includes(option1.parentId)) {
                const parent = props.options?.find(
                  (el: IOptionWithSub) =>
                    '' + el.value === '' + option1.parentId
                );
                if (!parent) return;

                props.setValue(
                  [
                    ...value.filter(
                      (el) => '' + el.value !== '' + option1.parentId
                    ),
                    ...parent.subOption,
                  ].filter((el: IOptionWithSub) => +el.value !== +option1.value)
                );
              } else {
                props.setValue(
                  value.filter(
                    (el: IOptionWithSub) => +el.value !== +option1.value
                  )
                );
              }
            }
          }}
        />{' '}
        <label className={styles.optionLabel}>{props.label}</label>
      </div>
    </Option>
  );
};
