import moment from 'moment';
import { TFunction } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  IS_LOCKED,
  LOCKED_BY_CURRENT_USER,
  LOCKED_BY_NAME,
  LOCKED_UNTIL,
} from '../constants/lockKeys';
import { ILock } from '../models/ILock';
import { Forms } from '../state/screening/state';

export const lockMessageHelper = (lock: ILock, t: TFunction): void => {
  const time = moment(lock[LOCKED_UNTIL])?.format('DD.MM.YYYY HH:mm');
  if (lock[IS_LOCKED] && lock[LOCKED_BY_CURRENT_USER]) {
    toast.success(
      t(`success.${LOCKED_BY_CURRENT_USER}`, {
        user: lock[LOCKED_BY_NAME],
        time,
      })
    );
  }
  if (lock[IS_LOCKED] && !lock[LOCKED_BY_CURRENT_USER]) {
    toast.error(
      t('errors.screeningLockedByOther', {
        user: lock[LOCKED_BY_NAME],
        time,
      })
    );
  }
};
export const errorsHandler = (errors: any[], formName: Forms, t: TFunction) => {
  const lockError = errors.find(
    (el: any) => el.extensions.code === 'OBJECT_LOCKED'
  );
  const changedError = errors.find(
    (el: any) => el.extensions.code === 'OBJECT_CHANGED_BEFORE'
  );
  if (lockError) {
    const name =
      lockError?.message?.indexOf('UserName1') !== -1 &&
      lockError?.message?.indexOf('UserName2') !== -1
        ? lockError?.message?.substring(
            lockError?.message?.indexOf('UserName1') + 9,
            lockError?.message?.indexOf('UserName2')
          )
        : '';
    toast.error(
      t('errors.lockedObject', {
        name,
      })
    );

    return {
      [formName !== Forms.PERSON && formName !== Forms.PERSON_LOCK
        ? Forms.SCREENING_LOCK
        : Forms.PERSON_LOCK]: {
        [LOCKED_BY_NAME]: name,
        [LOCKED_BY_CURRENT_USER]: false,
        [LOCKED_UNTIL]: null,
        [IS_LOCKED]: true,
      },
    };
  }
  if (changedError) {
    toast.error(t('errors.changedObject'));
    return null;
  }

  toast.error(t(`errors.${formName}`));
  return null;
};
