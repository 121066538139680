import React from 'react';
import { Tabs } from '../../state/tabs/state';
import { setTabValue } from '../../state/tabs/actions';
import styles from './tabSelectorControl.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../state/rootReducer';
import { useTranslation } from 'react-i18next';
import { LOCKED_BY_CURRENT_USER } from '../../constants/lockKeys';

const TabSelectorControl: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    tab: { currentTab },
    screening,
  } = useSelector((state: RootState) => state);

  const isEditing = screening.screeningLock[LOCKED_BY_CURRENT_USER];

  return (
    <ol className={styles.tabs}>
      {(Object.keys(Tabs) as Array<keyof typeof Tabs>).map((key) => (
        <li
          className={`${styles.tab} ${
            Tabs[key] === currentTab ? styles.selected : ''
          }
          ${!screening[Tabs[key]] || isEditing ? styles.disabledTab : null}
          `}
          key={key}
          onClick={() => {
            if (!screening[Tabs[key]] || isEditing) return null;
            dispatch(setTabValue(Tabs[key]));
          }}
        >
          {t(`tabs.${Tabs[key]}`)}
        </li>
      ))}
    </ol>
  );
};
export default TabSelectorControl;
