import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  REPORT_BI_EMBEDED_URL,
  REPORT_BI_TOKEN,
} from '../../constants/reportConfigs';
import { FetchReportConfig } from '../../services/ReportService';
import styles from './report.module.css';

const ReportContent: React.FC = () => {
  const { t } = useTranslation();

  const [sampleReportConfig, setReportConfig] = useState({
    type: 'report',
    embedUrl: '',
    tokenType: models.TokenType.Embed,
    accessToken: '',
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
      },
    },
  });

  const eventHandlersMap = new Map([
    [
      'error',
      function (event: any) {
        if (event) {
          toast.error(t('errors.reportConfigs'));
        }
      },
    ],
  ]);

  useEffect(() => {
    let mount = true;
    FetchReportConfig(t).then((res) => {
      if (!res || !mount) {
        return;
      }
      setReportConfig({
        ...sampleReportConfig,
        embedUrl: res[REPORT_BI_EMBEDED_URL],
        accessToken: res[REPORT_BI_TOKEN],
      });
    });

    return () => {
      mount = false;
    };
  }, []);

  return (
    <div>
      <div className={styles.title}>{t('menuNav.4')}</div>
      <PowerBIEmbed
        embedConfig={sampleReportConfig}
        eventHandlers={eventHandlersMap}
        cssClassName={styles.report}
      />
    </div>
  );
};

export default ReportContent;
