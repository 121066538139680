import { DRINKING_SIX_FREQUENCY_ID } from '../constants/preliminaryFormKeys';

export enum QueryType {
  boolean,
  string,
  array,
  number,
}

export const handleKey = (
  form: any,
  key: string,
  keyType: QueryType,
  nullabe: boolean = true
): string => {
  let validValue = form[key] !== undefined && form[key] !== null;
  if (keyType !== QueryType.string && form[key] === '') {
    validValue = false;
  }
  if (validValue && keyType === QueryType.string && !!form[key]?.trim()) {
    return `${key} : "${form[key]}",`;
  }
  if (validValue && keyType === QueryType.array) {
    return `${key} : ${JSON.stringify(form[key])?.replaceAll('"', '')},`;
  }
  if (
    validValue &&
    (keyType === QueryType.number || keyType === QueryType.boolean)
  ) {
    return `${key} : ${form[key]},`;
  }
  if (!validValue && nullabe) {
    return `${key} : null,`;
  }
  if (keyType === QueryType.string && (!validValue || !form[key]?.trim())) {
    return `${key} : "",`;
  }
  if (!validValue && keyType === QueryType.array) {
    return `${key} : [],`;
  }
  if (!validValue && keyType === QueryType.boolean) {
    return `${key} : false,`;
  }
  if (!validValue && keyType === QueryType.number) {
    return `${key} : 0,`;
  }
  return '';
};
