import React from 'react';
import Admin from '../../features/admin/admin';
import Layout from '../../features/layout/layout';
import OrderKitContent from '../../features/orderKitContent/orderKitContent';

const OrderKit: React.FC = () => {
  return (
    <Layout>
      <OrderKitContent />
    </Layout>
  );
};

export default OrderKit;
