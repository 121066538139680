import moment from 'moment';
import {
  BOWEL_EMPTY_SUCCESS_ID,
  SCOPY_DIAGNOSES,
  SCOPY_FINDING_IDS,
  SCOPY_LIMITATION_IDS,
  SCOPY_NOT_PERFORMED_REASON,
  SCOPY_PROCEDURE_IDS,
  SCOPY_REACHED_ID,
  SCOPY_REFERRAL_LOCATION,
  SCOPY_RETRIEVAL_MIN,
  COMPLICATION_IDS,
  SCOPY_DECISION_BY_ID,
  DECISION_BY_NAME,
  DECISION_DATE,
  DECISION_LOCATION,
  DIAGNOSES_DATE,
  DIAGNOSES_LOCATION,
  HAD_INNER_TURN_DONE,
  HAD_INTERVAENOUS_PREMEDICATION,
  OTHER_REASONS,
  PREVIOUS_YEAR,
  SCOPY_DATE,
  SCOPY_DONE_BY_ID,
  SCOPY_LOCATION_ID,
  SCOPY_DONE_BY_NAME,
  DECISION_ID,
} from '../constants/scopyForm';
import {
  IS_INERRUPTED,
  LARGEST_POLY_DIAMETER_ID,
  NUMBER_POLYP_ID,
  SAMPLE_ID,
  SCREENING_ID,
} from '../constants/commonKeys';
import {
  DIAGNOSES_BY_ID,
  DIAGNOSES_BY_NAME,
  DIAGNOSES_DIAMETER_MM_ID,
  DIAGNOSES_LOCATION_ID,
  DIAGNOSES_MORPHOLOGY_ID,
  MUTATION_HISTOLOGICAL_DIAGNOSIS,
} from '../constants/disgnosesTabel';
import {
  FOLLOW_UP_BY_ID,
  FOLLOW_UP_BY_NAME,
  FOLLOW_UP_DATE,
  FOLLOW_UP_LOCATION,
  FOLLOW_UP_DECISION_ID,
  FOLLOW_UP_REFERRAL_LOCATION,
} from '../constants/followUp';
import { IDiagnoses } from './IDiagnoses';
import { IOption, optionInitial } from './IOption';

export interface IScopyForm {
  [SAMPLE_ID]: number;
  [SCREENING_ID]: number;
  [IS_INERRUPTED]: boolean | null;
  [SCOPY_NOT_PERFORMED_REASON]: number | null;
  [PREVIOUS_YEAR]: number | null;
  [OTHER_REASONS]: string;
  [DECISION_ID]: number | null;
  [SCOPY_DECISION_BY_ID]: string;
  [DECISION_BY_NAME]: string;
  [DECISION_LOCATION]: string;
  [DECISION_DATE]: moment.Moment | null;
  [SCOPY_LOCATION_ID]: number | null;
  [SCOPY_DONE_BY_ID]: string;
  [SCOPY_DONE_BY_NAME]: string;
  [SCOPY_REFERRAL_LOCATION]: number | null;
  [SCOPY_DATE]: moment.Moment | null;
  [HAD_INTERVAENOUS_PREMEDICATION]: boolean | null;
  [SCOPY_RETRIEVAL_MIN]: number | null;
  [HAD_INNER_TURN_DONE]: boolean | null;
  [BOWEL_EMPTY_SUCCESS_ID]: number | null;
  [SCOPY_REACHED_ID]: number | null;
  [SCOPY_LIMITATION_IDS]: number[];
  [SCOPY_FINDING_IDS]: number[];
  [NUMBER_POLYP_ID]: number | null;
  [LARGEST_POLY_DIAMETER_ID]: number | null;
  [SCOPY_PROCEDURE_IDS]: number[];
  [COMPLICATION_IDS]: number[];
  [SCOPY_DIAGNOSES]: IDiagnoses[] | null;
  [DIAGNOSES_DATE]: moment.Moment | null;
  [DIAGNOSES_LOCATION]: number | null;
  [DIAGNOSES_BY_ID]: string;
  [DIAGNOSES_BY_NAME]: string;
  [MUTATION_HISTOLOGICAL_DIAGNOSIS]: string;
  [FOLLOW_UP_DECISION_ID]: number | null;
  [FOLLOW_UP_REFERRAL_LOCATION]: string;
  [FOLLOW_UP_DATE]: moment.Moment | null;
  [FOLLOW_UP_LOCATION]: string | null;
  [FOLLOW_UP_BY_ID]: number | '';
  [FOLLOW_UP_BY_NAME]: string;
}

export const scopyFormInitial: IScopyForm = {
  [IS_INERRUPTED]: null,
  [DECISION_ID]: null,
  [SCOPY_NOT_PERFORMED_REASON]: null,
  [SCOPY_DIAGNOSES]: null,
  [BOWEL_EMPTY_SUCCESS_ID]: null,
  [COMPLICATION_IDS]: [],
  [DECISION_DATE]: null,
  [SCOPY_DECISION_BY_ID]: '',
  [DECISION_BY_NAME]: '',
  [DECISION_LOCATION]: '',
  [SCOPY_FINDING_IDS]: [],
  [SCOPY_LIMITATION_IDS]: [],
  [SCOPY_PROCEDURE_IDS]: [],
  [SCOPY_REACHED_ID]: null,
  [SCOPY_REFERRAL_LOCATION]: null,
  [SCOPY_RETRIEVAL_MIN]: null,
  [DIAGNOSES_DATE]: null,
  [DIAGNOSES_BY_ID]: '',
  [DIAGNOSES_BY_NAME]: '',
  [DIAGNOSES_LOCATION]: null,
  [FOLLOW_UP_BY_ID]: '',
  [FOLLOW_UP_BY_NAME]: '',
  [FOLLOW_UP_DECISION_ID]: null,
  [FOLLOW_UP_REFERRAL_LOCATION]: '',
  [HAD_INNER_TURN_DONE]: null,
  [HAD_INTERVAENOUS_PREMEDICATION]: null,
  [LARGEST_POLY_DIAMETER_ID]: null,
  [MUTATION_HISTOLOGICAL_DIAGNOSIS]: '',
  [NUMBER_POLYP_ID]: null,
  [SAMPLE_ID]: 0,
  [FOLLOW_UP_LOCATION]: '',
  [FOLLOW_UP_DATE]: null,
  [OTHER_REASONS]: '',
  [PREVIOUS_YEAR]: null,
  [SCREENING_ID]: 0,
  [SCOPY_DATE]: null,
  [SCOPY_LOCATION_ID]: null,
  [SCOPY_DONE_BY_ID]: '',
  [SCOPY_DONE_BY_NAME]: '',
};

type DropDownsKeys =
  | typeof COMPLICATION_IDS
  | typeof SCOPY_FINDING_IDS
  | typeof SCOPY_LIMITATION_IDS
  | typeof SCOPY_PROCEDURE_IDS
  | typeof BOWEL_EMPTY_SUCCESS_ID
  | typeof SCOPY_REACHED_ID
  | typeof NUMBER_POLYP_ID
  | typeof DIAGNOSES_DIAMETER_MM_ID
  | typeof FOLLOW_UP_DECISION_ID
  | typeof SCOPY_NOT_PERFORMED_REASON
  | typeof DIAGNOSES_LOCATION_ID
  | typeof DIAGNOSES_MORPHOLOGY_ID;

export type IScopyDropdowns = {
  [key in DropDownsKeys]: IOption[];
};

export interface IScopyData extends IScopyDropdowns {
  colonoscopy: IScopyForm;
}

export const scopyDataInitial: IScopyDropdowns = {
  [COMPLICATION_IDS]: [optionInitial],
  [SCOPY_FINDING_IDS]: [optionInitial],
  [SCOPY_LIMITATION_IDS]: [optionInitial],
  [SCOPY_PROCEDURE_IDS]: [optionInitial],
  [SCOPY_NOT_PERFORMED_REASON]: [optionInitial],
  [BOWEL_EMPTY_SUCCESS_ID]: [optionInitial],
  [SCOPY_REACHED_ID]: [optionInitial],
  [NUMBER_POLYP_ID]: [optionInitial],
  [FOLLOW_UP_DECISION_ID]: [optionInitial],
  [DIAGNOSES_DIAMETER_MM_ID]: [optionInitial],
  [DIAGNOSES_MORPHOLOGY_ID]: [optionInitial],
  [DIAGNOSES_LOCATION_ID]: [optionInitial],
};
