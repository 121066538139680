import {
  SAMPLE_INTERPRETABILITY,
  INTERPRETABILITY_BLOCKERS,
  TEST_VALUE,
  TEST_RESULT_POSITIVE,
  ASSAY_ABOVE,
  RESULT_PLACE,
} from '../constants/analysisResultFromKeys';
import { ANALYSIS_DATE } from '../constants/commonKeys';
import { IOption, optionInitial } from './IOption';

export interface IAnalysisResultFrom {
  [SAMPLE_INTERPRETABILITY]: number | '';
  [INTERPRETABILITY_BLOCKERS]: string | null;
  [TEST_RESULT_POSITIVE]: boolean | null;
  [TEST_VALUE]: number | '';
  [ASSAY_ABOVE]: boolean;
  [ANALYSIS_DATE]: string | '';
  [RESULT_PLACE]: string;
}
export const analysisResultFromInitial: IAnalysisResultFrom = {
  [SAMPLE_INTERPRETABILITY]: '',
  [INTERPRETABILITY_BLOCKERS]: '',
  [TEST_RESULT_POSITIVE]: null,
  [TEST_VALUE]: '',
  [ASSAY_ABOVE]: false,
  [ANALYSIS_DATE]: '',
  [RESULT_PLACE]: '',
};

export type DropDownsKeys =
  | typeof SAMPLE_INTERPRETABILITY
  | typeof INTERPRETABILITY_BLOCKERS;

export type IAnalysisDropdowns = {
  [key in DropDownsKeys]: IOption[];
};

export interface IAnalysisData extends IAnalysisDropdowns {
  screening: {
    analysisAndResult: IAnalysisResultFrom;
  };
}

export const analysisDataInitial: IAnalysisDropdowns = {
  [SAMPLE_INTERPRETABILITY]: [optionInitial],
  [INTERPRETABILITY_BLOCKERS]: [optionInitial],
};
