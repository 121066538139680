import { ISignOutModel } from '../models/ISignOutModel';
import defineLanguage from './defineLanguage';
import getServerName from './getServerName';
import userManager from './userManager';

const LogoutHelper = async (path: string, token: string, lang?: string) => {
  const state: ISignOutModel = { path, lang: lang ?? defineLanguage() };

  await userManager.signoutRedirect({
    post_logout_redirect_uri: `https://${getServerName()}/signout-oidc`,
    id_token_hint: token,
    state,
  });
};
export default LogoutHelper;
