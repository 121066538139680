import { Dispatch } from 'react';
import { TFunction } from 'react-i18next';
import { toast } from 'react-toastify';
import init, { run_fetch } from 'wasm_dropdown';
import { dropdownQuery } from '../services/queries/dropdownQuery';
import { setLocationsSuccess } from '../state/screening/actions';
import { redirectAuthFail } from './redirectAuthFail';
import userManager from './userManager';

const WasmLoader = async (dispatch: Dispatch<any>, t: TFunction) => {
  try {
    const user = await userManager.getUser();
    // this is extra checking since we are not running this func until user is auth:ed
    if (!user) return;
    await init();
    await run_fetch(
      `${process.env.REACT_APP_API_URL}/graph`,
      JSON.stringify({
        query: dropdownQuery,
        operationName: 'getDropdownData',
      }),
      `${user.token_type} ${user.access_token}`
    );
    dispatch(setLocationsSuccess());
  } catch (er) {
    console.error(er);
    redirectAuthFail(t);
  }
};

export default WasmLoader;
