import {
  CONTROL_CODE,
  CONTROL_ID,
  MUNICIPALITY_ID,
  SAMPLE_DATE,
  SAMPLE_ID,
  SCREENING_CODE,
  SCREENING_ID,
  SCREENING_YEAR,
} from '../constants/commonKeys';
import {
  CONFIDENCE,
  DOES_SMOKE,
  DRINKING_AMOUNT_ID,
  DRINKING_FREQUENCY_ID,
  DRINKING_SIX_FREQUENCY_ID,
  HAS_MEDICINE_SENSITIVITY,
  HAS_RELATIVE_INTESTIONAL_CANCER,
  HAS_SMOKED_DAILY,
  HEIGHT,
  LONG_TERM_ILNESS_IDS,
  MEDICINE_SENSITIVITY,
  PRELIMINARY_ID,
  REGULAR_MEDICINE_IDS,
  RELATIVE_INTESTIONAL_CANCER_WHO,
  SMOKED_DAILY_AMOUNT_ID,
  SMOKED_DAILY_YEARS_ID,
  WEIGHT,
} from '../constants/preliminaryFormKeys';
import { ILock } from './ILock';
import { IOption, optionInitial } from './IOption';

export type IPreKeyOptions = 0 | 1 | 2 | 3 | 4;

export interface IPreliminaryForm {
  [PRELIMINARY_ID]: number | null;
  [SCREENING_ID]: number | '';
  [CONTROL_ID]: number | '';
  [HEIGHT]: number | '';
  [WEIGHT]: number | '';
  [HAS_RELATIVE_INTESTIONAL_CANCER]: number | '';
  [RELATIVE_INTESTIONAL_CANCER_WHO]: IPreKeyOptions[];
  [HAS_MEDICINE_SENSITIVITY]: boolean | null | '';
  [MEDICINE_SENSITIVITY]: string;
  [HAS_SMOKED_DAILY]: boolean | null | '';
  [CONTROL_CODE]: string;
  [SMOKED_DAILY_YEARS_ID]: number | '';
  [SMOKED_DAILY_AMOUNT_ID]: number | '';
  [DOES_SMOKE]: boolean | null | '';
  [DRINKING_FREQUENCY_ID]: number | '';
  [DRINKING_AMOUNT_ID]: number | '';
  [DRINKING_SIX_FREQUENCY_ID]: number | '';
  [LONG_TERM_ILNESS_IDS]: IPreKeyOptions[];
  [REGULAR_MEDICINE_IDS]: IPreKeyOptions[];
  [MUNICIPALITY_ID]: string;
  [CONFIDENCE]: number | null;
}

export const preliminaryFormStateInitial: IPreliminaryForm = {
  [PRELIMINARY_ID]: null,
  [SCREENING_ID]: 0,
  [CONTROL_ID]: 0,
  [HEIGHT]: '',
  [WEIGHT]: '',
  [MUNICIPALITY_ID]: '',
  [HAS_RELATIVE_INTESTIONAL_CANCER]: '',
  [RELATIVE_INTESTIONAL_CANCER_WHO]: [],
  [HAS_MEDICINE_SENSITIVITY]: '',
  [MEDICINE_SENSITIVITY]: '',
  [HAS_SMOKED_DAILY]: '',
  [CONTROL_CODE]: '',
  [SMOKED_DAILY_YEARS_ID]: '',
  [SMOKED_DAILY_AMOUNT_ID]: '',
  [DOES_SMOKE]: '',
  [DRINKING_FREQUENCY_ID]: '',
  [DRINKING_AMOUNT_ID]: '',
  [DRINKING_SIX_FREQUENCY_ID]: '',
  [LONG_TERM_ILNESS_IDS]: [],
  [REGULAR_MEDICINE_IDS]: [],
  [MUNICIPALITY_ID]: '',
  [CONFIDENCE]: null,
};

export type DropDownsKeys =
  | typeof LONG_TERM_ILNESS_IDS
  | typeof RELATIVE_INTESTIONAL_CANCER_WHO
  | typeof REGULAR_MEDICINE_IDS
  | typeof DRINKING_AMOUNT_ID
  | typeof DRINKING_FREQUENCY_ID
  | typeof DRINKING_SIX_FREQUENCY_ID
  | typeof HAS_RELATIVE_INTESTIONAL_CANCER
  | typeof MUNICIPALITY_ID;

export type IPreliminaryDropdowns = {
  [key in DropDownsKeys]: IOption[];
};

export interface IPreliminaryData extends IPreliminaryDropdowns {
  preliminaryInfo: IPreliminaryForm;
  screeningLock: ILock;
  isScopyEnabled: boolean;
  isSurgeryOrProcedureEnabled: boolean;
  isTtTTGraphyEnabled: boolean;
}

export const preliminaryDropdownInitial: IPreliminaryDropdowns = {
  [LONG_TERM_ILNESS_IDS]: [optionInitial],
  [RELATIVE_INTESTIONAL_CANCER_WHO]: [optionInitial],
  [HAS_RELATIVE_INTESTIONAL_CANCER]: [optionInitial],
  [REGULAR_MEDICINE_IDS]: [optionInitial],
  [DRINKING_AMOUNT_ID]: [optionInitial],
  [DRINKING_FREQUENCY_ID]: [optionInitial],
  [DRINKING_SIX_FREQUENCY_ID]: [optionInitial],
  [MUNICIPALITY_ID]: [optionInitial],
};
