import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './header.module.scss';

interface IHeaderProps {
  title?: string;
  wrapperClassName?: string;
  titleClassName?: string;
  confidence?: JSX.Element | undefined;
  children?: React.ReactElement;
}

const Header: React.FC<IHeaderProps> = ({
  title,
  wrapperClassName,
  titleClassName,
  confidence,
  children,
}) => {
  const { t } = useTranslation();
  return (
    <div className={`${styles.header} container__row ${wrapperClassName}`}>
      <div className={`grid-2-1st ${titleClassName} ${styles.gridSettings}`}>
        <div className={`${styles.title} ${titleClassName}`}>
          {t(title ?? '')}
        </div>
        {confidence && (
          <div className={`${styles.title} ${titleClassName}`}>
            {confidence}
          </div>
        )}
      </div>
      <div className={`grid-2-2nd ${styles.childrn}`}>{children}</div>
    </div>
  );
};

Header.defaultProps = {
  title: 'header.title',
  wrapperClassName: '',
  titleClassName: '',
};

export default Header;
