export const isEmptyObject = (
  obj: any,
  listKeys: string[],
  exclude: boolean = true
): boolean => {
  if (!obj) return true;
  let isEmpty = true;
  Object.keys(obj)
    .filter(
      (el) =>
        // if we want exclude the list we check just other values
        (exclude && !listKeys.includes(el)) ||
        // if we want include the list we check just list
        (!exclude && listKeys.includes(el))
    )
    .forEach((el) => {
      // if the field is an empty array
      if (Array.isArray(obj[el]) && !obj[el]?.length) return;
      if (obj[el] || obj[el] === false) return (isEmpty = false);
    });
  return isEmpty;
};
