import { useField } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Select, { MultiValue } from 'react-select';
import { STATUS } from '../../constants/commonKeys';
import { IOption } from '../../models/IOption';
import { RootState } from '../../state/rootReducer';
import { municipalitiesGroupHelper } from '../../utils/municipalitiesgroup';
import styles from './selectTasksCheckboxes.module.scss';
import { CustomOption } from './selectTasksCheckboxesHelper';

export interface IOptionWithSub extends IOption {
  subOption?: IOption[];
  hasSub: boolean | null;
  parentId?: number;
}

export interface ISelectTasksCheckboxeProps {
  value?: any[];
  disabled: boolean;
  parentSrc: string;
  src: string;
  options: IOptionWithSub[];
}

const SelectTasksCheckboxe: React.FC<ISelectTasksCheckboxeProps> = ({
  src,
  parentSrc,
  value,
  disabled,
  options,
}) => {
  const { t } = useTranslation();
  const [labelSrc, name] = src.split('.');
  // if you don't want to use Formik use value instead of field.value
  const [field, , meta] = useField<number[]>(name || '');
  const translatedOptions = options.map((el) => {
    if (el.hasSub && el.subOption) {
      return {
        ...el,
        subOption: el.subOption.map((sub) => ({
          ...sub,
          label: t(`${parentSrc}.${sub.langValue}`),
        })),
        label: t(`${name}.${el.langValue}`),
      };
    } else {
      return {
        ...el,
        label: t(`${parentSrc}.${el.langValue}`),
      };
    }
  });
  const valued = municipalitiesGroupHelper(
    translatedOptions.filter((el) => field.value?.includes(+el.value)),
    translatedOptions
  ).newSelectedValues;

  if (!name && !value) {
    return <></>;
  }
  return (
    <>
      <div>
        <label className={styles.label}>
          <span>{t(`${labelSrc}.${name}`)}</span>
        </label>
      </div>
      <Select
        isDisabled={disabled}
        options={translatedOptions}
        isMulti
        components={{ Option: CustomOption }}
        onChange={(val: MultiValue<IOptionWithSub>) => {
          meta.setValue(val.map((el) => +el.value));
        }}
        styles={{
          option: (provided) => ({
            ...provided,
            color: 'balck',
            backgroundColor: 'white',
          }),
        }}
        value={valued}
        placeholder={t('forms.select')}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
      />
    </>
  );
};

export default SelectTasksCheckboxe;
