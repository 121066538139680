import moment from 'moment';
import {
  ADITIONAL_INFO,
  SAMPLE_ID,
  SCREENING_ID,
} from '../constants/commonKeys';
import {
  DIAGNOSES_BY_ID,
  DIAGNOSES_BY_NAME,
  MUTATION_HISTOLOGICAL_DIAGNOSIS,
} from '../constants/disgnosesTabel';
import {
  CANCER_PTNM_GRADUS_ID,
  CANCER_PTNM_M_ID,
  CANCER_PTNM_N_ID,
  CANCER_PTNM_T_ID,
  SURGICAL_PROCEDURE_DIAGNOSES,
  SURGICAL_PROCEDURE_DATE,
  SURGICAL_PROCEDURE_BY_ID,
  SURGICAL_PROCEDURE_BY_NAME,
  SURGICAL_PROCEDURE_PRIMARY_ID,
  SURGICAL_PROCEDURE_CODE_ID,
  SURGICAL_PROCEDURE_LOCATION,
  SURGICAL_PROCEDURE_DIAGNOSES_DATE,
  SURGICAL_PROCEDURE_DIAGNOSES_LOCATION,
} from '../constants/surgicalProcedureFormKeys';
import { DiagnosesInitial, IDiagnoses } from './IDiagnoses';
import { IOption, optionInitial } from './IOption';

export interface ISurgicalProcedureForm {
  [ADITIONAL_INFO]: boolean | '';
  [CANCER_PTNM_GRADUS_ID]: string;
  [SURGICAL_PROCEDURE_DIAGNOSES]: IDiagnoses[];
  [SURGICAL_PROCEDURE_DIAGNOSES_DATE]: moment.Moment | null;
  [DIAGNOSES_BY_ID]: string;
  [DIAGNOSES_BY_NAME]: string;
  [SURGICAL_PROCEDURE_DIAGNOSES_LOCATION]: number | null;
  [SURGICAL_PROCEDURE_DATE]: string;
  [SURGICAL_PROCEDURE_BY_ID]: string;
  [SURGICAL_PROCEDURE_BY_NAME]: string;
  [SURGICAL_PROCEDURE_PRIMARY_ID]: number | null;
  [SURGICAL_PROCEDURE_CODE_ID]: number | null;
  [SURGICAL_PROCEDURE_LOCATION]: moment.Moment | null;
  [CANCER_PTNM_M_ID]: number | null;
  [CANCER_PTNM_N_ID]: number | null;
  [CANCER_PTNM_T_ID]: number | null;
  [SAMPLE_ID]: number;
  [SCREENING_ID]: number;
  [MUTATION_HISTOLOGICAL_DIAGNOSIS]: string;
}

export const surgicalProcedureFormInitial: ISurgicalProcedureForm = {
  [ADITIONAL_INFO]: '',
  [SCREENING_ID]: 0,
  [CANCER_PTNM_GRADUS_ID]: '',
  [SURGICAL_PROCEDURE_DIAGNOSES]: [{ ...DiagnosesInitial }],
  [SURGICAL_PROCEDURE_DIAGNOSES_DATE]: null,
  [DIAGNOSES_BY_ID]: '',
  [DIAGNOSES_BY_NAME]: '',
  [SURGICAL_PROCEDURE_DIAGNOSES_LOCATION]: null,
  [SURGICAL_PROCEDURE_DATE]: '',
  [SURGICAL_PROCEDURE_BY_ID]: '',
  [SURGICAL_PROCEDURE_BY_NAME]: '',
  [SURGICAL_PROCEDURE_PRIMARY_ID]: null,
  [SURGICAL_PROCEDURE_CODE_ID]: null,
  [SURGICAL_PROCEDURE_LOCATION]: null,
  [CANCER_PTNM_M_ID]: null,
  [CANCER_PTNM_N_ID]: null,
  [CANCER_PTNM_T_ID]: null,
  [SAMPLE_ID]: 0,
  [MUTATION_HISTOLOGICAL_DIAGNOSIS]: '',
};

type DropDownsKeys =
  | typeof SURGICAL_PROCEDURE_PRIMARY_ID
  | typeof CANCER_PTNM_M_ID
  | typeof CANCER_PTNM_N_ID
  | typeof CANCER_PTNM_T_ID
  | typeof CANCER_PTNM_GRADUS_ID;

export type ISurgicalDropdowns = {
  [key in DropDownsKeys]: IOption[];
};

export const surgicalDropdonwInitial: ISurgicalDropdowns = {
  [SURGICAL_PROCEDURE_PRIMARY_ID]: [optionInitial],
  [CANCER_PTNM_M_ID]: [optionInitial],
  [CANCER_PTNM_GRADUS_ID]: [optionInitial],
  [CANCER_PTNM_N_ID]: [optionInitial],
  [CANCER_PTNM_T_ID]: [optionInitial],
};
