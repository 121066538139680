import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/header/header';
import TabLastEdit from '../../components/tabLastEdit/tabLastEdit';
import { TEST_RESULT_POSITIVE } from '../../constants/analysisResultFromKeys';
import { RootState } from '../../state/rootReducer';
import { fetchForm } from '../../state/screening/actions';
import { Forms } from '../../state/screening/state';
import { Tabs } from '../../state/tabs/state';
import EditingBar from '../editingBar/editingBar';
import styles from '../personTabContainer/tabs.module.scss';
import Analysis from './analysis';
import Followup from './followupDecision';

const AnalysisResult: React.FC<{}> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { screening } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (screening.screeningId) {
      dispatch(fetchForm(screening.screeningId, Tabs.AnalysisResult, t));
    }
  }, [screening.screeningId]);

  return (
    <div className="container--fluid">
      <Header
        title={`tabs.${Tabs.AnalysisResult}`}
        titleClassName={styles.title}
      >
        <>
          {screening[Forms.ANALYSIS_RESULT][TEST_RESULT_POSITIVE] ? (
            <EditingBar>
              <TabLastEdit />
            </EditingBar>
          ) : null}
        </>
      </Header>
      <Analysis />
      {screening[Forms.ANALYSIS_RESULT][TEST_RESULT_POSITIVE] ? (
        <Followup />
      ) : null}
    </div>
  );
};

export default AnalysisResult;
