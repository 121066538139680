import { components } from 'react-select';
import { iconsList } from '../../constantsLists/lists';
import styles from './screeningStatus.module.scss';

export const CustomOption = (props: any) => {
  const { Option } = components;

  return (
    <Option {...props} className={styles.optioncontainer}>
      <img
        src={iconsList[props?.data?.langValue]}
        alt={props?.data?.label || ''}
        className={styles.icon}
      ></img>{' '}
      {props?.data?.label || ''}
    </Option>
  );
};
export const CustomValue = (props: any) => {
  const { SingleValue } = components;

  return (
    <SingleValue {...props} className={styles.optioncontainer}>
      <img
        src={iconsList[props?.data?.langValue]}
        alt={props?.data?.label || ''}
        className={styles.icon}
      ></img>
      {props?.data?.label || ''}
    </SingleValue>
  );
};
