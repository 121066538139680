import { IGetRowsParams, IServerSideGetRowsParams } from 'ag-grid-community';
import axios from 'axios';
import { TFunction } from 'react-i18next';
import { toast } from 'react-toastify';
import { SAMPLE_KIT_STATUS_ID } from '../constants/orderKitTable';
import { IKitOrder } from '../models/IKITOrder';
import { IOption } from '../models/IOption';
import { IUser } from '../models/IUser';
import { Screening } from '../state/screening/state';
import { redirectAuthFail } from '../utils/redirectAuthFail';
import userManager from '../utils/userManager';
import {
  kitOrderStatusCUpdate,
  searchKitOrderQuery,
} from './queries/searchQueries';

export const FetchKitOrdersList = async (
  t: TFunction,
  state: Screening,
  maxPageNo: number,
  params?: IGetRowsParams
): Promise<ISearchKitOrderData | null> => {
  const user = await userManager.getUser();
  if (!user) {
    redirectAuthFail(t);
    return null;
  }
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/graph`,
      {
        query: searchKitOrderQuery(state, maxPageNo, params),
        operationName: 'FetchKitOrderList',
      },
      { headers: { Authorization: `${user.token_type} ${user.access_token}` } }
    );

    if (Array.isArray(res.data.errors)) {
      toast.error(t('errors.userSearch'));
      return null;
    }
    return res?.data?.data;
  } catch (er) {
    toast.error(t('errors.userSearch'));
    return null;
  }
};

export interface ISearchKitOrderData {
  sampleKitOrderSearch: { results: IKitOrder[]; totalCount: number };
  [SAMPLE_KIT_STATUS_ID]?: IOption[];
}

export const UpdatKitOrdersStatus = async (
  t: TFunction,
  orderId: number,
  statusId: number
): Promise<boolean> => {
  const user = await userManager.getUser();
  if (!user) {
    redirectAuthFail(t);
    return false;
  }
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/graph`,
      {
        query: kitOrderStatusCUpdate(statusId, orderId),
        operationName: 'kitOrderStatusCUpdate',
      },
      { headers: { Authorization: `${user.token_type} ${user.access_token}` } }
    );

    if (Array.isArray(res.data.errors)) {
      toast.error(t('errors.userSearch'));
      return false;
    }
    toast.success(t('success.updateKitOrderStatus'));
    return true;
  } catch (er) {
    toast.error(t('errors.userSearch'));
    return false;
  }
};
