import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GridApi, GridReadyEvent } from 'ag-grid-community/dist/lib/main';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { useField } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import UseCreateDiagnosesColumn from '../../columns/diagnosesTableCols';
import EditRemoveRendere from '../../components/removeEditRenderer/removeEdit';
import SelectEditorRenderer from '../../components/selectEditorRenderer/gridCustomSelect';
import TooltipIconComponent from '../../components/tooltipComponent/tooltipIconComponent';
import { DIAGNOSES_MORPHOLOGY_ID } from '../../constants/disgnosesTabel';
import { IDiagnoses } from '../../models/IDiagnoses';
import styles from './diagnosesTable.module.scss';

interface TDiagnosesProps {
  name: string;
  disabled: boolean;
  errorClassName?: string;
  hideError?: string;
}

const DiagnosesTable: React.FC<TDiagnosesProps> = ({
  name,
  disabled,
  hideError,
  errorClassName,
}) => {
  const { t } = useTranslation();
  const {
    defaultColDefDiagnoses,
    diagnosesColumnDefs,
    addNew,
  } = UseCreateDiagnosesColumn(t, disabled);
  const [field, form, meta] = useField(name);
  const [gridApi, setGridApi] = useState<GridApi | undefined>();
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  return (
    <div className="container__row">
      <div className={`ag-theme-alpine ${styles.container}`}>
        <AgGridReact
          domLayout="autoHeight"
          onGridReady={onGridReady}
          groupHeaderHeight={75}
          stopEditingWhenCellsLoseFocus={true}
          rowData={field.value ?? []}
          onRowDataUpdated={(e) => {
            if (gridApi && e.type) {
              const items: IDiagnoses[] = [];
              gridApi.forEachNode(function (node) {
                items.push(node.data);
              });
              meta.setValue(items);
            }
          }}
          defaultColDef={defaultColDefDiagnoses}
          columnDefs={diagnosesColumnDefs}
          frameworkComponents={{ EditRemoveRendere, SelectEditorRenderer }}
          editType="fullRow"
          onRowEditingStopped={() => {
            if (gridApi) {
              const items: IDiagnoses[] = [];
              gridApi.forEachNode(function (node) {
                items.push(node.data);
              });
              gridApi.setRowData(items);
              meta.setValue(items);
            }
          }}
        ></AgGridReact>
      </div>
      {form.error && !hideError ? (
        <div className={`${styles.error} ${errorClassName}`}>
          {t(`errors.${DIAGNOSES_MORPHOLOGY_ID}`)}
        </div>
      ) : null}

      {!disabled ? (
        <div
          className={styles.addNew}
          onClick={() => {
            addNew(gridApi);
          }}
        >
          <FontAwesomeIcon
            className={styles.icon}
            icon={faPlusCircle}
            size="1x"
          />
          {t('tableColumns.addNewDiagnosis')}
          <TooltipIconComponent src="tableColumns" name="addNewDiagnosis" />
        </div>
      ) : null}
    </div>
  );
};
export default DiagnosesTable;
