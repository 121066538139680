import React, { useState } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import styles from './statusRenderer.module.scss';
import { IOption } from '../../models/IOption';
import { useTranslation } from 'react-i18next';
import { iconsList } from '../../constantsLists/lists';
import { STATUS } from '../../constants/commonKeys';

export interface IStatusRenderer extends ICellRendererParams {
  options: IOption[];
}

const StatusRenderer = ({ options, value }: IStatusRenderer) => {
  const { t } = useTranslation();
  const option = options?.find((el) => el.value === value);
  if (!option || !option.langValue) {
    return <></>;
  }

  return (
    <span className={styles.optioncontainer}>
      <span className={styles.imgContainer}>
        <img
          src={iconsList[option.langValue]}
          alt={t(`${STATUS}.${option.langValue}`) || ''}
          className={styles.icon}
        ></img>
      </span>
      <span>{t(`${STATUS}.${option.langValue}`) || ''}</span>
    </span>
  );
};
export default StatusRenderer;
