import moment from 'moment';
import { TFunction } from 'react-i18next';
import { Dispatch } from 'redux';
import * as Yup from 'yup';
import {
  ADITIONAL_INFO,
  IS_INERRUPTED,
  NUMBER_POLYP_ID,
} from '../../constants/commonKeys';
import {
  DIAGNOSES_BY_ID,
  DIAGNOSES_BY_NAME,
  DIAGNOSES_DIAMETER_MM_ID,
  DIAGNOSES_LOCATION_ID,
  DIAGNOSES_MORPHOLOGY_ID,
  DIAGNOSES_RESULT_ID,
  MUTATION_HISTOLOGICAL_DIAGNOSIS,
} from '../../constants/disgnosesTabel';
import {
  FOLLOW_UP_BY_ID,
  FOLLOW_UP_BY_NAME,
  FOLLOW_UP_DATE,
  FOLLOW_UP_DECISION_ID,
  FOLLOW_UP_LOCATION,
  FOLLOW_UP_REFERRAL_LOCATION,
} from '../../constants/followUp';
import {
  DECISION_BY_NAME,
  DECISION_DATE,
  DECISION_ID,
  DECISION_LOCATION,
  DIAGNOSES_DATE,
  DIAGNOSES_LOCATION,
  SCOPY_DATE,
  SCOPY_DECISION_BY_ID,
  SCOPY_DIAGNOSES,
  SCOPY_DONE_BY_ID,
  SCOPY_DONE_BY_NAME,
  SCOPY_FINDING_IDS,
  SCOPY_LIMITATION_IDS,
  SCOPY_LOCATION_ID,
  SCOPY_OTHER_FINDINGS,
  SCOPY_OTHER_LIMITS,
  SCOPY_REFERRAL_LOCATION,
  SCOPY_RETRIEVAL_MIN,
} from '../../constants/scopyForm';
import {
  validationNumberValues,
  validListScopy,
} from '../../constantsLists/validationLists';
import { IScopyForm } from '../../models/IScopyForm';
import { formsMutationList } from '../../services/queries/formsQueryHelper';
import {
  setLoadingValue,
  setScreeningData,
  setSubmitForm,
} from '../../state/screening/actions';
import { Forms } from '../../state/screening/state';
import { formCleaning } from '../../utils/formCleaning';
import { isEmptyObject } from '../../utils/isEmptyObject';

export const valideScopy = (t: TFunction) =>
  Yup.object().shape({
    [SCOPY_LOCATION_ID]: Yup.number()
      .nullable()
      .test(SCOPY_LOCATION_ID, t('forms.requiredLocation'), function (val) {
        const values: IScopyForm = this.parent;
        return !(
          !val &&
          val !== 0 &&
          values[DECISION_ID] === 6 &&
          (values[IS_INERRUPTED] ||
            !isEmptyObject(values, validListScopy[SCOPY_LOCATION_ID], false))
        );
      })
      .test(SCOPY_LOCATION_ID, t('forms.needMoreScopy'), function (val) {
        const values: IScopyForm = this.parent;
        return !(
          !!val &&
          values[DECISION_ID] === 6 &&
          !values[IS_INERRUPTED] &&
          isEmptyObject(values, validListScopy.scopyDecisionMin, false)
        );
      }),
    [SCOPY_DATE]: Yup.date()
      .nullable()
      .test(SCOPY_DATE, t('forms.requiredField'), function (val) {
        const values: IScopyForm = this.parent;
        return !(
          !val &&
          values[DECISION_ID] === 6 &&
          (values[IS_INERRUPTED] ||
            !isEmptyObject(values, validListScopy[SCOPY_DATE], false))
        );
      })

      .test(SCOPY_DATE, t('forms.needMoreScopy'), function (val) {
        const values: IScopyForm = this.parent;
        return !(
          !!val &&
          values[DECISION_ID] === 6 &&
          !values[IS_INERRUPTED] &&
          isEmptyObject(values, validListScopy.scopyDecisionMin, false)
        );
      })
      .test(SCOPY_DATE, t('forms.futureData'), (val) => {
        if (!val) return true;
        return moment(val).isSameOrBefore(moment(), 'day');
      }),
    [SCOPY_DONE_BY_NAME]: Yup.string()
      .nullable()
      .max(
        validationNumberValues[SCOPY_DONE_BY_NAME].max,
        t('forms.mustBeStringMax', {
          maxCharacter: validationNumberValues[SCOPY_DONE_BY_NAME].max,
        })
      )
      .when(IS_INERRUPTED, {
        is: true,
        then: Yup.string().required(t('forms.requiredField')),
      }),
    [SCOPY_RETRIEVAL_MIN]: Yup.number()
      .nullable()
      .typeError(t('forms.mustBeNumber')),
    [SCOPY_DONE_BY_ID]: Yup.string()
      .nullable()
      .max(
        validationNumberValues[SCOPY_DONE_BY_ID].max,
        t('forms.mustBeStringMax', {
          maxCharacter: validationNumberValues[SCOPY_DONE_BY_ID].max,
        })
      ),
    [SCOPY_DIAGNOSES]: Yup.array()
      .nullable()
      .of(
        Yup.object().shape({
          [DIAGNOSES_MORPHOLOGY_ID]: Yup.number()
            .typeError(t('forms.mustBeNumber'))
            .required(t('forms.requiredField')),
          [DIAGNOSES_LOCATION_ID]: Yup.number()
            .nullable()
            .typeError(t('forms.mustBeNumber')),
          [DIAGNOSES_DIAMETER_MM_ID]: Yup.number()
            .nullable()
            .typeError(t('forms.mustBeNumber')),
        })
      ),
    [DIAGNOSES_DATE]: Yup.date()
      .nullable()
      .test(DIAGNOSES_DATE, t('forms.requiredField'), function (val) {
        const values: IScopyForm = this.parent;
        return !(
          !val && !isEmptyObject(values, validListScopy[DIAGNOSES_DATE], false)
        );
      })

      .test(SCOPY_DIAGNOSES, t('forms.needMoreDiagnose'), function (val) {
        const values: IScopyForm = this.parent;
        return !(!!val && !values[SCOPY_DIAGNOSES]?.length);
      })
      .test(DIAGNOSES_DATE, t('forms.futureData'), (val) => {
        if (!val) return true;
        return moment(val).isSameOrBefore(moment(), 'day');
      }),
    [DIAGNOSES_LOCATION]: Yup.number()
      .nullable()
      .test(DIAGNOSES_LOCATION, t('forms.requiredLocation'), function (val) {
        const values: IScopyForm = this.parent;
        return !(
          !val &&
          val !== 0 &&
          !isEmptyObject(values, validListScopy[DIAGNOSES_LOCATION], false)
        );
      })
      .test(SCOPY_DIAGNOSES, t('forms.needMoreDiagnose'), function (val) {
        const values: IScopyForm = this.parent;
        return !(!!val && !values[SCOPY_DIAGNOSES]?.length);
      }),
    [DIAGNOSES_BY_ID]: Yup.string()
      .nullable()
      .max(
        validationNumberValues[DIAGNOSES_BY_ID].max,
        t('forms.mustBeStringMax', {
          maxCharacter: validationNumberValues[DIAGNOSES_BY_ID].max,
        })
      ),
    [DIAGNOSES_BY_NAME]: Yup.string()
      .nullable()
      .max(
        validationNumberValues[DIAGNOSES_BY_NAME].max,
        t('forms.mustBeStringMax', {
          maxCharacter: validationNumberValues[DIAGNOSES_BY_NAME].max,
        })
      ),
    [ADITIONAL_INFO]: Yup.string()
      .nullable()
      .max(
        validationNumberValues[ADITIONAL_INFO].max,
        t('forms.mustBeStringMax', {
          maxCharacter: validationNumberValues[ADITIONAL_INFO].max,
        })
      ),
    [MUTATION_HISTOLOGICAL_DIAGNOSIS]: Yup.string()
      .nullable()
      .max(
        validationNumberValues[MUTATION_HISTOLOGICAL_DIAGNOSIS].max,
        t('forms.mustBeStringMax', {
          maxCharacter:
            validationNumberValues[MUTATION_HISTOLOGICAL_DIAGNOSIS].max,
        })
      ),
    [FOLLOW_UP_BY_ID]: Yup.string()
      .nullable()
      .max(
        validationNumberValues[FOLLOW_UP_BY_ID].max,
        t('forms.mustBeStringMax', {
          maxCharacter: validationNumberValues[FOLLOW_UP_BY_ID].max,
        })
      ),
    [FOLLOW_UP_BY_NAME]: Yup.string()
      .nullable()
      .max(
        validationNumberValues[FOLLOW_UP_BY_NAME].max,
        t('forms.mustBeStringMax', {
          maxCharacter: validationNumberValues[FOLLOW_UP_BY_NAME].max,
        })
      ),
    [FOLLOW_UP_DATE]: Yup.date()
      .nullable()

      .test(FOLLOW_UP_DATE, t('forms.requiredField'), function (val) {
        const values: IScopyForm = this.parent;
        return !(
          !val &&
          !values[IS_INERRUPTED] &&
          !isEmptyObject(values, validListScopy.followUpDisabled, false) &&
          !isEmptyObject(values, validListScopy[FOLLOW_UP_DATE], false)
        );
      })
      .test(FOLLOW_UP_DATE, t('forms.futureData'), (val) => {
        if (!val) return true;
        return moment(val).isSameOrBefore(moment(), 'day');
      }),
    [FOLLOW_UP_LOCATION]: Yup.number()
      .nullable()
      .test(FOLLOW_UP_LOCATION, t('forms.requiredLocation'), function (val) {
        const values: IScopyForm = this.parent;
        return !(
          !val &&
          val !== 0 &&
          !values[IS_INERRUPTED] &&
          !isEmptyObject(values, validListScopy.followUpDisabled, false) &&
          !isEmptyObject(values, validListScopy[FOLLOW_UP_LOCATION], false)
        );
      }),

    [FOLLOW_UP_DECISION_ID]: Yup.number()
      .nullable()
      .test(FOLLOW_UP_DECISION_ID, t('forms.requiredField'), function (val) {
        const values: IScopyForm = this.parent;
        return !(
          !val &&
          !values[IS_INERRUPTED] &&
          !isEmptyObject(values, validListScopy.followUpDisabled, false) &&
          !isEmptyObject(values, validListScopy[FOLLOW_UP_DECISION_ID], false)
        );
      }),
    [NUMBER_POLYP_ID]: Yup.number()
      .nullable()
      .when(SCOPY_FINDING_IDS, {
        is: (val) => val?.includes(3),
        then: Yup.number().required(t('forms.requiredField')),
      }),
    [SCOPY_OTHER_LIMITS]: Yup.string()
      .nullable()
      .max(
        validationNumberValues[SCOPY_OTHER_LIMITS].max,
        t('forms.mustBeStringMax', {
          maxCharacter: validationNumberValues[SCOPY_OTHER_LIMITS].max,
        })
      ),
    [SCOPY_OTHER_FINDINGS]: Yup.string()
      .nullable()
      .max(
        validationNumberValues[SCOPY_OTHER_FINDINGS].max,
        t('forms.mustBeStringMax', {
          maxCharacter: validationNumberValues[SCOPY_OTHER_FINDINGS].max,
        })
      ),
    [DECISION_BY_NAME]: Yup.string()
      .nullable()
      .max(
        validationNumberValues[DECISION_BY_NAME].max,
        t('forms.mustBeStringMax', {
          maxCharacter: validationNumberValues[DECISION_BY_NAME].max,
        })
      ),
    [SCOPY_DECISION_BY_ID]: Yup.string()
      .nullable()
      .max(
        validationNumberValues[SCOPY_DECISION_BY_ID].max,
        t('forms.mustBeStringMax', {
          maxCharacter: validationNumberValues[SCOPY_DECISION_BY_ID].max,
        })
      ),
    [FOLLOW_UP_REFERRAL_LOCATION]: Yup.string()
      .nullable()
      .when(FOLLOW_UP_DECISION_ID, {
        is: (val) => val && val !== 5 && val !== 1,
        then: Yup.string().required(t('forms.requiredLocation')),
      }),
  });

export const scopySubmit = async (
  form: IScopyForm,
  t: TFunction,
  dispatch: Dispatch<any>,
  resetForm: () => void,
  isAnalysisAndResult: boolean
) => {
  dispatch(setLoadingValue(true));
  dispatch(setScreeningData({ [Forms.SCOPY]: form }));
  let cleanedForm = form;

  //clean diagnose from isNew property
  if (cleanedForm[SCOPY_DIAGNOSES]?.length) {
    cleanedForm[SCOPY_DIAGNOSES] = cleanedForm[SCOPY_DIAGNOSES]!.map((el) => ({
      [DIAGNOSES_MORPHOLOGY_ID]: el[DIAGNOSES_MORPHOLOGY_ID],
      [DIAGNOSES_LOCATION_ID]: el[DIAGNOSES_LOCATION_ID],
      [DIAGNOSES_DIAMETER_MM_ID]: el[DIAGNOSES_DIAMETER_MM_ID],
      [DIAGNOSES_RESULT_ID]: el[DIAGNOSES_RESULT_ID],
    }));
  }

  // clean if interrupted
  if (cleanedForm[IS_INERRUPTED]) {
    cleanedForm = formCleaning(cleanedForm, validListScopy.formInerrupted);
  }
  // if we have no colonscopy decision we copy use colonoscopyDecisionDate for colonoscopyDate, same for location
  if (cleanedForm[DECISION_ID] === 1 || cleanedForm[DECISION_ID] === 3) {
    cleanedForm = {
      ...formCleaning(cleanedForm, validListScopy.notDoneform),
      [SCOPY_DATE]: form[DECISION_DATE],
      [SCOPY_LOCATION_ID]: form[DECISION_LOCATION],
    };
  }
  // if we have no colonscopy decision we copy use colonoscopyDecisionDate for colonoscopyDate, same for location
  if (cleanedForm[DECISION_ID] === 6) {
    cleanedForm = formCleaning(cleanedForm, validListScopy.doneform, true);
  }

  // we clean Followup if it was filled and then disabled since there weren't the needed data
  if (isEmptyObject(cleanedForm, validListScopy.followUpDisabled, false)) {
    cleanedForm = formCleaning(cleanedForm, validListScopy.followUp, true);
  }
  // if colonoscopyFindingId array doesn't have polyppi we clean numberOfPolypsId
  if (!cleanedForm[SCOPY_FINDING_IDS]?.includes(3)) {
    cleanedForm = formCleaning(cleanedForm, [NUMBER_POLYP_ID], true);
  }
  // if colonoscopyFindingId array doesn't have Muu we clean it SCOPY_OTHER_FINDINGS
  if (!cleanedForm[SCOPY_FINDING_IDS]?.includes(8)) {
    cleanedForm = formCleaning(cleanedForm, [SCOPY_OTHER_FINDINGS], true);
  }
  // if colonoscopyLimitationIds array doesn't have Muu syy we clean it SCOPY_OTHER_LIMITS
  if (!cleanedForm[SCOPY_LIMITATION_IDS]?.includes(5)) {
    cleanedForm = formCleaning(cleanedForm, [SCOPY_OTHER_LIMITS], true);
  }

  // if followUpDecisionId's value is 1 or 5 we clean followUpReferralLocationId
  if (
    cleanedForm[FOLLOW_UP_DECISION_ID] === 1 ||
    cleanedForm[FOLLOW_UP_DECISION_ID] === 5
  ) {
    cleanedForm = formCleaning(
      cleanedForm,
      [FOLLOW_UP_REFERRAL_LOCATION],
      true
    );
  }
  // if colonoscopyDecisionId's value is 1 we clean colonoscopyReferralLocationId
  if (cleanedForm[DECISION_ID] === 1) {
    cleanedForm = formCleaning(cleanedForm, [SCOPY_REFERRAL_LOCATION], true);
  }

  dispatch(
    setSubmitForm(
      Forms.SCOPY,
      formsMutationList[Forms.SCOPY](cleanedForm, isAnalysisAndResult),
      t,
      resetForm
    )
  );
};
