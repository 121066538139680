import { Screening } from '../state/screening/state';
import { Tabs } from '../state/tabs/state';

export const lastEditFun = (screening: Screening, curTab: Tabs) => {
  let lastEdit: { editAt?: string; editBy?: string } = {
    editAt: '',
    editBy: '',
  };
  if (curTab === Tabs.AnalysisResult) {
    lastEdit = {
      editAt: screening.analysisAndResultModifiedAt,
      editBy: screening.analysisAndResultModifiedBy,
    };
  }
  if (curTab === Tabs.Preliminary) {
    lastEdit = {
      editAt: screening.preliminaryInfoModifiedAt,
      editBy: screening.preliminaryInfoModifiedBy,
    };
  }
  if (curTab === Tabs.Scopy) {
    lastEdit = {
      editAt: screening.colonoscopyModifiedAt,
      editBy: screening.colonoscopyModifiedBy,
    };
  }
  if (curTab === Tabs.TTGraphy) {
    lastEdit = {
      editAt: screening.ttColonographyModifiedAt,
      editBy: screening.ttColonographyModifiedBy,
    };
  }
  if (curTab === Tabs.SurgicalProcedure) {
    lastEdit = {
      editAt: screening.surgicalProcedureModifiedAt,
      editBy: screening.surgicalProcedureModifiedBy,
    };
  }
  if (lastEdit.editBy?.trim() && lastEdit.editBy.trim()) {
    return lastEdit;
  } else {
    return null;
  }
};
