import React from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from '../../state/tabs/state';
import { RootState } from '../../state/rootReducer';
import TTGraphy from '../ttgraphyTab/ttgraphy';
import Preliminary from '../preliminaryTab/preliminary';
import Scopy from '../scopyTab/scopy';
import AnalysisResult from '../analysisResultTab/analysisResult';
import SurgicalProcedure from '../surgicalProcedureTab/surgicalProcedure';

const TabSelector: React.FC = () => {
  const { currentTab } = useSelector((state: RootState) => state.tab);
  const allComponent = {
    [Tabs.TTGraphy]: TTGraphy,
    [Tabs.Preliminary]: Preliminary,
    [Tabs.Scopy]: Scopy,
    [Tabs.AnalysisResult]: AnalysisResult,
    [Tabs.SurgicalProcedure]: SurgicalProcedure,
  };

  const CurrentTab = allComponent[currentTab];
  return <CurrentTab />;
};

export default TabSelector;
