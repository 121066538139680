import axios from 'axios';
import { Dispatch } from 'react';
import { TFunction } from 'react-i18next';
import { toast } from 'react-toastify';
import { MUNICIPALITY_GROUP_IDS, ROLE_IDS } from '../constants/adminTabel';
import { MUNICIPALITY_ID } from '../constants/commonKeys';
import { IUser } from '../models/IUser';
import { setScreeningData } from '../state/screening/actions';
import { Screening } from '../state/screening/state';
import { redirectAuthFail } from '../utils/redirectAuthFail';
import userManager from '../utils/userManager';
import { currentUserDataQuery, userDataQuery } from './queries/userDataQueries';

export const FetchCurrentUser = async (t: TFunction): Promise<IUser | null> => {
  const user = await userManager.getUser();
  if (!user) {
    redirectAuthFail(t);
    return null;
  }
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/graph`,
      {
        query: currentUserDataQuery,
        operationName: 'FetchCurrentUser',
      },
      { headers: { Authorization: `${user.token_type} ${user.access_token}` } }
    );

    if (Array.isArray(res.data.errors)) {
      redirectAuthFail(t);
      return null;
    }
    return res?.data?.data?.currentUser;
  } catch (er) {
    toast.error(t('errors.currentUserData'));
    setTimeout(() => redirectAuthFail(t), 2500);
    return null;
  }
};
export const FetchUser = async (
  t: TFunction,
  userId: number,
  screening: Screening,
  dispatch: Dispatch<any>
): Promise<IUser | null> => {
  const user = await userManager.getUser();
  if (!user) {
    redirectAuthFail(t);
    return null;
  }
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/graph`,
      {
        query: userDataQuery(userId, screening),
        operationName: 'FetchUserDetails',
      },
      { headers: { Authorization: `${user.token_type} ${user.access_token}` } }
    );

    if (Array.isArray(res.data.errors)) {
      toast.error(t('errors.userDetail'));
      return null;
    }

    let screeningLists = {};
    if (res?.data?.data?.[ROLE_IDS]) {
      screeningLists = {
        ...screeningLists,
        [ROLE_IDS]: res?.data?.data?.[ROLE_IDS],
      };
    }
    if (res?.data?.data?.[MUNICIPALITY_ID]) {
      screeningLists = {
        ...screeningLists,
        [MUNICIPALITY_ID]: res?.data?.data?.[MUNICIPALITY_ID],
      };
    }
    if (res?.data?.data?.[MUNICIPALITY_GROUP_IDS]) {
      screeningLists = {
        ...screeningLists,
        [MUNICIPALITY_GROUP_IDS]: res?.data?.data?.[MUNICIPALITY_GROUP_IDS],
      };
    }
    if (Object.keys(screeningLists).length) {
      dispatch(setScreeningData(screeningLists));
    }
    return res?.data?.data?.userDetails;
  } catch (er) {
    toast.error(t('errors.userDetail'));
    return null;
  }
};

export const SubmitUser = async (
  t: TFunction,
  query: string
): Promise<IUser | null> => {
  const user = await userManager.getUser();
  if (!user) {
    redirectAuthFail(t);
    return null;
  }
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/graph`,
      {
        query,
        operationName: 'SubmitUserDetails',
      },
      { headers: { Authorization: `${user.token_type} ${user.access_token}` } }
    );

    if (Array.isArray(res.data.errors)) {
      toast.error(t('errors.userDetail'));
      return null;
    }

    return res?.data?.data?.updateUser;
  } catch (er) {
    toast.error(t('errors.userDetail'));
    return null;
  }
};
