import { ColDef } from 'ag-grid-community/dist/lib/main';
import { TFunction } from 'i18next';
import {
  END_DATE,
  MODIFIED_AT,
  MODIFIED_BY,
  MUNICIPALITY_IDS,
  NAME_ID_HAG,
  PERMISSION_STATUS,
  ROLE_IDS,
  START_DATE,
} from '../constants/adminTabel';
import { FIRSTNAME, LASTNAME, MUNICIPALITY_ID } from '../constants/commonKeys';
import { municipalityOptions } from '../features/user/userHelper';
import translationFi from '../locales/fi/strings';
import { Screening } from '../state/screening/state';
import { municipalitiesGroupHelper } from '../utils/municipalitiesgroup';

const UseCreateAdminColumn = (t: TFunction, screening: Screening) => {
  const defaultColDefAdmin: ColDef = {
    filter: false,
    sortable: false,
    editable: false,
    resizable: true,
    autoHeight: true,
    flex: 1,
    menuTabs: [],
    floatingFilter: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
  };

  const adminColumnDefs: ColDef[] = [
    {
      headerName: t(`tableColumns.${FIRSTNAME}`),
      field: FIRSTNAME,
      menuTabs: ['filterMenuTab'],
      filter: 'TextFilter',
      filterParams: {
        title: t(`tableColumns.${FIRSTNAME}`),
        defaultVal: null,
      },
    },
    {
      headerName: t(`tableColumns.${LASTNAME}`),
      field: LASTNAME,
      menuTabs: ['filterMenuTab'],
      filter: 'TextFilter',
      filterParams: {
        title: t(`tableColumns.${LASTNAME}`),
        defaultVal: null,
      },
    },
    {
      headerName: t(`tableColumns.${NAME_ID_HAG}`),
      field: NAME_ID_HAG,
      menuTabs: ['filterMenuTab'],
      filter: 'TextFilter',
      filterParams: {
        title: t(`tableColumns.${NAME_ID_HAG}`),
        defaultVal: null,
      },
    },
    {
      headerName: t(`tableColumns.${MUNICIPALITY_IDS}`),
      field: MUNICIPALITY_IDS,
      filter: true,
      menuTabs: ['filterMenuTab'],
      valueGetter: (v) => {
        let muns = '';

        const { newSelectedValues } = municipalitiesGroupHelper(
          municipalityOptions(screening).filter((el) =>
            v.data?.[MUNICIPALITY_IDS].includes(el.value)
          ),
          municipalityOptions(screening)
        );

        if (newSelectedValues) {
          newSelectedValues.forEach((el) => {
            if (el.hasSub && el.subOption) {
              muns += `${t(`${MUNICIPALITY_IDS}.${el.langValue}`)} `;
            } else {
              muns += `${t(`${MUNICIPALITY_ID}.${el.langValue}`)} `;
            }
          });
        }
        return muns;
      },
    },
    {
      headerName: t(`tableColumns.${ROLE_IDS}`),
      field: ROLE_IDS,
      valueGetter: (v) => {
        let roles = '';
        if (v.data?.[ROLE_IDS] && Array.isArray(v.data[ROLE_IDS])) {
          v.data[ROLE_IDS].forEach(
            (el: string) =>
              (roles += `${t(`${ROLE_IDS}.${el}`)}
          `)
          );
        }
        return roles;
      },
      filter: 'RadioFilter',
      menuTabs: ['filterMenuTab'],
      floatingFilter: true,
      filterParams: {
        title: t(`tableColumns.${ROLE_IDS}`),
        name: ROLE_IDS,
        options: Object.entries(translationFi[ROLE_IDS]).map((el) => ({
          langValue: el[0],
          value: el[0],
        })),
        defaultVal: '',
      },
    },
    {
      headerName: t(`tableColumns.${PERMISSION_STATUS}`),
      field: PERMISSION_STATUS,
      menuTabs: ['filterMenuTab'],
      filter: 'RadioFilter',
      floatingFilter: true,
      filterParams: {
        title: t(`tableColumns.${PERMISSION_STATUS}`),
        name: PERMISSION_STATUS,
        options: Object.entries(translationFi[PERMISSION_STATUS]).map((el) => ({
          langValue: el[0],
          value: el[0],
        })),
        defaultVal: '',
      },
    },
    {
      headerName: t(`tableColumns.${START_DATE}`),
      field: START_DATE,
      filter: true,
      menuTabs: ['filterMenuTab'],
    },
    {
      headerName: t(`tableColumns.${END_DATE}`),
      field: END_DATE,
      filter: true,
      menuTabs: ['filterMenuTab'],
    },
    {
      headerName: t(`tableColumns.${MODIFIED_BY}`),
      field: MODIFIED_BY,
      valueGetter: (e) => {
        return `${e.data?.[MODIFIED_BY]} ${e.data?.[MODIFIED_AT]}`;
      },

      menuTabs: ['filterMenuTab'],
    },
  ];
  return { adminColumnDefs, defaultColDefAdmin };
};

export default UseCreateAdminColumn;
