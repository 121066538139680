import { TFunction } from 'i18next';
import axios from 'axios';
import { toast } from 'react-toastify';
import userManager from '../utils/userManager';
import { IPdfImportSearchForm, IPdfSubmitForm } from '../models/IPdfImport';
import {
  fetchFailedImportsQuery,
  pdfImportMutation,
} from './queries/pdfImportQueries';
import { redirectAuthFail } from '../utils/redirectAuthFail';

export const FetchPdfImports = async (
  t: TFunction,
  formData: IPdfImportSearchForm
) => {
  const user = await userManager.getUser();
  if (!user) {
    redirectAuthFail(t);
    return null;
  }

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/graph`,
      {
        query: fetchFailedImportsQuery(formData),
        operationName: 'failedPdfImports',
      },
      { headers: { Authorization: `${user.token_type} ${user.access_token}` } }
    );

    if (Array.isArray(res.data.errors)) {
      toast.error(t('errors.userSearch'));
      return null;
    }
    return res?.data?.data;
  } catch (er) {
    toast.error(t('errors.userSearch'));
    return null;
  }
};

export const SubmitPreliminaryInfoPdfData = async (
  t: TFunction,
  formData: IPdfSubmitForm
) => {
  const user = await userManager.getUser();
  if (!user) {
    redirectAuthFail(t);
    return null;
  }

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/graph`,
      {
        query: pdfImportMutation(formData),
        operationName: 'UpdatePreliminaryInfoPdf',
      },
      { headers: { Authorization: `${user.token_type} ${user.access_token}` } }
    );

    if (Array.isArray(res.data.errors)) {
      toast.error(t('errors.userSearch'));
      return null;
    }
  } catch (er) {
    toast.error(t('errors.userSearch'));
    return null;
  }
};
