export const isSameobject = (obj1: any, obj2: any): boolean => {
  let same = true;
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }
  Object.keys(obj1).forEach((el) => {
    if (obj1[el] !== obj2[el]) {
      same = false;
    }
  });
  return same;
};
