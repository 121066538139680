import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  boxClosed,
  FetchAnnouncementData,
} from '../../state/announcements/actions';
import { RootState } from '../../state/rootReducer';
import styles from './announcementBox.module.scss';
import { IAnnouncement } from '../../models/IAnnouncement';

const AnnouncementBox: React.FC<{
  announcements: IAnnouncement[];
  warning: boolean;
}> = ({ announcements, warning }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <div
      className={`${styles.box} ${
        warning ? styles.warning : styles.info
      } container__col-12}`}
    >
      <div className={`${styles.title} container__col-12`}>
        <span className={styles.titleContent}>
          {t('announcementsBox.title')}
        </span>
        <div onClick={() => dispatch(boxClosed(warning))}>x</div>
      </div>
      <div className={`${styles.content} container__col-12`}>
        {announcements.map((a, ind) => {
          return (
            <div className={styles.contentContainer} key={ind}>
              <div className={styles.announcementTitle}>{a.title}</div>
              <div className={styles.announcementBody}>{a.text}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const AnnouncementBoxes: React.FC = () => {
  const dispatch = useDispatch();

  const { data, infoClosed, warningClosed, haveData } = useSelector(
    (state: RootState) => state.announcements
  );

  useEffect(() => {
    if (haveData) {
      return;
    }
    dispatch(FetchAnnouncementData());
  }, []);

  return (
    <>
      {data.filter((el) => el.warning).length !== 0 && !warningClosed && (
        <AnnouncementBox
          announcements={data.filter((el) => el.warning)}
          warning={true}
        />
      )}
      {data.filter((el) => !el.warning).length !== 0 && !infoClosed && (
        <AnnouncementBox
          announcements={data.filter((el) => !el.warning)}
          warning={false}
        />
      )}
    </>
  );
};

export default AnnouncementBoxes;
