import React from 'react';
import { useHistory } from 'react-router-dom';
import { CallbackComponent } from 'redux-oidc';
import userManager from '../../utils/userManager';

const SigninOidc: React.FC = () => {
  const history = useHistory();

  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={async (user) => {
        if (user) {
          history.push({
            pathname: `${
              user?.state?.redirectPath ? user.state.redirectPath : '/'
            }`,
            state: { user },
          });
        } else {
          window.location.href = 'https://www.hus.fi/seula-pro';
        }
      }}
      errorCallback={() => {
        window.location.href = 'https://www.hus.fi/seula-pro';
      }}
    >
      <div />
    </CallbackComponent>
  );
};

export default SigninOidc;
