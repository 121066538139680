import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../tabSelectorControl/tabSelectorControl.module.scss';

interface IPdfCategoryTab {
  tabs: { label: string; selected: boolean }[];
}

const PdfCategoryTab: React.FC<IPdfCategoryTab> = (props: IPdfCategoryTab) => {
  const { t } = useTranslation();

  return (
    <ol className={styles.tabs}>
      {props.tabs.map((tab) => (
        <li className={`${styles.tab} ${tab.selected ? styles.selected : ''}`}>
          {tab.label}
        </li>
      ))}
    </ol>
  );
};

export default PdfCategoryTab;
