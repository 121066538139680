import moment from 'moment';
import {
  CLASSIFOCATION_ID,
  TTGRAPHY_DATE,
  TTGRAPHY_FINDING_IDS,
  TTGRAPHY_LOCATION,
  MUTAIION_LOCATION_ID,
  OTHER_CLASSIFOCATION_ID,
  RADIOLOGIST_ID,
  RADIOLOGIST_NAME,
  RADIOLOGIST_STATMENT,
  RADIOLOGIST_STATMENT_DATE,
  RADIOLOGIST_STATMENT_LOCATION,
} from '../constants/ttgraphyFromKeys';
import {
  ADITIONAL_INFO,
  IS_INERRUPTED,
  LARGEST_POLY_DIAMETER_ID,
  NUMBER_POLYP_ID,
  SAMPLE_ID,
  SCREENING_ID,
} from '../constants/commonKeys';
import {
  FOLLOW_UP_BY_ID,
  FOLLOW_UP_BY_NAME,
  FOLLOW_UP_DATE,
  FOLLOW_UP_DECISION_ID,
  FOLLOW_UP_LOCATION,
  FOLLOW_UP_REFERRAL_LOCATION,
} from '../constants/followUp';
import { IOption, optionInitial } from './IOption';

export interface IColongraphyFrom {
  [SAMPLE_ID]: number;
  [ADITIONAL_INFO]: string | null;
  [TTGRAPHY_DATE]: moment.Moment | null;
  [TTGRAPHY_LOCATION]: number | null;
  [TTGRAPHY_FINDING_IDS]: number[];
  [MUTAIION_LOCATION_ID]: number | null;
  [NUMBER_POLYP_ID]: number | null;
  [LARGEST_POLY_DIAMETER_ID]: number | null;
  [CLASSIFOCATION_ID]: number | null;
  [OTHER_CLASSIFOCATION_ID]: number | null;
  [RADIOLOGIST_STATMENT]: string | null;
  [RADIOLOGIST_STATMENT_DATE]: moment.Moment | null;
  [RADIOLOGIST_STATMENT_LOCATION]: number | null;
  [RADIOLOGIST_ID]: string | null;
  [RADIOLOGIST_NAME]: string;
  [IS_INERRUPTED]: boolean | null;
  [SCREENING_ID]: number;
  [FOLLOW_UP_BY_ID]: number | '';
  [FOLLOW_UP_BY_NAME]: string;
  [FOLLOW_UP_DECISION_ID]: number | null;
  [FOLLOW_UP_REFERRAL_LOCATION]: number | null;
  [FOLLOW_UP_LOCATION]: number | null;
  [FOLLOW_UP_DATE]: moment.Moment | null;
}

export const colonographyFromInitial: IColongraphyFrom = {
  [SAMPLE_ID]: 0,
  [ADITIONAL_INFO]: null,
  [IS_INERRUPTED]: null,
  [TTGRAPHY_DATE]: null,
  [TTGRAPHY_LOCATION]: null,
  [LARGEST_POLY_DIAMETER_ID]: null,
  [TTGRAPHY_FINDING_IDS]: [],
  [MUTAIION_LOCATION_ID]: null,
  [NUMBER_POLYP_ID]: null,
  [CLASSIFOCATION_ID]: null,
  [OTHER_CLASSIFOCATION_ID]: null,
  [RADIOLOGIST_STATMENT]: null,
  [RADIOLOGIST_STATMENT_DATE]: null,
  [RADIOLOGIST_STATMENT_LOCATION]: null,
  [RADIOLOGIST_ID]: null,
  [RADIOLOGIST_NAME]: '',
  [SCREENING_ID]: 0,
  [FOLLOW_UP_BY_ID]: '',
  [FOLLOW_UP_BY_NAME]: '',
  [FOLLOW_UP_DECISION_ID]: null,
  [FOLLOW_UP_REFERRAL_LOCATION]: null,
  [FOLLOW_UP_LOCATION]: null,
  [FOLLOW_UP_DATE]: null,
};

export type DropDownsKeys =
  | typeof NUMBER_POLYP_ID
  | typeof MUTAIION_LOCATION_ID
  | typeof TTGRAPHY_FINDING_IDS
  | typeof CLASSIFOCATION_ID
  | typeof LARGEST_POLY_DIAMETER_ID
  | typeof FOLLOW_UP_DECISION_ID
  | typeof OTHER_CLASSIFOCATION_ID;

export type IColongrapgDropdowns = {
  [key in DropDownsKeys]: IOption[];
};

export const colonGraphDataInitial: IColongrapgDropdowns = {
  [NUMBER_POLYP_ID]: [optionInitial],
  [MUTAIION_LOCATION_ID]: [optionInitial],
  [TTGRAPHY_FINDING_IDS]: [optionInitial],
  [CLASSIFOCATION_ID]: [optionInitial],
  [OTHER_CLASSIFOCATION_ID]: [optionInitial],
  [LARGEST_POLY_DIAMETER_ID]: [optionInitial],
  [FOLLOW_UP_DECISION_ID]: [optionInitial],
};
