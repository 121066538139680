export enum Tabs {
  Preliminary = 'preliminaryTab',
  AnalysisResult = 'analysisResultTab',
  Scopy = 'scopyTab',
  TTGraphy = 'ttgraphyTab',
  SurgicalProcedure = 'surgicalProcedureTab',
}

export interface Tab {
  currentTab: Tabs;
}
