import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BreadCrums from '../../components/breadCrums/breadCrumbs';
import { Button } from '../../components/button/button';
import CheckboxGroup from '../../components/checkboxGroup/checkboxGroup';
import MunicipalitySelect from '../../components/municipalityselect/selectTasksCheckboxes';
import SectionHeader from '../../components/sectionHeader/sectionHeader';
import {
  COMMENT,
  CREATED_AT,
  EMAIL,
  END_DATE,
  IS_DISABLED,
  MODIFIED_AT,
  MODIFIED_BY,
  MUNICIPALITY_IDS,
  NAME_ID_HAG,
  PHONE_NUMBER,
  ROLE_IDS,
  START_DATE,
  USER_ID,
  EXPIRED_TEXT,
} from '../../constants/adminTabel';
import {
  FIRSTNAME,
  LASTNAME,
  MUNICIPALITY_ID,
  ORGANIZATION,
} from '../../constants/commonKeys';
import { IOption } from '../../models/IOption';
import { initialUserForm, IUser } from '../../models/IUser';
import { formsMutationList } from '../../services/queries/formsQueryHelper';
import { FetchUser, SubmitUser } from '../../services/UserService';
import { RootState } from '../../state/rootReducer';
import { changeToValue } from '../../utils/municipalitiesgroup';
import { timeformat } from '../../utils/timeFormat';
import DatePickerLabel from '../formInputs/datePickerLabel';
import TextInputLabel from '../formInputs/textInputLabel';
import styles from './user.module.scss';
import { municipalityOptions, userAdminValidation } from './userHelper';

const User: React.FC<{ userId: string }> = ({ userId }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const validation = userAdminValidation(t);
  const { screening } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(+userId ? false : true);
  const [userAdminForm, setUserAdminForm] = useState<IUser>(initialUserForm);
  const municipalitiesOption = municipalityOptions(screening);
  const newUser = !+userId || +userId === 0;

  useEffect(() => {
    let mount = true;
    if (!newUser) {
      FetchUser(t, +userId, screening, dispatch).then((user) => {
        if (user && mount) {
          setUserAdminForm(timeformat([user], [MODIFIED_AT, CREATED_AT])[0]);
        }
      });
    }
    return () => {
      mount = false;
    };
  }, []);

  return (
    <div className="container--fluid">
      <BreadCrums
        links={[
          { to: '/admin', name: 'breadCrumbs.home', disable: false },
          { to: '/', name: 'breadCrumbs.user', disable: true },
        ]}
      />
      <SectionHeader src="userForm" name="adminUserHeader" />
      <Formik
        initialValues={userAdminForm}
        enableReinitialize={true}
        validationSchema={validation}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        onSubmit={(form) => {
          form[MUNICIPALITY_IDS] = changeToValue(
            municipalitiesOption,
            form[MUNICIPALITY_IDS]
          );
          SubmitUser(t, formsMutationList.user(form)).then((re) => {
            if (!re) {
              setEdit(false);
              return;
            }
            setEdit(false);
            if (!newUser) {
              setUserAdminForm(timeformat([re], [MODIFIED_AT, CREATED_AT])[0]);
            } else {
              history.push(`/admin/${re[USER_ID]}`);
            }
          });
        }}
      >
        {({ values }) => (
          <Form>
            {!newUser ? (
              <div className={styles.sliderContainer}>
                <p>
                  {t(`userForm.${values[IS_DISABLED] ? 'inactive' : 'active'}`)}
                </p>
                <label className={styles.switch}>
                  <input
                    type="checkbox"
                    onChange={(v) => {
                      SubmitUser(
                        t,
                        formsMutationList.user({
                          ...userAdminForm,
                          [IS_DISABLED]: !v.target.checked,
                        })
                      ).then((re) => {
                        if (!re) {
                          setEdit(false);
                          return;
                        }
                        setUserAdminForm(
                          timeformat([re], [MODIFIED_AT, CREATED_AT])[0]
                        );
                        setEdit(false);
                      });
                    }}
                    checked={!values[IS_DISABLED]}
                    name={IS_DISABLED}
                  />
                  <span className={styles.slider}></span>
                </label>
              </div>
            ) : null}

            <div className="container__row ">
              <Button
                className={`${styles.formButton} grid-4-1st`}
                onClick={() => setEdit(true)}
                disabled={edit}
                noSpinner={true}
              >
                {t('control.edit')}
              </Button>
              {!newUser && (
                <div className="grid-4-2nd align-end ">
                  <p>
                    {`${t(`userForm.${MODIFIED_AT}`)}: ${
                      values[MODIFIED_AT] || ''
                    }`}{' '}
                  </p>
                  &nbsp;
                  <p>
                    {`${t(`userForm.${MODIFIED_BY}`)}: ${
                      values[MODIFIED_BY] || ''
                    }`}{' '}
                  </p>
                </div>
              )}
            </div>

            <div className="container__row ">
              <div className="grid-4-1st">
                <TextInputLabel
                  src={`userForm.${FIRSTNAME}`}
                  disabled={!edit}
                />
              </div>
              <div className="grid-4-2nd">
                <TextInputLabel src={`userForm.${LASTNAME}`} disabled={!edit} />
              </div>
              <div className="grid-4-3rd">
                <TextInputLabel
                  src={`userForm.${NAME_ID_HAG}`}
                  disabled={!edit}
                />
              </div>
              <div className="grid-4-4th">
                <TextInputLabel
                  src={`userForm.${ORGANIZATION}`}
                  disabled={!edit}
                />
              </div>
            </div>
            <div className="container__row ">
              <div className="grid-4-1st">
                <TextInputLabel src={`userForm.${EMAIL}`} disabled={!edit} />
              </div>
              <div className="grid-4-2nd">
                <TextInputLabel
                  src={`userForm.${PHONE_NUMBER}`}
                  disabled={!edit}
                />
              </div>
            </div>
            <div className="container__row ">
              <div className="grid-4-1st">
                <DatePickerLabel
                  src={`userForm.${START_DATE}`}
                  disabled={!edit}
                />
              </div>
              <div className="grid-4-2nd">
                <DatePickerLabel
                  src={`userForm.${END_DATE}`}
                  disabled={!edit}
                />
              </div>
              {!newUser && (
                <div className="grid-4-3rd align-center">
                  {`${t(`userForm.${CREATED_AT}`)}: ${
                    values[CREATED_AT] || ''
                  }`}
                </div>
              )}
            </div>

            <div className="container__row ">
              <div className="grid-2-1st">
                <MunicipalitySelect
                  disabled={!edit}
                  src={`userForm.${MUNICIPALITY_IDS}`}
                  parentSrc={MUNICIPALITY_ID}
                  options={municipalitiesOption}
                />
              </div>
              {edit && (
                <div className="grid-2-2st">
                  <span className={styles.expiredText}>
                    {t(`userForm.${EXPIRED_TEXT}`)}
                  </span>
                </div>
              )}
            </div>
            <SectionHeader src="userForm" name="adminUserRole" />
            <div className="container__row ">
              <div className="container__row ">
                <CheckboxGroup
                  isGroupDisabled={!edit}
                  isCheckboxDisabled={(item: IOption) => {
                    if (
                      (Number(item.value) === 6 &&
                        values.roleIds?.includes(7)) ||
                      (Number(item.value) === 7 && values.roleIds?.includes(6))
                    ) {
                      return true;
                    }
                    return false;
                  }}
                  name={ROLE_IDS}
                  items={
                    screening[ROLE_IDS]?.filter(
                      (el) => '' + el.value !== '3'
                    ) || []
                  }
                />
              </div>
              <div className="grid-3-1st">
                <TextInputLabel src={`userForm.${COMMENT}`} disabled={!edit} />
              </div>
            </div>

            <div className="container__row space-between ">
              <Button
                noSpinner={true}
                disabled={!edit}
                type="button"
                onClick={() => setEdit(false)}
                className={styles.formButton}
              >
                {t('control.quit')}
              </Button>
              <Button
                type="submit"
                className={styles.formButton}
                disabled={!edit}
                noSpinner={true}
              >
                {t('control.save')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default User;
