const componentlocation = (selector: string) => {
  const coords =
    document.querySelector(selector)?.getBoundingClientRect().left || 0;
  const clientWidth = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
  const componentLocation = window.scrollY + coords;
  return {
    isLeft: () => {
      return (clientWidth - 70) / 2 >= componentLocation;
    },
  };
};
export default componentlocation;
