import { ISetFilterParams } from 'ag-grid-community';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styles from './textFilter.module.scss';

interface ITextProps extends ISetFilterParams {
  defaultVal: string;
  title: string;
}

export default forwardRef((props: ITextProps, ref) => {
  const { defaultVal, title } = props;
  const [keyWord, setKeyWord] = useState(defaultVal || '');
  const [keyWordFinal, setKeyWordFinal] = useState(defaultVal || '');
  const { t } = useTranslation();
  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return keyWord != null && keyWord !== '';
      },
      getModel() {
        return keyWord;
      },

      getModelAsString() {
        if (keyWord != null && keyWord !== '') {
          return keyWord;
        }
        return '';
      },
    };
  });

  useEffect(() => {
    props.filterChangedCallback();
  }, [keyWordFinal]);

  return (
    <div className={styles.container}>
      <p>{title}</p>

      <input
        type="text"
        value={keyWord}
        onChange={(event) => setKeyWord(event.target.value)}
      />
      <div className={styles.control}>
        <button
          className={styles.ok}
          onClick={() => {
            if (keyWord !== keyWordFinal) {
              setKeyWordFinal(keyWord);
            }
          }}
        >
          {t('agGrid.confirm')}
        </button>
        <button
          className={styles.cancel}
          onClick={() => {
            setKeyWordFinal('');
            setKeyWord('');
          }}
        >
          {t('agGrid.remove')}
        </button>
      </div>
    </div>
  );
});
