import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import defineLAng from '../../utils/defineLanguage';
import { setLanguage } from '../../state/language/actions';

const LaunguageGuard: React.FC<RouteComponentProps> = ({
  history,
}: RouteComponentProps) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    return history.listen(() => {
      const currentLan = defineLAng();
      i18n.changeLanguage(currentLan);
      dispatch(setLanguage(currentLan));
    });
  }, []);

  return <></>;
};

export default LaunguageGuard;
