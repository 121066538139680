import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/rootReducer';
import styles from './loader.module.scss';

const Loader: React.FC = () => {
  const { t } = useTranslation();
  const { isLoading } = useSelector((state: RootState) => state.screening);
  const [run, setRun] = useState(false);
  useEffect(() => {
    if (isLoading) {
      const timer = setTimeout(() => {
        setRun(true);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      setRun(false);
    }
  }, [isLoading]);

  if (!run) return <></>;
  return (
    <div className={styles.loaderContainer}>
      <div className={styles.spinner}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div>
          <h3>{t('pleaseWait')}...</h3>
        </div>
      </div>
    </div>
  );
};
export default Loader;
