import moment from 'moment';

export const timeformat = (arr: any, keys: string[]) => {
  arr.forEach((el: any) => {
    keys.forEach((key) => {
      if (el[key] && moment(el[key]).isValid()) {
        el[key] = moment(el[key])?.format('DD.MM.YYYY');
      }
    });
  });
  return arr;
};

// export const Timeformat = <T extends {}>(arr: T[], keys: (keyof T)[]): T[] => {
//   arr.forEach((el) => {
//     keys.forEach((key) => {
//       if (el[key] && moment(el[key]).isValid()) {
//         el[key] = moment(el[key])?.format('DD.MM.YYYY');
//       }
//     });
//   });
//   return arr;
// };
