import { ISetFilterParams } from 'ag-grid-community';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { IOption } from '../../models/IOption';
import styles from './radioFilter.module.scss';

interface IRadioProps extends ISetFilterParams {
  defaultVal: string;
  title: string;
  options: IOption[];
  name: string | null;
}

export default forwardRef((props: IRadioProps, ref) => {
  const { defaultVal, title, options, name } = props;
  const [value, setValue] = useState(defaultVal);
  const { t } = useTranslation();
  const [optionLocal, setOption] = useState(options);

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return !!value && value !== 'DEFAULT' && value !== 'null';
      },
      getModel() {
        return value;
      },
      refreshOptions(option: IOption[]) {
        setOption((pre) =>
          [...pre, ...option].filter(
            (el, index, self) =>
              index === self.findIndex((item) => item.value === el.value)
          )
        );
      },
      getModelAsString() {
        if (value && value !== 'DEFAULT' && value !== 'null') {
          const langValue = optionLocal.find((el) => '' + el.value === value)
            ?.langValue;
          return name ? t(`${name}.${langValue}`) : langValue;
        }
        return '';
      },
    };
  });

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    props.filterChangedCallback();
  }, [value]);

  return (
    <div className={styles.container}>
      <p>{title}</p>
      {optionLocal
        .map((el) => ({
          value: el.value,
          label: name ? t(`${name}.${el.langValue}`) : el.langValue,
        }))
        .sort((a, b) => {
          if (a.label === 'empty' || b.label === 'empty') {
            return 0;
          }
          if (a.value < b.value) {
            return -1;
          }
          if (a.value > b.value) {
            return 1;
          }
          return 0;
        })
        .map((el: IOption, inx) => {
          return (
            <p key={inx}>
              <label>
                <input
                  type="radio"
                  name="status"
                  value={el.value}
                  onChange={onChange}
                  checked={value === '' + el.value}
                />
                {el.label}
              </label>
            </p>
          );
        })}
    </div>
  );
});
