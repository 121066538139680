import React from 'react';
import Admin from '../../features/admin/admin';
import Layout from '../../features/layout/layout';

const AdminPage: React.FC = () => {
  return (
    <Layout>
      <Admin />
    </Layout>
  );
};

export default AdminPage;
