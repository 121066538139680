import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { IOptionWithSub } from '../../components/municipalityselect/selectTasksCheckboxes';
import {
  EMAIL,
  MUNICIPALITY_GROUP_IDS,
  NAME_ID_HAG,
} from '../../constants/adminTabel';
import {
  FIRSTNAME,
  LASTNAME,
  MUNICIPALITY_ID,
} from '../../constants/commonKeys';
import { IMunicipalityGroup, Screening } from '../../state/screening/state';

export const userAdminValidation = (t: TFunction) =>
  Yup.object().shape({
    [FIRSTNAME]: Yup.string().nullable().required(t('forms.requiredField')),
    [LASTNAME]: Yup.string().nullable().required(t('forms.requiredField')),
    [NAME_ID_HAG]: Yup.string().nullable().required(t('forms.requiredField')),
    [EMAIL]: Yup.string().nullable().required(t('forms.requiredField')),
  });

export const municipalityOptions = (screening: Screening): IOptionWithSub[] => {
  const munsGroup = screening[MUNICIPALITY_GROUP_IDS].reduce(
    (pre: IOptionWithSub[], cur: IMunicipalityGroup) => {
      const subMuns = cur.municipalities?.map((mun) => ({
        ...mun,
        hasSub: false,
        parentId: +cur.municipalityGroupId + 7000,
      }));
      pre.push(
        ...[
          {
            value: cur.municipalityGroupId + 7000,
            langValue: '' + cur.municipalityGroupId,
            subOption: subMuns,
            hasSub: true,
          },
          ...subMuns,
        ]
      );

      return pre;
    },
    []
  );

  const allGroupMunIds = screening[MUNICIPALITY_GROUP_IDS].reduce(
    (pre: number[], curr) => {
      return [...pre, ...curr.municipalities.map((el) => +el.value)];
    },
    []
  );

  const muns = screening[MUNICIPALITY_ID].filter(
    (mun) => !allGroupMunIds.includes(+mun.value)
  ).map((el) => ({
    ...el,
    hasSub: null,
  }));

  return [...munsGroup, ...muns];
};
