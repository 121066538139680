import React from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../features/layout/layout';
import User from '../../features/user/user';

const UserPage: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();

  return (
    <Layout>
      <User userId={userId} />
    </Layout>
  );
};

export default UserPage;
