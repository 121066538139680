import { TFunction } from 'i18next';
import moment from 'moment';
import { Dispatch } from 'react';
import {
  COMMENT,
  COULD_NOT_BE_REACHED_AT,
  FIRST_NAMES,
  SURNAME,
} from '../../constants/commonKeys';
import { PHONE } from '../../constants/userInfoKeys';
import { validationNumberValues } from '../../constantsLists/validationLists';
import { IPersonForm } from '../../models/IUSerInfo';
import { formsMutationList } from '../../services/queries/formsQueryHelper';
import { setSubmitForm } from '../../state/screening/actions';
import { Forms } from '../../state/screening/state';
import * as Yup from 'yup';

export const submitPersonForm = (
  form: IPersonForm,
  resetForm: () => void,
  dispatch: Dispatch<any>,
  t: TFunction
) => {
  if (form[COULD_NOT_BE_REACHED_AT]) {
    form[COULD_NOT_BE_REACHED_AT] = moment(form[COULD_NOT_BE_REACHED_AT])
      .startOf('day')
      .format('YYYY-MM-DDTHH:mm:ss');
  }
  dispatch(
    setSubmitForm(
      Forms.PERSON,
      formsMutationList[Forms.PERSON](form),
      t,
      resetForm
    )
  );
};

export const personFormSchema = (t: TFunction) =>
  Yup.object().shape({
    [FIRST_NAMES]: Yup.string()
      .typeError(t('forms.string'))
      .required(t('forms.requiredField'))
      .max(
        validationNumberValues[FIRST_NAMES].max,
        t('forms.mustBeStringMax', {
          maxCharacter: validationNumberValues[FIRST_NAMES].max,
        })
      ),
    [SURNAME]: Yup.string()
      .typeError(t('forms.string'))
      .required(t('forms.requiredField'))
      .max(
        validationNumberValues[SURNAME].max,
        t('forms.mustBeStringMax', {
          maxCharacter: validationNumberValues[SURNAME].max,
        })
      ),
    [PHONE]: Yup.string()
      .nullable()
      .typeError(t('forms.string'))
      .max(
        validationNumberValues[PHONE].max,
        t('forms.mustBeStringMax', {
          maxCharacter: validationNumberValues[PHONE].max,
        })
      ),
    [COULD_NOT_BE_REACHED_AT]: Yup.date()
      .nullable()
      .test(COULD_NOT_BE_REACHED_AT, t('forms.futureData'), (val) => {
        if (!val) return true;
        return moment(val).isSameOrBefore(moment(), 'day');
      }),
    [COMMENT]: Yup.string()
      .nullable()
      .typeError(t('forms.string'))
      .max(
        validationNumberValues[COMMENT].max,
        t('forms.mustBeStringMax', {
          maxCharacter: validationNumberValues[COMMENT].max,
        })
      ),
  });
