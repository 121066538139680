import { useField } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import {
  get_result_ids,
  get_result_titles,
  run_search,
  search_by_id,
} from 'wasm_dropdown';
import TooltipLabel from '../../components/tooltipLabel/tooltipLabel';
import { IOption } from '../../models/IOption';
import { RootState } from '../../state/rootReducer';
import styles from './asyncDropdown.module.scss';

interface AsyncDropdownProps {
  src: string;
  store: number;
  defaultOptions?: IOption[];
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  labelClass?: string;
  wrapperClassName?: string;
  hideErros?: boolean;
  errorClassName?: string;
}

const AsyncDropdown: React.FC<AsyncDropdownProps> = ({
  store,
  src,
  disabled,
  placeholder,
  required,
  labelClass,
  wrapperClassName,
  hideErros,
  errorClassName,
}) => {
  const [formName, name] = src.split('.');
  const { hasLocations } = useSelector((state: RootState) => state.screening);
  const [field, form, meta] = useField(name);

  const getDataFromWasm = () => {
    const labels = get_result_titles(store).split('\0');
    const values = get_result_ids(store);
    const results: IOption[] = [];
    values.forEach((x, i) => results.push({ label: labels[i], value: `${x}` }));
    return results;
  };

  const loadOptions = (
    inputValue: string,
    callback: (options: IOption[]) => void
  ) => {
    if (!hasLocations || !run_search) return [];
    run_search(store, inputValue);
    const values = getDataFromWasm();
    callback(
      store !== 1 ? values : values.filter((x) => x.label !== 'Ei tiedossa')
    );
  };

  const findById = (id: string) => {
    search_by_id(store, +id);
    const values = getDataFromWasm();
    return values.length !== 0 ? values : [];
  };
  return (
    <div
      className={`${styles.dropdownWrapper} ${wrapperClassName}`}
      data-testid={name}
    >
      <TooltipLabel
        label={formName}
        name={name}
        labelClassName={`${styles.label} ${
          required ? styles.required : ''
        } ${labelClass}`}
        required={required}
      />
      <AsyncSelect
        defaultOptions={
          field.value || field.value === 0 ? findById(field.value) : []
        }
        loadOptions={loadOptions}
        onChange={(value) =>
          value?.value ? meta.setValue(value.value) : meta.setValue(null)
        }
        value={
          field.value || field.value === 0
            ? findById(field.value)
            : ({} as IOption)
        }
        name={name}
        isClearable={true}
        isDisabled={disabled}
        placeholder={placeholder}
      />
      {form.error && !hideErros ? (
        <div className={`${styles.error} ${errorClassName}`}>{form.error}</div>
      ) : null}
    </div>
  );
};

AsyncDropdown.defaultProps = {
  disabled: false,
  placeholder: '',
  defaultOptions: [],
  required: false,
};

export default AsyncDropdown;
