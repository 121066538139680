import React from 'react';
import Layout from '../../features/layout/layout';
import PdfImportPageContent from '../../features/pdfImport/pdfImportPageContent';

const PdfImportPage: React.FC = () => {
  return (
    <Layout>
      <PdfImportPageContent />
    </Layout>
  );
};

export default PdfImportPage;
