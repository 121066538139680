import { ICellEditorParams } from 'ag-grid-community';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { IOption } from '../../models/IOption';
import styles from './gridCustomSelect.module.scss';
export interface IforwardRef extends ICellEditorParams {
  options: IOption[];
  labelSrc: string;
}

export default forwardRef(
  (
    { options, labelSrc, value }: IforwardRef,
    ref: React.ForwardedRef<unknown>
  ): any => {
    const [val, setVal] = useState<any>(value);
    const { t } = useTranslation();

    useImperativeHandle(ref, () => {
      return {
        getValue: () => val,
      };
    });
    return (
      <Select
        isClearable={true}
        styles={{
          menuList: (provided) => ({
            ...provided,
            padding: 0,
            margin: 0,
          }),
          control: (provided) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '100%',
            height: '100%',
          }),

          valueContainer: (provided) => ({
            ...provided,
            height: '100%',
            padding: '0 6px',
          }),

          input: (provided) => ({
            ...provided,
            margin: '0px',
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            height: '100%',
          }),
        }}
        menuPortalTarget={document.body}
        options={options.map((el) => ({
          ...el,
          label: t(`${labelSrc}.${el.langValue}`) || '',
        }))}
        classNamePrefix={styles.selectAg}
        value={options
          .filter((el) => {
            return (
              val === JSON.parse(el.value) ||
              val === t(`${labelSrc}.${el.langValue}`)
            );
          })
          .map((el) => ({
            value: el.value,
            label: t(`${labelSrc}.${el.langValue}`),
          }))}
        placeholder={t('forms.select')}
        onChange={(e: any) => {
          if (!e?.value || !+e.value) {
            return setVal(null);
          }
          setVal(+e.value);
        }}
      />
    );
  }
);
