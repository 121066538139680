import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import DatePickerLabel from '../formInputs/datePickerLabel';
import { Button } from '../../components/button/button';
import { useTranslation } from 'react-i18next';
import styles from './pdfImportPageContent.module.scss';
import PdfCategoryTab from './pdfCategoryTab';
import { AgGridReact } from 'ag-grid-react';
import ActionsCellRenderer from './actionsCellRenderer';
import LinkCellRenderer from './linkCellRenderer';
import {
  FetchPdfImports,
  SubmitPreliminaryInfoPdfData,
} from '../../services/searchPdfImportsService';
import {
  IPdfImportSearchForm,
  IPreliminaryInfoPdf,
} from '../../models/IPdfImport';
import { ValueGetterParams } from 'ag-grid-community';
import { CREATED_AT, DATE } from '../../constants/pdfImportKeys';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/rootReducer';

const PdfImportPageContent: React.FC = () => {
  const { t } = useTranslation();
  const [rows, setRows] = useState<IPreliminaryInfoPdf[]>([]);
  const [currentlyEditingFilename, setCurrentlyEditingFilename] = useState('');
  const userRoleIds = useSelector((state: RootState) => state.user.roleIds);

  const fetchRowData = (form: IPdfImportSearchForm) => {
    if (form[DATE] !== undefined && !form[DATE]) {
      delete form[DATE];
    }
    if (form[DATE]) {
      form[DATE] = moment(form[DATE]).format('YYYY-MM-DD');
    }
    FetchPdfImports(t, form).then((data) => {
      setRows(data.pdfImports.failed.results);
    });
  };

  const handleSubmitData = (filename: string, controlCode: string, formVersion: string) => {
    SubmitPreliminaryInfoPdfData(t, { filename, controlCode, formVersion }).then(() => {
      setCurrentlyEditingFilename('');
      setRows(
        rows.filter((file: IPreliminaryInfoPdf) => {
          return file.filename !== filename;
        })
      );
    });
  };

  const isValidControlCode = (controlCode: string) => {
    if (
      userRoleIds &&
      userRoleIds.includes(6) &&
      !controlCode.startsWith('H')
    ) {
      return false;
    } else if (
      userRoleIds &&
      userRoleIds.includes(7) &&
      !controlCode.startsWith('I')
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    fetchRowData({});
  }, []);

  return (
    <Formik
      initialValues={{ date: '' }}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={(form: IPdfImportSearchForm) => {
        fetchRowData(form);
      }}
    >
      {() => {
        return (
          <Form autoComplete="off">
            <div className={styles.filterContainer}>
              <div className="container__row">
                <div className="grid-4-1st">
                  <DatePickerLabel
                    src="pdfImport.date"
                    className="width-inherit"
                  />
                </div>
                <div className={`${styles.submitContainer} grid-4-2nd`}>
                  <Button
                    className={styles.searchButton}
                    type="submit"
                    label={t('control.search')}
                  />
                </div>
              </div>
            </div>
            <span className={styles.helpDescription}>
              <p>{t('pdfImport.helpDescription')}</p>
            </span>
            <div className={styles.gridContainer}>
              <div className={styles.tabs}>
                <PdfCategoryTab
                  tabs={[
                    {
                      label: `${t('pdfImport.tabFailed')} (${rows.length})`,
                      selected: true,
                    },
                  ]}
                />
              </div>
              <div className="ag-theme-alpine">
                <AgGridReact
                  className={`${styles.agGridTest}`}
                  domLayout="autoHeight"
                  pagination
                  paginationPageSize={25}
                  suppressRowClickSelection={true}
                  rowMultiSelectWithClick={false}
                  frameworkComponents={{
                    ActionsCellRenderer,
                    LinkCellRenderer,
                  }}
                  columnDefs={[
                    {
                      headerName: t('pdfImport.columnFilename'),
                      cellRenderer: 'LinkCellRenderer',
                      flex: 1,
                      menuTabs: [],
                    },
                    {
                      headerName: t('pdfImport.date'),
                      width: 200,
                      field: 'createdAt',
                      menuTabs: [],
                      valueGetter: (val: ValueGetterParams) => {
                        if (val?.data && val?.data[CREATED_AT]) {
                          return moment(val.data[CREATED_AT]).format(
                            'DD.MM.YYYY'
                          );
                        }
                        return '';
                      },
                    },
                    {
                      headerName: '',
                      flex: 2,
                      cellRenderer: 'ActionsCellRenderer',
                      cellRendererParams: {
                        currentlyEditingFilename,
                        onBeginInputClicked: (filename: string) =>
                          setCurrentlyEditingFilename(filename),
                        onSubmitClicked: handleSubmitData,
                        onCancelClicked: () => setCurrentlyEditingFilename(''),
                        validateInput: isValidControlCode,
                        updateRow: (data: IPreliminaryInfoPdf) => {
                          const index = rows.findIndex(
                            (row: IPreliminaryInfoPdf) =>
                              row.filename === data.filename
                          );
                          if (index !== -1) {
                            const updatedRows = [...rows];
                            updatedRows[index] = data;
                            setRows(updatedRows);
                          }
                        },
                      },
                      menuTabs: [],
                    },
                  ]}
                  rowData={rows}
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PdfImportPageContent;
