import {
  faPen,
  faSave,
  faTimesCircle,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/main';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './removeEdit.module.scss';

const EditRemoveRendere = (props: ICellRendererParams) => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState<boolean>(
    props.data?.isNew !== undefined && props.data?.isNew === true
  );

  const deleteRow = () => {
    if (!props.column) {
      return;
    }
    if (window.confirm(t('errors.deleteRow'))) {
      return props.api.applyTransaction({
        remove: [props.node.data],
      });
    }
  };
  const stopEditing = (force: boolean) => {
    const newItems: any[] = [];
    props.api.stopEditing(force);
    props.api.forEachNode((el) => {
      delete el.data?.isNew;
      newItems.push(el.data);
    });
    props.api.setRowData(newItems);
    setEdit(false);
  };
  const startEding = () => {
    if (props.node.rowIndex !== null && props.colDef?.field) {
      props.api.startEditingCell({
        rowIndex: props.node.rowIndex,
        colKey: props.colDef.field,
      });
    }
    setEdit(true);
  };

  return (
    <div className={styles.container}>
      {edit ? (
        <div className={styles.controler}>
          <FontAwesomeIcon
            icon={faSave}
            size="2x"
            onClick={() => stopEditing(false)}
          />
          <FontAwesomeIcon
            icon={faTimesCircle}
            size="3x"
            onClick={() => stopEditing(true)}
          />
        </div>
      ) : (
        <>
          <div className={styles.controler} onClick={deleteRow}>
            <FontAwesomeIcon size="3x" icon={faTrashAlt} />
          </div>

          <div className={styles.controler} onClick={startEding}>
            <FontAwesomeIcon size="2x" icon={faPen} />
          </div>
        </>
      )}
    </div>
  );
};
export default EditRemoveRendere;
