import {
  IS_LOCKED,
  LOCKED_BY_CURRENT_USER,
  LOCKED_BY_NAME,
  LOCKED_UNTIL,
} from '../../constants/lockKeys';
import { Forms } from '../../state/screening/state';
import { formsQueryList } from './formsQueryHelper';

export const userLockQuery = (personId: number) => `mutation SubmitpersonLock {
  lockPerson(personId: ${personId}) {
    ${Forms.PERSON} {
      ${formsQueryList[Forms.PERSON]}
    }
    ${Forms.PERSON_LOCK} {
      ${IS_LOCKED}
      ${LOCKED_BY_NAME}
      ${LOCKED_BY_CURRENT_USER}
      ${LOCKED_UNTIL}
    }
  }
}
`;

export const userUnlockQuery = (
  personId: number
) => `mutation SubmitpersonLock {
  unlockPerson(personId: ${personId}) {
    ${IS_LOCKED}
    ${LOCKED_BY_NAME}
    ${LOCKED_BY_CURRENT_USER}
    ${LOCKED_UNTIL}
  }
}`;
