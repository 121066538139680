import axios from 'axios';
import { TFunction } from 'react-i18next';
import { toast } from 'react-toastify';
import { IReport } from '../models/IReport';
import { redirectAuthFail } from '../utils/redirectAuthFail';
import userManager from '../utils/userManager';
import { fetchReportConfigs } from './queries/reportBi';

export const FetchReportConfig = async (
  t: TFunction
): Promise<IReport | null> => {
  const user = await userManager.getUser();
  if (!user) {
    redirectAuthFail(t);
    return null;
  }
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/graph`,
      {
        query: fetchReportConfigs(),
        operationName: 'reportConfigs',
      },
      { headers: { Authorization: `${user.token_type} ${user.access_token}` } }
    );

    if (Array.isArray(res.data.errors)) {
      toast.error(t('errors.reportConfigs'));
      return null;
    }
    return res?.data?.data?.report;
  } catch (er) {
    toast.error(t('errors.reportConfigs'));
    return null;
  }
};
