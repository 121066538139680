import {
  ColDef,
  GridApi,
  ValueGetterParams,
} from 'ag-grid-community/dist/lib/main';
import { TFunction } from 'i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { MUNICIPALITY_IDS } from '../constants/adminTabel';
import { TEST_RESULT_POSITIVE } from '../constants/analysisResultFromKeys';
import {
  ANALYSIS_DATE,
  HANDLER_LIST,
  SCREENING_CODE,
  COMMENT,
  SSN,
  STATUS,
  STATUS_LIST,
  MUNICIPALITY_ID,
  COULD_NOT_BE_REACHED_AT,
} from '../constants/commonKeys';
import { INITIAL_RESULT } from '../constants/tableColumnsKeys';
import { InitialResult } from '../models/InitialResult';
import { IOption } from '../models/IOption';
import { RootState } from '../state/rootReducer';
import { Forms, Screening } from '../state/screening/state';

const UseCreateScreeningColumn = (t: TFunction) => {
  const { screening, searchKeys, user } = useSelector(
    (state: RootState) => state
  );

  const checkIfOptions = (gridApi: GridApi, data: Partial<Screening>) => {
    const statusFilter: any = gridApi.getFilterInstance(Forms.STATUS);
    const handlerFilter: any = gridApi.getFilterInstance(Forms.HANDLER);
    const municipalityFilter: any = gridApi.getFilterInstance(MUNICIPALITY_ID);
    // if the status and handler list is in Redux we will not fetch them so better to check here
    if (
      statusFilter &&
      handlerFilter &&
      municipalityFilter &&
      data[STATUS_LIST] &&
      data[MUNICIPALITY_ID] &&
      data[HANDLER_LIST]
    ) {
      statusFilter?.componentInstance?.refreshOptions(data[STATUS_LIST]);
      municipalityFilter?.componentInstance?.refreshOptions(
        data[MUNICIPALITY_ID]?.filter((el) =>
          user[MUNICIPALITY_IDS]?.includes(+el.value)
        )
      );
      handlerFilter?.componentInstance?.refreshOptions(
        data[HANDLER_LIST]?.map((el) => ({
          langValue: el.label ? `${el.label} ${el.langValue}` : el.langValue,
          value: el.value,
        }))
      );
      gridApi.refreshHeader();
    }
  };

  const defaultColDefPerson: ColDef = {
    filter: false,
    sortable: false,
    editable: false,
    resizable: true,
    autoHeight: true,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    flex: 1,
    menuTabs: [],
  };
  const InitialResultOptions: IOption[] = Object.keys(InitialResult)
    .filter((el) => isNaN(+el))
    .map((el) => ({ value: el, langValue: el }));
  const statusList = screening[STATUS_LIST] || [];
  const handlerList = screening[HANDLER_LIST] || [];
  const municipalities = screening[MUNICIPALITY_ID]
    ? screening[MUNICIPALITY_ID].filter((el) =>
        user[MUNICIPALITY_IDS]?.includes(+el.value)
      )
    : [];

  const screeningColumnDefs: ColDef[] = [
    {
      headerName: t(`tableColumns.${STATUS}`),
      field: STATUS,
      minWidth: 250,
      menuTabs: ['filterMenuTab'],
      cellRenderer: 'StatusRenderer',
      cellRendererParams: {
        options: screening[STATUS_LIST],
      },
      filter: 'RadioFilter',
      floatingFilter: true,
      filterParams: {
        title: t(`tableColumns.${STATUS}`),
        name: STATUS,
        options: [
          { value: 'null', langValue: 'empty' },
          { value: '-1', langValue: 'active' },
          ...statusList,
        ],
        defaultVal: searchKeys[Forms.STATUS],
      },
    },
    {
      headerName: t(`tableColumns.${SSN}`),
      field: SSN,
      menuTabs: ['filterMenuTab'],
      filter: 'TextFilter',
      floatingFilter: true,
      filterParams: {
        title: t(`tableColumns.${SSN}`),
        defaultVal: searchKeys[SSN],
      },
    },
    {
      headerName: t(`tableColumns.${SCREENING_CODE}`),
      field: SCREENING_CODE,
      floatingFilter: true,
      menuTabs: ['filterMenuTab'],
      filter: 'TextFilter',
      filterParams: {
        title: t(`tableColumns.${SCREENING_CODE}`),
        defaultVal: searchKeys[SCREENING_CODE],
      },
    },
    {
      headerName: t(`tableColumns.${INITIAL_RESULT}`),
      field: INITIAL_RESULT,
      filter: 'RadioFilter',
      menuTabs: ['filterMenuTab'],
      floatingFilter: true,
      filterParams: {
        title: t(`tableColumns.${INITIAL_RESULT}`),
        name: INITIAL_RESULT,
        options: InitialResultOptions,
        defaultVal: searchKeys[INITIAL_RESULT],
      },
      valueGetter: (val: ValueGetterParams) => {
        if (val?.data && val?.data[INITIAL_RESULT]) {
          return t(`${TEST_RESULT_POSITIVE}.${val?.data[INITIAL_RESULT]}`);
        }
      },
    },
    {
      headerName: t(`tableColumns.${MUNICIPALITY_ID}`),
      field: MUNICIPALITY_ID,
      menuTabs: ['filterMenuTab'],
      filter: 'RadioFilter',
      floatingFilter: true,
      valueGetter: (val: ValueGetterParams) => {
        if (val?.data && val?.data[MUNICIPALITY_ID]) {
          const minicip = municipalities.find(
            (el) => el.value === val?.data[MUNICIPALITY_ID]
          );
          return minicip ? t(`${MUNICIPALITY_ID}.${minicip?.langValue}`) : '';
        }
      },
      filterParams: {
        title: t(`tableColumns.${MUNICIPALITY_ID}`),
        name: MUNICIPALITY_ID,
        options: [{ value: 'null', langValue: 'empty' }, ...municipalities],
        defaultVal: searchKeys[MUNICIPALITY_ID],
      },
    },
    {
      headerName: t(`tableColumns.${ANALYSIS_DATE}`),
      field: ANALYSIS_DATE,
      filterParams: {},
      valueGetter: (val: ValueGetterParams) =>
        val?.data && val?.data[ANALYSIS_DATE]
          ? moment(val.data[ANALYSIS_DATE]).format('DD.MM.YYYY')
          : null,
    },
    {
      headerName: t(`tableColumns.${COULD_NOT_BE_REACHED_AT}`),
      field: COULD_NOT_BE_REACHED_AT,
      filter: 'DateFilter',
      floatingFilter: true,
      menuTabs: ['filterMenuTab'],
      filterParams: {
        browserDatePicker: true,
        suppressAndOrCondition: true,
        filterOptions: ['equals'],
      },
      valueGetter: (val: ValueGetterParams) =>
        val?.data && val?.data[COULD_NOT_BE_REACHED_AT]
          ? moment(val.data[COULD_NOT_BE_REACHED_AT]).format('DD.MM.YYYY')
          : null,
    },
    {
      headerName: t(`tableColumns.${Forms.HANDLER}`),
      field: Forms.HANDLER,
      filter: 'RadioFilter',
      menuTabs: ['filterMenuTab'],
      floatingFilter: true,
      filterParams: {
        title: t(`tableColumns.${Forms.HANDLER}`),
        name: null,
        options: [
          { value: 'null', langValue: t(`${Forms.HANDLER}.empty`) },
          ...handlerList,
        ],
        defaultVal: searchKeys[Forms.HANDLER],
      },
      valueGetter: (val: ValueGetterParams) => {
        if (val?.data && val?.data[Forms.HANDLER]) {
          return (
            handlerList.find((el) => el.value === val?.data[Forms.HANDLER])
              ?.langValue || ''
          );
        }
      },
    },
    {
      headerName: t(`tableColumns.${COMMENT}`),
      field: COMMENT,
      floatingFilter: true,
      menuTabs: ['filterMenuTab'],
      filter: 'TextFilter',
      filterParams: {
        title: t(`tableColumns.${COMMENT}`),
        defaultVal: searchKeys[COMMENT],
      },
    },
  ];
  return { defaultColDefPerson, screeningColumnDefs, checkIfOptions };
};

export default UseCreateScreeningColumn;
