import { SEARCH_KEYS } from '../../constants/commonKeys';
import { INITIAL_RESULT } from '../../constants/tableColumnsKeys';
import { Forms } from '../screening/state';
import { ActionTypes, TabActionTypes } from './actions';
import { SearchKeys } from './state';

export const initialSearchKeysState: SearchKeys = JSON.parse(
  localStorage.getItem(SEARCH_KEYS) ||
    `{"${Forms.STATUS}":"-1","${Forms.HANDLER}":"null","${INITIAL_RESULT}":"POSITIVE"}`
);

export const SearchKeysReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: SearchKeys = initialSearchKeysState,
  action: TabActionTypes
): SearchKeys => {
  switch (action.type) {
    case ActionTypes.setSearchKeys:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
