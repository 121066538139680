import {
  ASSAY_ABOVE,
  INTERPRETABILITY_BLOCKERS,
  RESULT_PLACE,
  SAMPLE_INTERPRETABILITY,
  TEST_RESULT_POSITIVE,
  TEST_VALUE,
} from '../../constants/analysisResultFromKeys';
import {
  CLASSIFOCATION_ID,
  TTGRAPHY_BY_ID,
  TTGRAPHY_BY_NAME,
  TTGRAPHY_DATE,
  TTGRAPHY_FINDING_IDS,
  TTGRAPHY_LOCATION,
  MUTAIION_LOCATION_ID,
  OTHER_CLASSIFOCATION_ID,
  RADIOLOGIST_ID,
  RADIOLOGIST_NAME,
  RADIOLOGIST_STATMENT,
  RADIOLOGIST_STATMENT_DATE,
  RADIOLOGIST_STATMENT_LOCATION,
  TTGRAPHY_OTHER_FINDINGS,
} from '../../constants/ttgraphyFromKeys';
import {
  BOWEL_EMPTY_SUCCESS_ID,
  SCOPY_DATE,
  SCOPY_DECISION_BY_ID,
  SCOPY_DIAGNOSES,
  SCOPY_DONE_BY_ID,
  SCOPY_DONE_BY_NAME,
  SCOPY_FINDING_IDS,
  SCOPY_LIMITATION_IDS,
  SCOPY_LOCATION_ID,
  SCOPY_NOT_PERFORMED_REASON,
  SCOPY_PROCEDURE_IDS,
  SCOPY_REACHED_ID,
  SCOPY_REFERRAL_LOCATION,
  SCOPY_RETRIEVAL_MIN,
  COMPLICATION_IDS,
  DECISION_BY_NAME,
  DECISION_DATE,
  DECISION_ID,
  DECISION_LOCATION,
  DIAGNOSES_DATE,
  DIAGNOSES_LOCATION,
  HAD_INNER_TURN_DONE,
  HAD_INTERVAENOUS_PREMEDICATION,
  OTHER_REASONS,
  PREVIOUS_YEAR,
  SCOPY_OTHER_LIMITS,
  SCOPY_OTHER_FINDINGS,
} from '../../constants/scopyForm';
import {
  ADITIONAL_INFO,
  ANALYSIS_DATE,
  CONTROL_ID,
  FIRSTNAME,
  FIRST_NAMES,
  COMMENT,
  IS_INERRUPTED,
  LARGEST_POLY_DIAMETER_ID,
  LASTNAME,
  MUNICIPALITY_ID,
  NUMBER_POLYP_ID,
  ORGANIZATION,
  SAMPLE_DATE,
  SAMPLE_ID,
  SCREENING_CODE,
  SCREENING_ID,
  SCREENING_YEAR,
  SSN,
  STATUS,
  SURNAME,
  COULD_NOT_BE_REACHED_AT,
  CONTROL_CODE,
} from '../../constants/commonKeys';
import {
  DIAGNOSES_BY_ID,
  DIAGNOSES_BY_NAME,
  DIAGNOSES_DIAMETER_MM_ID,
  DIAGNOSES_LOCATION_ID,
  DIAGNOSES_MORPHOLOGY_ID,
  DIAGNOSES_RESULT_ID,
  MUTATION_HISTOLOGICAL_DIAGNOSIS,
} from '../../constants/disgnosesTabel';
import {
  FOLLOW_UP_BY_ID,
  FOLLOW_UP_BY_NAME,
  FOLLOW_UP_DATE,
  FOLLOW_UP_DECISION_ID,
  FOLLOW_UP_LOCATION,
  FOLLOW_UP_REFERRAL_LOCATION,
} from '../../constants/followUp';
import {
  IS_LOCKED,
  LOCKED_BY_CURRENT_USER,
  LOCKED_BY_NAME,
  LOCKED_UNTIL,
} from '../../constants/lockKeys';
import {
  CONFIDENCE,
  DOES_SMOKE,
  DRINKING_AMOUNT_ID,
  DRINKING_FREQUENCY_ID,
  DRINKING_SIX_FREQUENCY_ID,
  HAS_MEDICINE_SENSITIVITY,
  HAS_RELATIVE_INTESTIONAL_CANCER,
  HAS_SMOKED_DAILY,
  HEIGHT,
  LONG_TERM_ILNESS_IDS,
  MEDICINE_SENSITIVITY,
  REGULAR_MEDICINE_IDS,
  RELATIVE_INTESTIONAL_CANCER_WHO,
  SMOKED_DAILY_AMOUNT_ID,
  SMOKED_DAILY_YEARS_ID,
  WEIGHT,
} from '../../constants/preliminaryFormKeys';
import {
  CANCER_PTNM_GRADUS_ID,
  CANCER_PTNM_M_ID,
  CANCER_PTNM_N_ID,
  CANCER_PTNM_T_ID,
  SURGICAL_PROCEDURE_BY_ID,
  SURGICAL_PROCEDURE_BY_NAME,
  SURGICAL_PROCEDURE_CODE_ID,
  SURGICAL_PROCEDURE_DATE,
  SURGICAL_PROCEDURE_DIAGNOSES,
  SURGICAL_PROCEDURE_DIAGNOSES_DATE,
  SURGICAL_PROCEDURE_DIAGNOSES_LOCATION,
  SURGICAL_PROCEDURE_LOCATION,
  SURGICAL_PROCEDURE_PRIMARY_ID,
} from '../../constants/surgicalProcedureFormKeys';
import { MOTHER_LANG, PERSON_ID, PHONE } from '../../constants/userInfoKeys';
import { IColongraphyFrom } from '../../models/ITTGaphyFrom';
import { IScopyForm } from '../../models/IScopyForm';
import { IPreliminaryForm } from '../../models/IPreliminaryForm';
import { IPersonForm } from '../../models/IUSerInfo';
import { ISurgicalProcedureForm } from '../../models/surgicalProcedureFrom';
import { Forms } from '../../state/screening/state';
import { handleKey, QueryType } from '../../utils/queryCleanup';
import {
  CREATED_AT,
  EMAIL,
  END_DATE,
  IS_DELETED,
  IS_DISABLED,
  MODIFIED_BY,
  MODIFIED_AT,
  MUNICIPALITY_IDS,
  NAME_ID_HAG,
  PERMISSION_STATUS,
  PHONE_NUMBER,
  ROLE_IDS,
  START_DATE,
  USER_ID,
} from '../../constants/adminTabel';
import { IUser } from '../../models/IUser';

export const formsQueryList = {
  user: `
  ${COMMENT}
  ${CREATED_AT}
  ${EMAIL}
  ${FIRSTNAME}
  ${END_DATE}
  ${IS_DELETED}
  ${IS_DISABLED}
  ${LASTNAME}
  ${MODIFIED_BY}
  ${MODIFIED_AT}
  ${MUNICIPALITY_IDS}
  ${NAME_ID_HAG}
  ${ORGANIZATION}
  ${PERMISSION_STATUS}
  ${PHONE_NUMBER}
  ${ROLE_IDS}
  ${START_DATE}
  ${USER_ID}`,

  [Forms.SCREENING_LOCK]: ` 
          ${IS_LOCKED}
          ${LOCKED_BY_CURRENT_USER}
          ${LOCKED_BY_NAME}
          ${LOCKED_UNTIL}
        `,
  [Forms.PERSON_LOCK]: ` 
          ${IS_LOCKED}
          ${LOCKED_BY_CURRENT_USER}
          ${LOCKED_BY_NAME}
          ${LOCKED_UNTIL}
        `,
  [Forms.PRELIMINARY]: `
          ${DRINKING_SIX_FREQUENCY_ID}
          ${DRINKING_FREQUENCY_ID}
          ${DRINKING_AMOUNT_ID}
          ${DOES_SMOKE}
          ${HEIGHT}
          ${HAS_SMOKED_DAILY}
          ${HAS_RELATIVE_INTESTIONAL_CANCER}
          ${HAS_MEDICINE_SENSITIVITY}
          ${LONG_TERM_ILNESS_IDS}
          ${MEDICINE_SENSITIVITY}
          ${MUNICIPALITY_ID}
          ${REGULAR_MEDICINE_IDS}
          ${RELATIVE_INTESTIONAL_CANCER_WHO}
          ${CONTROL_CODE}
          ${SMOKED_DAILY_YEARS_ID}
          ${SMOKED_DAILY_AMOUNT_ID}
          ${WEIGHT}
          ${CONTROL_ID}
          ${CONFIDENCE}
        `,
  followUp: `
          ${DECISION_ID}
          ${DECISION_DATE}
          ${SCOPY_DECISION_BY_ID}
          ${DECISION_BY_NAME}
          ${DECISION_LOCATION}
          ${SCOPY_REFERRAL_LOCATION}
          ${PREVIOUS_YEAR}
          ${SCOPY_NOT_PERFORMED_REASON}
          ${SCREENING_ID}
`,
  [Forms.ANALYSIS_RESULT]: `
              ${ANALYSIS_DATE}
              ${TEST_RESULT_POSITIVE}
              ${SAMPLE_ID}
              ${RESULT_PLACE}
              ${SAMPLE_INTERPRETABILITY}
              ${INTERPRETABILITY_BLOCKERS}
              ${ASSAY_ABOVE}
              ${TEST_VALUE}
            `,
  [Forms.PERSON]: `
              ${PHONE}
              ${MOTHER_LANG}
              ${SSN}
              ${SURNAME}
              ${FIRST_NAMES}
              ${COMMENT}              
              ${PERSON_ID}
              ${COULD_NOT_BE_REACHED_AT}
            `,
  [Forms.SCOPY]: `
            ${SCOPY_DIAGNOSES} {
              ${DIAGNOSES_MORPHOLOGY_ID}
              ${DIAGNOSES_LOCATION_ID}
              ${DIAGNOSES_DIAMETER_MM_ID}
              ${DIAGNOSES_RESULT_ID}
            }
            ${BOWEL_EMPTY_SUCCESS_ID}
            ${COMPLICATION_IDS}
            ${DECISION_ID}
            ${SCOPY_DATE}
            ${SCOPY_LOCATION_ID}
            ${SCOPY_DONE_BY_ID}
            ${SCOPY_DONE_BY_NAME}
            ${DECISION_DATE}
            ${SCOPY_DECISION_BY_ID}
            ${IS_INERRUPTED}
            ${DECISION_BY_NAME}
            ${DECISION_LOCATION}
            ${SCOPY_FINDING_IDS}
            ${SCOPY_LIMITATION_IDS}
            ${SCOPY_PROCEDURE_IDS}
            ${SCOPY_REACHED_ID}
            ${SCOPY_REFERRAL_LOCATION}
            ${SCOPY_RETRIEVAL_MIN}
            ${DIAGNOSES_DATE}
            ${DIAGNOSES_BY_ID}
            ${DIAGNOSES_BY_NAME}
            ${DIAGNOSES_LOCATION}
            ${FOLLOW_UP_BY_ID}
            ${FOLLOW_UP_BY_NAME}
            ${FOLLOW_UP_DECISION_ID}
            ${FOLLOW_UP_REFERRAL_LOCATION}
            ${HAD_INNER_TURN_DONE}
            ${HAD_INTERVAENOUS_PREMEDICATION}
            ${LARGEST_POLY_DIAMETER_ID}
            ${MUTATION_HISTOLOGICAL_DIAGNOSIS}
            ${NUMBER_POLYP_ID}
            ${SAMPLE_ID}
            ${FOLLOW_UP_LOCATION}
            ${FOLLOW_UP_DATE}
            ${SCOPY_OTHER_LIMITS}
            ${SCOPY_OTHER_FINDINGS}
            ${ADITIONAL_INFO}
            ${OTHER_REASONS}
            ${PREVIOUS_YEAR}
            ${SCOPY_NOT_PERFORMED_REASON}
            ${SCREENING_ID}`,
  [Forms.TTGRAPHY]: ` 
            ${CLASSIFOCATION_ID}
            ${ADITIONAL_INFO}
            ${IS_INERRUPTED}
            ${LARGEST_POLY_DIAMETER_ID}
            ${TTGRAPHY_OTHER_FINDINGS}
            ${TTGRAPHY_BY_ID}
            ${TTGRAPHY_BY_NAME}
            ${NUMBER_POLYP_ID}
            ${MUTAIION_LOCATION_ID}
            ${OTHER_CLASSIFOCATION_ID}
            ${RADIOLOGIST_ID}
            ${RADIOLOGIST_STATMENT}
            ${RADIOLOGIST_STATMENT_DATE}
            ${SAMPLE_ID}
            ${SCREENING_ID}
            ${TTGRAPHY_FINDING_IDS}
            ${TTGRAPHY_DATE}
            ${TTGRAPHY_LOCATION}
            ${RADIOLOGIST_STATMENT_LOCATION}
            ${RADIOLOGIST_NAME}
            ${FOLLOW_UP_DATE} 
            ${FOLLOW_UP_LOCATION} 
            ${FOLLOW_UP_BY_ID}
            ${FOLLOW_UP_BY_NAME} 
            ${FOLLOW_UP_DECISION_ID}
            ${FOLLOW_UP_REFERRAL_LOCATION}`,
  [Forms.SURGICAL_PROCEDURE]: `
            ${ADITIONAL_INFO},
            ${CANCER_PTNM_GRADUS_ID},
            ${CANCER_PTNM_M_ID},
            ${CANCER_PTNM_N_ID},
            ${CANCER_PTNM_T_ID},
            ${SAMPLE_ID},
            ${SCREENING_ID},
            ${MUTATION_HISTOLOGICAL_DIAGNOSIS},
            ${SAMPLE_ID},
            ${SURGICAL_PROCEDURE_DIAGNOSES} 
              {
                ${DIAGNOSES_MORPHOLOGY_ID}
                ${DIAGNOSES_LOCATION_ID}
                ${DIAGNOSES_DIAMETER_MM_ID}
                ${DIAGNOSES_RESULT_ID}
              }
            ,
            ${SURGICAL_PROCEDURE_DIAGNOSES_DATE},
            ${DIAGNOSES_BY_ID},
            ${DIAGNOSES_BY_NAME},
            ${SURGICAL_PROCEDURE_DIAGNOSES_LOCATION},
            ${SURGICAL_PROCEDURE_BY_ID},
            ${SURGICAL_PROCEDURE_BY_NAME},
            ${SURGICAL_PROCEDURE_PRIMARY_ID},
            ${SURGICAL_PROCEDURE_CODE_ID},
            ${SURGICAL_PROCEDURE_DATE}
            ${SURGICAL_PROCEDURE_LOCATION},
            `,
};

export const formsMutationList = {
  [Forms.PERSON]: (form: IPersonForm) => `mutation Submitperson {
        updatePerson(
          ${handleKey(form, PERSON_ID, QueryType.number, false)}
          person:{
          ${handleKey(form, SURNAME, QueryType.string)}
          ${handleKey(form, SSN, QueryType.string)}
          ${handleKey(form, FIRST_NAMES, QueryType.string)}
          ${handleKey(form, COMMENT, QueryType.string)}          
          ${handleKey(form, PHONE, QueryType.string)}
          ${handleKey(form, COULD_NOT_BE_REACHED_AT, QueryType.string)}
          ${handleKey(form, MOTHER_LANG, QueryType.string)}
        }
          ) {
            ${formsQueryList[Forms.PERSON]}
        }
      }`,

  [Forms.STATUS]: (
    screeningId: number,
    prevStatusValue: number,
    nextStatusValue: number
  ) => `mutation SubmitscreeningProcessStatusId {
          ${Forms.STATUS}:updateScreeningStatus(nextScreeningStatusId: ${nextStatusValue}, prevScreeningStatusId: ${prevStatusValue}, screeningId: ${screeningId})
        }`,
  [Forms.HANDLER]: (screeningId: number, handlerValue: number) => `
  mutation SubmithandlerId {
    ${Forms.HANDLER} :updateScreeningHandler(handlerId: ${handlerValue}, screeningId: ${screeningId})
  }
  `,

  [Forms.PRELIMINARY]: (
    form: IPreliminaryForm
  ) => `mutation SubmitpreliminaryInfo {
          updatePreliminaryInfo(preliminaryInfo:
          {
            ${handleKey(form, CONTROL_ID, QueryType.number, false)}
            ${handleKey(form, LONG_TERM_ILNESS_IDS, QueryType.array, false)}
            ${handleKey(form, REGULAR_MEDICINE_IDS, QueryType.array, false)}
            ${handleKey(
              form,
              RELATIVE_INTESTIONAL_CANCER_WHO,
              QueryType.array,
              false
            )}
            ${handleKey(form, MUNICIPALITY_ID, QueryType.number, false)}
            ${handleKey(form, HEIGHT, QueryType.number)}
            ${handleKey(form, WEIGHT, QueryType.number)}
            ${handleKey(form, HAS_SMOKED_DAILY, QueryType.boolean)}
            ${handleKey(
              form,
              HAS_RELATIVE_INTESTIONAL_CANCER,
              QueryType.number
            )}
            ${handleKey(form, HAS_MEDICINE_SENSITIVITY, QueryType.boolean)}
            ${handleKey(form, CONFIDENCE, QueryType.number)}
            ${handleKey(form, DRINKING_SIX_FREQUENCY_ID, QueryType.number)}
            ${handleKey(form, DRINKING_FREQUENCY_ID, QueryType.number)}
            ${handleKey(form, DRINKING_AMOUNT_ID, QueryType.number)}
            ${handleKey(form, DOES_SMOKE, QueryType.boolean)}
            ${handleKey(form, MEDICINE_SENSITIVITY, QueryType.string)}
            ${handleKey(form, SMOKED_DAILY_AMOUNT_ID, QueryType.number)}
            ${handleKey(form, SMOKED_DAILY_YEARS_ID, QueryType.number)}
          }){
  
            ${formsQueryList[Forms.PRELIMINARY]}
      
          }
      }
      `,

  [Forms.SCOPY]: (form: IScopyForm, isAnalysisAndResult: boolean) => `
      mutation Submitcolonoscopy {
        updateColonoscopy (isAnalysisAndResult: ${isAnalysisAndResult},colonoscopy: {
          ${handleKey(form, SAMPLE_ID, QueryType.number, false)}
          ${handleKey(form, SCREENING_ID, QueryType.number, false)}
          ${handleKey(form, IS_INERRUPTED, QueryType.boolean)}
          ${handleKey(form, SCOPY_NOT_PERFORMED_REASON, QueryType.number)}
          ${handleKey(form, SCOPY_OTHER_LIMITS, QueryType.string)}
          ${handleKey(form, SCOPY_OTHER_FINDINGS, QueryType.string)}
          ${handleKey(form, ADITIONAL_INFO, QueryType.string)}
          ${handleKey(form, PREVIOUS_YEAR, QueryType.number)}
          ${handleKey(form, OTHER_REASONS, QueryType.string)}
          ${handleKey(form, DECISION_ID, QueryType.number)}
          ${handleKey(form, SCOPY_DECISION_BY_ID, QueryType.string)}
          ${handleKey(form, DECISION_BY_NAME, QueryType.string)}
          ${handleKey(form, DECISION_LOCATION, QueryType.number)}
          ${handleKey(form, DECISION_DATE, QueryType.string)}
          ${handleKey(form, SCOPY_REFERRAL_LOCATION, QueryType.number)}
          ${handleKey(form, SCOPY_DATE, QueryType.string)}
          ${handleKey(form, SCOPY_LOCATION_ID, QueryType.number)}
          ${handleKey(form, SCOPY_DONE_BY_ID, QueryType.string)}
          ${handleKey(form, SCOPY_DONE_BY_NAME, QueryType.string)}
          ${handleKey(form, HAD_INTERVAENOUS_PREMEDICATION, QueryType.boolean)}
          ${handleKey(form, SCOPY_RETRIEVAL_MIN, QueryType.number)}
          ${handleKey(form, HAD_INNER_TURN_DONE, QueryType.boolean)}
          ${handleKey(form, BOWEL_EMPTY_SUCCESS_ID, QueryType.number)}
          ${handleKey(form, SCOPY_REACHED_ID, QueryType.number)}
          ${handleKey(form, SCOPY_LIMITATION_IDS, QueryType.array, false)}
          ${handleKey(form, SCOPY_FINDING_IDS, QueryType.array, false)}
          ${handleKey(form, NUMBER_POLYP_ID, QueryType.number)}
          ${handleKey(form, LARGEST_POLY_DIAMETER_ID, QueryType.number)}
          ${handleKey(form, SCOPY_PROCEDURE_IDS, QueryType.array, false)}
          ${handleKey(form, COMPLICATION_IDS, QueryType.array, false)}
          ${handleKey(form, SCOPY_DIAGNOSES, QueryType.array, false)}
          ${handleKey(form, DIAGNOSES_DATE, QueryType.string)}
          ${handleKey(form, DIAGNOSES_LOCATION, QueryType.number)}
          ${handleKey(form, DIAGNOSES_BY_ID, QueryType.string)}
          ${handleKey(form, DIAGNOSES_BY_NAME, QueryType.string)}
          ${handleKey(form, MUTATION_HISTOLOGICAL_DIAGNOSIS, QueryType.string)}
          ${handleKey(form, FOLLOW_UP_DECISION_ID, QueryType.number)}
          ${handleKey(form, FOLLOW_UP_REFERRAL_LOCATION, QueryType.number)}
          ${handleKey(form, FOLLOW_UP_LOCATION, QueryType.number)}
          ${handleKey(form, FOLLOW_UP_DATE, QueryType.string)}
          ${handleKey(form, FOLLOW_UP_BY_ID, QueryType.string)}
          ${handleKey(form, FOLLOW_UP_BY_NAME, QueryType.string)}
        }){
          
          ${formsQueryList[Forms.SCOPY]}
      }
      }`,
  [Forms.TTGRAPHY]: (form: IColongraphyFrom) => `mutation SubmitttColonography {
          __typename
          updateTtColonography(ttColonography: {
            ${handleKey(form, CLASSIFOCATION_ID, QueryType.number)}
            ${handleKey(form, ADITIONAL_INFO, QueryType.string)}
            ${handleKey(form, TTGRAPHY_BY_NAME, QueryType.string)}
            ${handleKey(form, TTGRAPHY_BY_ID, QueryType.string)}
            ${handleKey(form, LARGEST_POLY_DIAMETER_ID, QueryType.number)}
            ${handleKey(form, TTGRAPHY_OTHER_FINDINGS, QueryType.string)}
            ${handleKey(form, IS_INERRUPTED, QueryType.boolean)}
            ${handleKey(form, NUMBER_POLYP_ID, QueryType.number)}
            ${handleKey(form, MUTAIION_LOCATION_ID, QueryType.number)}
            ${handleKey(form, OTHER_CLASSIFOCATION_ID, QueryType.number)}
            ${handleKey(form, RADIOLOGIST_ID, QueryType.string)}
            ${handleKey(form, RADIOLOGIST_STATMENT, QueryType.string)}
            ${handleKey(form, RADIOLOGIST_STATMENT_DATE, QueryType.string)}
            ${handleKey(form, SAMPLE_ID, QueryType.number, false)}
            ${handleKey(form, SCREENING_ID, QueryType.number, false)}
            ${handleKey(form, TTGRAPHY_FINDING_IDS, QueryType.array, false)}
            ${handleKey(form, TTGRAPHY_DATE, QueryType.string)}
            ${handleKey(form, TTGRAPHY_LOCATION, QueryType.number)}
            ${handleKey(form, RADIOLOGIST_STATMENT_LOCATION, QueryType.number)}
            ${handleKey(form, RADIOLOGIST_NAME, QueryType.string)}
            ${handleKey(form, FOLLOW_UP_DATE, QueryType.string)}
            ${handleKey(form, FOLLOW_UP_LOCATION, QueryType.number)}
            ${handleKey(form, FOLLOW_UP_BY_ID, QueryType.string)}
            ${handleKey(form, FOLLOW_UP_BY_NAME, QueryType.string)}
            ${handleKey(form, FOLLOW_UP_DECISION_ID, QueryType.number)}
            ${handleKey(form, FOLLOW_UP_REFERRAL_LOCATION, QueryType.number)}
          }) {
            ${formsQueryList[Forms.TTGRAPHY]}
          }
        }
        `,
  [Forms.SURGICAL_PROCEDURE]: (form: ISurgicalProcedureForm) => `
        mutation SubmitsurgicalProcedure {
          updateSurgicalProcedure (surgicalProcedure: {
            ${handleKey(form, CANCER_PTNM_GRADUS_ID, QueryType.number)}
            ${handleKey(form, CANCER_PTNM_T_ID, QueryType.number)}
            ${handleKey(form, CANCER_PTNM_M_ID, QueryType.number)}
            ${handleKey(form, CANCER_PTNM_N_ID, QueryType.number)}
            ${handleKey(form, ADITIONAL_INFO, QueryType.string)}
            ${handleKey(form, SURGICAL_PROCEDURE_DATE, QueryType.string)}
            ${handleKey(form, SURGICAL_PROCEDURE_PRIMARY_ID, QueryType.number)}
            ${handleKey(form, SURGICAL_PROCEDURE_BY_ID, QueryType.string)}
            ${handleKey(form, SURGICAL_PROCEDURE_BY_NAME, QueryType.string)}
            ${handleKey(form, SURGICAL_PROCEDURE_LOCATION, QueryType.number)}
            ${handleKey(form, SURGICAL_PROCEDURE_CODE_ID, QueryType.number)}
            ${handleKey(
              form,
              SURGICAL_PROCEDURE_DIAGNOSES_DATE,
              QueryType.string
            )}
            ${handleKey(
              form,
              SURGICAL_PROCEDURE_DIAGNOSES_LOCATION,
              QueryType.number
            )}
            ${handleKey(form, DIAGNOSES_BY_ID, QueryType.string)}
            ${handleKey(form, DIAGNOSES_BY_NAME, QueryType.string)}
            ${handleKey(
              form,
              MUTATION_HISTOLOGICAL_DIAGNOSIS,
              QueryType.string
            )}
            ${handleKey(form, SAMPLE_ID, QueryType.number, false)}
            ${handleKey(form, SCREENING_ID, QueryType.number, false)}
            ${handleKey(
              form,
              SURGICAL_PROCEDURE_DIAGNOSES,
              QueryType.array,
              false
            )}
        
            })
          {
            ${formsQueryList[Forms.SURGICAL_PROCEDURE]}
          }
        }`,

  user: (form: IUser) => {
    return `
        mutation SubmitUserDetails {
          updateUser: ${
            form?.[USER_ID] ? 'updateUser' : 'createUser'
          }(userDetails: {
        ${handleKey(form, USER_ID, QueryType.number, false)}
        ${handleKey(form, COMMENT, QueryType.string)}
        ${handleKey(form, FIRSTNAME, QueryType.string, false)}
        ${handleKey(form, LASTNAME, QueryType.string, false)}
        ${handleKey(form, EMAIL, QueryType.string)}
        ${handleKey(form, END_DATE, QueryType.string)}
        ${handleKey(form, IS_DISABLED, QueryType.boolean)}
        ${handleKey(form, MUNICIPALITY_IDS, QueryType.array, false)}
        ${handleKey(form, NAME_ID_HAG, QueryType.string)}
        ${handleKey(form, ORGANIZATION, QueryType.string)}
        ${handleKey(form, PHONE_NUMBER, QueryType.string)}
        ${handleKey(form, START_DATE, QueryType.string)}
        ${handleKey(form, ROLE_IDS, QueryType.array, false)}
      }) {
    
        ${formsQueryList.user}
      }
    }
    `;
  },
};
