import { ActionTypes, AnnouncementActionTypes } from './actions';
import { AnnouncementsState } from './state';

export const initialAnnouncementState: AnnouncementsState = {
  data: [],
  haveData: false,
  fetchingError: false,
  fetching: false,
  errorText: '',
  warningClosed: false,
  infoClosed: false,
};

export const AnnouncementsReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: AnnouncementsState = initialAnnouncementState,
  action: AnnouncementActionTypes
): AnnouncementsState => {
  switch (action.type) {
    case ActionTypes.FetchingSuccessful:
      return {
        ...state,
        data: action.payload,
        haveData: true,
        fetching: false,
      };
    case ActionTypes.FetchingError:
      return {
        ...state,
        data: [],
        haveData: false,
        fetchingError: true,
        errorText: action.payload,
        fetching: false,
      };
    case ActionTypes.Fetching:
      return { ...state, fetching: true };
    case ActionTypes.Infoclosed:
      return { ...state, infoClosed: true };
    case ActionTypes.Warningclosed:
      return { ...state, warningClosed: true };
    default:
      return state;
  }
};
