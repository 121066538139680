import axios from 'axios';
import { Dispatch } from 'react';
import { TFunction } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  HANDLER_LIST,
  MUNICIPALITY_ID,
  STATUS_LIST,
} from '../constants/commonKeys';
import { IOption } from '../models/IOption';
import { IPersonTable } from '../models/IPersonTable';
import { setScreeningData } from '../state/screening/actions';
import { redirectAuthFail } from '../utils/redirectAuthFail';
import userManager from '../utils/userManager';

export const FetchScreeningList = async (
  t: TFunction,
  query: string,
  dispatch?: Dispatch<any>
): Promise<ISearchPersonData | null> => {
  const user = await userManager.getUser();
  if (!user) {
    redirectAuthFail(t);
    return null;
  }
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/graph`,
      {
        query,
        operationName: 'FetchScreeningList',
      },
      { headers: { Authorization: `${user.token_type} ${user.access_token}` } }
    );

    if (Array.isArray(res.data.errors)) {
      toast.error(t('errors.personSearch'));
      return null;
    }

    if (res?.data?.data?.[STATUS_LIST] && dispatch) {
      dispatch(
        setScreeningData({ [STATUS_LIST]: res?.data?.data?.[STATUS_LIST] })
      );
    }
    if (res?.data?.data?.[HANDLER_LIST] && dispatch) {
      dispatch(
        setScreeningData({ [HANDLER_LIST]: res?.data?.data?.[HANDLER_LIST] })
      );
    }
    if (res?.data?.data?.[MUNICIPALITY_ID] && dispatch) {
      dispatch(
        setScreeningData({
          [MUNICIPALITY_ID]: res?.data?.data?.[MUNICIPALITY_ID],
        })
      );
    }
    return res?.data?.data;
  } catch (er) {
    toast.error(t('errors.personSearch'));
    return null;
  }
};

export interface ISearchPersonData {
  screeningSearch: { results: IPersonTable[]; totalCount: number };
  [STATUS_LIST]: IOption[];
  [HANDLER_LIST]: IOption[];
}
