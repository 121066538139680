import { Tabs } from '../../state/tabs/state';

export const tabsAvailabilityQuery = (screeningId: number) => `
query CheckTabAvailability
{
  screening (screeningId: ${screeningId}) {
    ${Tabs.Scopy}:isColonoscopyEnabled
    ${Tabs.SurgicalProcedure}:isSurgeryOrProcedureEnabled
    ${Tabs.TTGraphy}:isTtColonographyEnabled
    isLatestScreening
    ttColonographyModifiedBy
    ttColonographyModifiedAt
    surgicalProcedureModifiedBy
    surgicalProcedureModifiedAt
    preliminaryInfoModifiedBy
    preliminaryInfoModifiedAt
    colonoscopyModifiedBy
    colonoscopyModifiedAt
    analysisAndResultModifiedBy
    analysisAndResultModifiedAt
  }
}`;
