import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import componentStyles from './button.module.scss';

interface ButtonProps {
  label?: String;
  onClick?: (e: any) => void;
  type?: 'submit' | 'button';
  disabled?: boolean;
  className?: string;
  noSpinner?: boolean;
  styles?: React.CSSProperties;
  about?: string;
  title?: string;
  id?: string;
}

export const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  type,
  disabled,
  className,
  noSpinner,
  styles,
  about,
  title,
  id,
  children,
}) => {
  return (
    <button
      id={id}
      about={about}
      title={title}
      className={`${componentStyles.button} ${className}`}
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={(e) => {
        if (disabled) return;
        if (onClick) onClick(e);
      }}
      disabled={disabled}
      style={styles}
      data-testid={id}
    >
      {!noSpinner && disabled ? (
        <FontAwesomeIcon icon={faSpinner} spin />
      ) : null}{' '}
      {label !== '' ? label : ''}
      {children}
    </button>
  );
};

Button.defaultProps = {
  label: '',
  onClick: () => null,
  disabled: false,
  className: '',
  type: 'submit',
  noSpinner: false,
  styles: {},
  about: '',
  title: '',
  id: '',
};
