import React from 'react';
import Inputformik from '../../components/FormInput/formInput';
import TooltipLabel from '../../components/tooltipLabel/tooltipLabel';
import styles from './formInputs.module.scss';

interface ITextInputLabel {
  src: string;

  inputClassName?: string;
  disabled?: boolean;
  labelClassName?: string;

  required?: boolean;
  maxLength?: number;
  inputMode?:
    | 'text'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
    | undefined;
  errorClassName?: string;
  hideErros?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  value?: any | null;
}

const TextInputLabel: React.FC<ITextInputLabel> = ({
  src,

  inputClassName,
  disabled,

  labelClassName,
  required,
  maxLength,
  inputMode,
  errorClassName,
  hideErros,
  onChange,
  onBlur,
  value,
}) => {
  const [form, name] = src.split('.');

  return (
    <div className={styles.wrapper}>
      <TooltipLabel
        label={form}
        name={name}
        labelClassName={labelClassName}
        required={required}
      />
      <Inputformik
        name={name}
        inputClassName={inputClassName}
        disabled={disabled}
        maxLength={maxLength}
        inputMode={inputMode}
        errorClassName={errorClassName}
        hideErros={hideErros}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
      />
    </div>
  );
};

export default TextInputLabel;
