import {
  REPORT_BI_EMBEDED_URL,
  REPORT_BI_TOKEN,
} from '../../constants/reportConfigs';

export const fetchReportConfigs = () => `query reportConfigs {
    report {
       ${REPORT_BI_EMBEDED_URL} :embedUrl
       ${REPORT_BI_TOKEN}: token
      }
  }
  `;
