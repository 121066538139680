import moment from 'moment';
import { TFunction } from 'react-i18next';
import { Dispatch } from 'redux';
import * as Yup from 'yup';
import {
  DIAGNOSES_BY_ID,
  DIAGNOSES_BY_NAME,
  DIAGNOSES_DIAMETER_MM_ID,
  DIAGNOSES_LOCATION_ID,
  DIAGNOSES_MORPHOLOGY_ID,
  DIAGNOSES_RESULT_ID,
  MUTATION_HISTOLOGICAL_DIAGNOSIS,
} from '../../constants/disgnosesTabel';
import {
  CANCER_PTNM_GRADUS_ID,
  SURGICAL_PROCEDURE_BY_ID,
  SURGICAL_PROCEDURE_BY_NAME,
  SURGICAL_PROCEDURE_CODE_ID,
  SURGICAL_PROCEDURE_DATE,
  SURGICAL_PROCEDURE_DIAGNOSES,
  SURGICAL_PROCEDURE_DIAGNOSES_DATE,
  SURGICAL_PROCEDURE_DIAGNOSES_LOCATION,
  SURGICAL_PROCEDURE_LOCATION,
  SURGICAL_PROCEDURE_PRIMARY_ID,
} from '../../constants/surgicalProcedureFormKeys';
import {
  validationNumberValues,
  validListSurgical,
} from '../../constantsLists/validationLists';
import { ISurgicalProcedureForm } from '../../models/surgicalProcedureFrom';
import { formsMutationList } from '../../services/queries/formsQueryHelper';
import { setLoadingValue, setSubmitForm } from '../../state/screening/actions';
import { Forms } from '../../state/screening/state';
import { formCleaning } from '../../utils/formCleaning';
import { isEmptyObject } from '../../utils/isEmptyObject';

export const surgicalValidation = (t: TFunction) =>
  Yup.object().shape({
    [SURGICAL_PROCEDURE_DATE]: Yup.date()
      .nullable()

      .test(SURGICAL_PROCEDURE_DATE, t('forms.requiredField'), function (val) {
        const values: ISurgicalProcedureForm = this.parent;
        return !(
          !val &&
          !isEmptyObject(
            values,
            validListSurgical[SURGICAL_PROCEDURE_DATE],
            false
          )
        );
      })
      .test(SURGICAL_PROCEDURE_DATE, t('forms.needMoreScopy'), function (val) {
        const values: ISurgicalProcedureForm = this.parent;
        return !(
          !!val &&
          isEmptyObject(values, validListSurgical.surgicalDecisionMin, false)
        );
      })
      .test(SURGICAL_PROCEDURE_DATE, t('forms.futureData'), (val) => {
        if (!val) return true;
        return moment(val).isSameOrBefore(moment(), 'day');
      }),
    [SURGICAL_PROCEDURE_LOCATION]: Yup.number()
      .nullable()
      .test(
        SURGICAL_PROCEDURE_LOCATION,
        t('forms.requiredLocation'),
        function (val) {
          const values: ISurgicalProcedureForm = this.parent;
          return !(
            !val &&
            val !== 0 &&
            !isEmptyObject(
              values,
              validListSurgical[SURGICAL_PROCEDURE_LOCATION],
              false
            )
          );
        }
      )
      .test(
        SURGICAL_PROCEDURE_LOCATION,
        t('forms.needMoreScopy'),
        function (val) {
          const values: ISurgicalProcedureForm = this.parent;
          return !(
            !!val &&
            isEmptyObject(values, validListSurgical.surgicalDecisionMin, false)
          );
        }
      ),

    [SURGICAL_PROCEDURE_DIAGNOSES_DATE]: Yup.date()
      .nullable()

      .test(
        SURGICAL_PROCEDURE_DIAGNOSES_DATE,
        t('forms.requiredField'),
        function (val) {
          const values: ISurgicalProcedureForm = this.parent;
          return !(
            !val &&
            !isEmptyObject(
              values,
              validListSurgical[SURGICAL_PROCEDURE_DIAGNOSES_DATE],
              false
            )
          );
        }
      )
      .test(
        SURGICAL_PROCEDURE_DIAGNOSES_DATE,
        t('forms.needMoreDiagnose'),
        function (val) {
          const values: ISurgicalProcedureForm = this.parent;
          return !(!!val && !values[SURGICAL_PROCEDURE_DIAGNOSES]?.length);
        }
      )
      .test(SURGICAL_PROCEDURE_DIAGNOSES_DATE, t('forms.futureData'), (val) => {
        if (!val) return true;
        return moment(val).isSameOrBefore(moment(), 'day');
      }),
    [SURGICAL_PROCEDURE_BY_NAME]: Yup.string()
      .nullable()
      .max(
        validationNumberValues[SURGICAL_PROCEDURE_BY_NAME].max,
        t('forms.mustBeStringMax', {
          maxCharacter: validationNumberValues[SURGICAL_PROCEDURE_BY_NAME].max,
        })
      ),
    [SURGICAL_PROCEDURE_BY_ID]: Yup.string()
      .nullable()
      .max(
        validationNumberValues[SURGICAL_PROCEDURE_BY_ID].max,
        t('forms.mustBeStringMax', {
          maxCharacter: validationNumberValues[SURGICAL_PROCEDURE_BY_ID].max,
        })
      ),

    [DIAGNOSES_BY_NAME]: Yup.string()
      .nullable()
      .max(
        validationNumberValues[DIAGNOSES_BY_NAME].max,
        t('forms.mustBeStringMax', {
          maxCharacter: validationNumberValues[DIAGNOSES_BY_NAME].max,
        })
      ),
    [DIAGNOSES_BY_ID]: Yup.string()
      .nullable()
      .max(
        validationNumberValues[DIAGNOSES_BY_ID].max,
        t('forms.mustBeStringMax', {
          maxCharacter: validationNumberValues[DIAGNOSES_BY_ID].max,
        })
      ),
    [MUTATION_HISTOLOGICAL_DIAGNOSIS]: Yup.string()
      .nullable()
      .max(
        validationNumberValues[MUTATION_HISTOLOGICAL_DIAGNOSIS].max,
        t('forms.mustBeStringMax', {
          maxCharacter:
            validationNumberValues[MUTATION_HISTOLOGICAL_DIAGNOSIS].max,
        })
      ),
    [SURGICAL_PROCEDURE_DIAGNOSES_LOCATION]: Yup.number()
      .nullable()
      .test(
        SURGICAL_PROCEDURE_DIAGNOSES_LOCATION,
        t('forms.requiredLocation'),
        function (val) {
          const values: ISurgicalProcedureForm = this.parent;
          return !(
            !val &&
            val !== 0 &&
            !isEmptyObject(
              values,
              validListSurgical[SURGICAL_PROCEDURE_DIAGNOSES_LOCATION],
              false
            )
          );
        }
      )
      .test(
        SURGICAL_PROCEDURE_DIAGNOSES_LOCATION,
        t('forms.needMoreDiagnose'),
        function (val) {
          const values: ISurgicalProcedureForm = this.parent;
          return !(
            !!val &&
            val !== 0 &&
            !values[SURGICAL_PROCEDURE_DIAGNOSES]?.length
          );
        }
      ),

    [SURGICAL_PROCEDURE_DIAGNOSES]: Yup.array()
      .nullable()
      .of(
        Yup.object().shape({
          [DIAGNOSES_MORPHOLOGY_ID]: Yup.number()
            .typeError(t('forms.mustBeNumber'))
            .required(t('forms.requiredField')),
          [DIAGNOSES_LOCATION_ID]: Yup.number()
            .nullable()
            .typeError(t('forms.mustBeNumber')),
          [DIAGNOSES_DIAMETER_MM_ID]: Yup.number()
            .nullable()
            .typeError(t('forms.mustBeNumber')),
        })
      ),
  });

export const surgicalSubmit = (
  form: ISurgicalProcedureForm,
  t: TFunction,
  dispatch: Dispatch<any>,
  resetForm: () => void
) => {
  dispatch(setLoadingValue(true));
  let cleanedForm = form;

  //clean diagnose from isNew property
  if (cleanedForm[SURGICAL_PROCEDURE_DIAGNOSES]?.length) {
    cleanedForm[SURGICAL_PROCEDURE_DIAGNOSES] = cleanedForm[
      SURGICAL_PROCEDURE_DIAGNOSES
    ]!.map((el) => ({
      [DIAGNOSES_MORPHOLOGY_ID]: el[DIAGNOSES_MORPHOLOGY_ID],
      [DIAGNOSES_LOCATION_ID]: el[DIAGNOSES_LOCATION_ID],
      [DIAGNOSES_DIAMETER_MM_ID]: el[DIAGNOSES_DIAMETER_MM_ID],
      [DIAGNOSES_RESULT_ID]: el[DIAGNOSES_RESULT_ID],
    }));
  }

  // if surgicalProcedureAsPrimaryTreatmentId is (Neoadjuvantti ensisijaisena hoitona) we remove the added cancerPtnmGradusId
  if (form[SURGICAL_PROCEDURE_PRIMARY_ID] === 3) {
    cleanedForm = formCleaning(cleanedForm, [CANCER_PTNM_GRADUS_ID], true);
  }
  // if surgicalProcedureAsPrimaryTreatmentId is (Kirurgiaa ei tehty) we remove the added surgicalProcedureCodeId
  if (form[SURGICAL_PROCEDURE_PRIMARY_ID] === 2) {
    cleanedForm = formCleaning(cleanedForm, [SURGICAL_PROCEDURE_CODE_ID], true);
  }
  dispatch(
    setSubmitForm(
      Forms.SURGICAL_PROCEDURE,
      formsMutationList[Forms.SURGICAL_PROCEDURE](cleanedForm),
      t,
      resetForm
    )
  );
};
