import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/rootReducer';
import { lastEditFun } from '../../utils/lastEditedTab';
import styles from './tabLastEdit.module.scss';

const TabLastEdit = () => {
  const { t } = useTranslation();
  const {
    tab: { currentTab },
    screening,
  } = useSelector((state: RootState) => state);
  const lastEditObj = lastEditFun(screening, currentTab);

  if (!lastEditObj) {
    return <></>;
  }

  return (
    <div className={`container__row ${styles.container}`}>
      {t('header.lastEdit')}: {lastEditObj.editBy},{' '}
      {moment(lastEditObj.editAt).format('DD.MM.YYYY, HH:mm')}
    </div>
  );
};
export default TabLastEdit;
