import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../components/button/button';
import FocusScrollError from '../../components/focusScrollFormError/focusScrollError';
import RadioSelect from '../../components/radioSelect/radioSelect';
import SectionHeader from '../../components/sectionHeader/sectionHeader';
import { SCREENING_ID } from '../../constants/commonKeys';
import { LOCKED_BY_CURRENT_USER } from '../../constants/lockKeys';
import {
  DECISION_BY_NAME,
  DECISION_DATE,
  DECISION_ID,
  DECISION_LOCATION,
  OTHER_REASONS,
  PREVIOUS_YEAR,
  SCOPY_DECISION_BY_ID,
  SCOPY_NOT_PERFORMED_REASON,
  SCOPY_REFERRAL_LOCATION,
} from '../../constants/scopyForm';
import { decisionList } from '../../constantsLists/lists';
import { screeningUnlock } from '../../services/queries/screeningLock';
import { RootState } from '../../state/rootReducer';
import { setSubmitForm } from '../../state/screening/actions';
import { Forms } from '../../state/screening/state';
import AsyncDropdown from '../asyncDropdown/asyncDropdown';
import styles from '../personTabContainer/tabs.module.scss';

import DatePickerLabel from '../formInputs/datePickerLabel';
import DropdownFormik from '../formInputs/dropdownFormik';
import TextInputLabel from '../formInputs/textInputLabel';
import { scopySubmit, valideFollowup } from './analysisHelper';

const Followup: React.FC = () => {
  const { t } = useTranslation();
  const screening = useSelector((state: RootState) => state.screening);
  const isEditing = screening.screeningLock[LOCKED_BY_CURRENT_USER];
  const isLoading = screening.isLoading;
  const validate = valideFollowup(t);
  const dispatch = useDispatch();
  let showChangeFollowUpWarning = !!screening[Forms.SCOPY]?.[DECISION_ID];

  return (
    <Formik
      initialValues={{
        ...screening[Forms.SCOPY],
        [SCREENING_ID]: screening.screeningId,
      }}
      enableReinitialize={true}
      validationSchema={validate}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={(vals, { resetForm }) =>
        scopySubmit(
          vals,
          t,
          dispatch,
          () => resetForm({ values: screening[Forms.SCOPY] }),
          true
        )
      }
    >
      {({ values, resetForm }) => {
        return (
          <Form className="container__row">
            <FocusScrollError />
            {/*/////////////////////////////////////////// ROW 1 ///////////////////////////////*/}
            <SectionHeader src="scopyForm" name="decisionFollow" />
            <div className="container__row">
              <div className="grid-4-1st">
                <DatePickerLabel
                  src={`scopyForm.${DECISION_DATE}`}
                  disabled={!isEditing}
                  required={isEditing}
                />
              </div>
              <div className="grid-4-2nd">
                <AsyncDropdown
                  defaultOptions={[]}
                  src={`scopyForm.${DECISION_LOCATION}`}
                  store={1}
                  disabled={!isEditing}
                  required={isEditing}
                />
              </div>
              <div className="grid-4-3rd">
                <TextInputLabel
                  src={`scopyForm.${DECISION_BY_NAME}`}
                  disabled={!isEditing}
                />
              </div>
              <div className="grid-4-4th">
                <TextInputLabel
                  src={`scopyForm.${SCOPY_DECISION_BY_ID}`}
                  disabled={!isEditing}
                />
              </div>
            </div>
            <div className="container__row">
              {/*/////////////////////////////////////////// ROW 2 ///////////////////////////////*/}

              <RadioSelect
                optionsList={decisionList}
                name={DECISION_ID}
                disabled={!isEditing}
                onChangeWarning={() => {
                  // Clear these to ensure validation works
                  values[SCOPY_NOT_PERFORMED_REASON] = null;
                  values[OTHER_REASONS] = '';
                  values[PREVIOUS_YEAR] = null;
                  // Show warning on button change until user clicks ok on warning
                  if (showChangeFollowUpWarning) {
                    const warningResult = window.confirm(
                      t('forms.changePrimaryFollowUpWarning')
                    );
                    if (warningResult === true) {
                      showChangeFollowUpWarning = false;
                    }
                    return warningResult;
                  } else {
                    return true;
                  }
                }}
              />
            </div>
            <div className="container__row">
              {/*/////////////////////////////////////////// ROW 3 ///////////////////////////////*/}

              <div className="grid-4-1st">
                <AsyncDropdown
                  defaultOptions={[]}
                  src={`scopyForm.${SCOPY_REFERRAL_LOCATION}`}
                  store={1}
                  disabled={!isEditing || values[DECISION_ID] === 1}
                  required={
                    isEditing &&
                    (values[DECISION_ID] === 3 || values[DECISION_ID] === 6)
                  }
                />
              </div>
            </div>

            {!!values[DECISION_ID] && values[DECISION_ID] !== 6 && (
              <>
                <SectionHeader src="scopyForm" name="moreInfo" />
                <div className="container__row">
                  {/*/////////////////////////////////////////// ROW 4 ///////////////////////////////*/}
                  <div className="grid-2-1st">
                    <DropdownFormik
                      dropDown={screening[SCOPY_NOT_PERFORMED_REASON]}
                      src={`scopyForm.${SCOPY_NOT_PERFORMED_REASON}`}
                      disabled={!isEditing || values[DECISION_ID] === 6}
                      required={
                        !!values[DECISION_ID] && values[DECISION_ID] !== 6
                      }
                      onChange={(option) => {
                        resetForm({
                          values: {
                            ...values,
                            [PREVIOUS_YEAR]: null,
                            [OTHER_REASONS]: '',
                          },
                        });
                        // Making sure we are getting correct values correct way
                        if (Array.isArray(option))
                          return option.map((el) => JSON.parse(el.value));
                        return JSON.parse(option.value);
                      }}
                    />
                  </div>
                  <div className="grid-4-3rd">
                    <TextInputLabel
                      src={`scopyForm.${PREVIOUS_YEAR}`}
                      disabled={
                        !isEditing || values[SCOPY_NOT_PERFORMED_REASON] !== 3
                      }
                      required={values[SCOPY_NOT_PERFORMED_REASON] === 3}
                    />
                  </div>
                </div>
                {/*/////////////////////////////////////////// ROW 5 ///////////////////////////////*/}
                <div className="container__row">
                  <div className="container__col-xl-9 container__col-12">
                    <TextInputLabel
                      src={`scopyForm.${OTHER_REASONS}`}
                      disabled={
                        !isEditing || values[SCOPY_NOT_PERFORMED_REASON] !== 4
                      }
                      required={values[SCOPY_NOT_PERFORMED_REASON] === 4}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="container__row space-between ">
              <Button
                disabled={!isEditing || isLoading}
                className={styles.formButton}
                type="submit"
                noSpinner={!isEditing}
              >
                {t('control.save')}
              </Button>
              <Button
                type="button"
                onClick={() => {
                  resetForm({
                    values: screening[Forms.SCOPY],
                  });
                  dispatch(
                    setSubmitForm(
                      Forms.SCREENING_LOCK,
                      screeningUnlock(screening.screeningId),
                      t
                    )
                  );
                }}
                disabled={!isEditing || screening.isLoading}
                noSpinner={!isEditing}
                className={styles.formButton}
              >
                {t('control.quit')}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Followup;
