import { TFunction } from 'react-i18next';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { SCREENING_ID } from '../../constants/commonKeys';
import { ILock, lockInitial } from '../../models/ILock';
import { IOption } from '../../models/IOption';
import { FetchFromData, SubmitFormData } from '../../services/FormService';
import { CheckTabAvailability } from '../../services/tabAvailabilityService';
import { RootState } from '../rootReducer';
import { checkIfTabAvailabel } from '../tabs/actions';
import { Tabs } from '../tabs/state';
import { Forms, ScreeningState, TabAvailability } from './state';

export enum ActionTypes {
  registerId = 'NEW_SCREENING_ID',
  setLocationsSuccess = 'SET_LOCATIONS_SUCCESS',
  setFormLock = 'SET_FORM_LOCK',
  setLoading = 'SET_LOADING',
  setForm = 'SET_FORM',
  setTabAvailability = 'SET_TAP_AVAILBILITY',
  setDefault = 'SET_DEFAULT',
}

type ScreeningThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  ScreeningActionTypes
>;

interface SetFormLockAction extends Action {
  type: ActionTypes.setFormLock;
  payload: { [Forms.SCREENING_LOCK]: ILock } | { [Forms.PERSON_LOCK]: ILock };
}
interface SetDefaultAction extends Action {
  type: ActionTypes.setDefault;
}

interface SetTabAvailabilityAction extends Action {
  type: ActionTypes.setTabAvailability;
  payload: TabAvailability;
}

interface SetLocationsSuccessAction extends Action {
  type: ActionTypes.setLocationsSuccess;
  payload: IOption[];
}
interface ScreeningAction extends Action {
  type: ActionTypes.registerId;
  payload: number;
}
export interface SetLoadingAction extends Action {
  type: ActionTypes.setLoading;
  payload: boolean;
}
export interface SetPreliminary extends Action {
  type: ActionTypes.setForm;
  payload: Partial<ScreeningState>;
}

export const setLocationsSuccess = () => ({
  type: ActionTypes.setLocationsSuccess,
});

export const setScreeningId = (value: number | null) => ({
  type: ActionTypes.registerId,
  payload: value,
});

export const setLoadingValue = (val: boolean) => ({
  type: ActionTypes.setLoading,
  payload: val,
});
export const setScreeningData = (val: Partial<ScreeningState>) => ({
  type: ActionTypes.setForm,
  payload: val,
});

export const setDefaultScreening = () => ({
  type: ActionTypes.setDefault,
});

export const setAvailability = (
  screeningID: number,
  t: TFunction
): ScreeningThunk => async (dispatch) => {
  const tabs = await CheckTabAvailability(screeningID, t);
  if (!tabs) {
    return;
  }
  dispatch({
    type: ActionTypes.setTabAvailability,
    payload: {
      [Tabs.Preliminary]: true,
      [Tabs.AnalysisResult]: true,
      isLatestScreening: tabs.isLatestScreening,
      [Tabs.Scopy]: tabs[Tabs.Scopy],
      [Tabs.TTGraphy]: tabs[Tabs.TTGraphy],
      [Tabs.SurgicalProcedure]: tabs[Tabs.SurgicalProcedure],
    },
  });
};

export const fetchForm = (
  screeningId: number,
  tab: Tabs,
  t: TFunction
): ScreeningThunk => async (dispatch, getState) => {
  dispatch({
    type: ActionTypes.setLoading,
    payload: true,
  });
  const screeningState = getState().screening;
  const form = await FetchFromData(tab, screeningId, screeningState, t);
  dispatch({
    type: ActionTypes.setLoading,
    payload: false,
  });
  if (
    ![`/screening/${screeningId}`, `/screening/${screeningId}/`].includes(
      window.location.pathname
    ) ||
    !form
  )
    return;
  dispatch({
    type: ActionTypes.setForm,
    payload: form,
  });
  dispatch(checkIfTabAvailabel());
};

export const setSubmitForm = (
  formName: Forms,
  query: string,
  t: TFunction,
  resetForms?: () => void,
  onError?: (errors: any[]) => void
): ScreeningThunk => async (dispatch, getState) => {
  dispatch({
    type: ActionTypes.setLoading,
    payload: true,
  });
  const form = await SubmitFormData(
    formName,
    query,
    t,
    getState().screening[SCREENING_ID],
    onError
  );

  if (resetForms) {
    resetForms();
  }
  dispatch({
    type: ActionTypes.setLoading,
    payload: false,
  });
  if (formName === Forms.PERSON) {
    dispatch({
      type: ActionTypes.setFormLock,
      payload: { [Forms.PERSON_LOCK]: lockInitial },
    });
  }
  if (
    formName === Forms.PRELIMINARY ||
    formName === Forms.SCOPY ||
    formName === Forms.TTGRAPHY ||
    formName === Forms.SURGICAL_PROCEDURE
  ) {
    dispatch({
      type: ActionTypes.setFormLock,
      payload: { [Forms.SCREENING_LOCK]: lockInitial },
    });
  }
  if (!form) return;
  dispatch({
    type: ActionTypes.setForm,
    payload: form,
  });
  dispatch(checkIfTabAvailabel());
};
export type ScreeningActionTypes =
  | ScreeningAction
  | SetLocationsSuccessAction
  | SetFormLockAction
  | SetLoadingAction
  | SetPreliminary
  | SetDefaultAction
  | SetTabAvailabilityAction;
