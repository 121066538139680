import { TFunction } from 'react-i18next';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ROLE_IDS } from '../../constants/adminTabel';
import { FetchCurrentUser } from '../../services/UserService';
import logoutHelper from '../../utils/logoutHelper';
import userManager from '../../utils/userManager';
import { RootState } from '../rootReducer';
import { initialUsersState } from './reducer';
import { CurrentUser } from './state';

export enum ActionTypes {
  LogoutUser = 'LOGOUT_USER',
  LoginUser = 'LOGIN_USER',
}

interface LogoutUserAction extends Action {
  type: ActionTypes.LogoutUser;
  payLoad: CurrentUser;
}
interface LoginUserAction extends Action {
  type: ActionTypes.LoginUser;
  payLoad: CurrentUser;
}

export type UsersActionTypes = LogoutUserAction | LoginUserAction;

type UserThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  UsersActionTypes
>;
export const LogoutUser = (): UserThunk => async (dispatch) => {
  dispatch({
    type: ActionTypes.LogoutUser,
    payLoad: initialUsersState,
  });
  const user = await userManager.getUser();
  if (user) {
    await logoutHelper('logout', user.id_token ?? '');
  }
};

export const loginUser = (t: TFunction): UserThunk => async (dispatch) => {
  const user = await FetchCurrentUser(t);
  if (!user) {
    return;
  }
  dispatch({
    type: ActionTypes.LoginUser,
    payLoad: {
      isLoggedIn: true,
      ...user,
    },
  });
};
