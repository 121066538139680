import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../components/button/button';
import FocusScrollError from '../../components/focusScrollFormError/focusScrollError';
import Header from '../../components/header/header';
import SectionHeader from '../../components/sectionHeader/sectionHeader';
import {
  ADITIONAL_INFO,
  IS_INERRUPTED,
  LARGEST_POLY_DIAMETER_ID,
  NUMBER_POLYP_ID,
  SCREENING_ID,
} from '../../constants/commonKeys';
import {
  DIAGNOSES_BY_ID,
  DIAGNOSES_BY_NAME,
  DIAGNOSES_DIAMETER_MM_ID,
  MUTATION_HISTOLOGICAL_DIAGNOSIS,
} from '../../constants/disgnosesTabel';
import {
  FOLLOW_UP_BY_ID,
  FOLLOW_UP_BY_NAME,
  FOLLOW_UP_DATE,
  FOLLOW_UP_DECISION_ID,
  FOLLOW_UP_LOCATION,
  FOLLOW_UP_REFERRAL_LOCATION,
} from '../../constants/followUp';
import { LOCKED_BY_CURRENT_USER } from '../../constants/lockKeys';
import {
  BOWEL_EMPTY_SUCCESS_ID,
  COMPLICATION_IDS,
  DECISION_ID,
  DIAGNOSES_DATE,
  DIAGNOSES_LOCATION,
  FOLLOW_UP_DECISION_ID_COLONSCOPY,
  HAD_INNER_TURN_DONE,
  HAD_INTERVAENOUS_PREMEDICATION,
  SCOPY_DATE,
  SCOPY_DIAGNOSES,
  SCOPY_DONE_BY_ID,
  SCOPY_DONE_BY_NAME,
  SCOPY_FINDING_IDS,
  SCOPY_LIMITATION_IDS,
  SCOPY_LOCATION_ID,
  SCOPY_OTHER_FINDINGS,
  SCOPY_OTHER_LIMITS,
  SCOPY_PROCEDURE_IDS,
  SCOPY_REACHED_ID,
  SCOPY_RETRIEVAL_MIN,
} from '../../constants/scopyForm';
import { yesOrNo } from '../../constantsLists/lists';
import { validListScopy } from '../../constantsLists/validationLists';
import { screeningUnlock } from '../../services/queries/screeningLock';
import { RootState } from '../../state/rootReducer';
import { fetchForm, setSubmitForm } from '../../state/screening/actions';
import { Forms } from '../../state/screening/state';
import { Tabs } from '../../state/tabs/state';
import { isEmptyObject } from '../../utils/isEmptyObject';
import AsyncDropdown from '../asyncDropdown/asyncDropdown';
import DiagnosesTable from '../diagnoseTable/diagoseTable';
import DatePickerLabel from '../formInputs/datePickerLabel';
import DropdownFormik from '../formInputs/dropdownFormik';
import TextInputLabel from '../formInputs/textInputLabel';
import EditingBar from '../editingBar/editingBar';
import styles from '../personTabContainer/tabs.module.scss';
import { scopySubmit, valideScopy } from './scopyHelper';
import TabLastEdit from '../../components/tabLastEdit/tabLastEdit';

const Scopy: React.FC = () => {
  const { t } = useTranslation();
  const screening = useSelector((state: RootState) => state.screening);
  const isEditing = screening.screeningLock[LOCKED_BY_CURRENT_USER];
  const isLoading = screening.isLoading;
  const validate = valideScopy(t);
  const dispatch = useDispatch();

  useEffect(() => {
    if (screening.screeningId) {
      dispatch(fetchForm(screening.screeningId, Tabs.Scopy, t));
    }
  }, [screening.screeningId]);

  return (
    <div className="container--fluid">
      <Header title={`tabs.${Tabs.Scopy}`} titleClassName={styles.title}>
        <EditingBar>
          <TabLastEdit />
        </EditingBar>
      </Header>
      <Formik
        initialValues={{
          ...screening[Forms.SCOPY],
          [SCREENING_ID]: screening.screeningId,
        }}
        enableReinitialize={true}
        validationSchema={validate}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        onSubmit={(vals, { resetForm }) =>
          scopySubmit(
            vals,
            t,
            dispatch,
            () => resetForm({ values: screening[Forms.SCOPY] }),
            false
          )
        }
      >
        {({ setFieldValue, values, resetForm }) => {
          const interrupted: boolean = !isEditing || !!values[IS_INERRUPTED];
          const followUpDisabled = isEmptyObject(
            values,
            validListScopy.followUpDisabled,
            false
          );
          return (
            <Form className="container__row">
              <FocusScrollError />

              <>
                <SectionHeader
                  src="scopyForm"
                  name=""
                  desciption="scopyForm.colonscopyDesc"
                />
                <div className="container__row">
                  {/*/////////////////////////////////////////// ROW 4 ///////////////////////////////*/}
                  <div className={`grid-4-1st ${styles.checkBoxContainer}`}>
                    <input
                      type="checkbox"
                      name={IS_INERRUPTED}
                      disabled={!isEditing}
                      onChange={(val) => {
                        if (
                          val.target.checked &&
                          window.confirm(t('forms.interruptedWarning'))
                        ) {
                          return setFieldValue(
                            IS_INERRUPTED,
                            val.target.checked
                          );
                        }
                        if (val.target.checked) {
                          return;
                        }
                        return setFieldValue(IS_INERRUPTED, val.target.checked);
                      }}
                      checked={!!values[IS_INERRUPTED]}
                    />
                    <label htmlFor={IS_INERRUPTED}>
                      {t(`scopyForm.${IS_INERRUPTED}`)}
                    </label>
                  </div>
                </div>
                <div className="container__row">
                  {/*/////////////////////////////////////////// ROW 5 ///////////////////////////////*/}
                  <div className="grid-4-1st">
                    <DatePickerLabel
                      src={`scopyForm.${SCOPY_DATE}`}
                      disabled={!isEditing}
                      required={
                        (isEditing && !!values[IS_INERRUPTED]) ||
                        !isEmptyObject(
                          values,
                          validListScopy[SCOPY_DATE],
                          false
                        )
                      }
                    />
                  </div>
                  <div className="grid-4-2nd">
                    <AsyncDropdown
                      defaultOptions={[]}
                      src={`scopyForm.${SCOPY_LOCATION_ID}`}
                      store={1}
                      disabled={!isEditing}
                      required={
                        (isEditing && !!values[IS_INERRUPTED]) ||
                        !isEmptyObject(
                          values,
                          validListScopy[SCOPY_LOCATION_ID],
                          false
                        )
                      }
                    />
                  </div>
                  <div className="grid-4-3rd">
                    <TextInputLabel
                      src={`scopyForm.${SCOPY_DONE_BY_NAME}`}
                      disabled={!isEditing}
                      required={!!values[IS_INERRUPTED]}
                    />
                  </div>
                  <div className="grid-4-4th">
                    <TextInputLabel
                      src={`scopyForm.${SCOPY_DONE_BY_ID}`}
                      disabled={!isEditing}
                    />
                  </div>
                </div>
                <div className="container__row">
                  {/*/////////////////////////////////////////// ROW 6 ///////////////////////////////*/}
                  <div className="grid-4-1st">
                    <DropdownFormik
                      src={`scopyForm.${HAD_INTERVAENOUS_PREMEDICATION}`}
                      dropDown={yesOrNo}
                      disabled={interrupted}
                    />
                  </div>
                  <div className="grid-4-2nd">
                    <TextInputLabel
                      src={`scopyForm.${SCOPY_RETRIEVAL_MIN}`}
                      disabled={interrupted}
                    />
                  </div>
                  <div className="grid-4-3rd">
                    <DropdownFormik
                      src={`scopyForm.${HAD_INNER_TURN_DONE}`}
                      dropDown={yesOrNo}
                      disabled={interrupted}
                    />
                  </div>
                  <div className="grid-4-4th">
                    <DropdownFormik
                      src={`scopyForm.${BOWEL_EMPTY_SUCCESS_ID}`}
                      dropDown={screening[BOWEL_EMPTY_SUCCESS_ID]}
                      disabled={interrupted}
                    />
                  </div>
                </div>
                <div className="container__row">
                  {/*/////////////////////////////////////////// ROW 7 ///////////////////////////////*/}
                  <div className="grid-4-1st">
                    <DropdownFormik
                      src={`scopyForm.${SCOPY_REACHED_ID}`}
                      dropDown={screening[SCOPY_REACHED_ID]}
                      disabled={interrupted}
                    />
                  </div>
                  <div className="grid-4-2nd">
                    <DropdownFormik
                      src={`scopyForm.${SCOPY_LIMITATION_IDS}`}
                      dropDown={screening[SCOPY_LIMITATION_IDS]}
                      disabled={interrupted}
                      isMulti
                    />
                  </div>
                  <div className="grid-2-2nd">
                    <TextInputLabel
                      src={`scopyForm.${SCOPY_OTHER_LIMITS}`}
                      disabled={
                        interrupted ||
                        !values[SCOPY_LIMITATION_IDS] ||
                        values[SCOPY_LIMITATION_IDS]?.indexOf(5) === -1
                      }
                    />
                  </div>
                </div>
                <div className="container__row">
                  {/*/////////////////////////////////////////// ROW 8 ///////////////////////////////*/}
                  <div className="grid-4-1st">
                    <DropdownFormik
                      src={`scopyForm.${SCOPY_FINDING_IDS}`}
                      dropDown={screening[SCOPY_FINDING_IDS]}
                      disabled={interrupted}
                      isMulti
                    />
                  </div>
                  <div className="grid-3-2nd">
                    <TextInputLabel
                      src={`scopyForm.${SCOPY_OTHER_FINDINGS}`}
                      disabled={
                        interrupted ||
                        !values[SCOPY_FINDING_IDS] ||
                        values[SCOPY_FINDING_IDS]?.indexOf(8) === -1
                      }
                    />
                  </div>
                </div>
                <div className="container__row">
                  {/*/////////////////////////////////////////// ROW 9 ///////////////////////////////*/}
                  <div className="grid-4-1st">
                    <DropdownFormik
                      src={`scopyForm.${NUMBER_POLYP_ID}`}
                      dropDown={screening[NUMBER_POLYP_ID]}
                      disabled={
                        interrupted ||
                        !values[SCOPY_FINDING_IDS] ||
                        values[SCOPY_FINDING_IDS]?.indexOf(3) === -1
                      }
                      required={
                        isEditing &&
                        values[SCOPY_FINDING_IDS] &&
                        values[SCOPY_FINDING_IDS]?.indexOf(3) !== -1
                      }
                    />
                  </div>
                  <div className="grid-4-2nd">
                    <DropdownFormik
                      src={`scopyForm.${LARGEST_POLY_DIAMETER_ID}`}
                      dropDown={screening[DIAGNOSES_DIAMETER_MM_ID]}
                      optionSrc={DIAGNOSES_DIAMETER_MM_ID}
                      disabled={interrupted}
                    />
                  </div>
                  <div className="grid-4-3rd">
                    <DropdownFormik
                      src={`scopyForm.${SCOPY_PROCEDURE_IDS}`}
                      dropDown={screening[SCOPY_PROCEDURE_IDS]}
                      disabled={interrupted}
                      isMulti
                    />
                  </div>

                  <div className="grid-4-4th">
                    <DropdownFormik
                      src={`scopyForm.${COMPLICATION_IDS}`}
                      dropDown={screening[COMPLICATION_IDS]}
                      disabled={interrupted}
                      isMulti
                    />
                  </div>
                </div>
                <div className="container__row">
                  {/*/////////////////////////////////////////// ROW 10 ///////////////////////////////*/}
                  <div className="container__col-12">
                    <TextInputLabel
                      src={`scopyForm.${ADITIONAL_INFO}`}
                      disabled={interrupted}
                    />
                  </div>
                </div>
                <SectionHeader
                  src="scopyForm"
                  name="diagnoses"
                  desciption="scopyForm.diagnosesDesc"
                />
                <div className="container__row">
                  {/*/////////////////////////////////////////// ROW 11 ///////////////////////////////*/}
                  <div className="grid-4-1st">
                    <DatePickerLabel
                      src={`scopyForm.${DIAGNOSES_DATE}`}
                      disabled={interrupted}
                      required={
                        isEditing &&
                        !isEmptyObject(
                          values,
                          validListScopy[DIAGNOSES_DATE],
                          false
                        )
                      }
                    />
                  </div>
                  <div className="grid-4-2nd">
                    <AsyncDropdown
                      defaultOptions={[]}
                      src={`scopyForm.${DIAGNOSES_LOCATION}`}
                      store={1}
                      disabled={interrupted}
                      required={
                        isEditing &&
                        !isEmptyObject(
                          values,
                          validListScopy[DIAGNOSES_LOCATION],
                          false
                        )
                      }
                    />
                  </div>
                  <div className="grid-4-3rd">
                    <TextInputLabel
                      src={`scopyForm.${DIAGNOSES_BY_NAME}`}
                      disabled={interrupted}
                    />
                  </div>
                  <div className="grid-4-4th">
                    <TextInputLabel
                      src={`scopyForm.${DIAGNOSES_BY_ID}`}
                      disabled={interrupted}
                    />
                  </div>
                </div>
                <DiagnosesTable disabled={interrupted} name={SCOPY_DIAGNOSES} />

                <div className="container__row">
                  {/*/////////////////////////////////////////// ROW 12 ///////////////////////////////*/}
                  <div className="container__col-12">
                    <TextInputLabel
                      src={`scopyForm.${MUTATION_HISTOLOGICAL_DIAGNOSIS}`}
                      disabled={interrupted}
                    />
                  </div>
                </div>
                <SectionHeader src="scopyForm" name="followUp" />

                <div className="container__row">
                  {/*/////////////////////////////////////////// ROW 13 ///////////////////////////////*/}

                  <div className="grid-4-1st">
                    <DatePickerLabel
                      src={`scopyForm.${FOLLOW_UP_DATE}`}
                      disabled={interrupted || followUpDisabled}
                      required={
                        !interrupted &&
                        !followUpDisabled &&
                        !isEmptyObject(
                          values,
                          validListScopy[FOLLOW_UP_DATE],
                          false
                        )
                      }
                    />
                  </div>
                  <div className="grid-4-2nd">
                    <AsyncDropdown
                      defaultOptions={[]}
                      src={`scopyForm.${FOLLOW_UP_LOCATION}`}
                      store={1}
                      disabled={interrupted || followUpDisabled}
                      required={
                        !interrupted &&
                        !followUpDisabled &&
                        !isEmptyObject(
                          values,
                          validListScopy[FOLLOW_UP_LOCATION],
                          false
                        )
                      }
                    />
                  </div>
                  <div className="grid-4-3rd">
                    <TextInputLabel
                      src={`scopyForm.${FOLLOW_UP_BY_NAME}`}
                      disabled={interrupted || followUpDisabled}
                    />
                  </div>
                  <div className="grid-4-4th">
                    <TextInputLabel
                      src={`scopyForm.${FOLLOW_UP_BY_ID}`}
                      disabled={interrupted || followUpDisabled}
                    />
                  </div>
                </div>
                <div className="container__row">
                  {/*/////////////////////////////////////////// ROW 14 ///////////////////////////////*/}

                  <div className="grid-2-1st">
                    <DropdownFormik
                      dropDown={screening[FOLLOW_UP_DECISION_ID]?.filter(
                        (el) => el.langValue !== '5'
                      )}
                      src={`scopyForm.${FOLLOW_UP_DECISION_ID}`}
                      clearOption={!screening[Forms.SCOPY][DECISION_ID]}
                      disabled={interrupted || followUpDisabled}
                      optionSrc={FOLLOW_UP_DECISION_ID_COLONSCOPY}
                      onChange={(val) => {
                        if (Array.isArray(val)) return false;
                        if (
                          val.value === '2' &&
                          window.confirm(t('forms.renewalwarning'))
                        ) {
                          setFieldValue(
                            FOLLOW_UP_REFERRAL_LOCATION,
                            values[SCOPY_LOCATION_ID]
                          );
                          return 2;
                        }
                        if (val.value === '2') {
                          return values[FOLLOW_UP_DECISION_ID];
                        } else {
                          return JSON.parse(val.value);
                        }
                      }}
                      required={
                        !interrupted &&
                        !followUpDisabled &&
                        !isEmptyObject(
                          values,
                          validListScopy[FOLLOW_UP_DECISION_ID],
                          false
                        )
                      }
                    />
                  </div>

                  <div className="grid-4-2nd">
                    <AsyncDropdown
                      defaultOptions={[]}
                      src={`scopyForm.${FOLLOW_UP_REFERRAL_LOCATION}`}
                      store={1}
                      required={
                        isEditing &&
                        !!values[FOLLOW_UP_DECISION_ID] &&
                        values[FOLLOW_UP_DECISION_ID] !== 5 &&
                        values[FOLLOW_UP_DECISION_ID] !== 1
                      }
                      disabled={
                        interrupted ||
                        followUpDisabled ||
                        values[FOLLOW_UP_DECISION_ID] === 1 ||
                        values[FOLLOW_UP_DECISION_ID] === 5
                      }
                    />
                  </div>
                </div>
              </>

              <div className="container__row space-between ">
                <Button
                  disabled={!isEditing || isLoading}
                  className={styles.formButton}
                  type="submit"
                  noSpinner={!isEditing}
                >
                  {t('control.save')}
                </Button>
                <Button
                  type="button"
                  onClick={() => {
                    resetForm({
                      values: screening[Forms.SCOPY],
                    });
                    dispatch(
                      setSubmitForm(
                        Forms.SCREENING_LOCK,
                        screeningUnlock(screening.screeningId),
                        t
                      )
                    );
                  }}
                  disabled={!isEditing || screening.isLoading}
                  noSpinner={!isEditing}
                  className={styles.formButton}
                >
                  {t('control.quit')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Scopy;
