import { FormikErrors, useField } from 'formik';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IOption } from '../../models/IOption';
import styles from './checkboxGroup.module.scss';

interface CheckboxGroupProps {
  name: string;
  items: IOption[];
  onChange?: (val: number[]) => number[];
  required?: boolean;
  isGroupDisabled: boolean;
  isCheckboxDisabled?: (item: IOption) => boolean;
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  name,
  items,
  isGroupDisabled,
  isCheckboxDisabled,
}) => {
  const { t } = useTranslation();
  const [tr, form, meta] = useField(name);

  return (
    <div
      id={name}
      className={`${styles.wrapper} ${isGroupDisabled ? styles.disabled : ''}`}
    >
      {items.map((item) => {
        const isDisabled =
          isGroupDisabled ||
          (isCheckboxDisabled !== undefined && isCheckboxDisabled(item));
        return (
          <div
            key={item.value}
            className={`${styles.itemWrapper} ${
              isDisabled ? styles.disabled : ''
            }`}
          >
            <input
              id={`${name}-${item.value}`}
              type="checkbox"
              checked={
                form.value?.find((el: string) => '' + el === '' + item.value) ||
                false
              }
              onChange={(v) => {
                if (isDisabled) return;
                if (v.target.checked) {
                  meta.setValue([...form.value, item.value]);
                } else {
                  meta.setValue(
                    [...form.value].filter((el) => '' + el !== '' + item.value)
                  );
                }
              }}
              name={name}
            />
            <label htmlFor={`${name}-${item.value}`}>
              {t(`${name}.${item.value}`)}
            </label>
          </div>
        );
      })}
      {form.error ? <div className={styles.error}>{form.error}</div> : null}
    </div>
  );
};

export default CheckboxGroup;
