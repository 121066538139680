import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ALLOWED_HANDLER_LIST } from '../../constants/commonKeys';
import { RootState } from '../../state/rootReducer';
import Select from 'react-select';
import styles from './screeningHandler.module.scss';
import { Forms } from '../../state/screening/state';
import { setSubmitForm } from '../../state/screening/actions';
import { formsMutationList } from '../../services/queries/formsQueryHelper';

export const ScreeningHandler: React.FC = () => {
  const screening = useSelector((state: RootState) => state.screening);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const translateOptions = screening[ALLOWED_HANDLER_LIST]?.map((el) => ({
    label: el.langValue,
    value: el.value,
  }));

  return (
    <>
      <div>
        <label className={styles.label}>
          <span>{t(`personFrom.${Forms.HANDLER}`)}</span>
        </label>
      </div>
      <Select
        options={
          translateOptions
            ? [{ value: 'null', label: t('forms.clear') }, ...translateOptions]
            : translateOptions
        }
        onChange={(val) => {
          if (!val?.value || +val.value === screening[Forms.HANDLER]) return;

          dispatch(
            setSubmitForm(
              Forms.HANDLER,
              formsMutationList[Forms.HANDLER](
                screening.screeningId,
                JSON.parse(val.value)
              ),
              t
            )
          );
        }}
        value={translateOptions?.find(
          (el) => +el.value === screening[Forms.HANDLER]
        )}
        placeholder={t('forms.select')}
      />
    </>
  );
};
