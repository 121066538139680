export const CLASSIFOCATION_ID = 'colonFindingsClassificationId';
export const TTGRAPHY_DATE = 'ttColonographyDate';
export const TTGRAPHY_LOCATION = 'ttColonographyLocationId';
export const MUTAIION_LOCATION_ID = 'mutationLocationId';
export const OTHER_CLASSIFOCATION_ID = 'otherFindingsClassificationId';
export const RADIOLOGIST_ID = 'radiologistStatementDoneById';
export const RADIOLOGIST_NAME = 'radiologistStatementDoneByName';
export const RADIOLOGIST_STATMENT = 'radiologistStatement';
export const RADIOLOGIST_STATMENT_DATE = 'radiologistStatementDate';
export const RADIOLOGIST_STATMENT_LOCATION = 'radiologistStatementLocationId';
export const TTGRAPHY_FINDING_IDS = 'ttColonographyFindingIds';
export const TTGRAPHY_BY_ID = 'ttColonographyDoneById';
export const TTGRAPHY_BY_NAME = 'ttColonographyDoneByName';
export const FOLLOW_UP_DECISION_ID_TTGRAPHY = 'followUpDecisionIdColonography';
export const TTGRAPHY_OTHER_FINDINGS = 'ttColonographyOtherFindingDescription';
