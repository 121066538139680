import {
  FIRST_NAMES,
  SCREENING_CODE,
  SSN,
  STATUS,
  SURNAME,
  COMMENT,
  COULD_NOT_BE_REACHED_AT,
} from '../constants/commonKeys';
import { MOTHER_LANG, PHONE, PERSON_ID } from '../constants/userInfoKeys';

export interface IPersonForm {
  [SSN]: string;
  [FIRST_NAMES]: string;
  [COMMENT]: string;
  [SURNAME]: string;
  [MOTHER_LANG]: string;
  [PHONE]: string;
  [SCREENING_CODE]: number | null;
  [STATUS]: string;
  [PERSON_ID]: number | null;
  [COULD_NOT_BE_REACHED_AT]: string | null;
}

export const personFormInitial: IPersonForm = {
  [SSN]: '',
  [FIRST_NAMES]: '',
  [COMMENT]: '',
  [SURNAME]: '',
  [MOTHER_LANG]: '',
  [PHONE]: '',
  [SCREENING_CODE]: 0,
  [STATUS]: '',
  [PERSON_ID]: null,
  [COULD_NOT_BE_REACHED_AT]: null,
};
