import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../rootReducer';
import { Tabs } from './state';

export enum ActionTypes {
  setTab = 'SET_TAP',
  setTabAvailability = 'SET_TAP_AVAILBILITY',
}

type TabThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  TabActionTypes
>;
interface SetTabAction extends Action {
  type: ActionTypes.setTab;
  payload: Tabs;
}

export type TabActionTypes = SetTabAction;

export const setTabValue = (value: Tabs) => ({
  type: ActionTypes.setTab,
  payload: value,
});

export const checkIfTabAvailabel = (): TabThunk => (dispatch, getState) => {
  const { screening, tab } = getState();
  if (!screening[tab.currentTab]) {
    dispatch({ type: ActionTypes.setTab, payload: Tabs.AnalysisResult });
  }
};
