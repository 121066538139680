import {
  NUMBER_POLYP_ID,
  LARGEST_POLY_DIAMETER_ID,
  SAMPLE_ID,
  SCREENING_ID,
  IS_INERRUPTED,
  ADITIONAL_INFO,
  FIRST_NAMES,
  SURNAME,
  COMMENT,
} from '../constants/commonKeys';
import { PHONE } from '../constants/userInfoKeys';
import {
  DIAGNOSES_BY_ID,
  DIAGNOSES_BY_NAME,
  DIAGNOSES_DIAMETER_MM_ID,
  MUTATION_HISTOLOGICAL_DIAGNOSIS,
} from '../constants/disgnosesTabel';
import {
  FOLLOW_UP_DATE,
  FOLLOW_UP_LOCATION,
  FOLLOW_UP_DECISION_ID,
  FOLLOW_UP_REFERRAL_LOCATION,
  FOLLOW_UP_BY_ID,
  FOLLOW_UP_BY_NAME,
} from '../constants/followUp';
import {
  HEIGHT,
  MEDICINE_SENSITIVITY,
  SMOKED_DAILY_AMOUNT_ID,
  SMOKED_DAILY_YEARS_ID,
  WEIGHT,
} from '../constants/preliminaryFormKeys';
import {
  SCOPY_LOCATION_ID,
  SCOPY_DATE,
  HAD_INTERVAENOUS_PREMEDICATION,
  SCOPY_RETRIEVAL_MIN,
  BOWEL_EMPTY_SUCCESS_ID,
  SCOPY_REACHED_ID,
  COMPLICATION_IDS,
  SCOPY_LIMITATION_IDS,
  SCOPY_PROCEDURE_IDS,
  SCOPY_FINDING_IDS,
  SCOPY_DIAGNOSES,
  HAD_INNER_TURN_DONE,
  SCOPY_NOT_PERFORMED_REASON,
  SCOPY_DONE_BY_NAME,
  SCOPY_DONE_BY_ID,
  DECISION_ID,
  DECISION_DATE,
  DECISION_LOCATION,
  SCOPY_DECISION_BY_ID,
  DECISION_BY_NAME,
  SCOPY_REFERRAL_LOCATION,
  PREVIOUS_YEAR,
  OTHER_REASONS,
  SCOPY_OTHER_LIMITS,
  SCOPY_OTHER_FINDINGS,
  DIAGNOSES_DATE,
  DIAGNOSES_LOCATION,
} from '../constants/scopyForm';
import {
  SURGICAL_PROCEDURE_DATE,
  SURGICAL_PROCEDURE_PRIMARY_ID,
  SURGICAL_PROCEDURE_CODE_ID,
  SURGICAL_PROCEDURE_DIAGNOSES,
  CANCER_PTNM_GRADUS_ID,
  CANCER_PTNM_M_ID,
  CANCER_PTNM_N_ID,
  CANCER_PTNM_T_ID,
  SURGICAL_PROCEDURE_BY_NAME,
  SURGICAL_PROCEDURE_BY_ID,
  SURGICAL_PROCEDURE_LOCATION,
  SURGICAL_PROCEDURE_DIAGNOSES_DATE,
  SURGICAL_PROCEDURE_DIAGNOSES_LOCATION,
} from '../constants/surgicalProcedureFormKeys';
import {
  RADIOLOGIST_STATMENT_DATE,
  RADIOLOGIST_STATMENT,
  TTGRAPHY_FINDING_IDS,
  MUTAIION_LOCATION_ID,
  CLASSIFOCATION_ID,
  OTHER_CLASSIFOCATION_ID,
  RADIOLOGIST_STATMENT_LOCATION,
  RADIOLOGIST_ID,
  RADIOLOGIST_NAME,
  TTGRAPHY_DATE,
  TTGRAPHY_LOCATION,
  TTGRAPHY_BY_ID,
  TTGRAPHY_BY_NAME,
  TTGRAPHY_OTHER_FINDINGS,
} from '../constants/ttgraphyFromKeys';

export const validListScopy = {
  [SCOPY_LOCATION_ID]: [
    SCOPY_DATE,
    HAD_INTERVAENOUS_PREMEDICATION,
    SCOPY_RETRIEVAL_MIN,
    BOWEL_EMPTY_SUCCESS_ID,
    SCOPY_REACHED_ID,
    NUMBER_POLYP_ID,
    LARGEST_POLY_DIAMETER_ID,
    COMPLICATION_IDS,
    ADITIONAL_INFO,
    SCOPY_LIMITATION_IDS,
    SCOPY_PROCEDURE_IDS,
    SCOPY_FINDING_IDS,
    SCOPY_DIAGNOSES,
    HAD_INNER_TURN_DONE,
    SCOPY_NOT_PERFORMED_REASON,
    SCOPY_DONE_BY_NAME,
    SCOPY_DONE_BY_ID,
  ],
  [DIAGNOSES_DATE]: [
    SCOPY_DIAGNOSES,
    MUTATION_HISTOLOGICAL_DIAGNOSIS,
    DIAGNOSES_BY_ID,
    DIAGNOSES_BY_NAME,
  ],
  [DIAGNOSES_LOCATION]: [
    SCOPY_DIAGNOSES,
    MUTATION_HISTOLOGICAL_DIAGNOSIS,
    DIAGNOSES_BY_ID,
    DIAGNOSES_BY_NAME,
  ],
  [SCOPY_DATE]: [
    SCOPY_LOCATION_ID,
    HAD_INTERVAENOUS_PREMEDICATION,
    SCOPY_RETRIEVAL_MIN,
    BOWEL_EMPTY_SUCCESS_ID,
    ADITIONAL_INFO,
    SCOPY_REACHED_ID,
    NUMBER_POLYP_ID,
    LARGEST_POLY_DIAMETER_ID,
    COMPLICATION_IDS,
    SCOPY_LIMITATION_IDS,
    SCOPY_PROCEDURE_IDS,
    SCOPY_FINDING_IDS,
    SCOPY_DIAGNOSES,
    HAD_INNER_TURN_DONE,
    SCOPY_NOT_PERFORMED_REASON,
    SCOPY_DONE_BY_NAME,
    SCOPY_DONE_BY_ID,
  ],
  [FOLLOW_UP_DATE]: [
    FOLLOW_UP_LOCATION,
    FOLLOW_UP_DECISION_ID,
    FOLLOW_UP_REFERRAL_LOCATION,
    FOLLOW_UP_BY_ID,
    FOLLOW_UP_BY_NAME,
  ],
  [FOLLOW_UP_LOCATION]: [
    FOLLOW_UP_DATE,
    FOLLOW_UP_DECISION_ID,
    FOLLOW_UP_REFERRAL_LOCATION,
    FOLLOW_UP_BY_ID,
    FOLLOW_UP_BY_NAME,
  ],
  [FOLLOW_UP_DECISION_ID]: [
    FOLLOW_UP_REFERRAL_LOCATION,
    FOLLOW_UP_BY_ID,
    FOLLOW_UP_BY_NAME,
    FOLLOW_UP_DATE,
    FOLLOW_UP_LOCATION,
  ],
  followUpDisabled: [
    SCOPY_DATE,
    SCOPY_LOCATION_ID,
    LARGEST_POLY_DIAMETER_ID,
    SCOPY_DONE_BY_NAME,
    SCOPY_DONE_BY_ID,
    HAD_INTERVAENOUS_PREMEDICATION,
    SCOPY_RETRIEVAL_MIN,
    HAD_INNER_TURN_DONE,
    BOWEL_EMPTY_SUCCESS_ID,
    SCOPY_REACHED_ID,
    SCOPY_LIMITATION_IDS,
    SCOPY_FINDING_IDS,
    NUMBER_POLYP_ID,
    DIAGNOSES_DIAMETER_MM_ID,
    SCOPY_PROCEDURE_IDS,
    COMPLICATION_IDS,
  ],
  followUp: [
    FOLLOW_UP_DATE,
    FOLLOW_UP_DECISION_ID,
    FOLLOW_UP_LOCATION,
    FOLLOW_UP_REFERRAL_LOCATION,
    FOLLOW_UP_BY_ID,
    FOLLOW_UP_BY_NAME,
  ],
  notDoneform: [
    DECISION_ID,
    DECISION_DATE,
    DECISION_LOCATION,
    SCOPY_DECISION_BY_ID,
    DECISION_BY_NAME,
    SCOPY_REFERRAL_LOCATION,
    SCOPY_NOT_PERFORMED_REASON,
    PREVIOUS_YEAR,
    OTHER_REASONS,
    SAMPLE_ID,
    SCREENING_ID,
  ],
  doneform: [SCOPY_NOT_PERFORMED_REASON, PREVIOUS_YEAR, OTHER_REASONS],
  formInerrupted: [
    SAMPLE_ID,
    SCREENING_ID,
    DECISION_ID,
    SCOPY_REFERRAL_LOCATION,
    DECISION_DATE,
    DECISION_LOCATION,
    DECISION_BY_NAME,
    SCOPY_DECISION_BY_ID,
    IS_INERRUPTED,
    SCOPY_DATE,
    SCOPY_LOCATION_ID,
    SCOPY_DONE_BY_NAME,
    SCOPY_DONE_BY_ID,
  ],
  scopyDecisionMin: [
    HAD_INTERVAENOUS_PREMEDICATION,
    SCOPY_RETRIEVAL_MIN,
    BOWEL_EMPTY_SUCCESS_ID,
    SCOPY_REACHED_ID,
    NUMBER_POLYP_ID,
    ADITIONAL_INFO,
    LARGEST_POLY_DIAMETER_ID,
    COMPLICATION_IDS,
    SCOPY_LIMITATION_IDS,
    SCOPY_PROCEDURE_IDS,
    SCOPY_FINDING_IDS,
    SCOPY_DIAGNOSES,
    HAD_INNER_TURN_DONE,
    SCOPY_NOT_PERFORMED_REASON,
  ],
};

export const validListGraphy = {
  [RADIOLOGIST_STATMENT_DATE]: [
    RADIOLOGIST_STATMENT,
    TTGRAPHY_FINDING_IDS,
    LARGEST_POLY_DIAMETER_ID,
    MUTAIION_LOCATION_ID,
    NUMBER_POLYP_ID,
    ADITIONAL_INFO,
    CLASSIFOCATION_ID,
    OTHER_CLASSIFOCATION_ID,
    RADIOLOGIST_STATMENT_LOCATION,
    RADIOLOGIST_ID,
    RADIOLOGIST_NAME,
  ],
  [RADIOLOGIST_STATMENT_LOCATION]: [
    RADIOLOGIST_STATMENT_DATE,
    RADIOLOGIST_STATMENT,
    TTGRAPHY_FINDING_IDS,
    LARGEST_POLY_DIAMETER_ID,
    MUTAIION_LOCATION_ID,
    NUMBER_POLYP_ID,
    ADITIONAL_INFO,
    CLASSIFOCATION_ID,
    OTHER_CLASSIFOCATION_ID,
    RADIOLOGIST_ID,
    RADIOLOGIST_NAME,
  ],
  [FOLLOW_UP_DATE]: [
    FOLLOW_UP_LOCATION,
    FOLLOW_UP_DECISION_ID,
    FOLLOW_UP_REFERRAL_LOCATION,
    FOLLOW_UP_BY_ID,
    FOLLOW_UP_BY_NAME,
  ],
  [FOLLOW_UP_LOCATION]: [
    FOLLOW_UP_DATE,
    FOLLOW_UP_DECISION_ID,
    FOLLOW_UP_REFERRAL_LOCATION,
    FOLLOW_UP_BY_ID,
    FOLLOW_UP_BY_NAME,
  ],
  [FOLLOW_UP_DECISION_ID]: [
    FOLLOW_UP_REFERRAL_LOCATION,
    FOLLOW_UP_BY_ID,
    FOLLOW_UP_BY_NAME,
    FOLLOW_UP_DATE,
    FOLLOW_UP_LOCATION,
  ],

  followUpDisabled: [
    TTGRAPHY_FINDING_IDS,
    MUTAIION_LOCATION_ID,
    NUMBER_POLYP_ID,
    ADITIONAL_INFO,
    CLASSIFOCATION_ID,
    OTHER_CLASSIFOCATION_ID,
    RADIOLOGIST_STATMENT,
    RADIOLOGIST_STATMENT_DATE,
    RADIOLOGIST_STATMENT_LOCATION,
    RADIOLOGIST_ID,
    RADIOLOGIST_NAME,
  ],
  followUp: [
    FOLLOW_UP_DATE,
    FOLLOW_UP_DECISION_ID,
    FOLLOW_UP_LOCATION,
    FOLLOW_UP_REFERRAL_LOCATION,
    FOLLOW_UP_BY_ID,
    FOLLOW_UP_BY_NAME,
  ],
  formInerrupted: [
    TTGRAPHY_DATE,
    TTGRAPHY_LOCATION,
    IS_INERRUPTED,
    TTGRAPHY_BY_ID,
    TTGRAPHY_BY_NAME,
    SAMPLE_ID,
    SCREENING_ID,
  ],
  graphyStatmentMin: [
    RADIOLOGIST_STATMENT,
    TTGRAPHY_FINDING_IDS,
    LARGEST_POLY_DIAMETER_ID,
    MUTAIION_LOCATION_ID,
    NUMBER_POLYP_ID,
    ADITIONAL_INFO,
    CLASSIFOCATION_ID,
    OTHER_CLASSIFOCATION_ID,
  ],
};

export const validListSurgical = {
  [SURGICAL_PROCEDURE_DATE]: [
    SURGICAL_PROCEDURE_PRIMARY_ID,
    SURGICAL_PROCEDURE_CODE_ID,
    ADITIONAL_INFO,
    SURGICAL_PROCEDURE_DIAGNOSES,
    CANCER_PTNM_GRADUS_ID,
    CANCER_PTNM_M_ID,
    CANCER_PTNM_N_ID,
    CANCER_PTNM_T_ID,
    SURGICAL_PROCEDURE_BY_NAME,
    SURGICAL_PROCEDURE_BY_ID,
    SURGICAL_PROCEDURE_LOCATION,
  ],
  surgicalDecisionMin: [
    SURGICAL_PROCEDURE_PRIMARY_ID,
    ADITIONAL_INFO,
    SURGICAL_PROCEDURE_DIAGNOSES_DATE,
    SURGICAL_PROCEDURE_DIAGNOSES_LOCATION,
  ],
  [SURGICAL_PROCEDURE_LOCATION]: [
    SURGICAL_PROCEDURE_PRIMARY_ID,
    SURGICAL_PROCEDURE_CODE_ID,
    ADITIONAL_INFO,
    SURGICAL_PROCEDURE_DIAGNOSES,
    CANCER_PTNM_GRADUS_ID,
    CANCER_PTNM_M_ID,
    CANCER_PTNM_N_ID,
    CANCER_PTNM_T_ID,
    SURGICAL_PROCEDURE_BY_NAME,
    SURGICAL_PROCEDURE_BY_ID,
    SURGICAL_PROCEDURE_DATE,
  ],
  [SURGICAL_PROCEDURE_DIAGNOSES_DATE]: [
    SURGICAL_PROCEDURE_DIAGNOSES,
    SURGICAL_PROCEDURE_DIAGNOSES_LOCATION,
    DIAGNOSES_BY_NAME,
    DIAGNOSES_BY_ID,
    CANCER_PTNM_GRADUS_ID,
    CANCER_PTNM_M_ID,
    CANCER_PTNM_N_ID,
    CANCER_PTNM_T_ID,
    MUTATION_HISTOLOGICAL_DIAGNOSIS,
  ],
  [SURGICAL_PROCEDURE_DIAGNOSES_LOCATION]: [
    SURGICAL_PROCEDURE_DIAGNOSES,
    SURGICAL_PROCEDURE_DIAGNOSES_DATE,
    DIAGNOSES_BY_NAME,
    DIAGNOSES_BY_ID,
    CANCER_PTNM_GRADUS_ID,
    CANCER_PTNM_M_ID,
    CANCER_PTNM_N_ID,
    CANCER_PTNM_T_ID,
    MUTATION_HISTOLOGICAL_DIAGNOSIS,
  ],
};

export const validationNumberValues = {
  // if value has no min or mix and you want to add, please add that in Yup validation
  // if you are changing the value of min or mix no need for further action

  // Person
  [FIRST_NAMES]: { max: 64 },
  [SURNAME]: { max: 64 },
  [PHONE]: { max: 64 },
  [COMMENT]: { max: 1024 },
  // Preliminary
  [HEIGHT]: { max: 999 },
  [WEIGHT]: { max: 999 },
  [SMOKED_DAILY_YEARS_ID]: { max: 99 },
  [SMOKED_DAILY_AMOUNT_ID]: { max: 99 },
  [MEDICINE_SENSITIVITY]: { max: 1024 },
  // Scopy
  [DECISION_BY_NAME]: { max: 128 },
  [SCOPY_DECISION_BY_ID]: { max: 11 },
  [SCOPY_DONE_BY_ID]: { max: 11 },
  [SCOPY_DONE_BY_NAME]: { max: 128 },
  [OTHER_REASONS]: { max: 1024 },
  [SCOPY_OTHER_LIMITS]: { max: 1024 },
  [SCOPY_OTHER_FINDINGS]: { max: 1024 },
  // TTGraphy
  [TTGRAPHY_OTHER_FINDINGS]: { max: 1024 },
  [TTGRAPHY_BY_NAME]: { max: 128 },
  [TTGRAPHY_BY_ID]: { max: 11 },
  [RADIOLOGIST_ID]: { max: 11 },
  [RADIOLOGIST_NAME]: { max: 128 },
  [RADIOLOGIST_STATMENT]: { max: 1024 },
  // Surgical
  [SURGICAL_PROCEDURE_BY_NAME]: { max: 128 },
  [SURGICAL_PROCEDURE_BY_ID]: { max: 11 },
  // Diagnoses
  [DIAGNOSES_BY_ID]: { max: 11 },
  [DIAGNOSES_BY_NAME]: { max: 128 },
  [MUTATION_HISTOLOGICAL_DIAGNOSIS]: { max: 1024 },
  // FollowUp
  [FOLLOW_UP_BY_ID]: { max: 11 },
  [FOLLOW_UP_BY_NAME]: { max: 128 },
  // common
  [ADITIONAL_INFO]: { max: 1024 },
};
