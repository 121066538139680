import {
  ALLOWED_HANDLER_LIST,
  HANDLER_LIST,
  MUNICIPALITY_ID,
  SAMPLE_DATE,
  SCREENING_CODE,
  SCREENING_ID,
  SCREENING_YEAR,
  STATUS_LIST,
} from '../../constants/commonKeys';
import {
  IAnalysisDropdowns,
  IAnalysisResultFrom,
} from '../../models/IAnalysisResultFrom';
import {
  IColongrapgDropdowns,
  IColongraphyFrom,
} from '../../models/ITTGaphyFrom';
import { IScopyDropdowns, IScopyForm } from '../../models/IScopyForm';
import { ILock } from '../../models/ILock';
import { IOption } from '../../models/IOption';
import {
  IPreliminaryDropdowns,
  IPreliminaryForm,
} from '../../models/IPreliminaryForm';
import { IPersonForm } from '../../models/IUSerInfo';
import {
  ISurgicalDropdowns,
  ISurgicalProcedureForm,
} from '../../models/surgicalProcedureFrom';
import { Tabs } from '../tabs/state';
import { MUNICIPALITY_GROUP_IDS, ROLE_IDS } from '../../constants/adminTabel';
import { SAMPLE_KIT_STATUS_ID } from '../../constants/orderKitTable';

export enum Forms {
  PERSON = 'person',
  HANDLER = 'handlerId',
  PRELIMINARY = 'preliminaryInfo',
  ANALYSIS_RESULT = 'analysisAndResult',
  SCOPY = 'colonoscopy',
  TTGRAPHY = 'ttColonography',
  SURGICAL_PROCEDURE = 'surgicalProcedure',
  SCREENING_LOCK = 'screeningLock',
  PERSON_LOCK = 'personLock',
  STATUS = 'screeningProcessStatusId',
}

export interface Screening extends ScreeningState {
  screeningId: number;
  hasLocations: boolean;
  isLoading: boolean;
}

export interface ScreeningState
  extends IPreliminaryDropdowns,
    IAnalysisDropdowns,
    IScopyDropdowns,
    TabAvailability,
    TabModifications,
    IColongrapgDropdowns,
    ISurgicalDropdowns {
  [Forms.PERSON]: IPersonForm;
  [Forms.STATUS]: number;
  [Forms.HANDLER]: number;
  [SCREENING_CODE]: number;
  [Forms.PRELIMINARY]: IPreliminaryForm;
  [Forms.ANALYSIS_RESULT]: IAnalysisResultFrom;
  [Forms.SCOPY]: IScopyForm;
  [Forms.SURGICAL_PROCEDURE]: ISurgicalProcedureForm;
  [Forms.SCREENING_LOCK]: ILock;
  [Forms.PERSON_LOCK]: ILock;
  [Forms.TTGRAPHY]: IColongraphyFrom;
  [STATUS_LIST]: IOption[] | null;
  [HANDLER_LIST]: IOption[] | null;
  [ALLOWED_HANDLER_LIST]: IOption[];
  [SAMPLE_KIT_STATUS_ID]: IOption[] | null;
  [ROLE_IDS]: IOption[] | null;
  [MUNICIPALITY_GROUP_IDS]: IMunicipalityGroup[];
  personScreenings: IPersonScreening[];
  [SCREENING_YEAR]: string;
  [SAMPLE_DATE]: string;
}

export interface TabAvailability {
  [Tabs.Scopy]: boolean;
  [Tabs.TTGraphy]: boolean;
  [Tabs.SurgicalProcedure]: boolean;
  [Tabs.AnalysisResult]: boolean;
  [Tabs.Preliminary]: boolean;
  isLatestScreening: boolean;
}

export interface TabModifications {
  ttColonographyModifiedBy?: string;
  ttColonographyModifiedAt?: string;
  surgicalProcedureModifiedBy?: string;
  surgicalProcedureModifiedAt?: string;
  preliminaryInfoModifiedBy?: string;
  preliminaryInfoModifiedAt?: string;
  colonoscopyModifiedBy?: string;
  colonoscopyModifiedAt?: string;
  analysisAndResultModifiedBy?: string;
  analysisAndResultModifiedAt?: string;
}

export interface IPersonScreening {
  [SCREENING_CODE]: string;
  [SCREENING_ID]: number;
}

export interface IMunicipalityGroup {
  municipalities: IOption[];
  municipalityGroupId: string;
}
