import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../components/button/button';
import FocusScrollError from '../../components/focusScrollFormError/focusScrollError';
import Header from '../../components/header/header';
import SectionHeader from '../../components/sectionHeader/sectionHeader';
import {
  ADITIONAL_INFO,
  IS_INERRUPTED,
  LARGEST_POLY_DIAMETER_ID,
  NUMBER_POLYP_ID,
  SCREENING_ID,
} from '../../constants/commonKeys';
import {
  FOLLOW_UP_BY_ID,
  FOLLOW_UP_BY_NAME,
  FOLLOW_UP_DATE,
  FOLLOW_UP_DECISION_ID,
  FOLLOW_UP_LOCATION,
  FOLLOW_UP_REFERRAL_LOCATION,
} from '../../constants/followUp';
import { LOCKED_BY_CURRENT_USER } from '../../constants/lockKeys';
import {
  CLASSIFOCATION_ID,
  FOLLOW_UP_DECISION_ID_TTGRAPHY,
  MUTAIION_LOCATION_ID,
  OTHER_CLASSIFOCATION_ID,
  RADIOLOGIST_ID,
  RADIOLOGIST_NAME,
  RADIOLOGIST_STATMENT,
  RADIOLOGIST_STATMENT_DATE,
  RADIOLOGIST_STATMENT_LOCATION,
  TTGRAPHY_BY_ID,
  TTGRAPHY_BY_NAME,
  TTGRAPHY_DATE,
  TTGRAPHY_FINDING_IDS,
  TTGRAPHY_LOCATION,
  TTGRAPHY_OTHER_FINDINGS,
} from '../../constants/ttgraphyFromKeys';
import { excludeValues } from '../../constantsLists/lists';
import { validListGraphy } from '../../constantsLists/validationLists';
import { screeningUnlock } from '../../services/queries/screeningLock';
import { RootState } from '../../state/rootReducer';
import { fetchForm, setSubmitForm } from '../../state/screening/actions';
import { Forms } from '../../state/screening/state';
import { Tabs } from '../../state/tabs/state';
import { isEmptyObject } from '../../utils/isEmptyObject';
import AsyncDropdown from '../asyncDropdown/asyncDropdown';
import DatePickerLabel from '../formInputs/datePickerLabel';
import DropdownFormik from '../formInputs/dropdownFormik';
import TextInputLabel from '../formInputs/textInputLabel';
import EditingBar from '../editingBar/editingBar';
import styles from '../personTabContainer/tabs.module.scss';
import { colonographySubmit, validateColongraphy } from './ttgraphyHelper';
import TabLastEdit from '../../components/tabLastEdit/tabLastEdit';

const TTGraphy: React.FC = () => {
  const { t } = useTranslation();
  const screening = useSelector((state: RootState) => state.screening);
  const isEditing = screening.screeningLock[LOCKED_BY_CURRENT_USER];
  const validate = validateColongraphy(t);

  const dispatch = useDispatch();

  useEffect(() => {
    if (screening.screeningId) {
      dispatch(fetchForm(screening.screeningId, Tabs.TTGraphy, t));
    }
  }, [screening.screeningId]);

  return (
    <div className="container--fluid">
      <Header title={`tabs.${Tabs.TTGraphy}`} titleClassName={styles.title}>
        <EditingBar>
          <TabLastEdit />
        </EditingBar>
      </Header>
      <p>{t('colongraphForm.pageInfo')}</p>
      <Formik
        initialValues={{
          ...screening[Forms.TTGRAPHY],
          [SCREENING_ID]: screening.screeningId,
        }}
        enableReinitialize={true}
        validationSchema={validate}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        onSubmit={(vals, { resetForm }) =>
          colonographySubmit(vals, t, dispatch, () =>
            resetForm({ values: screening[Forms.TTGRAPHY] })
          )
        }
      >
        {({ setFieldValue, values, resetForm }) => {
          const interruption = !isEditing || !!values[IS_INERRUPTED];
          const followUpDisabled = isEmptyObject(
            values,
            validListGraphy.followUpDisabled,
            false
          );
          return (
            <Form>
              <FocusScrollError />
              {/*/////////////////////////////////////////////// ROW 1 ///////////////////////////////////////////////*/}
              <div className="container__row ">
                <div className={`grid-4-1st ${styles.checkBoxContainer}`}>
                  <input
                    type="checkbox"
                    name={IS_INERRUPTED}
                    disabled={!isEditing}
                    onChange={(val) => {
                      if (
                        val.target.checked &&
                        window.confirm(t('forms.interruptedWarning'))
                      ) {
                        return setFieldValue(IS_INERRUPTED, val.target.checked);
                      }
                      if (val.target.checked) {
                        return;
                      }
                      return setFieldValue(IS_INERRUPTED, val.target.checked);
                    }}
                    checked={!!values[IS_INERRUPTED]}
                  />
                  <label htmlFor={IS_INERRUPTED}>
                    {t(`colongraphForm.${IS_INERRUPTED}`)}
                  </label>
                </div>
              </div>
              {/*/////////////////////////////////////////////// ROW 2 ///////////////////////////////////////////////*/}
              <div className="container__row ">
                <div className="grid-4-1st">
                  <DatePickerLabel
                    src={`colongraphForm.${TTGRAPHY_DATE}`}
                    disabled={!isEditing}
                    required
                  />
                </div>
                <div className="grid-4-2nd">
                  <AsyncDropdown
                    src={`colongraphForm.${TTGRAPHY_LOCATION}`}
                    defaultOptions={[]}
                    store={1}
                    disabled={!isEditing}
                    required
                  />
                </div>
                {values[IS_INERRUPTED] ? (
                  <>
                    <div className="grid-4-3rd">
                      <TextInputLabel
                        src={`colongraphForm.${TTGRAPHY_BY_NAME}`}
                        disabled={!isEditing}
                        required={!!values[IS_INERRUPTED]}
                      />
                    </div>
                    <div className="grid-4-4th">
                      <TextInputLabel
                        src={`colongraphForm.${TTGRAPHY_BY_ID}`}
                        disabled={!isEditing}
                      />
                    </div>
                  </>
                ) : null}
              </div>
              <SectionHeader
                src="colongraphForm"
                name="summary"
                desciption="colongraphForm.summaryDesc"
              />
              <div className="container__row ">
                {/*/////////////////////////////////////////////// ROW 3 ///////////////////////////////////////////////*/}
                <div className="grid-4-1st">
                  <DatePickerLabel
                    src={`colongraphForm.${RADIOLOGIST_STATMENT_DATE}`}
                    disabled={interruption}
                    required={
                      !interruption &&
                      !isEmptyObject(
                        values,
                        validListGraphy[RADIOLOGIST_STATMENT_DATE],
                        false
                      )
                    }
                  />
                </div>
                <div className="grid-4-2nd">
                  <AsyncDropdown
                    defaultOptions={[]}
                    src={`colongraphForm.${RADIOLOGIST_STATMENT_LOCATION}`}
                    store={1}
                    disabled={interruption}
                    required={
                      !interruption &&
                      !isEmptyObject(
                        values,
                        validListGraphy[RADIOLOGIST_STATMENT_LOCATION],
                        false
                      )
                    }
                  />
                </div>
                <div className="grid-4-3rd">
                  <TextInputLabel
                    src={`colongraphForm.${RADIOLOGIST_NAME}`}
                    disabled={interruption}
                  />
                </div>
                <div className="grid-4-4th">
                  <TextInputLabel
                    src={`colongraphForm.${RADIOLOGIST_ID}`}
                    disabled={interruption}
                  />
                </div>
              </div>
              <div className="container__row ">
                <div className="grid-4-1st">
                  <DropdownFormik
                    src={`colongraphForm.${TTGRAPHY_FINDING_IDS}`}
                    dropDown={screening[TTGRAPHY_FINDING_IDS]}
                    disabled={interruption}
                    isMulti
                  />
                </div>
                <div className="grid-3-2nd">
                  <TextInputLabel
                    src={`colongraphForm.${TTGRAPHY_OTHER_FINDINGS}`}
                    disabled={
                      interruption ||
                      !values[TTGRAPHY_FINDING_IDS] ||
                      values[TTGRAPHY_FINDING_IDS]?.indexOf(7) === -1
                    }
                  />
                </div>
              </div>
              <div className="container__row ">
                {/*/////////////////////////////////////////////// ROW 4 ///////////////////////////////////////////////*/}
                <div className="grid-4-1st">
                  <DropdownFormik
                    src={`colongraphForm.${MUTAIION_LOCATION_ID}`}
                    dropDown={screening[MUTAIION_LOCATION_ID]}
                    disabled={interruption}
                  />
                </div>
                <div className="grid-4-2nd">
                  <DropdownFormik
                    src={`colongraphForm.${NUMBER_POLYP_ID}`}
                    dropDown={screening[NUMBER_POLYP_ID]}
                    disabled={
                      interruption ||
                      !values[TTGRAPHY_FINDING_IDS] ||
                      values[TTGRAPHY_FINDING_IDS]?.indexOf(3) === -1
                    }
                    required={
                      values[TTGRAPHY_FINDING_IDS] &&
                      values[TTGRAPHY_FINDING_IDS]?.indexOf(3) !== -1
                    }
                  />
                </div>
                <div className="grid-2-2nd">
                  <DropdownFormik
                    dropDown={screening[LARGEST_POLY_DIAMETER_ID]}
                    src={`colongraphForm.${LARGEST_POLY_DIAMETER_ID}`}
                    optionSrc={LARGEST_POLY_DIAMETER_ID}
                    disabled={interruption}
                  />
                </div>
              </div>
              {/*/////////////////////////////////////////////// ROW 5 ///////////////////////////////////////////////*/}
              <div className="container__row ">
                <div className="container__col-xl-9 container__col-12">
                  <TextInputLabel
                    src={`colongraphForm.${ADITIONAL_INFO}`}
                    disabled={interruption}
                  />
                </div>
              </div>
              {/*/////////////////////////////////////////////// ROW 6 ///////////////////////////////////////////////*/}
              <div className="container__row ">
                <div className="container__col-xl-9 container__col-12">
                  <DropdownFormik
                    src={`colongraphForm.${CLASSIFOCATION_ID}`}
                    dropDown={screening[CLASSIFOCATION_ID]}
                    disabled={interruption}
                  />
                </div>
              </div>
              {/*/////////////////////////////////////////////// ROW 7 ///////////////////////////////////////////////*/}
              <div className="container__row ">
                <div className="container__col-xl-9 container__col-12">
                  <DropdownFormik
                    src={`colongraphForm.${OTHER_CLASSIFOCATION_ID}`}
                    dropDown={screening[OTHER_CLASSIFOCATION_ID]}
                    disabled={interruption}
                  />
                </div>
              </div>
              {/*/////////////////////////////////////////////// ROW 8 ///////////////////////////////////////////////*/}
              <div className="container__row ">
                <div className="container__col-xl-9 container__col-12">
                  <TextInputLabel
                    src={`colongraphForm.${RADIOLOGIST_STATMENT}`}
                    disabled={interruption}
                  />
                </div>
              </div>
              {/*/////////////////////////////////////////////// ROW 9 ///////////////////////////////////////////////*/}

              <SectionHeader src="colongraphForm" name="followUp" />
              <div className="container__row">
                {/*/////////////////////////////////////////////// ROW 10 ///////////////////////////////////////////////*/}

                <div className="grid-4-1st">
                  <DatePickerLabel
                    src={`colongraphForm.${FOLLOW_UP_DATE}`}
                    disabled={interruption || followUpDisabled}
                    required={
                      !interruption &&
                      !followUpDisabled &&
                      !isEmptyObject(
                        values,
                        validListGraphy[FOLLOW_UP_DATE],
                        false
                      )
                    }
                  />
                </div>
                <div className="grid-4-2nd">
                  <AsyncDropdown
                    defaultOptions={[]}
                    src={`colongraphForm.${FOLLOW_UP_LOCATION}`}
                    store={1}
                    disabled={interruption || followUpDisabled}
                    required={
                      !interruption &&
                      !followUpDisabled &&
                      !isEmptyObject(
                        values,
                        validListGraphy[FOLLOW_UP_LOCATION],
                        false
                      )
                    }
                  />
                </div>
                <div className="grid-4-3rd">
                  <TextInputLabel
                    src={`colongraphForm.${FOLLOW_UP_BY_NAME}`}
                    disabled={interruption || followUpDisabled}
                  />
                </div>
                <div className="grid-4-4th">
                  <TextInputLabel
                    src={`colongraphForm.${FOLLOW_UP_BY_ID}`}
                    disabled={interruption || followUpDisabled}
                  />
                </div>
              </div>
              <div className="container__row">
                {/*/////////////////////////////////////////////// ROW 11 ///////////////////////////////////////////////*/}

                <div className="grid-2-1st">
                  <DropdownFormik
                    dropDown={screening[FOLLOW_UP_DECISION_ID]?.filter(
                      (el) => el.langValue !== '5'
                    )}
                    src={`colongraphForm.${FOLLOW_UP_DECISION_ID}`}
                    clearOption={
                      !screening[Forms.TTGRAPHY][FOLLOW_UP_DECISION_ID]
                    }
                    disabled={interruption || followUpDisabled}
                    optionSrc={FOLLOW_UP_DECISION_ID_TTGRAPHY}
                    onChange={(val) => {
                      if (Array.isArray(val)) return false;
                      if (
                        val.value === '2' &&
                        window.confirm(t('forms.renewalwarning'))
                      ) {
                        setFieldValue(
                          FOLLOW_UP_REFERRAL_LOCATION,
                          values[TTGRAPHY_LOCATION]
                        );
                        return 2;
                      }
                      if (val.value === '2') {
                        return values[FOLLOW_UP_DECISION_ID];
                      } else {
                        return JSON.parse(val.value);
                      }
                    }}
                    required={
                      !interruption &&
                      !followUpDisabled &&
                      !isEmptyObject(
                        values,
                        validListGraphy[FOLLOW_UP_DECISION_ID],
                        false
                      )
                    }
                  />
                </div>

                <div className="grid-4-2nd">
                  <AsyncDropdown
                    defaultOptions={[]}
                    src={`colongraphForm.${FOLLOW_UP_REFERRAL_LOCATION}`}
                    store={1}
                    required={
                      isEditing &&
                      !!values[FOLLOW_UP_DECISION_ID] &&
                      values[FOLLOW_UP_DECISION_ID] !== 5 &&
                      values[FOLLOW_UP_DECISION_ID] !== 1
                    }
                    disabled={
                      interruption ||
                      followUpDisabled ||
                      values[FOLLOW_UP_DECISION_ID] === 1 ||
                      values[FOLLOW_UP_DECISION_ID] === 5
                    }
                  />
                </div>
              </div>

              <div className="container__row space-between ">
                <Button
                  type="submit"
                  noSpinner={!isEditing || isEmptyObject(values, excludeValues)}
                  disabled={
                    !isEditing ||
                    isEmptyObject(values, excludeValues) ||
                    screening.isLoading
                  }
                  className={styles.formButton}
                >
                  {t('control.save')}
                </Button>
                <Button
                  onClick={() => {
                    resetForm();
                    dispatch(
                      setSubmitForm(
                        Forms.SCREENING_LOCK,
                        screeningUnlock(screening.screeningId),
                        t
                      )
                    );
                  }}
                  disabled={!isEditing || screening.isLoading}
                  noSpinner={!isEditing}
                  className={styles.formButton}
                >
                  {t('control.quit')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default TTGraphy;
