import {
  CONTROL_CODE,
  DATE,
  FILENAME,
  IS_UNRESOLVABLE,
  SKIP,
  FORM_VERSION,
} from '../../constants/pdfImportKeys';
import { IPdfImportSearchForm, IPdfSubmitForm } from '../../models/IPdfImport';
import { handleKey, QueryType } from '../../utils/queryCleanup';

const addParams = (form: IPdfImportSearchForm) =>
  Object.keys(form).length > 0
    ? `(
      ${form[DATE] ? handleKey(form, DATE, QueryType.string, false) : ''}
      ${form[SKIP] ? handleKey(form, SKIP, QueryType.number, false) : ''}  
    )`
    : '';

export const fetchFailedImportsQuery = (formData: IPdfImportSearchForm) => {
  return `query failedPdfImports {
        pdfImports${addParams(formData)}{
          failed {
            results {
              createdAt
              filename
              isUserUnableToResolve
            }
          }
        }
      }
    `;
};

export const pdfImportMutation = (form: IPdfSubmitForm) => {
  return `mutation UpdatePreliminaryInfoPdf {
        updatePreliminaryInfoPdf(
            ${FILENAME}:"${form.filename}", 
            ${
              form.controlCode !== undefined
                ? `${handleKey(form, CONTROL_CODE, QueryType.string)}`
                : ''
            }
            ${
              form.isUnresolvable !== undefined
                ? `${handleKey(form, IS_UNRESOLVABLE, QueryType.boolean)}`
                : ''
            }
            ${
              form.formVersion !== undefined
              ? `${handleKey(form, FORM_VERSION, QueryType.string)}`
              : ''
            }
        )
    }`;
};
