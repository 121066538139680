import {
  ASSAY_ABOVE,
  INTERPRETABILITY_BLOCKERS,
  RESULT_PLACE,
  SAMPLE_INTERPRETABILITY,
  TEST_RESULT_POSITIVE,
  TEST_VALUE,
} from '../../constants/analysisResultFromKeys';

import {
  CONFIDENCE,
  DOES_SMOKE,
  DRINKING_AMOUNT_ID,
  DRINKING_FREQUENCY_ID,
  DRINKING_SIX_FREQUENCY_ID,
  HAS_MEDICINE_SENSITIVITY,
  HAS_RELATIVE_INTESTIONAL_CANCER,
  HAS_SMOKED_DAILY,
  HEIGHT,
  LONG_TERM_ILNESS_IDS,
  MEDICINE_SENSITIVITY,
  REGULAR_MEDICINE_IDS,
  RELATIVE_INTESTIONAL_CANCER_WHO,
  SMOKED_DAILY_AMOUNT_ID,
  SMOKED_DAILY_YEARS_ID,
  WEIGHT,
} from '../../constants/preliminaryFormKeys';
import { MOTHER_LANG, PHONE } from '../../constants/userInfoKeys';
import { INITIAL_RESULT } from '../../constants/tableColumnsKeys';
import {
  ANALYSIS_DATE,
  FIRST_NAMES,
  COMMENT,
  MUNICIPALITY_ID,
  SAMPLE_DATE,
  SCREENING_CODE,
  SCREENING_ID,
  SCREENING_YEAR,
  SSN,
  STATUS,
  SURNAME,
} from '../../constants/commonKeys';

const translationSv = {
  header: {
    title: 'Suolistosyöpien seulonta ohjelmisto sv',
    lastEdit: 'Viimeksi muokannut sv',
    screenedPerson: 'Seulottu henkilö sv',
    screenSearch: 'Seulottujen haku sv',
  },
  links: {
    logout: 'Kirjaudu ulos sv',
  },
  tabs: {
    preliminary: 'Esitiedot sv',
    colonosCopy: 'Kolonoskopia sv',
    analysisResult: 'Testin analyysi ja tulos sv',
    surgicalProcedure: 'Kirurginen toimenpide sv',
    colonGraph: 'TT-kolongrafia sv',
  },
  personFrom: {
    [SSN]: 'Henkilötunnus sv',
    [FIRST_NAMES]: 'Etunimi sv',
    [SURNAME]: 'Sukunimi sv',
    [MOTHER_LANG]: 'Asiointikieli sv',
    [PHONE]: 'Puhelinnumero sv',
    [SCREENING_CODE]: 'Seulontatunnus sv',
    [STATUS]: 'Käsittelyn tila sv',
    [COMMENT]: 'Kommentti sv',
  },
  analysisResultForm: {
    [SAMPLE_INTERPRETABILITY]: 'Näytteen tulkittavuus sv',
    [INTERPRETABILITY_BLOCKERS]: 'Näytteen tulkittavuutta haittaavat seiket sv',
    [TEST_VALUE]: 'Testin arvo  sv',
    [TEST_RESULT_POSITIVE]: 'Testin tulos sv',
    [ASSAY_ABOVE]: 'Määritysarian ylapuolella sv',
    [ANALYSIS_DATE]: 'Päivämäärä sv',
    [RESULT_PLACE]: 'Paikka sv',
  },

  preliminaryForm: {
    [SCREENING_ID]: 'Seulontatunnus',
    [HEIGHT]: 'Pituus (cm) sv',
    [WEIGHT]: 'Paino (kg) sv',
    [LONG_TERM_ILNESS_IDS]: 'Pitkäaikaissairaudet sv',
    [HAS_RELATIVE_INTESTIONAL_CANCER]:
      'Sukulaisilla todettu paksusuolensyöpä tai peräsuolisyöpä sv',
    [RELATIVE_INTESTIONAL_CANCER_WHO]: 'Kuka sukulainen sv',
    [REGULAR_MEDICINE_IDS]:
      'Säännölliset lääkkeet vähintään kerran viikossa sv',
    [HAS_MEDICINE_SENSITIVITY]: 'Lääkeaineyliherkkyys sv',
    [MEDICINE_SENSITIVITY]: 'Mikä lääkeaine sv',
    [DOES_SMOKE]:
      'Polttanut vähintään vuoden ajan päivittäin tupakkaa, sikareita tai piippua sv',
    [SMOKED_DAILY_YEARS_ID]: 'Yhteensä vuotta sv',
    [SMOKED_DAILY_AMOUNT_ID]: 'Savuketta/piipullista päivässä sv',
    [HAS_SMOKED_DAILY]: 'Päivittäinen tupakointi  sv',
    [DRINKING_AMOUNT_ID]: 'Alkoholinkäyttö sv',
    [DRINKING_FREQUENCY_ID]: 'Annosta alkoholia päivässä sv',
    [DRINKING_SIX_FREQUENCY_ID]:
      'Vähintään kuusi annosta alkoholia vuorokaudessa sv',
    [SCREENING_YEAR]: 'Kutsuvuosi sv',
    [MUNICIPALITY_ID]: 'Kutsukunta sv',
    [SAMPLE_DATE]: 'Näytteenottopäivä sv',
    [CONFIDENCE]: 'Luotettavuus sv',
  },
  control: {
    quit: 'Lopeta tallentamatta muutoksia sv',
    save: 'Tallenna sv',
    edit: 'Muokkaa sv',
    addScreenPerson: 'Lisää seulottava henkilö sv',
  },
  tableColumns: {
    [STATUS]: 'Käsittelyn tila sv',
    [FIRST_NAMES]: 'Sukunimi sv',
    [SURNAME]: 'Etunimi sv',
    [SSN]: 'Henkilötunnus sv',
    [SCREENING_CODE]: 'Seulontatunnus sv',
    [COMMENT]: 'Kommentti sv',
    [INITIAL_RESULT]: 'Näytetulos sv',
    [ANALYSIS_DATE]: 'Analyysipäivä sv',
    [MUNICIPALITY_ID]: 'Kunta sv',
  },
  [DRINKING_AMOUNT_ID]: {
    '1': '1-2 annosta sv',
    '2': '3-4 annosta sv',
    '3': '5-6 annosta sv',
    '4': '7-9 annosta sv',
    '5': '10 annosta tai enemmän sv',
  },
  [DRINKING_FREQUENCY_ID]: {
    '0': 'En käytä lainkaan alkoholijuomia sv',
    '1': 'Noin kerran kuukaudessa tai harvemmin sv',
    '2': '2-4 kertaa kuukaudessa sv',
    '3': '2-3 kertaa viikossa sv',
    '4': '4 kertaa viikossa tai useammin sv',
  },

  [DRINKING_SIX_FREQUENCY_ID]: {
    '0': 'En koskaan sv',
    '1': 'Harvemmin kuin kerran kuussa sv',
    '2': 'Noin kerran kuukaudessa sv',
    '3': 'Noin kerran viikossa sv',
    '4': 'Päivittäin tai lähes päivittäin sv',
  },

  [LONG_TERM_ILNESS_IDS]: {
    '1': 'Insuliinihoitoinen diabetes sv',
    '2': 'Sydämen läppävika sv',
    '3': 'Sydäninfarkti viimeisen 3 kk:n aikana sv',
    '4': 'Vaikea keuhko- tai sydänsairaus sv',
    '5': 'Vaikea liikuntakykyä rajoittava sairaus sv',
  },

  [REGULAR_MEDICINE_IDS]: {
    '1': 'Verenohennuslääke (Marevan, Pradaxa, Hepariini) sv',
    '2':
      'Asetyylisalisyylihappovalmisteet (esim. Asperin, Disperin, Primaspan) sv',
    '3': 'Muu tulehduskipulääke (esim. Burana) sv',
  },

  [RELATIVE_INTESTIONAL_CANCER_WHO]: {
    '1': 'Vanhemmillani sv',
    '2': 'Sisaruksillani sv',
    '3': 'Lapsillani sv',
    '4': 'Jollain muulla sukulaisella (esim. tädillä, serkulla) sv',
  },
  common: {
    not: 'Ei ole sv',
    yes: 'Kyllä sv',
    no: 'Ei sv',
    idont: 'En sv',
    dontKnow: 'En tiedä sv',
    havent: 'En ole sv',
  },
  forms: {
    requiredField: 'Pakollinen kenttä sv',
    mustBeNumber: 'Tämän pitäisi olla numero sv',
    mustBeNumberMax999: 'Tämän pitäisi olla numero max 999 sv',
    mustBeNumberMax99: 'Tämän pitäisi olla numero max 99 sv',
    mustBeNumberMax255: 'Tämän pitäisi olla alle 255 merkkiä sv',
    mustBeMin1: 'Tämän pitäisi olla vähintään yksi',
  },
  [STATUS]: {
    '0': 'Uusi sv',
    '1': 'Käynnissä sv',
    '2': 'Päättynyt sv',
    '3': 'Päätetty keskeyttää sv',
    '4': 'Keskeytynyt: kotikunta vaihtuu, muutto ulkomaille jne. sv',
    '5': 'Keskeytynyt: Siirtynyt yksityiselle sv',
    '6':
      'Keskeytynyt: seulottu on kieltänyt jatkotutkimustietojen luovuttamisen seulojalle sv',
    '7': 'Päättynyt, kuollut sv',
  },
  [MUNICIPALITY_ID]: {
    '000': 'Oigenkännlig',
    '004': '(Utgånget) Alahärmä',
    '005': 'Alajärvi',
    '006': '(Utgånget) Alastaro',
    '009': 'Alavieska',
    '010': 'Alavus',
    '014': '(Utgånget) Anttola',
    '015': '(Utgånget) Artsjö',
    '016': 'Asikkala',
    '017': '(Utgånget) Villnäs',
    '018': 'Askola',
    '019': 'Aura',
    '020': 'Akaa',
    '035': 'Brändö',
    '040': '(Utgånget) Dragsfjärd',
    '043': 'Eckerö',
    '044': '(Utgånget) Elimäki',
    '045': '(Utgånget) Eno',
    '046': 'Enonkoski',
    '047': 'Enontekis',
    '049': 'Esbo',
    '050': 'Eura',
    '051': 'Euraåminne',
    '052': 'Evijärvi',
    '060': 'Finström',
    '061': 'Forssa',
    '062': 'Föglö',
    '065': 'Geta',
    '069': 'Haapajärvi',
    '071': 'Haapavesi',
    '072': 'Karlö',
    '073': '(Utgånget) Halikko',
    '074': 'Halsua',
    '075': 'Fredrikshamn',
    '076': 'Hammarland',
    '077': 'Hankasalmi',
    '078': 'Hangö',
    '079': 'Harjavalta',
    '081': 'Hartola',
    '082': 'Hattula',
    '083': '(Utgånget) Hauho',
    '084': '(Utgånget) Haukipudas',
    '085': '(Utgånget) Haukivuori',
    '086': 'Hausjärvi',
    '088': '(Utgånget) Heinola',
    '089': '(Utgånget) Heinola lk',
    '090': 'Heinävesi',
    '091': 'Helsingfors',
    '092': 'Vanda',
    '095': '(Utgånget) Himanka',
    '097': 'Hirvensalmi',
    '098': 'Hollola',
    '099': '(Utgånget) Honkajoki',
    '101': '(Utgånget) Houtskär',
    '102': 'Huittinen',
    '103': 'Humppila',
    '105': 'Hyrynsalmi',
    '106': 'Hyvinge',
    '108': 'Tavastkyro',
    '109': 'Tavastehus',
    '111': 'Heinola',
    '139': 'Ii',
    '140': 'Idensalmi',
    '142': 'Iitti',
    '143': 'Ikalis',
    '145': 'Ilmajoki',
    '146': 'Ilomants',
    '148': 'Enare',
    '149': 'Ingå',
    '150': '(Utgånget) Iniö',
    '151': 'Storå',
    '152': 'Storkyro',
    '153': 'Imatra',
    '163': '(Utgånget) Jaala',
    '164': '(Utgånget) Jalasjärvi',
    '165': 'Janakkala',
    '167': 'Joensuu',
    '169': 'Jockis',
    '170': 'Jomala',
    '171': 'Jorois',
    '172': 'Joutsa',
    '173': '(Utgånget) Joutseno',
    '174': '(Utgånget) Juankoski',
    '175': '(Utgånget) Jurva',
    '176': 'Juuka',
    '177': 'Juupajoki',
    '178': 'Juva',
    '179': 'Jyväskylä',
    '180': '(Utgånget) Jyväskylä lk',
    '181': 'Jämijärvi',
    '182': 'Jämsä',
    '183': '(Utgånget) Jämsänkoski',
    '184': '(Utgånget) Jäppilä',
    '186': 'Träskända',
    '198': 'Ingen hemkommun i Finland',
    '199': 'Okända',
    '200': 'Utlandet',
    '202': 'S:t Karins',
    '204': 'Kaavi',
    '205': 'Kajana',
    '208': 'Kalajoki',
    '209': '(Utgånget) Kalanti',
    '210': '(Utgånget) Kalvola',
    '211': 'Kangasala',
    '212': '(Utgånget) Kangaslampi',
    '213': 'Kangasniemi',
    '214': 'Kankaanpää',
    '216': 'Kannonkoski',
    '217': 'Kannus',
    '218': 'Bötom',
    '219': '(Utgånget) Karinainen',
    '220': '(Utgånget) Karis',
    '223': '(Utgånget) Karislojo',
    '224': 'Högfors',
    '226': 'Karstula',
    '227': '(Utgånget) Karttula',
    '230': 'Karvia',
    '231': 'Kaskö',
    '232': 'Kauhajoki',
    '233': 'Kauhava',
    '235': 'Grankulla',
    '236': 'Kaustby',
    '239': 'Keitele',
    '240': 'Kemi',
    '241': 'Keminmaa',
    '243': '(Utgånget) Kimito',
    '244': 'Kempele',
    '245': 'Kervo',
    '246': '(Utgånget) Kerimäki',
    '247': '(Utgånget) Kestilä',
    '248': '(Utgånget) Kesälahti',
    '249': 'Keuruu',
    '250': 'Kihniö',
    '251': '(Utgånget) Kiihtelysvaara',
    '252': '(Utgånget) Kiikala',
    '254': '(Utgånget) Kiikoinen',
    '255': '(Utgånget) Kiiminki',
    '256': 'Kinnula',
    '257': 'Kyrkslätt',
    '259': '(Utgånget) Kisko',
    '260': 'Kitee',
    '261': 'Kittilä',
    '262': '(Utgånget) Kiukainen',
    '263': 'Kiuruvesi',
    '265': 'Kivijärvi',
    '266': '(Utgånget) Kodisjoki',
    '271': 'Kumo',
    '272': 'Karleby',
    '273': 'Kolari',
    '274': '(Utgånget) Konginkangas',
    '275': 'Konnevesi',
    '276': 'Kontiolahti',
    '277': '(Utgånget) Korpilahti',
    '279': '(Utgånget) Korpo',
    '280': 'Korsnäs',
    '281': '(Utgånget) Kortesjärvi',
    '283': '(Utgånget) Hämeenkoski',
    '284': 'Koski Tl',
    '285': 'Kotka',
    '286': 'Kouvola',
    '287': 'Kristinestad',
    '288': 'Kronoby',
    '289': '(Utgånget) Kuhmalahti',
    '290': 'Kuhmo',
    '291': 'Kuhmoinen',
    '292': '(Utgånget) Kuivaniemi',
    '293': '(Utgånget) Kullaa',
    '295': 'Kumlinge',
    '297': 'Kuopio',
    '299': '(Utgånget) Kuorevesi',
    '300': 'Kuortane',
    '301': 'Kurikka',
    '303': '(Utgånget) Kuru',
    '304': 'Gustavs',
    '305': 'Kuusamo',
    '306': '(Utgånget) Kuusankoski',
    '308': '(Utgånget) Kuusjoki',
    '309': 'Outokumpu',
    '310': '(Utgånget) Kylmäkoski',
    '312': 'Kyyjärvi',
    '315': '(Utgånget) Kelviå',
    '316': 'Kärkölä',
    '317': 'Kärsämäki',
    '318': 'Kökar',
    '319': '(Utgånget) Kjulo',
    '320': 'Kemijärvi',
    '322': 'Kimitoön',
    '398': 'Lahtis',
    '399': 'Laihela',
    '400': 'Laitila',
    '401': '(Utgånget) Lammi',
    '402': 'Lapinlahti',
    '403': 'Lappajärvi',
    '405': 'Villmanstrand',
    '406': '(Utgånget) Lappi',
    '407': 'Lappträsk',
    '408': 'Lappo',
    '410': 'Laukaa',
    '413': '(Utgånget) Lavia',
    '414': '(Utgånget) Lehtimäki',
    '415': '(Utgånget) Leivonmäki',
    '416': 'Lemi',
    '417': 'Lemland',
    '418': 'Lempäälä',
    '419': '(Utgånget) Lemu',
    '420': 'Leppävirta',
    '421': 'Lestijärvi',
    '422': 'Lieksa',
    '423': 'Lundo',
    '424': '(Utgånget) Liljendal',
    '425': 'Limingo',
    '426': 'Liperi',
    '427': '(Utgånget) Lojo',
    '428': '(Utgånget) Lojo kommun',
    '429': '(Utgånget) Lochteå',
    '430': 'Loimaa',
    '431': '(Utgånget) Loimaa kommun',
    '433': 'Loppi',
    '434': 'Lovisa',
    '435': 'Luhanka',
    '436': 'Lumijoki',
    '438': 'Lumparland',
    '439': '(Utgånget) Luopioinen',
    '440': 'Larsmo',
    '441': 'Luumäki',
    '442': '(Utgånget) Luvia',
    '443': '(Utgånget) Längelmäki',
    '444': 'Lojo',
    '445': 'Pargas',
    '475': 'Malax',
    '476': '(Utgånget) Maaninka',
    '478': 'Mariehamn',
    '479': '(Utgånget) Maksmo',
    '480': 'Marttila',
    '481': 'Masku',
    '482': '(Utgånget) Mellilä',
    '483': 'Merijärvi',
    '484': 'Sastmola',
    '485': '(Utgånget) Merimasku',
    '489': 'Miehikkälä',
    '490': '(Utgånget) Mietoinen',
    '491': 'S:t Michel',
    '492': '(Utgånget) S:t Michels lk',
    '493': '(Utgånget) Mouhijärvi',
    '494': 'Muhos',
    '495': 'Multia',
    '498': 'Muonio',
    '499': 'Korsholm',
    '500': 'Muurame',
    '501': '(Utgånget) Muurla',
    '503': 'Mynämäki',
    '504': 'Mörskom',
    '505': 'Mäntsälä',
    '506': '(Utgånget) Mänttä',
    '507': 'Mäntyharju',
    '508': 'Mänttä-Filpula',
    '529': 'Nådendal',
    '531': 'Nakkila',
    '532': '(Utgånget) Nastola',
    '533': '(Utgånget) Nagu',
    '534': '(Utgånget) Nilsiä',
    '535': 'Nivala',
    '536': 'Nokia',
    '537': '(Utgånget) Norrmark',
    '538': 'Nousis',
    '540': '(Utgånget) Nummi-Pusula',
    '541': 'Nurmes',
    '543': 'Nurmijärvi',
    '544': '(Utgånget) Nurmo',
    '545': 'Närpes',
    '559': '(Utgånget) Oravais',
    '560': 'Orimattila',
    '561': 'Oripää',
    '562': 'Orivesi',
    '563': 'Oulainen',
    '564': 'Uleåborg',
    '567': '(Utgånget) Oulunsalo',
    '573': '(Utgånget) Pargas',
    '576': 'Padasjoki',
    '577': 'Pemar',
    '578': 'Paltamo',
    '580': 'Parikkala',
    '581': 'Parkano',
    '582': '(Utgånget) Pattijoki',
    '583': 'Pelkosenniemi',
    '584': 'Perho',
    '585': '(Utgånget) Pernå',
    '586': '(Utgånget) Bjärnå',
    '587': '(Utgånget) Pertteli',
    '588': 'Pertunmaa',
    '589': '(Utgånget) Peräseinäjoki',
    '592': 'Petäjävesi',
    '593': 'Pieksämäki',
    '594': '(Utgånget) Pieksämäki lk',
    '595': 'Pielavesi',
    '598': 'Jakobstad',
    '599': 'Pedersöre',
    '601': 'Pihtipudas',
    '602': '(Utgånget) Pikis',
    '603': '(Utgånget) Piippola',
    '604': 'Birkala',
    '606': '(Utgånget) Pojo',
    '607': 'Polvijärvi',
    '608': 'Påmark',
    '609': 'Björneborg',
    '611': 'Borgnäs',
    '612': '(Utgånget) Borgå',
    '613': '(Utgånget) Borgå lk',
    '614': 'Posio',
    '615': 'Pudasjärvi',
    '616': 'Pukkila',
    '617': '(Utgånget) Pulkkila',
    '618': '(Utgånget) Punkaharju',
    '619': 'Punkalaidun',
    '620': 'Puolanka',
    '623': 'Puumala',
    '624': 'Pyttis',
    '625': 'Pyhäjoki',
    '626': 'Pyhäjärvi',
    '630': 'Pyhäntä',
    '631': 'Pyhäranta',
    '632': '(Utgånget) Pyhäselkä',
    '633': '(Utgånget) Pylkönmäki',
    '635': 'Pälkäne',
    '636': 'Pöytyä',
    '638': 'Borgå',
    '640': '(Utgånget) Pieksänmaa',
    '678': 'Brahestad',
    '680': 'Reso',
    '681': 'Rantasalmi',
    '682': '(Utgånget) Rantsila',
    '683': 'Ranua',
    '684': 'Raumo',
    '685': '(Utgånget) Raumo lk',
    '686': 'Rautalampi',
    '687': 'Rautavaara',
    '689': 'Rautjärvi',
    '691': 'Reisjärvi',
    '692': '(Utgånget) Renko',
    '694': 'Riihimäki',
    '696': '(Utgånget) Ristiina',
    '697': 'Ristijärvi',
    '698': 'Rovaniemi',
    '699': '(Utgånget) Rovaniemi lk',
    '700': 'Ruokolahti',
    '701': '(Utgånget) Strömfors',
    '702': 'Ruovesi',
    '704': 'Rusko',
    '705': '(Utgånget) Rimito',
    '707': 'Rääkkylä',
    '708': '(Utgånget) Ruukki',
    '710': 'Raseborg',
    '728': '(Utgånget) Saari',
    '729': 'Saarijärvi',
    '730': '(Utgånget) Sahalahti',
    '732': 'Salla',
    '734': 'Salo',
    '736': 'Saltvik',
    '737': '(Utgånget) Sammatti',
    '738': 'Sagu',
    '739': 'Savitaipale',
    '740': 'Nyslott',
    '741': '(Utgånget) Savonranta',
    '742': 'Savukoski',
    '743': 'Seinäjoki',
    '746': 'Sievi',
    '747': 'Siikainen',
    '748': 'Siikajoki',
    '749': 'Siilinjärvi',
    '751': 'Simo',
    '752': '(Utgånget) Simpele',
    '753': 'Sibbo',
    '754': '(Utgånget) Anjalankoski',
    '755': 'Sjundeå',
    '758': 'Sodankylä',
    '759': 'Soini',
    '761': 'Somero',
    '762': 'Sonkajärvi',
    '765': 'Sotkamo',
    '766': 'Sottunga',
    '768': 'Sulkava',
    '770': '(Utgånget) Sumiainen',
    '771': 'Sund',
    '772': '(Utgånget) Suodenniemi',
    '774': '(Utgånget) Suolahti',
    '775': '(Utgånget) Suomenniemi',
    '776': '(Utgånget) Suomusjärvi',
    '777': 'Suomussalmi',
    '778': 'Suonenjoki',
    '781': 'Sysmä',
    '783': 'Säkylä',
    '784': '(Utgånget) Finby',
    '785': 'Vaala',
    '787': '(Utgånget) Säynätsalo',
    '790': 'Sastamala',
    '791': 'Siikalatva',
    '831': 'Taipalsaari',
    '832': 'Taivalkoski',
    '833': 'Tövsala',
    '834': 'Tammela',
    '835': '(Utgånget) Ekenäs',
    '837': 'Tammerfors',
    '838': '(Utgånget) Tarvasjoki',
    '841': '(Utgånget) Temmes',
    '842': '(Utgånget) Tenhola',
    '844': 'Tervo',
    '845': 'Tervola',
    '846': 'Östermark',
    '848': 'Tohmajärvi',
    '849': 'Toholampi',
    '850': 'Toivakka',
    '851': 'Torneå',
    '853': 'Åbo',
    '854': 'Pello',
    '855': '(Utgånget) Tuulos',
    '856': '(Utgånget) Tuupovaara',
    '857': 'Tuusniemi',
    '858': 'Tusby',
    '859': 'Tyrnävä',
    '863': '(Utgånget) Töysä',
    '864': '(Utgånget) Toijala',
    '885': '(Utgånget) Ullava',
    '886': 'Ulvsby',
    '887': 'Urjala',
    '889': 'Utajärvi',
    '890': 'Utsjoki',
    '891': '(Utgånget) Uukuniemi',
    '892': 'Uurainen',
    '893': 'Nykarleby',
    '895': 'Nystad',
    '905': 'Vasa',
    '906': '(Utgånget) Vahto',
    '908': 'Valkeakoski',
    '909': '(Utgånget) Valkeala',
    '911': '(Utgånget) Valtimo',
    '912': '(Utgånget) Vammala',
    '913': '(Utgånget) Vampula',
    '915': 'Varkaus',
    '916': '(Utgånget) Varpaisjärvi',
    '917': '(Utgånget) Vehkalahti',
    '918': 'Vehmaa',
    '919': '(Utgånget) Vehmersalmi',
    '920': '(Utgånget) Velkua',
    '921': 'Vesanto',
    '922': 'Vesilahti',
    '923': '(Utgånget) Västanfjärd',
    '924': 'Vetil',
    '925': 'Vieremä',
    '926': '(Utgånget) Vihanti',
    '927': 'Vichtis',
    '928': '(Utgånget) Viiala',
    '931': 'Viitasaari',
    '932': '(Utgånget) Viljakkala',
    '933': '(Utgånget) Vilppula',
    '934': 'Vimpeli',
    '935': 'Virolahti',
    '936': 'Virdois',
    '937': '(Utgånget) Virtasalmi',
    '940': '(Utgånget) Vuolijoki',
    '941': 'Vårdö',
    '942': '(Utgånget) Lillkyro',
    '943': '(Utgånget) Värtsilä',
    '944': '(Utgånget) Vöyri',
    '945': '(Utgånget) Vörå-Maxmo',
    '946': 'Vörå',
    '971': '(Utgånget) Ylihärmä',
    '972': '(Utgånget) Yli-Ii',
    '973': '(Utgånget) Ylikiiminki',
    '975': '(Utgånget) Ylistaro',
    '976': 'Övertorneå',
    '977': 'Ylivieska',
    '978': '(Utgånget) Ylämaa',
    '979': '(Utgånget) Yläne',
    '980': 'Ylöjärvi',
    '981': 'Ypäjä',
    '988': '(Utgånget) Äetsä',
    '989': 'Etseri',
    '992': 'Äänekoski',
  },
  languages: {
    fi: 'Suomi sv',
    sv: 'Ruotsi sv',
    en: 'Englanti sv',
    ru: 'Venäjä sv',
    ku: 'Kurdi sv',
    so: 'Somali sv',
    ar: 'Arabia sv',
  },
};

export default translationSv;
