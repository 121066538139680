import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SCREENING_CODE, SCREENING_ID, SSN } from '../../constants/commonKeys';
import TextInputLabel from '../../features/formInputs/textInputLabel';
import { fetchExpressScreeningQuery } from '../../services/queries/searchQueries';
import { FetchScreeningList } from '../../services/searchPersonsService';
import { Button } from '../button/button';
import styles from './screeningSearch.module.scss';

const ScreeningSearch: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <Formik
        initialValues={{ [SSN]: '', [SCREENING_CODE]: '' }}
        onSubmit={async (v, { resetForm }) => {
          if (!v[SCREENING_CODE].trim() && !v[SSN].trim()) return;
          const data = await FetchScreeningList(
            t,
            fetchExpressScreeningQuery(v[SSN], v[SCREENING_CODE])
          );
          if (!data || !data?.screeningSearch?.results?.length) {
            toast.error(t('errors.noScreeningResult'));
            return null;
          }
          const {
            screeningSearch: { results },
          } = data;
          if (results.length) {
            resetForm({});
            history.push(
              `/screening/${results[results.length - 1][SCREENING_ID]}`
            );
          }
        }}
      >
        {() => (
          <Form>
            <div className={`${styles.container}`}>
              <div className="grid-2-1st">
                <TextInputLabel src={`tableColumns.${SSN}`} />
              </div>
              <div className="grid-2-2nd">
                <TextInputLabel src={`tableColumns.${SCREENING_CODE}`} />
              </div>
              <Button
                type="submit"
                label={t('control.search')}
                className={styles.btn}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default ScreeningSearch;
