import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GridApi, GridReadyEvent, IGetRowsParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import UseCreateAdminColumn from '../../columns/adminTableCols';
import BreadCrums from '../../components/breadCrums/breadCrumbs';
import StatusRenderer from '../../components/statusRenderer/statusRenderer';
import {
  CREATED_AT,
  END_DATE,
  MODIFIED_AT,
  START_DATE,
  USER_ID,
} from '../../constants/adminTabel';
import { FetchUsersList } from '../../services/searchUsersService';
import { RootState } from '../../state/rootReducer';
import { setLoadingValue } from '../../state/screening/actions';
import { timeformat } from '../../utils/timeFormat';
import RadioFilter from '../gridCustomFilter/radioFilter';
import TextFilter from '../gridCustomFilter/textFilter';
import styles from './admin.module.scss';

const Admin: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { screening } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [gridApi, setGridApi] = useState<GridApi>();
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const { adminColumnDefs, defaultColDefAdmin } = UseCreateAdminColumn(
    t,
    screening
  );

  useEffect(() => {
    let mount = true;
    if (gridApi) {
      const dataSource = {
        getRows: async (params: IGetRowsParams) => {
          gridApi.refreshHeader();
          dispatch(setLoadingValue(true));
          const data = await FetchUsersList(
            t,
            screening,
            gridApi?.paginationGetPageSize(),
            params,
            dispatch
          );
          dispatch(setLoadingValue(false));
          if (!data || !mount) {
            return null;
          }
          data.userSearch.results = timeformat(data.userSearch.results, [
            START_DATE,
            END_DATE,
            MODIFIED_AT,
            CREATED_AT,
          ]);

          params.successCallback(
            data.userSearch.results,
            data.userSearch.totalCount
          );
        },
      };
      gridApi.setDatasource(dataSource);
    }
    return () => {
      mount = false;
    };
  }, [gridApi]);

  return (
    <>
      <div className="container__row  space-between">
        <div className={`${styles.headTitle} container__col-6`}>
          {t('header.adminSearch')}
        </div>
      </div>
      <div
        className={styles.addNew}
        onClick={() => {
          history.push('/admin/0');
        }}
      >
        <FontAwesomeIcon
          className={styles.icon}
          icon={faPlusCircle}
          size="1x"
        />
        {t('tableColumns.addNewUser')}
      </div>
      <div className={`ag-theme-alpine ${styles.container} `}>
        <AgGridReact
          domLayout="autoHeight"
          popupParent={document.body}
          onRowClicked={(row) => {
            history.push(`/admin/${row.data[USER_ID]}`);
          }}
          rowModelType="infinite"
          onGridReady={onGridReady}
          groupHeaderHeight={75}
          cacheQuickFilter={true}
          pagination
          paginationPageSize={25}
          cacheBlockSize={25}
          frameworkComponents={{
            TextFilter,
            StatusRenderer,
            RadioFilter,
          }}
          columnDefs={adminColumnDefs}
          defaultColDef={defaultColDefAdmin}
          className={styles.screeningTable}
        ></AgGridReact>
      </div>
    </>
  );
};

export default Admin;
