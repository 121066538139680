import translationFi from '../locales/fi/strings';

export function hasTranslation(
  keys: string,
  obj: any = translationFi
): boolean {
  if (!keys) return false;
  const arrayOfKeys = keys.split('.');
  if (obj[arrayOfKeys[0]] && arrayOfKeys.length > 1) {
    const first = arrayOfKeys.shift();
    if (!first) return false;
    return hasTranslation(arrayOfKeys.join('.'), obj[first]);
  }
  if (obj[arrayOfKeys[0]] && arrayOfKeys.length === 1) {
    return true;
  } else {
    return false;
  }
}
