import {
  ColDef,
  GridApi,
  ValueGetterParams,
} from 'ag-grid-community/dist/lib/main';
import { TFunction } from 'i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  DIAGNOSES_DIAMETER_MM_ID,
  DIAGNOSES_LOCATION_ID,
  DIAGNOSES_MORPHOLOGY_ID,
  DIAGNOSES_RESULT_ID,
} from '../constants/disgnosesTabel';
import { DiagnosesInitial, IDiagnoses } from '../models/IDiagnoses';
import { RootState } from '../state/rootReducer';

const UseCreateDiagnosesColumn = (t: TFunction, disable: boolean) => {
  const screening = useSelector((state: RootState) => state.screening);
  const defaultColDefDiagnoses: ColDef = {
    filter: false,
    sortable: false,
    editable: !disable,
    resizable: false,
    autoHeight: true,
    flex: 1,
    menuTabs: [],
  };

  const addNew = (gridApi?: GridApi) => {
    if (gridApi) {
      const items: IDiagnoses[] = [];
      gridApi.forEachNode(function (node: any) {
        items.push(node.data);
      });
      if (
        items
          .filter((el) => el[DIAGNOSES_RESULT_ID] === 0)
          .some((el) => !el[DIAGNOSES_MORPHOLOGY_ID])
      ) {
        return toast.warning(t('warnings.newRow'));
      }
      gridApi.applyTransaction({
        add: [{ ...DiagnosesInitial, isNew: true }],
        addIndex: items.length,
      });
      setTimeout(
        () =>
          gridApi.startEditingCell({
            rowIndex: items.length,
            colKey: 'morphologyId',
          }),
        300
      );
    }
  };

  const diagnosesColumnDefs: ColDef[] = [
    {
      headerName: t(`tableColumns.${DIAGNOSES_LOCATION_ID}`),
      field: DIAGNOSES_LOCATION_ID,
      cellEditor: 'SelectEditorRenderer',
      cellEditorParams: {
        options: screening[DIAGNOSES_LOCATION_ID],
        labelSrc: DIAGNOSES_LOCATION_ID,
      },
      valueGetter: (params: ValueGetterParams) => {
        return t(
          `${DIAGNOSES_LOCATION_ID}.${
            screening[DIAGNOSES_LOCATION_ID]?.find(
              (option) => option.value === params.data[DIAGNOSES_LOCATION_ID]
            )?.langValue
          }`
        );
      },
    },
    {
      headerName: t(`tableColumns.${DIAGNOSES_MORPHOLOGY_ID}`),
      field: DIAGNOSES_MORPHOLOGY_ID,
      cellEditor: 'SelectEditorRenderer',
      cellEditorParams: {
        options: screening[DIAGNOSES_MORPHOLOGY_ID],
        labelSrc: DIAGNOSES_MORPHOLOGY_ID,
      },
      valueGetter: (params: ValueGetterParams) => {
        return t(
          `${DIAGNOSES_MORPHOLOGY_ID}.${
            screening[DIAGNOSES_MORPHOLOGY_ID]?.find(
              (option) => option.value === params.data[DIAGNOSES_MORPHOLOGY_ID]
            )?.langValue
          }`
        );
      },
    },
    {
      headerName: t(`tableColumns.${DIAGNOSES_DIAMETER_MM_ID}`),
      field: DIAGNOSES_DIAMETER_MM_ID,
      cellEditor: 'SelectEditorRenderer',
      cellEditorParams: {
        options: screening[DIAGNOSES_DIAMETER_MM_ID],
        labelSrc: DIAGNOSES_DIAMETER_MM_ID,
      },
      valueGetter: (params: ValueGetterParams) => {
        return t(
          `${DIAGNOSES_DIAMETER_MM_ID}.${
            screening[DIAGNOSES_DIAMETER_MM_ID]?.find(
              (option) => option.value === params.data[DIAGNOSES_DIAMETER_MM_ID]
            )?.langValue
          }`
        );
      },
    },
    {
      headerName: '',
      field: DIAGNOSES_MORPHOLOGY_ID,
      cellRenderer: !disable ? 'EditRemoveRendere' : '',
      editable: false,
      valueGetter: () => '',
    },
  ];
  return { defaultColDefDiagnoses, addNew, diagnosesColumnDefs };
};

export default UseCreateDiagnosesColumn;
