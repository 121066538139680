import { Action } from 'redux';
import { Languages } from './state';

export enum ActionTypes {
  setLanguage = 'SET_LANGUAGE',
}

interface LanguageAction extends Action {
  type: ActionTypes.setLanguage;
  payload: Languages;
}

export const setLanguage = (value: Languages) => ({
  type: ActionTypes.setLanguage,
  payload: value,
});

export type LanguageActionTypes = LanguageAction;
