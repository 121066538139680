import React from 'react';
import DatePicker from '../../components/datePicker/datePicker';
import TooltipLabel from '../../components/tooltipLabel/tooltipLabel';

interface IDatePickerLabel {
  src: string;
  locale?: string;
  required?: boolean;
  disabled?: boolean;
  hideErros?: boolean;
  errorClassName?: string;
  className?: string;
  labelClass?: string;
  dateFormat?: string;
  datePickerClass?: string;
  onChange?: (v: Date) => void;
}

const DatePickerLabel: React.FC<IDatePickerLabel> = ({
  src,
  required,
  disabled,
  className,
  hideErros,
  errorClassName,
  labelClass,
  dateFormat,
  datePickerClass,
  locale,
  onChange,
}) => {
  const [form, name] = src.split('.');

  return (
    <div>
      <TooltipLabel
        label={form}
        name={name}
        labelClassName={labelClass}
        required={required}
      />
      <DatePicker
        name={name}
        disabled={disabled}
        className={className}
        hideErros={hideErros}
        errorClassName={errorClassName}
        dateFormat={dateFormat}
        datePickerClass={datePickerClass}
        locale={locale}
        onChange={onChange}
      />
    </div>
  );
};

export default DatePickerLabel;
