import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/button/button';
import styles from './actionsCellRenderer.module.scss';
import { ICellRendererParams } from 'ag-grid-community';
import { SubmitPreliminaryInfoPdfData } from '../../services/searchPdfImportsService';
import { FILENAME, FORM_VERSION, IS_UNRESOLVABLE } from '../../constants/pdfImportKeys';
import { IPreliminaryInfoPdf } from '../../models/IPdfImport';
import { IOption } from '../../models/IOption';
import Select from 'react-select';
import Dropdown from '../../components/dropdown/dropdown';
import RadioSelect from '../../components/radioSelect/radioSelect';

interface IActionsCellRenderer extends ICellRendererParams {
  currentlyEditingFilename: string;
  onBeginInputClicked: (filename: string) => void;
  onSubmitClicked: (
    filename: string,
    controlCode: string,
    formVersion: string
  ) => void;
  onCancelClicked: () => void;
  validateInput: (controlCode: string) => boolean;
  updateRow: (data: IPreliminaryInfoPdf) => void;
}

const ActionsCellRenderer: React.FC<IActionsCellRenderer> = (
  props: IActionsCellRenderer
) => {
  const { t } = useTranslation();
  const [controlCode, setControlCode] = useState('');
  const [formVersion, setFormVersion] = useState('');
  const isEditingThisRow =
    props.currentlyEditingFilename !== '' &&
    props.data.filename === props.currentlyEditingFilename;

  const formVersionOptions: IOption[] = [
    { value: 'old', label: 'old', langValue: 'old' },
    { value: 'new', label: 'new', langValue: 'new' },
  ];

  if (isEditingThisRow) {
    const validValue = controlCode !== '' && props.validateInput(controlCode) && formVersion !== '';
    const showValidationError =
      controlCode !== '' && !props.validateInput(controlCode);
    return (
      <div className={styles.secondaryActions}>
        <input
          className={showValidationError ? styles.error : ''}
          type="text"
          placeholder={t('pdfImport.controlCode')}
          value={controlCode}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setControlCode(e.target.value.toUpperCase())
          }
        />
        <div style={{ paddingLeft: 10, paddingRight: 5 }}>
          <input
            type="radio"
            name="pdfImport.formVersion.old"
            checked={formVersion === '2022'}
            onChange={() => setFormVersion('2022')}
          />
          <label htmlFor="pdfImport.formVersion.old">
            {t('pdfImport.formVersion.old')}
          </label>
        </div>
        <div style={{ paddingLeft: 5, paddingRight: 20 }}>
          <input
            type="radio"
            name="pdfImport.formVersion.new"
            checked={formVersion === '2024'}
            onChange={() => setFormVersion('2024')}
          />
          <label htmlFor="pdfImport.formVersion.new">
            {t('pdfImport.formVersion.new')}
          </label>
        </div>
        <Button
          type="button"
          label={t('pdfImport.saveInputId')}
          noSpinner={true}
          disabled={!validValue}
          onClick={() =>
            props.onSubmitClicked(props.data.filename, controlCode, formVersion)
          }
        />
        <Button
          type="button"
          label={t('pdfImport.cancel')}
          onClick={props.onCancelClicked}
        />
      </div>
    );
  }

  return (
    <div className={`${styles.mainActions}`}>
      <Button
        type="button"
        label={t('pdfImport.inputId')}
        onClick={() => props.onBeginInputClicked(props.data.filename)}
      />
      <input
        type="checkbox"
        defaultChecked={props.data.isUserUnableToResolve}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          props.updateRow({
            ...props.data,
            isUserUnableToResolve: e.target.checked,
          });
          SubmitPreliminaryInfoPdfData(t, {
            [FILENAME]: props.data.filename,
            [IS_UNRESOLVABLE]: e.target.checked,
          });
        }}
      />
      <label>{t('pdfImport.unknownPerson')}</label>
    </div>
  );
};

export default ActionsCellRenderer;
