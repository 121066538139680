import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './breadCrumbs.module.scss';

interface IBreadCrumbsProps {
  links: {
    to: string;
    name: string;
    disable: boolean;
  }[];
}

export const BreadCrums: React.FC<IBreadCrumbsProps> = ({ links }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      {links.map((el, ind) => (
        <Link
          key={ind}
          to={el.to}
          className={el.disable ? styles.disabled : ''}
          onClick={el.disable ? (e) => e.preventDefault() : () => {}}
        >
          {`${t(el.name)} ${ind === links.length - 1 ? '' : '/'} `}
        </Link>
      ))}
    </div>
  );
};

export default BreadCrums;
