import {
  GridApi,
  GridReadyEvent,
  IGetRowsParams,
} from 'ag-grid-community/dist/lib/main';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import UseCreateScreeningColumn from '../../columns/screeningTableCols';
import StatusRenderer from '../../components/statusRenderer/statusRenderer';
import {
  SCREENING_CODE,
  SCREENING_ID,
  SSN,
  COMMENT,
  MUNICIPALITY_ID,
  SEARCH_KEYS,
  COULD_NOT_BE_REACHED_AT,
} from '../../constants/commonKeys';
import { searchScreeningQuery } from '../../services/queries/searchQueries';
import { FetchScreeningList } from '../../services/searchPersonsService';
import { RootState } from '../../state/rootReducer';
import { setLoadingValue } from '../../state/screening/actions';
import { setSearchKeys } from '../../state/searchKeys/actions';
import { setTabValue } from '../../state/tabs/actions';
import { Tabs } from '../../state/tabs/state';
import { isSameobject } from '../../utils/isSameObject';
import RadioFilter from '../gridCustomFilter/radioFilter';
import TextFilter from '../gridCustomFilter/textFilter';
import DateFilter from '../gridCustomFilter/dateFilter';
import styles from './screenTable.module.scss';
import moment from 'moment';

const ScreenTable: React.FC = () => {
  const { t } = useTranslation();
  const { screening, searchKeys } = useSelector((state: RootState) => state);
  const [rerender, setRerender] = useState(false);
  const [isModelSet, setIsModelSet] = useState(false);
  const history = useHistory();
  const [gridApi, setGridApi] = useState<GridApi | undefined>();
  const dispatch = useDispatch();

  const {
    defaultColDefPerson,
    screeningColumnDefs,
    checkIfOptions,
  } = UseCreateScreeningColumn(t);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    let mount = true;
    if (gridApi) {
      if (!isSameobject(gridApi.getFilterModel(), searchKeys) && !isModelSet) {
        // we set the filter model to be as redux state
        gridApi.setFilterModel({
          ...searchKeys,
          [SSN]: '',
          [SCREENING_CODE]: '',
          [COMMENT]: '',
          [MUNICIPALITY_ID]: '',
          [COULD_NOT_BE_REACHED_AT]: '',
        });
        setIsModelSet(true);
        // set filter model is taking short while and this will wait until filter are set on all columns and force rerender
        setTimeout(() => setRerender(!rerender), 50);
        return;
      }
      const dataSource = {
        destroy: () => (mount = false),
        getRows: async (params: IGetRowsParams) => {
          gridApi.refreshHeader();
          dispatch(setLoadingValue(true));
          const dateObj = params.filterModel[COULD_NOT_BE_REACHED_AT];
          if (dateObj) {
            params.filterModel[COULD_NOT_BE_REACHED_AT] = moment(
              dateObj
            ).format('YYYY-MM-DDTHH:mm:ss');
          }
          // we set the  redux state to be as filter model
          dispatch(setSearchKeys(params.filterModel));
          localStorage.setItem(SEARCH_KEYS, JSON.stringify(params.filterModel));
          const data = await FetchScreeningList(
            t,
            searchScreeningQuery(params, screening),
            dispatch
          );
          dispatch(setLoadingValue(false));
          if (!data || !mount) {
            return null;
          }
          checkIfOptions(gridApi, data);
          params.successCallback(
            data.screeningSearch.results,
            data.screeningSearch.totalCount
          );
        },
      };
      gridApi.setDatasource(dataSource);
    }
    return () => {
      mount = false;
    };
  }, [gridApi, rerender]);

  return (
    <>
      <div className="container__row  space-between">
        <div className={`${styles.headTitle} container__col-6`}>
          {t('header.screenSearch')}
        </div>
      </div>
      <div className={`ag-theme-alpine ${styles.container} `}>
        <AgGridReact
          domLayout="autoHeight"
          popupParent={document.body}
          onRowClicked={(row) => {
            dispatch(setTabValue(Tabs.Preliminary));
            history.push(`/screening/${row.data[SCREENING_ID]}`);
          }}
          rowModelType="infinite"
          onGridReady={onGridReady}
          groupHeaderHeight={75}
          cacheQuickFilter={true}
          pagination
          paginationPageSize={25}
          cacheBlockSize={25}
          frameworkComponents={{
            TextFilter,
            StatusRenderer,
            RadioFilter,
            DateFilter,
          }}
          columnDefs={screeningColumnDefs}
          defaultColDef={defaultColDefPerson}
          className={styles.screeningTable}
        ></AgGridReact>
      </div>
    </>
  );
};
export default ScreenTable;
