import { AgGridReact } from 'ag-grid-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import UseCreateOrderKitColumn from '../../columns/orderKitCols';
import RadioFilter from '../gridCustomFilter/radioFilter';
import TextFilter from '../gridCustomFilter/textFilter';
import SampleKitRenderer from '../kitStatusRenderer/kitStatusrenderer';
import styles from './orderKitContent.module.scss';

const OrderKitContent: React.FC = () => {
  const { t } = useTranslation();
  const {
    orderKitColumnDefs,
    onGridReady,
    defaultColDefOrderKit,
  } = UseCreateOrderKitColumn(t);

  return (
    <>
      <div className="container__row  space-between">
        <div className={`${styles.headTitle} container__col-6`}>
          {t('header.orderKitTitle')}
        </div>
      </div>

      <div className={`ag-theme-alpine ${styles.container} `}>
        <AgGridReact
          domLayout="autoHeight"
          popupParent={document.body}
          onGridReady={onGridReady}
          groupHeaderHeight={75}
          pagination
          paginationPageSize={25}
          cacheBlockSize={25}
          rowModelType="infinite"
          frameworkComponents={{ SampleKitRenderer, RadioFilter, TextFilter }}
          columnDefs={orderKitColumnDefs}
          defaultColDef={defaultColDefOrderKit}
          className={styles.screeningTable}
        ></AgGridReact>
      </div>
    </>
  );
};

export default OrderKitContent;
