// any change here should be alligned with Wasm structs
export const dropdownQuery = `query getDropdownData {
    a: codeTable(type: HEALTH_CARE_ORGANIZATION) {
      value: databaseId
      label: value
    }
    b:codeTable(type: SURGERY_OR_PROCEDURE) {
      value: databaseId
      label: value
    }
  }`;
