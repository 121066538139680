import * as React from 'react';
import { memo } from 'react';
import {
  BrowserRouter as Router,
  Route,
  RouteComponentProps,
  Switch,
} from 'react-router-dom';
import LaunguageGuard from '../components/languageGaurd/LaunguageGuard';
import Loader from '../components/loader/loader';
import PreventRefresh from '../components/preventRefresh/preventRefresh';
import SigninOidc from '../features/oidc/signinOidc';
import SingoutOidc from '../features/oidc/signoutOidc';
import AdminPage from '../pages/admin/adminPage';
import NotFoundPage from '../pages/notFoundPage/notFoundPage';
import OrderKit from '../pages/orderKit/orderKitPage';
import PersonInfo from '../pages/personInfo/personInfo';
import ReportPage from '../pages/report/reportPage';
import ScreeningTable from '../pages/screenigTable/screeningTable';
import UserPage from '../pages/userPage/userPage';
import PdfImportPage from '../pages/pdfImport/pdfImportPage';
import AuthGuard from './guards/authGuard';
import LogoutPage from '../pages/logout/logout';

const RouterComponent: React.FC = () => (
  <Router>
    {/*LaunguageGuard is listening to the rout and changing the language  */}
    <Route component={(props: RouteComponentProps) => LaunguageGuard(props)} />
    <Loader />
    <PreventRefresh />
    <Switch>
      <Route exact path="/signin-oidc" component={SigninOidc} />
      <Route exact path="/sv/signin-oidc" component={SigninOidc} />

      <Route path="/signout-oidc" component={SingoutOidc} />
      <Route path="/sv/signout-oidc" component={SingoutOidc} />

      <Route path="/logout" component={LogoutPage} />

      <AuthGuard exact path="/" allowedRoles={[1]} component={ScreeningTable} />
      <AuthGuard
        exact
        path="/sv"
        allowedRoles={[1]}
        component={ScreeningTable}
      />

      <AuthGuard
        exact
        path="/screening/:screeningId"
        allowedRoles={[1]}
        component={PersonInfo}
      />
      <AuthGuard
        exact
        path="/sv/screening/:screeningId"
        component={PersonInfo}
        allowedRoles={[1]}
      />

      <AuthGuard
        exact
        path="/orderkit"
        allowedRoles={[2]}
        component={OrderKit}
      />
      <AuthGuard
        exact
        allowedRoles={[2]}
        path="/sv/orderkit"
        component={OrderKit}
      />
      <AuthGuard allowedRoles={[3]} exact path="/admin" component={AdminPage} />
      <AuthGuard
        allowedRoles={[3]}
        exact
        path="/sv/admin"
        component={AdminPage}
      />

      <AuthGuard
        allowedRoles={[3]}
        exact
        path="/admin/:userId"
        component={UserPage}
      />
      <AuthGuard
        allowedRoles={[3]}
        exact
        path="/sv/admin/:userId"
        component={UserPage}
      />
      <AuthGuard
        allowedRoles={[4]}
        exact
        path="/report"
        component={ReportPage}
      />
      <AuthGuard
        allowedRoles={[4]}
        exact
        path="/sv/report"
        component={ReportPage}
      />
      <AuthGuard
        allowedRoles={[8]}
        exact
        path="/pdfImport"
        component={PdfImportPage}
      />
      <Route component={NotFoundPage} />
    </Switch>
  </Router>
);

export default memo(RouterComponent);
