import {
  ColDef,
  GridReadyEvent,
  IDatasource,
  IGetRowsParams,
} from 'ag-grid-community/dist/lib/main';
import { TFunction } from 'i18next';
import moment from 'moment';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FIRSTNAME, LASTNAME, SCREENING_CODE } from '../constants/commonKeys';
import {
  CONTROL_CODE,
  ORDER_AT,
  SAMPLE_KIT_STATUS_ID,
} from '../constants/orderKitTable';
import { FetchKitOrdersList } from '../services/searchKitOrderService';
import { RootState } from '../state/rootReducer';
import { setScreeningData } from '../state/screening/actions';

const UseCreateOrderKitColumn = (t: TFunction) => {
  const { screening } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const defaultColDefOrderKit: ColDef = {
    filter: false,
    sortable: false,
    editable: false,
    resizable: true,
    autoHeight: true,
    flex: 1,
    menuTabs: [],
  };

  const orderKitColumnDefs: ColDef[] = [
    {
      headerName: t(`tableColumns.${SAMPLE_KIT_STATUS_ID}`),
      field: SAMPLE_KIT_STATUS_ID,
      menuTabs: ['filterMenuTab'],
      cellRenderer: 'SampleKitRenderer',
      filter: 'RadioFilter',
      floatingFilter: true,
      filterParams: {
        title: t(`tableColumns.${SAMPLE_KIT_STATUS_ID}`),
        name: SAMPLE_KIT_STATUS_ID,
        options: screening[SAMPLE_KIT_STATUS_ID]
          ? [
              { value: 'null', langValue: 'empty' },
              ...screening[SAMPLE_KIT_STATUS_ID]!,
            ]
          : [],
        defaultVal: null,
      },
    },
    {
      headerName: t(`tableColumns.${LASTNAME}`),
      field: LASTNAME,
    },
    {
      headerName: t(`tableColumns.${FIRSTNAME}`),
      field: FIRSTNAME,
    },
    {
      headerName: t(`tableColumns.${ORDER_AT}`),
      field: ORDER_AT,
      valueGetter: (v) => {
        if (v.node?.data?.[ORDER_AT]) {
          return moment(v.node?.data[ORDER_AT]).format('DD.MM.YYYY');
        } else {
          return '';
        }
      },
    },

    {
      headerName: t(`tableColumns.${CONTROL_CODE}`),
      field: CONTROL_CODE,
      floatingFilter: true,
      menuTabs: ['filterMenuTab'],
      filter: 'TextFilter',
      filterParams: {
        title: t(`tableColumns.${CONTROL_CODE}`),
      },
    },
    {
      headerName: t(`tableColumns.${SCREENING_CODE}`),
      field: SCREENING_CODE,
    },
  ];

  const onGridReady = useCallback((params: GridReadyEvent) => {
    let mount = true;
    params.api.setFilterModel({
      [CONTROL_CODE]: '',
      [SAMPLE_KIT_STATUS_ID]: null,
    });
    const dataSource: IDatasource = {
      getRows: async (filterparams: IGetRowsParams) => {
        FetchKitOrdersList(
          t,
          screening,
          params?.api.paginationGetPageSize(),
          filterparams
        ).then((re) => {
          if (re?.[SAMPLE_KIT_STATUS_ID]) {
            dispatch(
              setScreeningData({
                [SAMPLE_KIT_STATUS_ID]: re[SAMPLE_KIT_STATUS_ID],
              })
            );
            const filterCol: any = params.api.getFilterInstance(
              SAMPLE_KIT_STATUS_ID
            );
            filterCol?.componentInstance?.refreshOptions(
              [
                { value: 'null', langValue: 'empty' },
                ...(re?.[SAMPLE_KIT_STATUS_ID] || []),
              ].map((el) => ({
                langValue: el.label
                  ? `${el.label} ${el.langValue}`
                  : el.langValue,
                value: el.value,
              }))
            );
          }
          if (mount) {
            filterparams?.successCallback(
              re?.sampleKitOrderSearch?.results!,
              re?.sampleKitOrderSearch?.totalCount
            );
            params.api.refreshHeader();
          }
        });
      },
    };
    const timeout = setTimeout(() => {
      params?.api.setDatasource(dataSource);
    }, 500);
    return () => {
      mount = false;
      clearTimeout(timeout);
    };
  }, []);

  return { orderKitColumnDefs, onGridReady, defaultColDefOrderKit };
};

export default UseCreateOrderKitColumn;
