import React from 'react';
import { useField } from 'formik';
import moment from 'moment';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import * as language from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './datePicker.module.scss';
import defineLang from '../../utils/defineLanguage';
import TooltipLabel from '../tooltipLabel/tooltipLabel';

interface IDatePicker {
  name: string;
  locale?: string;
  disabled?: boolean;
  hideErros?: boolean;
  errorClassName?: string;
  className?: string;
  dateFormat?: string;
  datePickerClass?: string;
  onChange?: (v: Date) => void;
}

const DatePicker: React.FC<IDatePicker> = ({
  name,
  disabled,
  className,
  hideErros,
  errorClassName,
  dateFormat,
  datePickerClass,
  locale,
  onChange,
}) => {
  const [field, meta, form] = useField(name);
  const lang = defineLang();
  if (lang && language[lang]) registerLocale('fi', language[lang]);

  const formatValue = (val: any) => {
    return typeof val === 'string' && val.trim() !== ''
      ? moment(val).toDate()
      : undefined;
  };

  return (
    <>
      <ReactDatePicker
        data-testid={name}
        preventOpenOnFocus={true}
        name={name}
        wrapperClassName={`${styles.datePickerWrapper} ${className}`}
        className={`${styles.datePickerInput} ${datePickerClass}`}
        locale={locale}
        selected={formatValue(field.value)}
        dateFormat={dateFormat}
        onChange={(val: Date) => {
          if (!val || moment.isMoment(val)) return form.setValue(null);
          if (onChange) onChange(val);
          form.setValue(moment(val).format());
        }}
        onBlur={field.onBlur}
        disabled={disabled}
      />
      {meta.error && !hideErros ? (
        <div className={`${styles.error} ${errorClassName}`}>{meta.error}</div>
      ) : null}
    </>
  );
};

DatePicker.defaultProps = {
  disabled: false,
  className: '',
  dateFormat: 'dd.MM.yyyy',
  datePickerClass: '',
  locale: 'fi',
  onChange: undefined,
};

export default DatePicker;
