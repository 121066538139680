export interface IOption {
  value: string;
  label?: string;
  langValue?: string;
}

export const optionInitial: IOption = {
  value: 'null',
  label: '',
  langValue: '',
};
