import {
  IS_LOCKED,
  LOCKED_BY_CURRENT_USER,
  LOCKED_BY_NAME,
  LOCKED_UNTIL,
} from '../../constants/lockKeys';
import { Forms, ScreeningState } from '../../state/screening/state';
import { Tabs } from '../../state/tabs/state';
import { formsQueryList } from './formsQueryHelper';

export const screeningCheckLock = (
  screeningId: number
) => `mutation SubmitscreeningLock {
    getScreeningLockInfo(screeningId: ${screeningId}) {
      ${IS_LOCKED}
      ${LOCKED_BY_NAME}
      ${LOCKED_BY_CURRENT_USER}
      ${LOCKED_UNTIL}
    }
  }`;

export const screeningLock = (screeningId: number, tab: Tabs) => {
  const preliminary = tab === Tabs.Preliminary,
    colonoscopy = tab === Tabs.Scopy,
    colonography = tab === Tabs.TTGraphy,
    analysis = tab === Tabs.AnalysisResult,
    surgical = tab === Tabs.SurgicalProcedure;
  return `mutation SubmitscreeningLock {
    lockScreening(screeningId: ${screeningId}) {
      ${
        preliminary
          ? `${Forms.PRELIMINARY} {
        ${formsQueryList[Forms.PRELIMINARY]}
      }`
          : ''
      }
      ${
        colonoscopy
          ? `${Forms.SCOPY} {
        ${formsQueryList[Forms.SCOPY]}
      }`
          : ''
      }
      ${
        analysis
          ? `${Forms.SCOPY} {
        ${formsQueryList.followUp}
      }`
          : ''
      }
      ${
        colonography
          ? `${Forms.TTGRAPHY} {
        ${formsQueryList[Forms.TTGRAPHY]}
      }`
          : ''
      }
      ${
        surgical
          ? `${Forms.SURGICAL_PROCEDURE} {
          ${formsQueryList[Forms.SURGICAL_PROCEDURE]}
        }`
          : ''
      }
      ${`${Forms.SCREENING_LOCK}:lockInfo {
        ${formsQueryList[Forms.SCREENING_LOCK]}
      }`}
      ${`${Forms.PERSON_LOCK} {
        ${formsQueryList[Forms.PERSON_LOCK]}
      }`}
     
    }
  } `;
};

export const screeningUnlock = (
  screeningId: number
) => `mutation SubmitscreeningLock {
    unlockScreening(screeningId: ${screeningId}) {
      ${IS_LOCKED}
      ${LOCKED_BY_NAME}
      ${LOCKED_BY_CURRENT_USER}
      ${LOCKED_UNTIL}
    }
  }`;
