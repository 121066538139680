import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ROLE_IDS } from '../../constants/adminTabel';
import { rolesAllowedPages } from '../../constantsLists/lists';
import { RootState } from '../../state/rootReducer';
import styles from './menu.module.scss';

const HIDDEN_ROLE_IDS = [5, 6, 7];

const MenuNav: React.FC = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);
  const { user } = useSelector((state: RootState) => state);

  return (
    <>
      <div
        onClick={() => setShow(false)}
        className={`${styles.backdrop} ${!show ? styles.hide : ''}`}
      ></div>
      <div className={styles.container}>
        <button onClick={() => setShow(!show)} className={styles.menuBtn}>
          {t('menuButtonName')}
        </button>
        <div className={`${styles.listContainer} ${!show ? styles.hide : ''}`}>
          {user[ROLE_IDS]?.filter((el) => !HIDDEN_ROLE_IDS.includes(el)).map(
            (el, ind) => (
              <div key={ind}>
                <NavLink
                  exact
                  activeClassName={styles.active}
                  to={rolesAllowedPages[el] ?? '/'}
                >
                  {t(`menuNav.${el}`)}
                </NavLink>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default MenuNav;
