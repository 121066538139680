import { IGetRowsParams } from 'ag-grid-community';
import {
  END_DATE,
  MUNICIPALITY_GROUP_IDS,
  NAME_ID_HAG,
  PERMISSION_STATUS,
  ROLE_IDS,
  START_DATE,
} from '../../constants/adminTabel';
import {
  ANALYSIS_DATE,
  COMMENT,
  COULD_NOT_BE_REACHED_AT,
  FIRSTNAME,
  HANDLER_LIST,
  LASTNAME,
  MUNICIPALITY_ID,
  SCREENING_CODE,
  SCREENING_ID,
  SSN,
  STATUS,
  STATUS_LIST,
} from '../../constants/commonKeys';
import {
  CONTROL_CODE,
  ORDER_AT,
  SAMPLE_KIT_ORDER_ID,
  SAMPLE_KIT_STATUS_ID,
} from '../../constants/orderKitTable';
import { INITIAL_RESULT } from '../../constants/tableColumnsKeys';
import { Forms, ScreeningState } from '../../state/screening/state';
import { needDownload } from '../../utils/isTableReady';

export const searchScreeningQuery = (
  params: IGetRowsParams,
  screeningstate: ScreeningState
) => {
  let query = '';
  if (params.filterModel[INITIAL_RESULT]) {
    query += `${INITIAL_RESULT}: ${params.filterModel[INITIAL_RESULT]}, `;
  }
  if (params.filterModel[COULD_NOT_BE_REACHED_AT]) {
    query += `${COULD_NOT_BE_REACHED_AT}: "${params.filterModel[COULD_NOT_BE_REACHED_AT]}", `;
  }
  if (
    params.filterModel[Forms.HANDLER] &&
    params.filterModel[Forms.HANDLER] !== null
  ) {
    query += `${Forms.HANDLER}: ${params.filterModel[Forms.HANDLER]}, `;
  }
  if (params.filterModel[STATUS] && !isNaN(+params.filterModel[STATUS])) {
    query += `screeningStatusId: ${+params.filterModel[STATUS]}, `;
  }
  if (params.filterModel[SCREENING_CODE]) {
    query += `${SCREENING_CODE}: "${params.filterModel[SCREENING_CODE]}", `;
  }
  if (params.filterModel[SSN]) {
    query += `${SSN}: "${params.filterModel[SSN]}", `;
  }
  if (params.filterModel[COMMENT]) {
    query += `${COMMENT}: "${params.filterModel[COMMENT]}", `;
  }
  if (
    params.filterModel[MUNICIPALITY_ID] &&
    +params.filterModel[MUNICIPALITY_ID]
  ) {
    query += `${MUNICIPALITY_ID}: ${+params.filterModel[MUNICIPALITY_ID]}, `;
  }

  return `query FetchScreeningList {
        screeningSearch(${query} skip: ${params.startRow}) {
          results {
            ${ANALYSIS_DATE}
            ${INITIAL_RESULT}
            ${STATUS}
            ${SCREENING_ID}
            ${SCREENING_CODE}
            ${SSN}
            ${MUNICIPALITY_ID}
            ${COULD_NOT_BE_REACHED_AT}
            ${Forms.HANDLER}
            ${COMMENT}            
          }
          totalCount
        }
        ${needDownload(
          screeningstate,
          STATUS_LIST,
          'SCREENING_PROCESS_STATUS'
        )} 
        ${needDownload(
          screeningstate,
          HANDLER_LIST,
          `handlersWithCommonMunicipalities {
          langValue: firstName
          label:lastName
          value: userId
        }`,
          true
        )}
        ${needDownload(
          screeningstate,
          MUNICIPALITY_ID,
          `municipalities {
    value: municipalityId
    langValue: code
  }`,
          true
        )}

      }
      `;
};

export const fetchExpressScreeningQuery = (
  ssn: string,
  sceeningCode: string
) => {
  return `query FetchScreeningList {
  screeningSearch( screeningCode: "${sceeningCode}", ssn: "${ssn}",  skip: 0) {
    results {
      screeningId  
    }
  }
}
`;
};

export const searchUserQuery = (
  params: IGetRowsParams,
  maxPageNo: number,
  screeningstate: ScreeningState
) => {
  let query = '';
  [
    FIRSTNAME,
    LASTNAME,
    NAME_ID_HAG,
    ROLE_IDS,
    PERMISSION_STATUS,
    START_DATE,
    END_DATE,
  ].forEach((el) => {
    if (params.filterModel[el]) {
      if (el === ROLE_IDS) {
        query += `roleId:${params.filterModel[el]},`;
        return;
      }
      if (el === PERMISSION_STATUS) {
        query += `${el}:${params.filterModel[el]},`;
        return;
      } else {
        query += `${el}:"${params.filterModel[el]}",`;
      }
    }
  });

  return `query FetchUsersList {
    userSearch(page: ${
      params?.startRow || params?.startRow === 0
        ? params?.startRow / maxPageNo
        : 0
    } , search: {${query}}) {
      results {
        endDate
        firstName
        lastName
        modifiedAt
        modifiedBy
        municipalityIds
        nameIdHag
        permissionStatus
        roleIds
        startDate
        userId
      }
      totalCount
    }
    ${needDownload(
      screeningstate,
      ROLE_IDS,
      `roles {
        value:roleId
        langValue: roleId
      }`,
      true
    )} 
    ${needDownload(
      screeningstate,
      MUNICIPALITY_ID,
      `municipalities {
        value: municipalityId
        langValue: code
      }`,
      true
    )} 
    ${needDownload(
      screeningstate,
      MUNICIPALITY_GROUP_IDS,
      `municipalityGroups {
        municipalities {
          value: municipalityId
          langValue: code
        }
        municipalityGroupId
      }`,
      true
    )}
  }`;
};

export const searchKitOrderQuery = (
  state: ScreeningState,
  maxPageNo: number,
  params?: IGetRowsParams
) => {
  const controlCode = params?.filterModel?.[CONTROL_CODE]
    ? `"${params?.filterModel?.[CONTROL_CODE]}"`
    : null;

  let query = `search: {${SAMPLE_KIT_STATUS_ID}:${
    params?.filterModel?.[SAMPLE_KIT_STATUS_ID] || null
  }, ${CONTROL_CODE}:${controlCode}},`;

  if (params?.startRow || params?.startRow === 0) {
    query += `page: ${params?.startRow / maxPageNo}`;
  }

  return `query FetchKitOrderList {
    sampleKitOrderSearch(${query}) {
      results {
        ${CONTROL_CODE}
        ${FIRSTNAME}
        ${LASTNAME}
        ${ORDER_AT}
        ${SAMPLE_KIT_ORDER_ID}
        ${SAMPLE_KIT_STATUS_ID}
        ${SCREENING_CODE}
      }
      totalCount
    }
    ${needDownload(state, SAMPLE_KIT_STATUS_ID, 'SAMPLE_KIT_ORDER_STATUS')} 

  }`;
};

export const kitOrderStatusCUpdate = (statusId: number, orderId: number) => `
mutation kitOrderStatusCUpdate {
  __typename
  updateSampleKitOrderStatus(sampleKitOrderStatus: {sampleKitOrderId: ${orderId}, ${SAMPLE_KIT_STATUS_ID}: ${statusId}})
}
`;
