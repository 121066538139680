import {
  CREATED_AT,
  EMAIL,
  END_DATE,
  EXPIRED_TEXT,
  MODIFIED_AT,
  MODIFIED_BY,
  MUNICIPALITY_IDS,
  NAME_ID_HAG,
  PERMISSION_STATUS,
  PHONE_NUMBER,
  ROLE_IDS,
  START_DATE,
} from '../../constants/adminTabel';
import {
  ASSAY_ABOVE,
  INTERPRETABILITY_BLOCKERS,
  NEGATIVE,
  NO_REPLY,
  POSITIVE,
  RESULT_PLACE,
  SAMPLE_INTERPRETABILITY,
  TEST_RESULT_POSITIVE,
  TEST_VALUE,
} from '../../constants/analysisResultFromKeys';
import {
  ADITIONAL_INFO,
  ANALYSIS_DATE,
  FIRSTNAME,
  FIRST_NAMES,
  COMMENT,
  IS_INERRUPTED,
  LARGEST_POLY_DIAMETER_ID,
  LASTNAME,
  LOCATION,
  MUNICIPALITY_ID,
  NUMBER_POLYP_ID,
  ORGANIZATION,
  SAMPLE_DATE,
  SCREENING_CODE,
  SCREENING_YEAR,
  SSN,
  STATUS,
  SURNAME,
  COULD_NOT_BE_REACHED_AT,
  SAMPLE_ID,
} from '../../constants/commonKeys';
import {
  DIAGNOSES_BY_ID,
  DIAGNOSES_BY_NAME,
  DIAGNOSES_DIAMETER_MM_ID,
  DIAGNOSES_LOCATION_ID,
  DIAGNOSES_MORPHOLOGY_ID,
  MUTATION_HISTOLOGICAL_DIAGNOSIS,
} from '../../constants/disgnosesTabel';
import {
  FOLLOW_UP_BY_ID,
  FOLLOW_UP_BY_NAME,
  FOLLOW_UP_DATE,
  FOLLOW_UP_DECISION_ID,
  FOLLOW_UP_LOCATION,
  FOLLOW_UP_REFERRAL_LOCATION,
} from '../../constants/followUp';
import { LOCKED_BY_CURRENT_USER } from '../../constants/lockKeys';
import {
  CONTROL_CODE,
  ORDER_AT,
  SAMPLE_KIT_STATUS_ID,
} from '../../constants/orderKitTable';
import {
  CONFIDENCE,
  DOES_SMOKE,
  DRINKING_AMOUNT_ID,
  DRINKING_FREQUENCY_ID,
  DRINKING_SIX_FREQUENCY_ID,
  HAS_MEDICINE_SENSITIVITY,
  HAS_RELATIVE_INTESTIONAL_CANCER,
  HAS_SMOKED_DAILY,
  HEIGHT,
  LONG_TERM_ILNESS_IDS,
  MEDICINE_SENSITIVITY,
  REGULAR_MEDICINE_IDS,
  RELATIVE_INTESTIONAL_CANCER_WHO,
  SMOKED_DAILY_AMOUNT_ID,
  SMOKED_DAILY_YEARS_ID,
  WEIGHT,
} from '../../constants/preliminaryFormKeys';
import {
  BOWEL_EMPTY_SUCCESS_ID,
  COMPLICATION_IDS,
  DECISION_BY_NAME,
  DECISION_DATE,
  DECISION_ID,
  DECISION_LOCATION,
  DIAGNOSES_DATE,
  DIAGNOSES_LOCATION,
  FOLLOW_UP_DECISION_ID_COLONSCOPY,
  HAD_INNER_TURN_DONE,
  HAD_INTERVAENOUS_PREMEDICATION,
  OTHER_REASONS,
  PREVIOUS_YEAR,
  SCOPY_DATE,
  SCOPY_DECISION_BY_ID,
  SCOPY_DONE_BY_ID,
  SCOPY_DONE_BY_NAME,
  SCOPY_FINDING_IDS,
  SCOPY_LIMITATION_IDS,
  SCOPY_LOCATION_ID,
  SCOPY_NOT_PERFORMED_REASON,
  SCOPY_OTHER_FINDINGS,
  SCOPY_OTHER_LIMITS,
  SCOPY_PROCEDURE_IDS,
  SCOPY_REACHED_ID,
  SCOPY_REFERRAL_LOCATION,
  SCOPY_RETRIEVAL_MIN,
} from '../../constants/scopyForm';
import {
  CANCER_PTNM_GRADUS_ID,
  CANCER_PTNM_M_ID,
  CANCER_PTNM_N_ID,
  CANCER_PTNM_T_ID,
  SURGICAL_PROCEDURE_BY_ID,
  SURGICAL_PROCEDURE_BY_NAME,
  SURGICAL_PROCEDURE_CODE_ID,
  SURGICAL_PROCEDURE_DATE,
  SURGICAL_PROCEDURE_DIAGNOSES_DATE,
  SURGICAL_PROCEDURE_DIAGNOSES_LOCATION,
  SURGICAL_PROCEDURE_LOCATION,
  SURGICAL_PROCEDURE_PRIMARY_ID,
} from '../../constants/surgicalProcedureFormKeys';
import { INITIAL_RESULT } from '../../constants/tableColumnsKeys';
import {
  CLASSIFOCATION_ID,
  FOLLOW_UP_DECISION_ID_TTGRAPHY,
  MUTAIION_LOCATION_ID,
  OTHER_CLASSIFOCATION_ID,
  RADIOLOGIST_ID,
  RADIOLOGIST_NAME,
  RADIOLOGIST_STATMENT,
  RADIOLOGIST_STATMENT_DATE,
  RADIOLOGIST_STATMENT_LOCATION,
  TTGRAPHY_BY_ID,
  TTGRAPHY_BY_NAME,
  TTGRAPHY_DATE,
  TTGRAPHY_FINDING_IDS,
  TTGRAPHY_LOCATION,
  TTGRAPHY_OTHER_FINDINGS,
} from '../../constants/ttgraphyFromKeys';
import { MOTHER_LANG, PHONE } from '../../constants/userInfoKeys';
import Admin from '../../features/admin/admin';
import { Forms } from '../../state/screening/state';
import { Tabs } from '../../state/tabs/state';

const translationFi = {
  header: {
    adminSearch: 'Etsi, muokkaa tai lisää oikeudet henkilölle',
    title: 'Suolistosyöpien seulontaohjelmisto',
    lastEdit: 'Viimeksi muokannut',
    screenedPerson: 'Seulottu henkilö',
    screenSearch: 'Seulottujen haku',
    orderKitTitle: 'Näytteenottopakkausten tilaukset',
  },
  links: {
    logout: 'Kirjaudu ulos',
  },
  warnings: {
    newRow:
      'Täytä pakolliset kentät edelliselle riville ennen uuden rivin lisäämistä',
    inactiveLogout:
      'Tilisi kirjataan ulos 10 minuutin kuluttua epäaktiivisuuden takia. Voit perua uloskirjautumisen liikuttamalla hiirtäsi.',
  },
  [ROLE_IDS]: {
    null: 'Tyhjennä',
    1: 'Seulontoja tekevä ammattilainen',
    2: 'Näytteenottopakettien lähetyksen käsittelijä',
    3: 'Käyttäjähallinnan pääkäyttäjä',
    4: 'Kunnan raporttien lukija',
    5: 'Esitietojen muokkaaja',
    6: 'HUSLAB',
    7: 'ISLAB',
    8: 'Esitietojen PDF-tuonnin käsittelijä',
  },
  menuButtonName: 'Valikko',

  menuNav: {
    1: 'Seulottujen lista',
    2: 'Näytteenottotilaukset',
    3: 'Luvitus',
    4: 'Raportit',
    8: 'Esitietojen PDF-tuonti',
  },

  [SAMPLE_KIT_STATUS_ID]: {
    '1': 'Ei käsitelty',
    '2': 'Käsitelty',
    '3': 'Hylätty',
    empty: 'Tyhjennä',
  },

  [PERMISSION_STATUS]: {
    null: 'Tyhjennä',
    VOIMASSA: 'Voimassa',
    VANHENTUNUT: 'Vanhentunut',
    PASSIVOITU: 'Passivoitu',
  },
  userForm: {
    adminUserHeader: 'Henkilön oikeudet',
    adminUserRole: 'Rooli',
    active: 'Aktiivinen ',
    inactive: 'Passiivinen ',
    [MODIFIED_BY]: 'Muokkaaja',
    [MODIFIED_AT]: 'Muokkauspvm',
    [CREATED_AT]: 'Luotu pvm',
    [FIRSTNAME]: 'Etunimi',
    [LASTNAME]: 'Sukunimi',
    [NAME_ID_HAG]: 'HAG-ID',
    [ORGANIZATION]: 'Henkilön organisaatio',
    [EMAIL]: 'Sähköposti',
    [PHONE_NUMBER]: 'Puhelinnumero',
    [START_DATE]: 'Oikeuksien alkupäivä',
    [END_DATE]: 'Oikeuksien loppupäivä',
    [MUNICIPALITY_IDS]: 'Henkilölle luvitetut kunnat',
    [EXPIRED_TEXT]:
      'Anna oikeudet myös kuntaan liitettyihin vanhentuneisiin kuntiin, koska niihin saattaa olla vielä viittauksia.',
    rol1: 'Seulontoja tekevä ammattilainen',
    role2: 'Näytteenottopakettien lähetystä käsittelevä ammattilainen',
    [COMMENT]: 'Kommentti',
  },
  announcementsBox: {
    title: 'Ilmoitukset',
  },
  errors: {
    [DIAGNOSES_MORPHOLOGY_ID]: 'Muutoksen morfologia on pakollinen tieto',
    noRoles: 'Sinulla ei joko ole tilillesi osoitettua roolia',
    noScreeningResult: 'Haullasi ei löytynyt yhtään henkilöä',
    screeningLockedByOther:
      'Kohde on lukittuna käyttäjälle {{user}} {{time}} asti',
    [Tabs.Preliminary]: 'Esitietoja ei voitu hakea',
    [Forms.PRELIMINARY]: 'Esitietoja ei voitu lähettää palvelimelle',
    [Tabs.AnalysisResult]: 'Testin analyysiä ja tulosta ei voitu hakea',
    [Tabs.Scopy]: 'Kolonoskopia tietoja ei voitu hakea',
    [Forms.SCOPY]: 'Tiedon päivittäminen epäonnistui',
    [Tabs.TTGraphy]: 'TT-kolongrafian tietoja ei voitu hakea',
    [Forms.TTGRAPHY]: 'Tiedon päivittäminen epäonnistui',
    [Tabs.SurgicalProcedure]:
      'Kirurgia tai hoitotoimenpide tietoja ei voitu hakea',
    [Forms.SURGICAL_PROCEDURE]: 'Tiedon päivittäminen epäonnistui',
    [Forms.PERSON]: 'Tiedon päivittäminen epäonnistui',
    [Forms.STATUS]: 'Tiedon päivittäminen epäonnistui',
    [Forms.HANDLER]: 'Tiedon päivittäminen epäonnistui',
    screeningId: 'Seulontatunnus ei kelpaa',
    currentUserData: 'Käyttäjätietoja ei voitu hakea',
    userAuth: 'Käyttäjätietoja ei löydy, koska käyttäjää ei ole todennettu',
    userAuthExp: 'Istuntosi on vanhentunut. Ole hyvä ja kirjaudu uudelleen',
    colonographyDataSubmit:
      'TT-kolongrafian tietoja ei voitu lähettää palvelimelle',
    deleteRow: 'Oletko varma, että haluat poistaa diagnoosin?',
    personSearch: 'Tiedon päivittäminen epäonnistui',
    userSearch: 'Tiedon päivittäminen epäonnistui',
    userDetail: 'Tiedon päivittäminen epäonnistui',
    reportConfigs: 'Tiedon päivittäminen epäonnistui',
    lockedObject:
      'Et voi muuttaa tätä tietoa, koska kohde on lukittuna käyttäjälle {{name}}',
    changedObject:
      'Päivittäminen epäonnistui. Tarkastelemasi tieto ei ole ajan tasalla.',
  },
  success: {
    [Forms.PRELIMINARY]: 'Tiedon päivittäminen onnistui',
    [Forms.SCOPY]: 'Tiedon päivittäminen onnistui',
    [Forms.TTGRAPHY]: 'Tiedon päivittäminen onnistui',
    [Forms.SURGICAL_PROCEDURE]: 'Tiedon päivittäminen onnistui',
    [Forms.PERSON]: 'Tiedon päivittäminen onnistui',
    [Forms.STATUS]: 'Tiedon päivittäminen onnistui',
    [Forms.HANDLER]: 'Tiedon päivittäminen onnistui',
    [LOCKED_BY_CURRENT_USER]: 'Kohde on lukittuna sinulle {{time}} asti',
    updateKitOrderStatus: 'Tiedon päivittäminen onnistui',
  },

  breadCrumbs: {
    home: 'Etusivu ',
    user: 'Henkilön oikeudet',
  },
  [LARGEST_POLY_DIAMETER_ID]: {
    '2': '<10 mm',
    '3': '10-20 mm',
    '4': '> 20 mm',
  },
  tabs: {
    [Tabs.Preliminary]: 'Esitiedot',
    [Tabs.Scopy]: 'Kolonoskopia',
    [Tabs.AnalysisResult]: 'Testin analyysi ja tulos',
    [Tabs.SurgicalProcedure]: 'Kirurgia tai hoitotoimenpide',
    [Tabs.TTGraphy]: 'TT-kolongrafia',
  },
  [FOLLOW_UP_DECISION_ID_TTGRAPHY]: {
    '0': 'Ei jatkotutkimuksia',
    '1': 'Tutkimus uusitaan (ei koske vuosikontrolleja)',
    '2': 'Muu löydöksen edellyttämä perusterveydenhuollon tutkimus',
    '3': 'Lähete erikoissairaanhoitoon',
    '4': 'Muu seuranta tai jatkohoito (esim vuosikontrollit)',
  },
  [FOLLOW_UP_DECISION_ID_COLONSCOPY]: {
    '0': 'Ei jatkotutkimuksia',
    '1': 'Tutkimus uusitaan (ei koske vuosikontrolleja)',
    '2': 'Tehdään TT-kolongrafia',
    '3': 'Lähete erikoissairaanhoitoon',
    '4': 'Muu seuranta tai jatkohoito (esim vuosikontrollit)',
  },
  [CANCER_PTNM_M_ID]: {
    '1': 'Mx',
    '2': 'M0',
    '3': 'M1',
    '31': 'M1a',
    '32': 'M1b',
    '33': 'M1c',
  },
  [CANCER_PTNM_GRADUS_ID]: {
    '1': 'Gradus I',
    '2': 'Matala-asteinen',
    '3': 'Korkea-asteinen',
    '4': 'Erilaistumaton',
  },
  [CANCER_PTNM_N_ID]: {
    '1': 'Nx',
    '2': 'N0',
    '3': 'N1',
    '4': 'N2',
    '41': 'N2a',
    '42': 'N2b',
  },
  [CANCER_PTNM_T_ID]: {
    '1': 'Tx',
    '3': 'Tis',
    '4': 'T1',
    '5': 'T2',
    '6': 'T3',
    '7': 'T4',
    '71': 'T4a',
    '72': 'T4b',
    '73': 'T4c',
    '74': 'T4d',
  },

  personFrom: {
    [SSN]: 'Henkilötunnus',
    [Forms.HANDLER]: 'Käsittelijä',
    [FIRST_NAMES]: 'Etunimi',
    [SURNAME]: 'Sukunimi',
    [MOTHER_LANG]: 'Asiointikieli',
    [PHONE]: 'Puhelinnumero',
    [SCREENING_CODE]: 'Seulontatunnus',
    [STATUS]: 'Käsittelyn tila ',
    [COMMENT]: 'Kommentti',
    [COULD_NOT_BE_REACHED_AT]: 'Ei tavoitettu, pvm',
  },
  analysisResultForm: {
    info: {},
    [SAMPLE_INTERPRETABILITY]: 'Näytteen tulkittavuus',
    [INTERPRETABILITY_BLOCKERS]: 'Näytteen tulkittavuutta haittaavat seikat',
    [TEST_VALUE]: 'Testin arvo (µg/g)',
    [TEST_RESULT_POSITIVE]: 'Testin tulos',
    [ASSAY_ABOVE]: 'Määritysrajan yläpuolella',
    [ANALYSIS_DATE]: 'Päivämäärä',
    [RESULT_PLACE]: 'Paikka',
  },
  scopyForm: {
    moreInfo: 'Lisätietoja',
    info: {
      [SCOPY_DECISION_BY_ID]:
        'Jatkotutkimuspäätöksen tehneen henkilön tiedot ensisijaisesti henkilön yksilöivänä Valviran rekisterinumerona tai SV-numerona. Toissijaisesti nimenä (sukunimi, etunimi).',
      [DECISION_BY_NAME]:
        'Jatkotutkimuspäätöksen tehneen henkilön tiedot ensisijaisesti henkilön yksilöivänä Valviran rekisterinumerona tai SV-numerona. Toissijaisesti nimenä (sukunimi, etunimi).',
      [DECISION_DATE]:
        'Päivämäärä, jolloin päätös jatkotoimenpiteestä on tehty.',
      [DECISION_LOCATION]: 'Päätöksentekijän organisaatio.',
      [SCOPY_REFERRAL_LOCATION]:
        'Paikka, jonne lähete erikoissairaanhoitoon/kolonoskopiaan/TT-kolongrafiaan tehdään. Jos paikka ei ole tiedossa, valitse listalta "Tuntematon"',
      [SCOPY_DATE]: 'Päivämäärä, jolloin kolonoskopia tehtiin.',
      [SCOPY_LOCATION_ID]: 'Paikka, jossa kolonoskopia tehtiin.',
      [SCOPY_DONE_BY_ID]:
        'Jos tiedossa. Kolonoskopian tehneen endoskopistin tiedot ensisijaisesti henkilön yksilöivänä Valviran rekisterinumerona tai SV-numerona. Toissijaisesti nimenä (sukunimi, etunimi).',
      [SCOPY_DONE_BY_NAME]:
        'Kolonoskopian tehneen endoskopistin tiedot ensisijaisesti henkilön yksilöivänä Valviran rekisterinumerona tai SV-numerona. Toissijaisesti nimenä (sukunimi, etunimi).',
      [HAD_INTERVAENOUS_PREMEDICATION]:
        'Tieto siitä, annettiinko potilaalle suonensisäinen esilääkitys.',
      [SCOPY_RETRIEVAL_MIN]:
        'Tieto kolonoskopian poisvetoajan kestosta kokonaislukuna minuutin tarkkuudella.',
      [HAD_INNER_TURN_DONE]:
        'Tieto siitä, tehtiinkö kolonoskopiassa näkyvyyttä parantava suolen sisäkäännös.',
      [BOWEL_EMPTY_SUCCESS_ID]:
        'Tieto siitä, oliko kolonoskopiaa varten tehty suolentyhjennys hyvä, riittävä, epätäydellinen vai riittämätön.',
      [SCOPY_REACHED_ID]:
        'Tieto siitä, kuinka pitkälle tähystin saatiin vietyä.',
      [SCOPY_LIMITATION_IDS]:
        'Tieto kaikista kolonoskopian tekoa rajoittaneista seikoista. Jos syytä ei ole listalla, valitaan ”Muu syy” ja syötetään syy omaan kenttäänsä.',
      [SCOPY_FINDING_IDS]:
        'Tieto kaikista kolonoskopian aikana tehdyistä löydöksistä. Jos löydöstä ei ole listalla, valitaan ”Muu” ja syötetään muu löydös omaan kenttäänsä. Normaali löydös valitaan, kun mitään muita listalla olevia löydöksiä ei tehty.',
      [NUMBER_POLYP_ID]:
        'Tieto siitä, kuinka monta polyyppia kolonoskopiassa löydettiin.',
      [LARGEST_POLY_DIAMETER_ID]:
        'Suurimman löydetyn polyypin tai tuumorin läpimitta millimetreinä.',
      [SCOPY_PROCEDURE_IDS]:
        'Tieto kaikista kolonoskopiassa tehdyistä toimenpiteistä.',
      [COMPLICATION_IDS]: 'Tieto kolonoskopian komplikaatioista.',
      [ADITIONAL_INFO]:
        'Seulonnan kannalta olennaiset tiedot, jotka eivät ilmene muista kentistä.',
      [DIAGNOSES_DATE]: 'Päivämäärä, jolloin patologi on lausunut näytteet.',
      [DIAGNOSES_LOCATION]:
        'Paikka, jossa näytteet on lausuttu (patologian laboratorio).',
      [DIAGNOSES_BY_NAME]:
        'Näytteet lausuneen patologin tiedot ensisijaisesti henkilön yksilöivänä Valviran rekisterinumerona tai SV-numerona. Toissijaisesti nimenä (sukunimi, etunimi).',
      [DIAGNOSES_BY_ID]:
        'Näytteet lausuneen patologin tiedot ensisijaisesti henkilön yksilöivänä Valviran rekisterinumerona tai SV-numerona. Toissijaisesti nimenä (sukunimi, etunimi).',
      [MUTATION_HISTOLOGICAL_DIAGNOSIS]:
        'Tähän voi kopioida patologian koko lausunnon.',
      [FOLLOW_UP_REFERRAL_LOCATION]:
        'Paikka, jonne lähete erikoissairaanhoitoon/kolonoskopiaan/TT-kolongrafiaan tehdään. Jos paikka ei ole tiedossa, valitse listalta "Tuntematon"',
      [FOLLOW_UP_DATE]: 'Päivämäärä, jolloin jatkotoimenpidepäätös on tehty.',
      [FOLLOW_UP_LOCATION]:
        'Paikka, jossa päätös jatkotoimenpiteestä on tehty.',
      [FOLLOW_UP_BY_ID]:
        'Jatkotutkimuspäätöksen tehneen henkilön tiedot ensisijaisesti henkilön yksilöivänä Valviran rekisterinumerona tai SV-numerona. Toissijaisesti nimenä (sukunimi, etunimi).',
      [FOLLOW_UP_BY_NAME]:
        'Jatkotutkimuspäätöksen tehneen henkilön tiedot ensisijaisesti henkilön yksilöivänä Valviran rekisterinumerona tai SV-numerona. Toissijaisesti nimenä (sukunimi, etunimi).',
      [SCOPY_NOT_PERFORMED_REASON]:
        'Kun kolonoskopiaa ei tehdä, tulee raportoida syy sen tekemättä jättämiselle.',
      [PREVIOUS_YEAR]: 'Vuosi, jolloin viimeisin kolonoskopia on tehty.',
      [OTHER_REASONS]: 'Muun syyn selite.',
      [FOLLOW_UP_DECISION_ID]:
        '<div class="info-box-with-html"><p>Valitaan listalta seulonnan jatkotoimenpidepäätös tai seulonnan päättyminen (ei jatkotutkimuksia).</p><ul><li>Jos tehdään jatkotoimenpiteenä uusi kolonoskopia, valitaan ”Tutkimus uusitaan (ei koske vuosikontrolleja)”.</li><li>Jos tehdään jatkotoimenpiteenä TT-kolongrafia, valitaan ”Tehdään TT-kolongrafia”.</li><li>Jos kolonoskopiassa otetun koepalan tulos edellyttää erikoissairaanhoitoa (pahanlaatuinen löydös), valitaan ”Lähete erikoissairaanhoitoon”.</li><li>Jos kolonoskopiassa otetun koepalan tulos ei edellytä välitöntä hoitoa, valitaan ”Ei jatkotutkimuksia” tai ”Muu seuranta tai jatkohoito (esim vuosikontrollit)”.</li><li>Jos kolonoskopiassa ei otettu koepaloja (näkymä normaali), valitaan ”Ei jatkotutkimuksia” tai ”Muu seuranta tai jatkohoito (esim vuosikontrollit)”.</li></ul></div>',
      decisionFollow:
        'Kun positiivisen testituloksen saaneeseen henkilöön on oltu yhteydessä puhelimitse, valitaan puhelun perusteella tehty jatkotoimenpidepäätös.',
      diagnoses:
        'Kolonoskopiassa otettujen patologisten näytteiden tiedoissa raportoidaan patologin vastaus kustakin näytteestä. Tiedot kerätään patologian välilehdeltä potilaskertomuksesta. Huom. Jokainen lausuttu näyte lisätään omana diagnoosinaan.',
      followUp:
        'Jatkotoimenpidepäätös raportoidaan aina, vaikkei histologisia näytteitä olisi otettu kolonoskopiassa.',
    },
    [IS_INERRUPTED]: 'Kolonoskopiaa ei voitu aloittaa',
    [SCOPY_NOT_PERFORMED_REASON]: 'Kolonoskopiaa ei tehty, koska',
    [PREVIOUS_YEAR]: 'Vuonna',
    [OTHER_REASONS]: 'Muu syy, mikä?',
    [FOLLOW_UP_BY_ID]: 'Päättäjän SV-/Valvira-nro (jos tiedossa)',
    [SCOPY_REFERRAL_LOCATION]: 'Lähete paikkaan',
    [FOLLOW_UP_BY_NAME]: 'Päättäjän nimi',
    [FOLLOW_UP_DATE]: 'Päivämäärä',
    [SCOPY_DATE]: 'Tutkimuspäivämäärä',
    [SCOPY_LOCATION_ID]: 'Paikka',
    [SCOPY_PROCEDURE_IDS]: 'Toimenpiteet',
    [SCOPY_DONE_BY_ID]: 'Skopistin/päättäjän SV-/Valvira-nro',
    [SCOPY_DONE_BY_NAME]: 'Skopistin/päättäjän nimi',
    [FOLLOW_UP_LOCATION]: 'Paikka',
    decisionFollow: 'Seulontatestin jatkotoimenpidepäätös',
    diagnoses: 'Kolonoskopiassa otettujen patologisten näytteiden diagnoosit',
    diagnosesDesc:
      'Päivämäärän ja paikan lisäksi on syötettävä vähintään yksi diagnoosi',
    colonoscopy: 'Kolonoskopia',
    colonscopyDesc:
      'Päivämäärän ja paikan lisäksi on syötettävä vähintään yksi muu tieto',
    followUp: 'Kolonoskopian jatkotoimenpidepäätös',
    [FOLLOW_UP_REFERRAL_LOCATION]: 'Lähete paikkaan',
    [DECISION_DATE]: 'Päätöspäivämäärä',
    [DECISION_LOCATION]: 'Paikka',
    [SCOPY_DECISION_BY_ID]: 'Päättäjän SV-/Valvira-nro (jos tiedossa)',
    [DECISION_BY_NAME]: 'Päättäjän nimi',
    [HAD_INTERVAENOUS_PREMEDICATION]: 'Suonensisäinen esilääkitys annettu',
    [SCOPY_RETRIEVAL_MIN]: 'Kolonoskopian poisvetoaika (min)',
    [HAD_INNER_TURN_DONE]: 'Sisäkäännös tehty',
    [BOWEL_EMPTY_SUCCESS_ID]: 'Suolentyhjennyksen onnistuminen',
    [SCOPY_REACHED_ID]: 'Mihin asti kolonoskopiassa päästiin',
    [SCOPY_LIMITATION_IDS]: 'Kolonoskopiaa rajoittavat seikat',
    [SCOPY_OTHER_LIMITS]: 'Mikä muu syy',
    [SCOPY_OTHER_FINDINGS]: 'Muu löydös, mikä?',
    [ADITIONAL_INFO]: 'Lisätietoja',
    [SCOPY_FINDING_IDS]: 'Löydökset',
    [NUMBER_POLYP_ID]: 'Polyyppien määrä',
    [LARGEST_POLY_DIAMETER_ID]: 'Suurimman polyypin/muutoksen läpimitta',
    [COMPLICATION_IDS]: 'Komplikaatiot',
    [DIAGNOSES_DATE]: 'Diagnoosin päivämäärä ',
    [DIAGNOSES_LOCATION]: 'Diagnoosipaikka',
    [DIAGNOSES_BY_ID]: 'Patologin SV-numero (jos tiedossa)',
    [DIAGNOSES_BY_NAME]: 'Patologin nimi',
    [MUTATION_HISTOLOGICAL_DIAGNOSIS]: 'Muutosten PAD-lausunnot sanallisesti',
    [FOLLOW_UP_DECISION_ID]: 'Jatkotoimenpidepäätös',
  },
  [SURGICAL_PROCEDURE_PRIMARY_ID]: {
    '1': 'Kirurgia tehty',
    '2': 'Kirurgiaa ei tehty',
    '3': 'Neoadjuvantti ensisijaisena hoitona',
  },
  [SURGICAL_PROCEDURE_CODE_ID]: {
    A: 'Hermosto',
    AA: 'Kallo ja kallon sisäiset elimet',
    AA_: 'Pään diagnostinen radiologia',
    AA1AA: 'Kallon röntgen',
  },

  surgicalProcedure: {
    info: {
      [SURGICAL_PROCEDURE_PRIMARY_ID]:
        'Tieto siitä, tehtiinkö ensisijaisena hoitona kirurgia vai neoadjuvanttihoito. Vain, jos mitään hoitotoimenpidettä ei tehdä, valitaan ”Kirurgiaa ei tehty”.',
      [SURGICAL_PROCEDURE_DATE]:
        'Päivä, jolloin kirurginen toimenpide tehtiin.',
      [SURGICAL_PROCEDURE_LOCATION]:
        'Paikka, jossa kirurginen toimenpide tehtiin.',
      [SURGICAL_PROCEDURE_BY_ID]:
        'Kirurgisen toimenpiteen suorittaneen kirurgin tiedot ensisijaisesti henkilön yksilöivänä SV-numerona. Toissijaisesti nimenä (sukunimi, etunimi).',
      [SURGICAL_PROCEDURE_BY_NAME]:
        'Kirurgisen toimenpiteen suorittaneen kirurgin tiedot ensisijaisesti henkilön yksilöivänä SV-numerona. Toissijaisesti nimenä (sukunimi, etunimi).',
      [ADITIONAL_INFO]:
        'Tähän voi täyttää kaikki sellaiset kirurgiaan liittyvät, seulonnan kannalta olennaiset lisätiedot, jotka eivät ilmene muista kentistä/joita ei voi rakenteellisessa muodossa ilmoittaa ja jotka mahdollisesti selittävät potilaan poikkeavan polun.',
      [SURGICAL_PROCEDURE_DIAGNOSES_DATE]:
        'Päivämäärä, jolloin PAD-lausunto on annettu.',
      [SURGICAL_PROCEDURE_DIAGNOSES_LOCATION]:
        'Paikka, jossa näytteet on lausuttu (patologian laboratorio).',
      [DIAGNOSES_BY_ID]:
        'Näytteet lausuneen patologin tiedot ensisijaisesti henkilön yksilöivänä Valviran rekisterinumerona tai SV-numerona. Toissijaisesti nimenä (sukunimi, etunimi).',
      [DIAGNOSES_BY_NAME]:
        'Näytteet lausuneen patologin tiedot ensisijaisesti henkilön yksilöivänä Valviran rekisterinumerona tai SV-numerona. Toissijaisesti nimenä (sukunimi, etunimi).',
      [MUTATION_HISTOLOGICAL_DIAGNOSIS]:
        'Tähän voi kopioida patologian koko lausunnon.',
      [CANCER_PTNM_T_ID]: 'Primaarituumorin luokitus',
      [CANCER_PTNM_N_ID]: 'Imusolmukkeiden luokitus',
      [CANCER_PTNM_M_ID]: 'Etäpesäkkeiden luokitus',
      [CANCER_PTNM_GRADUS_ID]:
        'Kasvaimen gradus. Raportoidaan vain, jos kirurgia on tehty.',
    },
    diagnosesDesc:
      'Päivämäärän ja paikan lisäksi on syötettävä vähintään yksi diagnoosi',
    pathologicalSpecimens:
      'Kirurgiassa tai hoitotoimenpidevaiheessa otettujen patologisten näytteiden tiedot ',
    pTNMClassification: 'Syövän levinneisyys pTNM-luokituksen mukaisesti',
    [SURGICAL_PROCEDURE_DATE]: 'Leikkaus- tai päätöksentekopäivämäärä',
    [SURGICAL_PROCEDURE_LOCATION]: 'Leikkaus- tai päätöksentekopaikka',
    [SURGICAL_PROCEDURE_BY_ID]: 'Kirurgin SV-numero (jos tiedossa)',
    [SURGICAL_PROCEDURE_BY_NAME]: 'Kirurgin nimi',
    [ADITIONAL_INFO]: 'Lisätietoa kirurgiasta tai hoitotoimenpiteestä',
    [SURGICAL_PROCEDURE_DIAGNOSES_DATE]: 'Diagnoosin päivämäärä ',
    [SURGICAL_PROCEDURE_DIAGNOSES_LOCATION]: 'Diagnoosipaikka',
    [DIAGNOSES_BY_ID]: 'Patologin SV-numero (jos tiedossa)',
    [DIAGNOSES_BY_NAME]: 'Patologin nimi',
    [CANCER_PTNM_T_ID]: 'T',
    [CANCER_PTNM_N_ID]: 'N',
    [CANCER_PTNM_M_ID]: 'M',
    [SURGICAL_PROCEDURE_CODE_ID]: 'Toimenpidekoodi',
    [SURGICAL_PROCEDURE_PRIMARY_ID]:
      'Kirurginen toimenpide tehty ensisijaisena hoitona',
    [CANCER_PTNM_GRADUS_ID]: 'Gradus',
    [MUTATION_HISTOLOGICAL_DIAGNOSIS]: 'Muutosten PAD-lausunnot sanallisesti',
  },
  [DECISION_ID]: {
    doScopy: 'Tehdään kolonoskopia',
    doColonosgraphy: 'Tehdään TT-kolongrafia',
    noContinuation: 'Ei jatkotutkimuksia',
  },
  [COMPLICATION_IDS]: {
    '1': 'Kipu',
    '2': 'Vuoto',
    '3': 'Perforaatio',
    '4': 'Kardiovaskulaarinen',
  },

  [NUMBER_POLYP_ID]: {
    '1': '1-3',
    '2': '4-10',
    '3': 'yli 10',
  },
  [SCOPY_LIMITATION_IDS]: {
    '1': 'Tukkiva tuumori',
    '2': 'Kipu',
    '3': 'Riittämätön tyhjennys/retentio',
    '4': 'Potilaan huono kunto',
    '5': 'Muu syy',
  },
  [SCOPY_FINDING_IDS]: {
    '1': 'Normaali löydös',
    '2': 'Tuumori',
    '3': 'Polyyppi',
    '4': 'Ahtauma',
    '5': 'Koliitti',
    '6': 'Divertikkelit',
    '7': 'Peräpukamat',
    '8': 'Muu',
  },
  [SCOPY_REACHED_ID]: {
    '1': 'Ileum terminale',
    '2': 'Caecum',
    '21': 'Colon ascendens',
    '3': 'Fleksura hepatica',
    '4': 'Colon transversum',
    '5': 'Fleksura  lienalis',
    '51': 'Colon descendens',
    '6': 'Sigma',
    '7': 'Rectum',
  },
  [BOWEL_EMPTY_SUCCESS_ID]: {
    '1': 'Hyvä',
    '2': 'Riittävä',
    '3': 'Epätäydellinen',
    '4': 'Riittämätön',
  },
  [LOCATION]: {
    '1.2.246.10.0132239.10.0': 'Euran kunta',
    '1.2.246.10.0187690.10.0': 'Oulun kaupunki',
    '1.2.246.10.01901003.10.0': 'Taivalkosken kunta',
    '1.2.246.10.02051258.10.0': 'Sunds Kommun',
  },

  [HAD_INTERVAENOUS_PREMEDICATION]: {
    true: 'Kyllä',
    false: 'Ei',
  },
  [HAD_INNER_TURN_DONE]: {
    true: 'Kyllä',
    false: 'Ei',
  },
  colongraphForm: {
    info: {
      [TTGRAPHY_DATE]: 'Päivämäärä, jolloin TT-kolongrafia tehtiin.',
      [TTGRAPHY_LOCATION]: 'Paikka, jossa TT-kolongrafia tehtiin.',
      [TTGRAPHY_FINDING_IDS]:
        'Tieto kaikista TT-kolongrafian aikana tehdyistä löydöksistä. Jos löydöstä ei ole listalla, valitaan ”muu” ja syötetään muu löydös omaan kenttäänsä. Normaali löydös valitaan, kun mitään muita listalla olevia löydöksiä ei tehty.',
      [MUTAIION_LOCATION_ID]:
        'Tieto siitä, missä suolen osassa polyyppi tai tuumori sijaitsee.',
      [NUMBER_POLYP_ID]:
        'Tieto siitä, kuinka monta polyyppia TT-kolongrafiassa löydettiin.',
      [LARGEST_POLY_DIAMETER_ID]:
        'Suurimman löydetyn polyypin tai tuumorin läpimitta millimetreinä.',
      [CLASSIFOCATION_ID]: 'Paksusuolen löydösten luokitus (C-RADS).',
      [OTHER_CLASSIFOCATION_ID]:
        'Paksusuolen ulkopuolella näkyvien löydösten luokitus (C-RADS).',
      [RADIOLOGIST_STATMENT]: 'Tähän voi kopioida radiologin lausunnon.',
      [RADIOLOGIST_STATMENT_DATE]:
        'Päivämäärä, jolloin TT-kolongrafian lausunto tehtiin.',
      [RADIOLOGIST_STATMENT_LOCATION]:
        'Paikka, jossa TT-kolongrafian lausunto tehtiin.',
      [RADIOLOGIST_ID]:
        'TT-kolongrafian kuvat lausuneen radiologin tiedot ensisijaisesti henkilön yksilöivänä Valviran rekisterinumerona tai SV-numerona. Toissijaisesti nimenä (sukunimi, etunimi).',
      [RADIOLOGIST_NAME]:
        'TT-kolongrafian kuvat lausuneen radiologin tiedot ensisijaisesti henkilön yksilöivänä Valviran rekisterinumerona tai SV-numerona. Toissijaisesti nimenä (sukunimi, etunimi).',
      [FOLLOW_UP_DECISION_ID]:
        '<div class="info-box-with-html"><p>Valitaan listalta seulonnan jatkotoimenpidepäätös tai seulonnan päättyminen (ei jatkotutkimuksia).</p><ul><li>Jos tehdään jatkotoimenpiteenä uusi TT-kolongrafia, valitaan ”Tutkimus uusitaan (ei koske vuosikontrolleja)”.</li><li>Jos tehdään jatkotoimenpiteenä kolonoskopia, valitaan ”Muu löydöksen edellyttämä perusterveydenhuollon tutkimus”.</li><li>Jos TT-kolongrafian löydös edellyttää erikoissairaanhoitoa (pahanlaatuinen löydös), valitaan ”Lähete erikoissairaanhoitoon”.</li><li>Jos TT-kolongrafian löydös ei edellytä välitöntä hoitoa, valitaan ”Ei jatkotutkimuksia” tai ”Muu seuranta tai jatkohoito (esim vuosikontrollit)”.</li><ul><div>',
      [FOLLOW_UP_REFERRAL_LOCATION]:
        'Paikka, jonne lähete erikoissairaanhoitoon/kolonoskopiaan/TT-kolongrafiaan tehdään. Jos paikka ei ole tiedossa, valitse listalta "Tuntematon"',
      [FOLLOW_UP_DATE]: 'Päivämäärä, jolloin jatkotoimenpidepäätös on tehty.',
      [FOLLOW_UP_LOCATION]:
        'Paikka, jossa päätös jatkotoimenpiteestä on tehty.',
      [FOLLOW_UP_BY_ID]:
        'Jatkotutkimuspäätöksen tehneen henkilön tiedot ensisijaisesti henkilön yksilöivänä Valviran rekisterinumerona tai SV-numerona. Toissijaisesti nimenä (sukunimi, etunimi).',
      [FOLLOW_UP_BY_NAME]:
        'Jatkotutkimuspäätöksen tehneen henkilön tiedot ensisijaisesti henkilön yksilöivänä Valviran rekisterinumerona tai SV-numerona. Toissijaisesti nimenä (sukunimi, etunimi).',
      [ADITIONAL_INFO]:
        'Seulonnan kannalta olennaiset lisätiedot, jotka eivät ilmene muista kentistä.',
      followUp: 'Jatkotoimenpidepäätös raportoidaan aina.',
    },
    pageInfo:
      'TT-kolongrafian tiedot kerätään kuvantamisen/radiologian välilehdeltä potilaskertomuksesta.',
    [IS_INERRUPTED]: 'TT-kolongrafiaa ei voitu aloittaa',
    [TTGRAPHY_DATE]: 'Päivämäärä',
    [TTGRAPHY_LOCATION]: 'Paikka',
    [TTGRAPHY_FINDING_IDS]: 'Löydökset',
    [MUTAIION_LOCATION_ID]: 'Muutoksen sijainti',
    [NUMBER_POLYP_ID]: 'Polyyppien määrä',
    [ADITIONAL_INFO]: 'Lisätietoja',
    [TTGRAPHY_OTHER_FINDINGS]: 'Muu löydös mikä',
    [LARGEST_POLY_DIAMETER_ID]: 'Suurimman polyypin/muutoksen läpimitta',
    [CLASSIFOCATION_ID]: 'Paksusuolen löydösten luokitus',
    [OTHER_CLASSIFOCATION_ID]:
      'Paksusuolen ulkopuolella näkyvien löydösten luokitus',
    [RADIOLOGIST_STATMENT]: 'Radiologin lausunto',
    [RADIOLOGIST_STATMENT_DATE]: 'Lausunnon päivämäärä ',
    [RADIOLOGIST_STATMENT_LOCATION]: 'Paikka',
    [RADIOLOGIST_ID]: 'Radiologin SV-numero (jos tiedossa)',
    [RADIOLOGIST_NAME]: 'Radiologin nimi',
    followUp: 'TT-kolongrafian jatkotoimenpidepäätös',
    summary: 'Yhteenveto (C-RADS)',
    summaryDesc:
      'Lausunnon päivämäärän ja paikan lisäksi on syötettävä vähintään yksi muu tieto',
    [FOLLOW_UP_DECISION_ID]: 'Jatkotoimenpidepäätös',
    [FOLLOW_UP_REFERRAL_LOCATION]: 'Lähete paikkaan',
    [FOLLOW_UP_DATE]: 'Päätöspäivämäärä ',
    [FOLLOW_UP_LOCATION]: 'Paikka',
    [FOLLOW_UP_BY_ID]: 'Päättäjän SV-/Valvira-nro (jos tiedossa)',
    [FOLLOW_UP_BY_NAME]: 'Päättäjän nimi',
    [TTGRAPHY_BY_ID]: 'Päättäjän SV-/Valvira-nro (jos tiedossa)',
    [TTGRAPHY_BY_NAME]: 'Päättäjän nimi',
  },
  preliminaryForm: {
    [SCREENING_CODE]: 'Seulontatunnus',
    [HEIGHT]: 'Pituus (cm)',
    [WEIGHT]: 'Paino (kg)',
    info: {},
    [LONG_TERM_ILNESS_IDS]: 'Onko asiakkaalla seuraavia pitkäaikaissairauksia?',
    [HAS_RELATIVE_INTESTIONAL_CANCER]:
      'Onko asiakkaan sukulaisilla todettu paksusuolensyöpää tai peräsuolisyöpää?',
    [RELATIVE_INTESTIONAL_CANCER_WHO]: 'Kuka sukulainen',
    [REGULAR_MEDICINE_IDS]:
      'Käyttääkö asiakas seuraavia lääkkeitä säännöllisesti eli vähintään kerran viikossa?',
    [HAS_MEDICINE_SENSITIVITY]: 'Onko asiakkaalla lääkeaineyliherkkyyttä?',
    [MEDICINE_SENSITIVITY]: 'Mikä lääkeaine',
    [DOES_SMOKE]:
      'Polttaako asiakas nykyisin päivittäin tupakkaa, sikareita tai piippua?',
    [SMOKED_DAILY_YEARS_ID]: 'Yhteensä vuotta',
    [SMOKED_DAILY_AMOUNT_ID]: 'Savuketta/piipullista päivässä',
    [HAS_SMOKED_DAILY]:
      'Onko asiakas koskaan polttanut vähintään vuoden ajan päivittäin tupakkaa, sikareita tai piippua?',
    [DRINKING_AMOUNT_ID]: 'Annosta alkoholia päivässä',
    [DRINKING_FREQUENCY_ID]: 'Kuinka usein asiakas juo alkoholijuomia?',
    [DRINKING_SIX_FREQUENCY_ID]:
      'Kuinka usein asiakas juo vuorokauden aikana vähintään kuusi annosta alkoholia?',
    [SCREENING_YEAR]: 'Kutsuvuosi',
    [MUNICIPALITY_ID]: 'Kunta',
    [SAMPLE_DATE]: 'Näytteenottopäivä',
    [CONTROL_CODE]: 'Näytetunnus',
    [CONFIDENCE]: 'Luotettavuus',
  },

  [SCOPY_NOT_PERFORMED_REASON]: {
    '1': 'Ei tavoitettu',
    '2': 'Kieltäytyi',
    '3': 'Kolonoskopia tehty hiljattain',
    '4': 'Muu syy',
    '5': 'Seurannassa suolistosyövän takia',
    '6': 'Seurannassa tulehduksellisen suolistosairauden takia (IBD)',
    '7': 'Paksusuoli poistettu (kolektomia)',
  },
  [TTGRAPHY_FINDING_IDS]: {
    '1': 'Normaali löydös',
    '2': 'Tuumori',
    '3': 'Polyyppi',
    '4': 'Ahtauma',
    '5': 'Koliitti',
    '6': 'Divertikkelit',
    '7': 'Muu',
  },
  [MUTAIION_LOCATION_ID]: {
    '1': 'Ileum terminale',
    '2': 'Caecum',
    '3': 'Colon ascendens',
    '4': 'Fleksura hepatica',
    '5': 'Colon transversum',
    '6': 'Fleksura  lienalis',
    '7': 'Colon descendens',
    '8': 'Sigma',
    '9': 'Rectum',
  },

  [CLASSIFOCATION_ID]: {
    '0': 'C0: riittämätön tutkimus',
    '1': 'C1: normaali löydös/hyvänlaatuinen muutos eikä > 5 mm polyyppejä',
    '2': 'C2: 6-9 mm polyyppi(t), lukumäärä < 3',
    '3': 'C3: ≥ 10 mm polyyppi tai ≥  3, 6-9 mm polyyppi(t)',
    '4':
      'C4: malignisuspekti, lumenia ahtauttava muutos tai merkkejä seinämän läpikasvusta',
  },
  [OTHER_CLASSIFOCATION_ID]: {
    '0': 'E0: puutteellinen tutkimus',
    '1': 'E1: ei patologista',
    '2': 'E2: kliinisesti hyvänlaatuinen merkityksetön löydös',
    '3':
      'E3: todennäköisesti hyvänlaatuinen, epäspesifiseksi jäävä löydös, joka vaatii mahdollisesti lisäselvittelyä',
    '4':
      'E4: kliinisesti merkittävä löydös, vaatii jatkotoimenpiteitä/lisäselvityksiä',
  },
  control: {
    quit: 'Lopeta tallentamatta muutoksia',
    save: 'Tallenna',
    edit: 'Muokkaa',
    addScreenPerson: 'Lisää seulottava henkilö',
    lockedFor: 'Kohde on lukittuna käyttäjälle {{name}}',
    search: 'Hae',
  },
  agGrid: { remove: 'Tyhjennä', confirm: 'Ok' },
  tableColumns: {
    info: {
      [DIAGNOSES_LOCATION_ID]:
        'Muutoksen sijainnilla tarkoitetaan anatomista paikkaa (topografia), josta näyte on otettu.',
      [DIAGNOSES_MORPHOLOGY_ID]:
        'Muutoksen morfologialla tarkoitetaan näytteeseen liittyvän muutoksen kudosopillista tyyppiä, esim. adenokarsinooma.',
      [DIAGNOSES_DIAMETER_MM_ID]:
        'Patologin antama muutoksen suurin läpimitta.',
      addNewDiagnosis:
        '<div class="info-box-with-html"><ul><li>Muutoksen sijainnilla tarkoitetaan anatomista paikkaa (topografia), josta näyte on otettu.</li><li>Muutoksen morfologialla tarkoitetaan näytteeseen liittyvän muutoksen kudosopillista tyyppiä, esim. adenokarsinooma.</li><li>Muutoksen läpimitalla tarkoitetaan patologin antamaa muutoksen suurinta läpimittaa.</li></ul></div>',
    },
    [SAMPLE_KIT_STATUS_ID]: 'Käsittelyn tila',
    [ORDER_AT]: 'Tilauspvm',
    [CONTROL_CODE]: 'Näytetunnus',
    addNewDiagnosis: 'Lisää uusi diagnoosi',
    addNewUser: 'Lisää uusi henkilö',
    [STATUS]: 'Käsittelyn tila',
    [Forms.HANDLER]: 'Käsittelijä',
    [FIRST_NAMES]: 'Etunimi',
    [SURNAME]: 'Sukunimi',
    [SSN]: 'Henkilötunnus',
    [SCREENING_CODE]: 'Seulontatunnus',
    [COMMENT]: 'Kommentti',
    [INITIAL_RESULT]: 'Näytetulos',
    [ANALYSIS_DATE]: 'Analyysipäivä',
    [COULD_NOT_BE_REACHED_AT]: 'Ei tavoitettu, pvm',
    [MUNICIPALITY_ID]: 'Kunta',
    [DIAGNOSES_MORPHOLOGY_ID]: 'Muutoksen morfologia',
    [DIAGNOSES_LOCATION_ID]: 'Muutoksen sijainti',
    [DIAGNOSES_DIAMETER_MM_ID]: 'Muutoksen läpimitta (mm)',
    [FIRSTNAME]: 'Etunimi',
    [LASTNAME]: 'Sukunimi',
    [NAME_ID_HAG]: 'HAG-ID',
    [MUNICIPALITY_IDS]: 'Kunnat',
    [ROLE_IDS]: 'Roolit',
    [PERMISSION_STATUS]: 'Voimassaolo',
    [START_DATE]: 'Alkupvm',
    [END_DATE]: 'Loppupvm',
    [MODIFIED_BY]: 'Viimeisin muokkaaja',
  },
  [MUNICIPALITY_IDS]: {
    all: 'kaikki',
    1: 'Keusote',
    2: 'Karviainen',
    3: 'Kymsote',
    4: 'Eksote',
    5: 'Ylä-Savon SOTE',
    6: 'KYS',
    7: 'Kysteri',
    8: 'Sisä-Savo',
    9: 'Essote',
    10: 'Sosteri',
  },
  [DIAGNOSES_DIAMETER_MM_ID]: {
    undefined: '',
    '1': 'Alle 5 mm',
    '2': 'Vähintään 5 mm mutta alle 10 mm',
    '3': 'Vähintään 10 mm mutta alle 20 mm',
    '4': 'Vähintään 20 mm',
  },
  [DIAGNOSES_MORPHOLOGY_ID]: {
    undefined: '',
    1: 'Normaali löydös',
    2: 'Insufficient material/tissue for diagnosis',
    3: 'Tissue unsatisfactory for diagnosis/Specimen inadequate for diagnosis',
    4: 'Ei koepalaa (No tissue received)',
    5: 'No evidence of malignancy/tumor',
    6: 'Diverticulosis',
    7: 'Ulcer NOS',
    8: 'Fistula NOS',
    9: 'Inflammation NOS',
    10: 'Inflammation ulcerative NOS',
    11: 'Inflammation erosive NOS',
    12: 'Inflammation acute NOS',
    13: 'Inflammation wit fibrosis NOS',
    14: 'Granulation tissue NOS',
    15: 'Diverticulitis NOS',
    16: 'Hyperplasia NOS',
    17: 'Hyperplastic polyp',
    18: 'Juvenile polyp',
    19: 'Polyp NOS',
    20: 'Neoplasm benign',
    21: 'Neoplasm uncertain whether benign or malignant',
    22: 'Neoplasm malignant',
    23: 'Carcinoma in situ NOS',
    24: 'Carcinoma NOS',
    25: 'Large cell neuroendocrine carcinoma',
    26: 'Carcinoma undifferentiated NOS',
    27: 'Spindle cell carcinoma NOS',
    28: 'Carcinoma with sarcomatoid component',
    29: 'Small cell carcinoma NOS',
    30: 'Papilloma NOS',
    31: 'Verrucous squamous cell carcinoma',
    32: 'Squamous cell carcinoma NOS',
    33: 'Squamous cell carcinoma spindle cell',
    34: 'Squamous intraepithelial neoplasia low grade',
    35: 'Squamous intraepithelial neoplasia high grade',
    36: 'Basaloid squamous cell carcinoma',
    37: 'Basaloid carcinoma',
    38: 'Adenoma',
    39: 'Adenocarcinoma  in situ NOS',
    40: 'Adenocarcinoma NOS',
    41: 'Glandular intraepithelial neoplasia low grade',
    42: 'Glandular intraepithelial neoplasia high grade',
    43: 'Canalicular adenoma',
    44: 'L cell type neuroendocrine tumor',
    45: 'L-cell tumour',
    46: 'Glucagon-like peptide-producing tumour',
    47: 'PP/PYY-producing tumour',
    48: 'Gastrinoma',
    49: 'Mixed neuroendocrine-non-neuroendocrine neoplasm (MiNEN)',
    50: 'Somatostatinoma',
    51: 'Adenoid cystic carcinoma',
    52: 'Cribriform (comedo-type) carcinoma NOS',
    53: 'Tubular adenoma',
    54: 'Tubular adenoma low grade',
    55: 'Tubular adenoma high-grade dysplasia',
    56: 'Tubular adenocarcinoma',
    57: 'Serrated adenoma',
    58: 'Serrated adenoma low grade',
    59: 'Traditional serrated adenoma low grade dysplasia',
    60: 'Serrated dysplasia low grade',
    61: 'Sessile serrated lesion SSL without dysplasia',
    62: 'Traditional serrated adenoma high grade dysplasia',
    63: 'Serrated dysplasia high grade',
    64: 'Sessile serrated lesion SSL with dysplasia',
    65: 'Serrated adenocarcinoma',
    66: 'Neuroendocrine tumour (NET) grade 1',
    67: 'Enterochromaffin cell carcinoid',
    68: 'Mucinous carcinoid',
    69: 'Mixed adenoneuroendocrine carcinoma',
    70: 'Tubular carcinoid',
    71: 'Neuroendocrine carcinoma (NEC) NOS',
    72: 'Neuroendocrine tumour (NET) grade 2  & grade 3',
    73: 'Mixed adenocarcinoma',
    74: 'Papillary adenocarcinoma NOS',
    75: 'Villous adenoma',
    76: 'Villous adenoma low grade',
    77: 'Villous adenoma high grade',
    78: 'Adenoma-like adenocarcinoma',
    79: 'Tubulovillous adenoma',
    80: 'Tubulovillous adenoma low grade',
    81: 'Tubulovillousadenoma high grade',
    82: 'Micropapillary carcinoma NOS',
    83: 'Mucoepidermoid carcinoma',
    84: 'Low grade appendiceal mucinous neoplasm (LAMN)',
    85: 'High-grade appendiceal mucinous neoplasm',
    86: 'Mucinous adenocarcinoma',
    87: 'Signet-ring cell carsinoma',
    88: 'Poorly cohesive carcinoma',
    89: 'Medullary carcinoma NOS',
    90: 'Medullary carcinoma with lymphoid stroma',
    91: 'Paget disease extramammary (except Paget disease of bone)',
    92: 'Adenosquamous carcinoma',
    93: 'Hepatoid adenocarcinoma',
    94: 'Glomus tumour',
    95: 'Malignant melanoma NOS',
    96: 'Soft tissue tumor bening',
    97: 'Sarcoma NOS',
    98: 'Plexiform fibromyxoma',
    99: 'Inflammatory myofibroblastic tumour',
    100: 'Lipoma',
    101: 'Atypical lipoma',
    102: 'Liposarcoma NOS',
    103: 'Leiomyoma',
    104: 'Leiomyosarcoma NOS',
    105: 'Rhabdomyosarcoma NOS',
    106: 'Gastrointestinal stromal tumor benign',
    107: 'Gastrointestinal stromal tumor (GIST) NOS',
    108: 'Gastrointestinal stromal sarcoma',
    109: 'Haemangioma',
    110: 'Angiosarcoma',
    111: 'Kaposi sarcoma',
    112: 'Schwannoma',
    113: 'Neuroma',
    114: 'Granular cell tumour',
    115: 'Malignant lymphoma NOS',
    116: 'Mantle cell lymphoma',
    117: 'Malignant lymphoma large B-cell diffuse NOS',
    118: 'Burkitt lymphoma NOS',
    119: 'Follicular lymphoma NOS',
    120: 'Marginal zone B-cell lymphoma NOS',
    121: 'Mature T-cell lymphoma NOS',
    122: 'Intestinal T-cell lymphoma',
    123: 'Immunoproliferative small intestinal disease',
  },

  [DIAGNOSES_LOCATION_ID]: {
    undefined: '',
    '150': 'Cervical esophagus',
    '151': 'Thoracic esophagus',
    '152': 'Abdominal esophagus',
    '153': 'Upper third of esophagus',
    '154': 'Middle third of esophagus',
    '155': 'Lower third of esophagus',
    '158': 'Overlapping lesion of esophagus',
    '159': 'Esophagus, NOS',
    '160': 'Cardia, NOS',
    '161': 'Fundus of stomach',
    '162': 'Body of stomach',
    '163': 'Gastric antrum',
    '164': 'Pylorus',
    '165': 'Lesser curvature of stomach, NOS',
    '166': 'Greater curvature of stomach, NOS',
    '168': 'Overlapping lesion of stomach',
    '169': 'Stomach, NOS',
    '170': 'Duodenum',
    '171': 'Jejunum',
    '172': 'Ileum',
    '173': 'Meckel diverticulum',
    '178': 'Overlapping lesion of small intestine',
    '179': 'Small intestine, NOS',
    '180': 'Cecum',
    '181': 'Appendix',
    '182': 'Ascending colon',
    '183': 'Hepatic flexure of colon',
    '184': 'Transverse colon',
    '185': 'Splenic flexure of colon',
    '186': 'Descending colon',
    '187': 'Sigmoid colon',
    '188': 'Overlapping lesion of colon',
    '189': 'Colon, NOS',
    '199': 'Rectosigmoid junction',
    '209': 'Rectum, NOS',
    '210': 'Anus, NOS',
    '211': 'Anal canal',
    '212': 'Cloacogenic zone',
    '218': 'Overlapping lesion of rectum, anus and anal canal',
    '220': 'Liver',
    '260': 'Intestinal tract, NOS',
    '268': 'Overlapping lesion of digestive system',
    '269': 'Gastrointestinal tract, NOS',
    '445': 'Skin of trunk',
    '772': 'Intra-abdominal lymph nodes',
    '775': 'Pelvic lymph nodes',
    '779': 'Lymph node, NOS',
    '809': 'Unknown primary site',
  },
  [Forms.HANDLER]: {
    empty: 'Tyhjennä',
  },
  [INITIAL_RESULT]: {
    DEFAULT: 'Tyhjennä',
    [NO_REPLY]: 'Ei vastausta',
    [POSITIVE]: 'Positiivinen',
    [NEGATIVE]: 'Negatiivinen',
  },
  [TEST_RESULT_POSITIVE]: {
    DEFAULT: 'Tyhjennä',
    [NO_REPLY]: 'Ei vastausta',
    [POSITIVE]: 'Positiivinen',
    [NEGATIVE]: 'Negatiivinen',
  },
  [SCOPY_PROCEDURE_IDS]: {
    '1': 'Polypektomia',
    '2': 'Näytepalan otto',
    '3': 'Vuodon koagulaatio',
    '4': 'Laajennus',
    '5': 'Tatuointi',
    '6': 'Muu toimenpide',
  },
  [DRINKING_AMOUNT_ID]: {
    '1': '1-2 annosta',
    '2': '3-4 annosta',
    '3': '5-6 annosta',
    '4': '7-9 annosta',
    '5': '10 annosta tai enemmän',
  },
  [DRINKING_FREQUENCY_ID]: {
    '0': 'En käytä lainkaan alkoholijuomia',
    '1': 'Noin kerran kuukaudessa tai harvemmin',
    '2': '2-4 kertaa kuukaudessa',
    '3': '2-3 kertaa viikossa',
    '4': '4 kertaa viikossa tai useammin',
  },

  [DRINKING_SIX_FREQUENCY_ID]: {
    '0': 'En koskaan',
    '1': 'Harvemmin kuin kerran kuussa',
    '2': 'Noin kerran kuukaudessa',
    '3': 'Noin kerran viikossa',
    '4': 'Päivittäin tai lähes päivittäin',
  },

  [LONG_TERM_ILNESS_IDS]: {
    '1': 'Insuliinihoitoinen diabetes',
    '2': 'Sydämen läppävika',
    '3': 'Sydäninfarkti viimeisen 3 kk:n aikana',
    '4': 'Vaikea keuhko- tai sydänsairaus',
    '5': 'Vaikea liikuntakykyä rajoittava sairaus',
  },

  [REGULAR_MEDICINE_IDS]: {
    '1': 'Verenohennuslääke (Marevan, Pradaxa, Hepariini)',
    '2':
      'Asetyylisalisyylihappovalmisteet (esim. Asperin, Disperin, Primaspan)',
    '3': 'Muu tulehduskipulääke (esim. Burana)',
  },

  [RELATIVE_INTESTIONAL_CANCER_WHO]: {
    '1': 'Vanhemmillani',
    '2': 'Sisaruksillani',
    '3': 'Lapsillani',
    '4': 'Jollain muulla sukulaisella (esim. tädillä, serkulla)',
  },
  common: {
    not: 'Ei ole',
    yes: 'Kyllä',
    no: 'Ei',
    idont: 'En',
    dontKnow: 'En tiedä',
    havent: 'En ole',
  },
  [HAS_RELATIVE_INTESTIONAL_CANCER]: {
    '0': 'Ei',
    '1': 'Kyllä',
    '2': 'En tiedä',
  },
  [HAS_MEDICINE_SENSITIVITY]: {
    true: 'Kyllä',
    false: 'Ei',
  },
  [DOES_SMOKE]: {
    true: 'Kyllä',
    false: 'Ei',
  },
  [HAS_SMOKED_DAILY]: {
    true: 'Kyllä',
    false: 'Ei',
  },
  forms: {
    futureData: 'Päivämäärää ei voi asettaa tulevaisuuteen',
    emptyReferalLocation:
      'Lähete paikkaan on välttämätön tieto, oletko varma että haluat jättää sen tyhjäksi?',
    needMoreDiagnose:
      'Päivämäärän ja paikan lisäksi on syötettävä vähintään yksi diagnoosi',
    needMoreGraphy:
      'Lausunnon päivämäärän ja paikan lisäksi on syötettävä vähintään yksi muu tieto',
    needMoreScopy:
      'Syötä päivämäärän ja paikan lisäksi vähintään yksi tieto alle',
    changeMunicipality:
      'jos vaihdat potilaan kunnan sellaiseen kuntaan, johon sinulla ei ole näkyvyyttä, et näe enää potilaan tietoja. Muutosta ei voi perua',
    requiredField: 'Pakollinen kenttä',
    requiredLocation:
      'Paikka on pakollinen tieto. Jos paikka ei ole tiedossa, valitse listalta "Tuntematon"',

    currentYeayMax: 'ei voi olla tulevina vuosina',
    string: 'Pitäisi olla merkkijono',
    clear: 'Tyhjennä',
    select: 'Valitse',
    mustBeNumber: 'Tämän pitäisi olla numero',
    mustBeNumberMax: 'Tämän pitäisi olla korkeintaan {{maxNumber}}',
    mustBeStringMax: 'Tämän pitäisi olla alle {{maxCharacter}} merkkiä',
    mustBeNumberMin: 'Tämän pitäisi olla vähintään {{minNumber}}',
    mustBeSelected: 'Valinta on pakollinen',
    interruptedWarning:
      'Oletko varma, että haluat merkitä tapahtuman keskeytetyksi? Kaikki tiedot menetetään.',
    renewalwarning:
      'Olet valinnut tutkimuksen uusinnan. Tallennettuasi valinnan et voi enää muokata nykyisen tutkimuksen tietoja. Haluatko jatkaa?',
    changePrimaryFollowUpWarning:
      'Oletko varma, että haluat muuttaa jatkotoimenpidepäätöksen? Jos aiemman päätöksen perusteella on jo syötetty kolonoskopiaan, TT-kolongrafiaan, kirurgiaan tai hoitotoimenpiteeseen liittyviä tietoja, nämä tiedot menetetään.',
  },
  [STATUS]: {
    active: 'Aktiiviset',
    INT1: 'Seulontakutsu lähetetty',
    INT2: 'Muistutuskirje lähetetty',
    INT3: 'Ulostenäyte vastattu (POS)',
    INT4: 'Ulostenäyte vastattu (NEG)',
    INT5: 'Ei osallistunut',
    INT7: 'Skopia kutsu lähetetty',
    INT8: 'Skopia epäonnistui',
    INT9: 'Skopia uusintakutsu',
    INT10: 'Skopia odottaa patologia',
    INT11: 'Skopia patologia vastattu',
    INT12: 'TT kutsu lähetetty',
    INT13: 'TT epäonnistui',
    INT14: 'TT uusintakutsu',
    INT15: 'TT odottaa analyysia',
    INT16: 'TT analysoitu',
    INT17: 'Kirurgia kutsu lähetetty',
    INT18: 'Kirurgia odottaa patologia',
    INT19: 'Kirurgia patologia vastattu',
    empty: 'Tyhjennä',
    '0': 'Uusi',
    '2': 'Päättynyt',
    '4': 'Keskeytynyt (muutto)',
    '5': 'Keskeytynyt (siirtynyt yksityiselle)',
    '6': 'Keskeytynyt (tietojen luovutus kielletty)',
    '7': 'Kuollut',
  },
  [INTERPRETABILITY_BLOCKERS]: {
    '1': 'Näyteputkessa liian vähän näytettä',
    '2': 'Näyteputkessa liikaa näytettä',
    '3': 'Näyte on otettu väärin',
    '4': 'Näyte tuhoutunut',
    '5': 'Tunnistetiedot puutteelliset',
    '6': 'Näytteen analyysi on viivästynyt yli määräajan',
    '7': 'Näytteenottopäivämäärä puuttuu',
    '8': 'Tekninen syy',
  },
  [SAMPLE_INTERPRETABILITY]: {
    '1': 'Riittävä',
    '2': 'Epävarma',
    '3': 'Ei tulkittavissa',
  },
  pleaseWait: 'Ladataan',
  [MUNICIPALITY_ID]: {
    empty: 'Tyhjennä',
    '000': 'Tunnistamaton',
    '004': '(Vanhentunut) Alahärmä',
    '005': 'Alajärvi',
    '006': '(Vanhentunut) Alastaro',
    '009': 'Alavieska',
    '010': 'Alavus',
    '014': '(Vanhentunut) Anttola',
    '015': '(Vanhentunut) Artjärvi',
    '016': 'Asikkala',
    '017': '(Vanhentunut) Askainen',
    '018': 'Askola',
    '019': 'Aura',
    '020': 'Akaa',
    '035': 'Brändö',
    '040': '(Vanhentunut) Dragsfjärd',
    '043': 'Eckerö',
    '044': '(Vanhentunut) Elimäki',
    '045': '(Vanhentunut) Eno',
    '046': 'Enonkoski',
    '047': 'Enontekiö',
    '049': 'Espoo',
    '050': 'Eura',
    '051': 'Eurajoki',
    '052': 'Evijärvi',
    '060': 'Finström',
    '061': 'Forssa',
    '062': 'Föglö',
    '065': 'Geta',
    '069': 'Haapajärvi',
    '071': 'Haapavesi',
    '072': 'Hailuoto',
    '073': '(Vanhentunut) Halikko',
    '074': 'Halsua',
    '075': 'Hamina',
    '076': 'Hammarland',
    '077': 'Hankasalmi',
    '078': 'Hanko',
    '079': 'Harjavalta',
    '081': 'Hartola',
    '082': 'Hattula',
    '083': '(Vanhentunut) Hauho',
    '084': '(Vanhentunut) Haukipudas',
    '085': '(Vanhentunut) Haukivuori',
    '086': 'Hausjärvi',
    '088': '(Vanhentunut) Heinola',
    '089': '(Vanhentunut) Heinolan mlk',
    '090': 'Heinävesi',
    '091': 'Helsinki',
    '092': 'Vantaa',
    '095': '(Vanhentunut) Himanka',
    '097': 'Hirvensalmi',
    '098': 'Hollola',
    '099': '(Vanhentunut) Honkajoki',
    '101': '(Vanhentunut) Houtskari',
    '102': 'Huittinen',
    '103': 'Humppila',
    '105': 'Hyrynsalmi',
    '106': 'Hyvinkää',
    '108': 'Hämeenkyrö',
    '109': 'Hämeenlinna',
    '111': 'Heinola',
    '139': 'Ii',
    '140': 'Iisalmi',
    '142': 'Iitti',
    '143': 'Ikaalinen',
    '145': 'Ilmajoki',
    '146': 'Ilomantsi',
    '148': 'Inari',
    '149': 'Inkoo',
    '150': '(Vanhentunut) Iniö',
    '151': 'Isojoki',
    '152': 'Isokyrö',
    '153': 'Imatra',
    '163': '(Vanhentunut) Jaala',
    '164': '(Vanhentunut) Jalasjärvi',
    '165': 'Janakkala',
    '167': 'Joensuu',
    '169': 'Jokioinen',
    '170': 'Jomala',
    '171': 'Joroinen',
    '172': 'Joutsa',
    '173': '(Vanhentunut) Joutseno',
    '174': '(Vanhentunut) Juankoski',
    '175': '(Vanhentunut) Jurva',
    '176': 'Juuka',
    '177': 'Juupajoki',
    '178': 'Juva',
    '179': 'Jyväskylä',
    '180': '(Vanhentunut) Jyväskylän mlk',
    '181': 'Jämijärvi',
    '182': 'Jämsä',
    '183': '(Vanhentunut) Jämsänkoski',
    '184': '(Vanhentunut) Jäppilä',
    '186': 'Järvenpää',
    '198': 'Ei kotikuntaa Suomessa',
    '199': 'Tuntematon',
    '200': 'Ulkomaat',
    '202': 'Kaarina',
    '204': 'Kaavi',
    '205': 'Kajaani',
    '208': 'Kalajoki',
    '209': '(Vanhentunut) Kalanti',
    '210': '(Vanhentunut) Kalvola',
    '211': 'Kangasala',
    '212': '(Vanhentunut) Kangaslampi',
    '213': 'Kangasniemi',
    '214': 'Kankaanpää',
    '216': 'Kannonkoski',
    '217': 'Kannus',
    '218': 'Karijoki',
    '219': '(Vanhentunut) Karinainen',
    '220': '(Vanhentunut) Karjaa',
    '223': '(Vanhentunut) Karjalohja',
    '224': 'Karkkila',
    '226': 'Karstula',
    '227': '(Vanhentunut) Karttula',
    '230': 'Karvia',
    '231': 'Kaskinen',
    '232': 'Kauhajoki',
    '233': 'Kauhava',
    '235': 'Kauniainen',
    '236': 'Kaustinen',
    '239': 'Keitele',
    '240': 'Kemi',
    '241': 'Keminmaa',
    '243': '(Vanhentunut) Kemiö',
    '244': 'Kempele',
    '245': 'Kerava',
    '246': '(Vanhentunut) Kerimäki',
    '247': '(Vanhentunut) Kestilä',
    '248': '(Vanhentunut) Kesälahti',
    '249': 'Keuruu',
    '250': 'Kihniö',
    '251': '(Vanhentunut) Kiihtelysvaara',
    '252': '(Vanhentunut) Kiikala',
    '254': '(Vanhentunut) Kiikoinen',
    '255': '(Vanhentunut) Kiiminki',
    '256': 'Kinnula',
    '257': 'Kirkkonummi',
    '259': '(Vanhentunut) Kisko',
    '260': 'Kitee',
    '261': 'Kittilä',
    '262': '(Vanhentunut) Kiukainen',
    '263': 'Kiuruvesi',
    '265': 'Kivijärvi',
    '266': '(Vanhentunut) Kodisjoki',
    '271': 'Kokemäki',
    '272': 'Kokkola',
    '273': 'Kolari',
    '274': '(Vanhentunut) Konginkangas',
    '275': 'Konnevesi',
    '276': 'Kontiolahti',
    '277': '(Vanhentunut) Korpilahti',
    '279': '(Vanhentunut) Korppoo',
    '280': 'Korsnäs',
    '281': '(Vanhentunut) Kortesjärvi',
    '283': '(Vanhentunut) Hämeenkoski',
    '284': 'Koski Tl',
    '285': 'Kotka',
    '286': 'Kouvola',
    '287': 'Kristiinankaupunki',
    '288': 'Kruunupyy',
    '289': '(Vanhentunut) Kuhmalahti',
    '290': 'Kuhmo',
    '291': 'Kuhmoinen',
    '292': '(Vanhentunut) Kuivaniemi',
    '293': '(Vanhentunut) Kullaa',
    '295': 'Kumlinge',
    '297': 'Kuopio',
    '299': '(Vanhentunut) Kuorevesi',
    '300': 'Kuortane',
    '301': 'Kurikka',
    '303': '(Vanhentunut) Kuru',
    '304': 'Kustavi',
    '305': 'Kuusamo',
    '306': '(Vanhentunut) Kuusankoski',
    '308': '(Vanhentunut) Kuusjoki',
    '309': 'Outokumpu',
    '310': '(Vanhentunut) Kylmäkoski',
    '312': 'Kyyjärvi',
    '315': '(Vanhentunut) Kälviä',
    '316': 'Kärkölä',
    '317': 'Kärsämäki',
    '318': 'Kökar',
    '319': '(Vanhentunut) Köyliö',
    '320': 'Kemijärvi',
    '322': 'Kemiönsaari',
    '398': 'Lahti',
    '399': 'Laihia',
    '400': 'Laitila',
    '401': '(Vanhentunut) Lammi',
    '402': 'Lapinlahti',
    '403': 'Lappajärvi',
    '405': 'Lappeenranta',
    '406': '(Vanhentunut) Lappi',
    '407': 'Lapinjärvi',
    '408': 'Lapua',
    '410': 'Laukaa',
    '413': '(Vanhentunut) Lavia',
    '414': '(Vanhentunut) Lehtimäki',
    '415': '(Vanhentunut) Leivonmäki',
    '416': 'Lemi',
    '417': 'Lemland',
    '418': 'Lempäälä',
    '419': '(Vanhentunut) Lemu',
    '420': 'Leppävirta',
    '421': 'Lestijärvi',
    '422': 'Lieksa',
    '423': 'Lieto',
    '424': '(Vanhentunut) Liljendal',
    '425': 'Liminka',
    '426': 'Liperi',
    '427': '(Vanhentunut) Lohja',
    '428': '(Vanhentunut) Lohjan kunta',
    '429': '(Vanhentunut) Lohtaja',
    '430': 'Loimaa',
    '431': '(Vanhentunut) Loimaan kunta',
    '433': 'Loppi',
    '434': 'Loviisa',
    '435': 'Luhanka',
    '436': 'Lumijoki',
    '438': 'Lumparland',
    '439': '(Vanhentunut) Luopioinen',
    '440': 'Luoto',
    '441': 'Luumäki',
    '442': '(Vanhentunut) Luvia',
    '443': '(Vanhentunut) Längelmäki',
    '444': 'Lohja',
    '445': 'Parainen',
    '475': 'Maalahti',
    '476': '(Vanhentunut) Maaninka',
    '478': 'Maarianhamina',
    '479': '(Vanhentunut) Maksamaa',
    '480': 'Marttila',
    '481': 'Masku',
    '482': '(Vanhentunut) Mellilä',
    '483': 'Merijärvi',
    '484': 'Merikarvia',
    '485': '(Vanhentunut) Merimasku',
    '489': 'Miehikkälä',
    '490': '(Vanhentunut) Mietoinen',
    '491': 'Mikkeli',
    '492': '(Vanhentunut) Mikkelin mlk',
    '493': '(Vanhentunut) Mouhijärvi',
    '494': 'Muhos',
    '495': 'Multia',
    '498': 'Muonio',
    '499': 'Mustasaari',
    '500': 'Muurame',
    '501': '(Vanhentunut) Muurla',
    '503': 'Mynämäki',
    '504': 'Myrskylä',
    '505': 'Mäntsälä',
    '506': '(Vanhentunut) Mänttä',
    '507': 'Mäntyharju',
    '508': 'Mänttä-Vilppula',
    '529': 'Naantali',
    '531': 'Nakkila',
    '532': '(Vanhentunut) Nastola',
    '533': '(Vanhentunut) Nauvo',
    '534': '(Vanhentunut) Nilsiä',
    '535': 'Nivala',
    '536': 'Nokia',
    '537': '(Vanhentunut) Noormarkku',
    '538': 'Nousiainen',
    '540': '(Vanhentunut) Nummi-Pusula',
    '541': 'Nurmes',
    '543': 'Nurmijärvi',
    '544': '(Vanhentunut) Nurmo',
    '545': 'Närpiö',
    '559': '(Vanhentunut) Oravainen',
    '560': 'Orimattila',
    '561': 'Oripää',
    '562': 'Orivesi',
    '563': 'Oulainen',
    '564': 'Oulu',
    '567': '(Vanhentunut) Oulunsalo',
    '573': '(Vanhentunut) Parainen',
    '576': 'Padasjoki',
    '577': 'Paimio',
    '578': 'Paltamo',
    '580': 'Parikkala',
    '581': 'Parkano',
    '582': '(Vanhentunut) Pattijoki',
    '583': 'Pelkosenniemi',
    '584': 'Perho',
    '585': '(Vanhentunut) Pernaja',
    '586': '(Vanhentunut) Perniö',
    '587': '(Vanhentunut) Pertteli',
    '588': 'Pertunmaa',
    '589': '(Vanhentunut) Peräseinäjoki',
    '592': 'Petäjävesi',
    '593': 'Pieksämäki',
    '594': '(Vanhentunut) Pieksämäen mlk',
    '595': 'Pielavesi',
    '598': 'Pietarsaari',
    '599': 'Pedersören kunta',
    '601': 'Pihtipudas',
    '602': '(Vanhentunut) Piikkiö',
    '603': '(Vanhentunut) Piippola',
    '604': 'Pirkkala',
    '606': '(Vanhentunut) Pohja',
    '607': 'Polvijärvi',
    '608': 'Pomarkku',
    '609': 'Pori',
    '611': 'Pornainen',
    '612': '(Vanhentunut) Porvoo',
    '613': '(Vanhentunut) Porvoon mlk',
    '614': 'Posio',
    '615': 'Pudasjärvi',
    '616': 'Pukkila',
    '617': '(Vanhentunut) Pulkkila',
    '618': '(Vanhentunut) Punkaharju',
    '619': 'Punkalaidun',
    '620': 'Puolanka',
    '623': 'Puumala',
    '624': 'Pyhtää',
    '625': 'Pyhäjoki',
    '626': 'Pyhäjärvi',
    '630': 'Pyhäntä',
    '631': 'Pyhäranta',
    '632': '(Vanhentunut) Pyhäselkä',
    '633': '(Vanhentunut) Pylkönmäki',
    '635': 'Pälkäne',
    '636': 'Pöytyä',
    '638': 'Porvoo',
    '640': '(Vanhentunut) Pieksänmaa',
    '678': 'Raahe',
    '680': 'Raisio',
    '681': 'Rantasalmi',
    '682': '(Vanhentunut) Rantsila',
    '683': 'Ranua',
    '684': 'Rauma',
    '685': '(Vanhentunut) Rauman mlk',
    '686': 'Rautalampi',
    '687': 'Rautavaara',
    '689': 'Rautjärvi',
    '691': 'Reisjärvi',
    '692': '(Vanhentunut) Renko',
    '694': 'Riihimäki',
    '696': '(Vanhentunut) Ristiina',
    '697': 'Ristijärvi',
    '698': 'Rovaniemi',
    '699': '(Vanhentunut) Rovaniemen mlk',
    '700': 'Ruokolahti',
    '701': '(Vanhentunut) Ruotsinpyhtää',
    '702': 'Ruovesi',
    '704': 'Rusko',
    '705': '(Vanhentunut) Rymättylä',
    '707': 'Rääkkylä',
    '708': '(Vanhentunut) Ruukki',
    '710': 'Raasepori',
    '728': '(Vanhentunut) Saari',
    '729': 'Saarijärvi',
    '730': '(Vanhentunut) Sahalahti',
    '732': 'Salla',
    '734': 'Salo',
    '736': 'Saltvik',
    '737': '(Vanhentunut) Sammatti',
    '738': 'Sauvo',
    '739': 'Savitaipale',
    '740': 'Savonlinna',
    '741': '(Vanhentunut) Savonranta',
    '742': 'Savukoski',
    '743': 'Seinäjoki',
    '746': 'Sievi',
    '747': 'Siikainen',
    '748': 'Siikajoki',
    '749': 'Siilinjärvi',
    '751': 'Simo',
    '752': '(Vanhentunut) Simpele',
    '753': 'Sipoo',
    '754': '(Vanhentunut) Anjalankoski',
    '755': 'Siuntio',
    '758': 'Sodankylä',
    '759': 'Soini',
    '761': 'Somero',
    '762': 'Sonkajärvi',
    '765': 'Sotkamo',
    '766': 'Sottunga',
    '768': 'Sulkava',
    '770': '(Vanhentunut) Sumiainen',
    '771': 'Sund',
    '772': '(Vanhentunut) Suodenniemi',
    '774': 'Suolahti',
    '775': 'Suomenniemi',
    '776': '(Vanhentunut) Suomusjärvi',
    '777': 'Suomussalmi',
    '778': 'Suonenjoki',
    '781': 'Sysmä',
    '783': 'Säkylä',
    '784': '(Vanhentunut) Särkisalo',
    '785': 'Vaala',
    '787': '(Vanhentunut) Säynätsalo',
    '790': 'Sastamala',
    '791': 'Siikalatva',
    '831': 'Taipalsaari',
    '832': 'Taivalkoski',
    '833': 'Taivassalo',
    '834': 'Tammela',
    '835': '(Vanhentunut) Tammisaari',
    '837': 'Tampere',
    '838': '(Vanhentunut) Tarvasjoki',
    '841': '(Vanhentunut) Temmes',
    '842': '(Vanhentunut) Tenhola',
    '844': 'Tervo',
    '845': 'Tervola',
    '846': 'Teuva',
    '848': 'Tohmajärvi',
    '849': 'Toholampi',
    '850': 'Toivakka',
    '851': 'Tornio',
    '853': 'Turku',
    '854': 'Pello',
    '855': '(Vanhentunut) Tuulos',
    '856': '(Vanhentunut) Tuupovaara',
    '857': 'Tuusniemi',
    '858': 'Tuusula',
    '859': 'Tyrnävä',
    '863': '(Vanhentunut) Töysä',
    '864': '(Vanhentunut) Toijala',
    '885': '(Vanhentunut) Ullava',
    '886': 'Ulvila',
    '887': 'Urjala',
    '889': 'Utajärvi',
    '890': 'Utsjoki',
    '891': '(Vanhentunut) Uukuniemi',
    '892': 'Uurainen',
    '893': 'Uusikaarlepyy',
    '895': 'Uusikaupunki',
    '905': 'Vaasa',
    '906': '(Vanhentunut) Vahto',
    '908': 'Valkeakoski',
    '909': '(Vanhentunut) Valkeala',
    '911': '(Vanhentunut) Valtimo',
    '912': '(Vanhentunut) Vammala',
    '913': '(Vanhentunut) Vampula',
    '915': 'Varkaus',
    '916': '(Vanhentunut) Varpaisjärvi',
    '917': '(Vanhentunut) Vehkalahti',
    '918': 'Vehmaa',
    '919': '(Vanhentunut) Vehmersalmi',
    '920': '(Vanhentunut) Velkua',
    '921': 'Vesanto',
    '922': 'Vesilahti',
    '923': '(Vanhentunut) Västanfjärd',
    '924': 'Veteli',
    '925': 'Vieremä',
    '926': '(Vanhentunut) Vihanti',
    '927': 'Vihti',
    '928': '(Vanhentunut) Viiala',
    '931': 'Viitasaari',
    '932': '(Vanhentunut) Viljakkala',
    '933': '(Vanhentunut) Vilppula',
    '934': 'Vimpeli',
    '935': 'Virolahti',
    '936': 'Virrat',
    '937': '(Vanhentunut) Virtasalmi',
    '940': '(Vanhentunut) Vuolijoki',
    '941': 'Vårdö',
    '942': '(Vanhentunut) Vähäkyrö',
    '943': '(Vanhentunut) Värtsilä',
    '944': '(Vanhentunut) Vöyri',
    '945': '(Vanhentunut) Vöyri-Maksamaa',
    '946': 'Vöyri',
    '971': '(Vanhentunut) Ylihärmä',
    '972': '(Vanhentunut) Yli-Ii',
    '973': '(Vanhentunut) Ylikiiminki',
    '975': '(Vanhentunut) Ylistaro',
    '976': 'Ylitornio',
    '977': 'Ylivieska',
    '978': '(Vanhentunut) Ylämaa',
    '979': '(Vanhentunut) Yläne',
    '980': 'Ylöjärvi',
    '981': 'Ypäjä',
    '988': '(Vanhentunut) Äetsä',
    '989': 'Ähtäri',
    '992': 'Äänekoski',
  },
  languages: {
    fi: 'suomi',
    sv: 'ruotsi',
    en: 'englanti',
    ru: 'venäjä',
    ku: 'kurdi',
    so: 'somali',
    ar: 'arabia',
  },
  pdfImport: {
    date: 'Päiväys',
    summaryTitle: 'Skannattujen tila',
    numTotal: 'Yhteensä',
    numFailed: 'Epäonnistunut',
    tabFailed: 'Epäonnistunut',
    columnFilename: 'Tiedoston nimi',
    inputId: 'Syötä näytetunnus',
    unknownPerson: 'Tuntematon henkilö',
    saveInputId: 'Tallenna',
    cancel: 'Peruuta',
    controlCode: 'Näytetunnus',
    helpDescription:
      'PDF-tiedoston uudelleenkäsittely voi kestää joitakin minuutteja. Tiedosto näkyy listalla kunnes käsittely on valmis.',
    formVersion: {
      'old': 'vanha lomake',
      'new': 'uusi lomake',
    }
  },
};

export default translationFi;
