import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { LogoutUser } from '../../state/user/actions';

const LogoutPage: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(LogoutUser());
  }, []);

  return <></>;
};

export default LogoutPage;
