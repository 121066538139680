import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Layout from '../../features/layout/layout';
import ScreenTable from '../../features/screenTable/screenTable';
import { setDefaultScreening } from '../../state/screening/actions';

const ScreeningTable: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setDefaultScreening());
  }, []);
  return (
    <Layout>
      <div className="container--fluid">
        <div className="container__row  space-between">
          <ScreenTable />
        </div>
      </div>
    </Layout>
  );
};

export default ScreeningTable;
