import moment from 'moment';
import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import {
  DECISION_DATE,
  DECISION_ID,
  DECISION_LOCATION,
  OTHER_REASONS,
  PREVIOUS_YEAR,
  SCOPY_NOT_PERFORMED_REASON,
  SCOPY_REFERRAL_LOCATION,
} from '../../constants/scopyForm';
import { validationNumberValues } from '../../constantsLists/validationLists';
import { IScopyForm } from '../../models/IScopyForm';

export { scopySubmit } from '../scopyTab/scopyHelper';

export const valideFollowup = (t: TFunction) =>
  Yup.object().shape({
    // NO FROM NEEDS
    [DECISION_ID]: Yup.number().nullable().required(t('forms.requiredField')),
    [DECISION_DATE]: Yup.date()
      .nullable()
      .required(t('forms.requiredField'))
      .test(DECISION_DATE, t('forms.futureData'), (val) => {
        if (!val) return true;
        return moment(val).isSameOrBefore(moment(), 'day');
      }),
    [DECISION_LOCATION]: Yup.number()
      .nullable()
      .required(t('forms.requiredLocation')),
    [SCOPY_NOT_PERFORMED_REASON]: Yup.number()
      .nullable()
      .test(
        SCOPY_NOT_PERFORMED_REASON,
        t('forms.requiredField'),
        function (val) {
          const values: IScopyForm = this.parent;
          return !(!val && !!values[DECISION_ID] && values[DECISION_ID] !== 6);
        }
      ),
    [SCOPY_REFERRAL_LOCATION]: Yup.mixed().test(
      SCOPY_REFERRAL_LOCATION,
      t('forms.requiredLocation'),
      function (val) {
        const values: IScopyForm = this.parent;
        if (
          values[DECISION_ID] &&
          (values[DECISION_ID] === 3 || values[DECISION_ID] === 6) &&
          !val &&
          val !== 0
        ) {
          return false;
        }
        return true;
      }
    ),
    [PREVIOUS_YEAR]: Yup.number()
      .nullable()
      .when(SCOPY_NOT_PERFORMED_REASON, {
        is: 3,
        then: Yup.number()
          .max(moment().year(), t('forms.currentYeayMax'))
          .typeError(t('forms.mustBeNumber'))
          .required(t('forms.requiredField')),
      }),
    [OTHER_REASONS]: Yup.string()
      .nullable()
      .when(SCOPY_NOT_PERFORMED_REASON, {
        is: 4,
        then: Yup.string()
          .required(t('forms.requiredField'))
          .max(
            validationNumberValues[OTHER_REASONS].max,
            t('forms.mustBeStringMax', {
              maxCharacter: validationNumberValues[OTHER_REASONS].max,
            })
          ),
      }),
  });
