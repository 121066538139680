import { ICellRendererParams } from 'ag-grid-community';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  SAMPLE_KIT_ORDER_ID,
  SAMPLE_KIT_STATUS_ID,
} from '../../constants/orderKitTable';
import { UpdatKitOrdersStatus } from '../../services/searchKitOrderService';
import { RootState } from '../../state/rootReducer';
import styles from './kitStatus.module.scss';

const SampleKitRenderer: React.FC<ICellRendererParams> = (props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(props.value);
  const { screening } = useSelector((st: RootState) => st);
  const [loading, setLoading] = useState(false);

  const onChange = (newStatus: number) => {
    setLoading(true);
    UpdatKitOrdersStatus(
      t,
      props?.node?.data[SAMPLE_KIT_ORDER_ID],
      newStatus
    ).then((re) => {
      setLoading(false);
      if (re) {
        setValue(newStatus);
      }
    });
  };

  return (
    <>
      {loading ? (
        <p className={styles.wait}>{t('pleaseWait')}...</p>
      ) : (
        <select
          value={value}
          className={styles.container}
          onChange={(v) => onChange(+v.target.value)}
        >
          {screening[SAMPLE_KIT_STATUS_ID]?.map((el) => {
            return (
              <option key={el.value} value={el.value}>
                {t(`${SAMPLE_KIT_STATUS_ID}.${el.langValue}`)}&#160;&#160;&#160;
              </option>
            );
          })}
        </select>
      )}
    </>
  );
};
export default SampleKitRenderer;
