import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../components/button/button';
import FocusScrollError from '../../components/focusScrollFormError/focusScrollError';
import Header from '../../components/header/header';
import TabLastEdit from '../../components/tabLastEdit/tabLastEdit';
import { ROLE_IDS } from '../../constants/adminTabel';
import {
  CONTROL_CODE,
  MUNICIPALITY_ID,
  SAMPLE_DATE,
  SAMPLE_ID,
  SCREENING_CODE,
  SCREENING_YEAR,
} from '../../constants/commonKeys';
import { LOCKED_BY_CURRENT_USER } from '../../constants/lockKeys';
import {
  CONFIDENCE,
  DOES_SMOKE,
  DRINKING_AMOUNT_ID,
  DRINKING_FREQUENCY_ID,
  DRINKING_SIX_FREQUENCY_ID,
  HAS_MEDICINE_SENSITIVITY,
  HAS_RELATIVE_INTESTIONAL_CANCER,
  HAS_SMOKED_DAILY,
  HEIGHT,
  LONG_TERM_ILNESS_IDS,
  MEDICINE_SENSITIVITY,
  REGULAR_MEDICINE_IDS,
  RELATIVE_INTESTIONAL_CANCER_WHO,
  SMOKED_DAILY_AMOUNT_ID,
  SMOKED_DAILY_YEARS_ID,
  WEIGHT,
} from '../../constants/preliminaryFormKeys';
import { excludeValues, yesOrNo } from '../../constantsLists/lists';
import { IOption } from '../../models/IOption';
import { IPreliminaryForm } from '../../models/IPreliminaryForm';
import { DownloadPaperform } from '../../services/paperFormService';
import { formsMutationList } from '../../services/queries/formsQueryHelper';
import { screeningUnlock } from '../../services/queries/screeningLock';
import { RootState } from '../../state/rootReducer';
import { fetchForm, setSubmitForm } from '../../state/screening/actions';
import { Forms } from '../../state/screening/state';
import { Tabs } from '../../state/tabs/state';
import { isEmptyObject } from '../../utils/isEmptyObject';
import EditingBar from '../editingBar/editingBar';
import DatePickerLabel from '../formInputs/datePickerLabel';
import DropdownFormik from '../formInputs/dropdownFormik';
import TextInputLabel from '../formInputs/textInputLabel';
import styles from '../personTabContainer/tabs.module.scss';
import { CreatePreConstants } from './prelimenaryHelper';

const Preliminary: React.FC = () => {
  const { t } = useTranslation();
  const { screening, user } = useSelector((state: RootState) => state);
  const isEditing = screening.screeningLock[LOCKED_BY_CURRENT_USER];
  const dispatch = useDispatch();
  const { validation } = CreatePreConstants();
  const history = useHistory();

  useEffect(() => {
    if (screening.screeningId) {
      dispatch(fetchForm(screening.screeningId, Tabs.Preliminary, t));
    }
  }, [screening.screeningId]);

  const submit = async (
    form: IPreliminaryForm,
    { resetForm }: { resetForm: () => void }
  ) => {
    const handleError = (errors: any[]) => {
      const notAuthorizedError = errors.some(
        (e) => e.extensions.code === 'USER_NOT_AUTHORIZED'
      );
      if (notAuthorizedError) {
        // user changed municipality to something they are not authorized to view
        toast.success(t(`success.${Forms.PRELIMINARY}`));
        dispatch(
          setSubmitForm(
            Forms.SCREENING_LOCK,
            screeningUnlock(screening.screeningId),
            t
          )
        );
        history.push('/');
      } else {
        toast.error(t(`errors.${Forms.PRELIMINARY}`));
      }
    };

    dispatch(
      setSubmitForm(
        Forms.PRELIMINARY,
        formsMutationList[Forms.PRELIMINARY](form),
        t,
        resetForm,
        handleError
      )
    );
  };

  const { confidence, controlId } = screening.preliminaryInfo;

  const downloadPaperform = async () => {
    if (controlId) {
      await DownloadPaperform(t, controlId);
    }
  };

  return (
    <div className="container--fluid">
      <Header
        title={`tabs.${Tabs.Preliminary}`}
        titleClassName={styles.title}
        confidence={
          confidence !== null ? (
            <button
              className={`${styles.title} ${styles.buttontoLink}`}
              onClick={downloadPaperform}
            >
              {t(`preliminaryForm.${CONFIDENCE}`) + ` ${confidence * 100}%`}
            </button>
          ) : undefined
        }
      >
        <>
          <EditingBar hideEditButton={!user[ROLE_IDS]?.includes(5)}>
            <TabLastEdit />
          </EditingBar>
        </>
      </Header>
      <Formik
        initialValues={screening.preliminaryInfo}
        enableReinitialize={true}
        validationSchema={validation}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        onSubmit={submit}
      >
        {({ values, resetForm, setFieldValue }) => (
          <Form>
            <FocusScrollError />
            {/* ROW 1 */}
            <div className="container__row ">
              <div className="grid-4-1st">
                <TextInputLabel
                  src={`preliminaryForm.${SCREENING_YEAR}`}
                  disabled
                  value={screening[SCREENING_YEAR]}
                />
              </div>
              <div className="grid-4-2nd">
                <DropdownFormik
                  src={`preliminaryForm.${MUNICIPALITY_ID}`}
                  onChange={(val) => {
                    if (
                      !window.confirm(t('forms.changeMunicipality')) ||
                      Array.isArray(val)
                    ) {
                      return +values[MUNICIPALITY_ID];
                    } else {
                      return +val.value;
                    }
                  }}
                  dropDown={screening[MUNICIPALITY_ID]}
                  disabled={!isEditing}
                />
              </div>
              <div className="grid-4-3rd">
                <TextInputLabel
                  src={`preliminaryForm.${CONTROL_CODE}`}
                  disabled
                  value={screening[Forms.PRELIMINARY][CONTROL_CODE]}
                />
              </div>
              <div className="grid-4-4th">
                <TextInputLabel
                  src={`preliminaryForm.${SAMPLE_DATE}`}
                  disabled
                  value={
                    moment(screening[SAMPLE_DATE]).isValid()
                      ? moment(screening[SAMPLE_DATE]).format('DD.MM.YYYY')
                      : ''
                  }
                />
              </div>
            </div>
            {/* ROW 2 */}
            <div className="container__row ">
              <div className="grid-4-1st">
                <TextInputLabel
                  src={`preliminaryForm.${HEIGHT}`}
                  disabled={!isEditing}
                />
              </div>

              <div className="grid-4-2nd">
                <TextInputLabel
                  src={`preliminaryForm.${WEIGHT}`}
                  disabled={!isEditing}
                />
              </div>
            </div>
            {/* ROW 3 */}
            <div className="container__row">
              <div className="grid-2-1st">
                <DropdownFormik
                  src={`preliminaryForm.${LONG_TERM_ILNESS_IDS}`}
                  dropDown={screening[LONG_TERM_ILNESS_IDS]}
                  disabled={!isEditing}
                  isMulti={true}
                />
              </div>
            </div>
            {/* ROW 4 */}
            <div className="container__row ">
              <div className="grid-2-1st">
                <DropdownFormik
                  src={`preliminaryForm.${HAS_RELATIVE_INTESTIONAL_CANCER}`}
                  onChange={(option) => {
                    if (Array.isArray(option)) return;
                    if ('' + option.value !== '2') {
                      setFieldValue(RELATIVE_INTESTIONAL_CANCER_WHO, []);
                    }
                    return JSON.parse(option.value);
                  }}
                  dropDown={screening[HAS_RELATIVE_INTESTIONAL_CANCER]}
                  clearOption={false}
                  disabled={!isEditing}
                />
              </div>
              <div className="grid-2-2nd">
                <DropdownFormik
                  src={`preliminaryForm.${RELATIVE_INTESTIONAL_CANCER_WHO}`}
                  dropDown={screening[RELATIVE_INTESTIONAL_CANCER_WHO]}
                  disabled={
                    !isEditing || values[HAS_RELATIVE_INTESTIONAL_CANCER] !== 2
                  }
                  isMulti={true}
                />
              </div>
            </div>
            {/* ROW 5 */}
            <div className="container__row align-end">
              <div className="grid-2-1st">
                <DropdownFormik
                  src={`preliminaryForm.${REGULAR_MEDICINE_IDS}`}
                  dropDown={screening[REGULAR_MEDICINE_IDS]}
                  disabled={!isEditing}
                  isMulti={true}
                />
              </div>
            </div>
            {/* ROW 6 */}
            <div className="container__row">
              <div className="grid-2-1st">
                <DropdownFormik
                  src={`preliminaryForm.${HAS_MEDICINE_SENSITIVITY}`}
                  dropDown={yesOrNo}
                  onChange={(option) => {
                    if (option instanceof Array) return;
                    if (option.value !== 'true') {
                      setFieldValue(MEDICINE_SENSITIVITY, '');
                    }
                    return JSON.parse(option.value);
                  }}
                  disabled={!isEditing}
                />
              </div>
              <div className="grid-2-2nd">
                <TextInputLabel
                  src={`preliminaryForm.${MEDICINE_SENSITIVITY}`}
                  disabled={!isEditing || !values[HAS_MEDICINE_SENSITIVITY]}
                />
              </div>
            </div>
            {/* ROW 7 */}
            <div className="container__row">
              <div className="grid-2-1st">
                <DropdownFormik
                  src={`preliminaryForm.${HAS_SMOKED_DAILY}`}
                  onChange={(option) => {
                    if (option instanceof Array) return;
                    if (option.value !== 'true') {
                      setFieldValue(SMOKED_DAILY_YEARS_ID, '');
                      setFieldValue(SMOKED_DAILY_AMOUNT_ID, '');
                      setFieldValue(DOES_SMOKE, false);
                    }
                    return JSON.parse(option.value);
                  }}
                  dropDown={yesOrNo}
                  disabled={!isEditing}
                />
              </div>
              <div className="grid-4-3rd">
                <TextInputLabel
                  src={`preliminaryForm.${SMOKED_DAILY_YEARS_ID}`}
                  disabled={!isEditing || !values[HAS_SMOKED_DAILY]}
                />
              </div>
              <div className="grid-4-4th">
                <TextInputLabel
                  src={`preliminaryForm.${SMOKED_DAILY_AMOUNT_ID}`}
                  disabled={!isEditing || !values[HAS_SMOKED_DAILY]}
                />
              </div>
            </div>
            {/* ROW 8 */}
            <div className="container__row align-end">
              <div className="grid-2-1st">
                <DropdownFormik
                  src={`preliminaryForm.${DOES_SMOKE}`}
                  dropDown={yesOrNo}
                  disabled={!isEditing || !values[HAS_SMOKED_DAILY]}
                />
              </div>
            </div>
            {/* ROW 9 */}
            <div className="container__row ">
              <div className="grid-2-1st">
                <DropdownFormik
                  src={`preliminaryForm.${DRINKING_FREQUENCY_ID}`}
                  dropDown={screening[DRINKING_FREQUENCY_ID]}
                  onChange={(option: IOption | IOption[]) => {
                    if (option instanceof Array) return;
                    if (option.value === '1') {
                      setFieldValue(DRINKING_AMOUNT_ID, '');
                      setFieldValue(DRINKING_SIX_FREQUENCY_ID, '');
                    }
                    return JSON.parse(option.value);
                  }}
                  disabled={!isEditing}
                />
              </div>
              <div className="grid-2-2nd">
                <DropdownFormik
                  src={`preliminaryForm.${DRINKING_AMOUNT_ID}`}
                  dropDown={screening[DRINKING_AMOUNT_ID]}
                  disabled={!isEditing || values[DRINKING_FREQUENCY_ID] === 1}
                />
              </div>
            </div>
            {/* ROW 10 */}
            <div className="container__row align-end">
              <div className="grid-2-1st">
                <DropdownFormik
                  src={`preliminaryForm.${DRINKING_SIX_FREQUENCY_ID}`}
                  dropDown={screening[DRINKING_SIX_FREQUENCY_ID]}
                  disabled={!isEditing || values[DRINKING_FREQUENCY_ID] === 1}
                />
              </div>
            </div>
            {user[ROLE_IDS]?.includes(5) && (
              <div className="container__row space-between ">
                <Button
                  type="submit"
                  disabled={
                    !isEditing ||
                    isEmptyObject(values, excludeValues) ||
                    screening.isLoading
                  }
                  className={styles.formButton}
                  noSpinner={!isEditing || isEmptyObject(values, excludeValues)}
                >
                  {t('control.save')}
                </Button>
                <Button
                  onClick={() => {
                    dispatch(
                      setSubmitForm(
                        Forms.SCREENING_LOCK,
                        screeningUnlock(screening.screeningId),
                        t
                      )
                    );
                    resetForm({ values: screening[Forms.PRELIMINARY] });
                  }}
                  type="button"
                  noSpinner={!isEditing}
                  disabled={!isEditing || screening.isLoading}
                  className={styles.formButton}
                >
                  {t('control.quit')}
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Preliminary;
