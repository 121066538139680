import React from 'react';
import styles from './changeLangButtons.module.scss';
import { useHistory } from 'react-router-dom';
import { langPath } from '../../utils/pathUtils';
import defineLang from '../../utils/defineLanguage';
import { Languages } from '../../i18n';

const ChangeLangButtons: React.FC = () => {
  const history = useHistory();
  const currentLang = defineLang();

  const handlePath = (lang: string) => {
    const newRoute =
      lang !== Languages.FI
        ? '/' + lang + window.location.pathname.replace('/sv', '')
        : window.location.pathname.replace('/sv', '');
    return history.push(`${newRoute}`);
  };

  return (
    <div className={styles.langs}>
      {/* Language choices commented out for PROB-12101
      {(Object.keys(Languages) as Array<keyof typeof Languages>).map(
        (lang, indx) => (
          <span key={lang}>
            <button
              className={
                Languages[lang] === currentLang
                  ? styles.baldLang
                  : styles.language
              }
              disabled={Languages[lang] === currentLang}
              onClick={() => handlePath(Languages[lang])}
            >
              {lang}
            </button>{' '}
            {indx !== Object.keys(Languages).length - 1 ? <span>|</span> : null}{' '}
          </span>
        )
      )} */}
    </div>
  );
};

export default ChangeLangButtons;
