import axios from 'axios';
import { TFunction } from 'react-i18next';
import { toast } from 'react-toastify';
import { TabAvailability } from '../state/screening/state';
import { redirectAuthFail } from '../utils/redirectAuthFail';
import userManager from '../utils/userManager';
import { tabsAvailabilityQuery } from './queries/tabAvailabilityQuery';

export const CheckTabAvailability = async (
  screenId: number | null,
  t: TFunction
): Promise<TabAvailability | null> => {
  const user = await userManager.getUser();
  if (!user || !screenId) {
    redirectAuthFail(t);
    return null;
  }
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/graph`,
      {
        query: tabsAvailabilityQuery(screenId),
        operationName: 'CheckTabAvailability',
      },
      { headers: { Authorization: `${user.token_type} ${user.access_token}` } }
    );
    if (Array.isArray(res.data.errors)) {
      toast.error(t('errors.CheckTabAvailability'));
      return null;
    }
    return res?.data?.data?.screening;
  } catch (er) {
    toast.error(t('errors.CheckTabAvailability'));
    return null;
  }
};
