import {
  EMAIL,
  MUNICIPALITY_GROUP_IDS,
  MUNICIPALITY_IDS,
  NAME_ID_HAG,
  PHONE_NUMBER,
  ROLE_IDS,
  USER_ID,
} from '../../constants/adminTabel';
import {
  FIRSTNAME,
  LASTNAME,
  MUNICIPALITY_ID,
  ORGANIZATION,
} from '../../constants/commonKeys';
import { Screening } from '../../state/screening/state';
import { needDownload } from '../../utils/isTableReady';
import { formsQueryList } from './formsQueryHelper';

export const currentUserDataQuery = `query FetchCurrentUser {
    currentUser {
      ${FIRSTNAME}
      ${LASTNAME}
      ${ORGANIZATION}
      ${ROLE_IDS}
      ${USER_ID}
      ${PHONE_NUMBER}
      ${NAME_ID_HAG}
      ${EMAIL}
      ${MUNICIPALITY_IDS}
    }
  }
  `;
export const userDataQuery = (
  userId: number,
  screeningstate: Screening
) => `query FetchUserDetails {
  userDetails(userId: ${userId}) {
    ${formsQueryList.user}
  }

  ${needDownload(
    screeningstate,
    ROLE_IDS,
    `roles {
      value:roleId
      langValue: roleId
    }`,
    true
  )} 
  ${needDownload(
    screeningstate,
    MUNICIPALITY_ID,
    `municipalities {
      value: municipalityId
      langValue: code
    }`,
    true
  )}
  ${needDownload(
    screeningstate,
    MUNICIPALITY_GROUP_IDS,
    `municipalityGroups {
      municipalities {
        value: municipalityId
        langValue: code
      }
      municipalityGroupId
    }`,
    true
  )}
}
  `;

export const userCurrentDataQuery = `query FetchCurrentUser {
    currentUser {
      ${FIRSTNAME}
      ${LASTNAME}
      ${ORGANIZATION}
    }
  }
  `;
