export const formCleaning = (
  form: any,
  ListFields: string[],
  emptyListFields: boolean = false
) => {
  const newForm: any = {};
  Object.keys(form).forEach((el) => {
    // If the the field is included in the list and we are NOT emptying the list of field we return the value
    if (ListFields.includes(el) && !emptyListFields) {
      return (newForm[el] = form[el]);
    }

    // If the the field is NOT included in the list and we are emptying the list of field we return the value
    if (!ListFields.includes(el) && emptyListFields) {
      return (newForm[el] = form[el]);
    } else {
      newForm[el] = undefined;
    }
  });
  return newForm;
};
