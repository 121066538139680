import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LOCKED_BY_CURRENT_USER } from '../../constants/lockKeys';
import { RootState } from '../../state/rootReducer';

const PreventRefresh: React.FC = () => {
  const { screeningLock } = useSelector((state: RootState) => state.screening);
  const isEditing = screeningLock[LOCKED_BY_CURRENT_USER];

  useEffect(() => {
    if (isEditing) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }, [isEditing]);
  return <div></div>;
};

export default PreventRefresh;
