import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './sectionHeader.module.scss';
import TooltipIconComponent from '../tooltipComponent/tooltipIconComponent';

interface IHeaderProps {
  desciption?: string;
  src?: string;
  name?: string;
}

const SectionHeader: React.FC<IHeaderProps> = ({ desciption, src, name }) => {
  const { t } = useTranslation();

  return (
    <div className={`container__row ${styles.container}`}>
      {!!name && (
        <div className="container__col-12">
          {t(`${src}.${name}`)}
          <TooltipIconComponent src={src || ''} name={name || ''} />
        </div>
      )}
      {desciption && <p className={styles.description}>{t(`${desciption}`)}</p>}
    </div>
  );
};

export default SectionHeader;
