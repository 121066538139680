import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../components/button/button';
import FocusScrollError from '../../components/focusScrollFormError/focusScrollError';
import Header from '../../components/header/header';
import SectionHeader from '../../components/sectionHeader/sectionHeader';
import { ADITIONAL_INFO, SCREENING_ID } from '../../constants/commonKeys';
import { LOCKED_BY_CURRENT_USER } from '../../constants/lockKeys';
import {
  CANCER_PTNM_GRADUS_ID,
  CANCER_PTNM_M_ID,
  CANCER_PTNM_N_ID,
  CANCER_PTNM_T_ID,
  SURGICAL_PROCEDURE_BY_ID,
  SURGICAL_PROCEDURE_BY_NAME,
  SURGICAL_PROCEDURE_CODE_ID,
  SURGICAL_PROCEDURE_DATE,
  SURGICAL_PROCEDURE_DIAGNOSES,
  SURGICAL_PROCEDURE_DIAGNOSES_DATE,
  SURGICAL_PROCEDURE_DIAGNOSES_LOCATION,
  SURGICAL_PROCEDURE_LOCATION,
  SURGICAL_PROCEDURE_PRIMARY_ID,
} from '../../constants/surgicalProcedureFormKeys';
import { validListSurgical } from '../../constantsLists/validationLists';
import { excludeValues } from '../../constantsLists/lists';
import { screeningUnlock } from '../../services/queries/screeningLock';
import { RootState } from '../../state/rootReducer';
import { fetchForm, setSubmitForm } from '../../state/screening/actions';
import { Forms } from '../../state/screening/state';
import { Tabs } from '../../state/tabs/state';
import { isEmptyObject } from '../../utils/isEmptyObject';
import AsyncDropdown from '../asyncDropdown/asyncDropdown';
import DiagnosesTable from '../diagnoseTable/diagoseTable';
import DropdownFormik from '../formInputs/dropdownFormik';
import EditingBar from '../editingBar/editingBar';
import styles from '../personTabContainer/tabs.module.scss';
import { surgicalSubmit, surgicalValidation } from './surgicalProcedureHelper';
import {
  DIAGNOSES_BY_ID,
  DIAGNOSES_BY_NAME,
  MUTATION_HISTOLOGICAL_DIAGNOSIS,
} from '../../constants/disgnosesTabel';
import DatePickerLabel from '../formInputs/datePickerLabel';
import TextInputLabel from '../formInputs/textInputLabel';
import TabLastEdit from '../../components/tabLastEdit/tabLastEdit';

const Scopy: React.FC = () => {
  const { t } = useTranslation();
  const screening = useSelector((state: RootState) => state.screening);
  const isEditing = screening.screeningLock[LOCKED_BY_CURRENT_USER];
  const validate = surgicalValidation(t);
  const dispatch = useDispatch();

  useEffect(() => {
    if (screening.screeningId) {
      dispatch(fetchForm(screening.screeningId, Tabs.SurgicalProcedure, t));
    }
  }, [screening.screeningId]);

  return (
    <div className="container--fluid">
      <Header
        title={`tabs.${Tabs.SurgicalProcedure}`}
        titleClassName={styles.title}
      >
        <EditingBar>
          <TabLastEdit />
        </EditingBar>
      </Header>
      <Formik
        initialValues={{
          ...screening[Forms.SURGICAL_PROCEDURE],
          [SCREENING_ID]: screening.screeningId,
        }}
        enableReinitialize={true}
        validationSchema={validate}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        onSubmit={(vals, { resetForm }) =>
          surgicalSubmit(vals, t, dispatch, () =>
            resetForm({ values: screening[Forms.SURGICAL_PROCEDURE] })
          )
        }
      >
        {({ values, resetForm }) => (
          <Form>
            <FocusScrollError />
            {/*/////////////////////////////////////////////// ROW 1 ///////////////////////////////////////////////*/}
            <div className="container__row">
              <div className="grid-4-1st">
                <DatePickerLabel
                  src={`surgicalProcedure.${SURGICAL_PROCEDURE_DATE}`}
                  disabled={!isEditing}
                  required={
                    !isEmptyObject(
                      values,
                      validListSurgical[SURGICAL_PROCEDURE_DATE],
                      false
                    )
                  }
                />
              </div>
              <div className="grid-4-2nd">
                <AsyncDropdown
                  defaultOptions={[]}
                  src={`surgicalProcedure.${SURGICAL_PROCEDURE_LOCATION}`}
                  store={1}
                  disabled={!isEditing}
                  required={
                    !isEmptyObject(
                      values,
                      validListSurgical[SURGICAL_PROCEDURE_LOCATION],
                      false
                    )
                  }
                />
              </div>
              <div className="grid-4-3rd">
                <TextInputLabel
                  src={`surgicalProcedure.${SURGICAL_PROCEDURE_BY_NAME}`}
                  disabled={!isEditing}
                />
              </div>
              <div className="grid-4-4th">
                <TextInputLabel
                  src={`surgicalProcedure.${SURGICAL_PROCEDURE_BY_ID}`}
                  disabled={!isEditing}
                />
              </div>
            </div>

            {/*/////////////////////////////////////////////// ROW 2 ///////////////////////////////////////////////*/}
            <div className="container__row">
              <div className="grid-2-1st">
                <DropdownFormik
                  src={`surgicalProcedure.${SURGICAL_PROCEDURE_PRIMARY_ID}`}
                  dropDown={screening[SURGICAL_PROCEDURE_PRIMARY_ID]}
                  disabled={!isEditing}
                />
              </div>
              <div className="grid-2-2nd">
                <AsyncDropdown
                  defaultOptions={[]}
                  src={`surgicalProcedure.${SURGICAL_PROCEDURE_CODE_ID}`}
                  store={2}
                  disabled={
                    !isEditing ||
                    values[SURGICAL_PROCEDURE_PRIMARY_ID] === 2 ||
                    !values[SURGICAL_PROCEDURE_PRIMARY_ID]
                  }
                />
              </div>
            </div>

            {/*/////////////////////////////////////////////// ROW 3 ///////////////////////////////////////////////*/}
            <div className="container__row">
              <div className="grid-2-1st">
                <TextInputLabel
                  src={`surgicalProcedure.${ADITIONAL_INFO}`}
                  disabled={!isEditing}
                />
              </div>
            </div>

            <SectionHeader
              src="surgicalProcedure"
              name="pathologicalSpecimens"
              desciption="surgicalProcedure.diagnosesDesc"
            />

            {/*/////////////////////////////////////////////// ROW 4 ///////////////////////////////////////////////*/}
            <div className="container__row">
              <div className="grid-4-1st">
                <DatePickerLabel
                  src={`surgicalProcedure.${SURGICAL_PROCEDURE_DIAGNOSES_DATE}`}
                  disabled={!isEditing}
                  required={
                    !isEmptyObject(
                      values,
                      validListSurgical[SURGICAL_PROCEDURE_DIAGNOSES_DATE],
                      false
                    )
                  }
                />
              </div>
              <div className="grid-4-2nd">
                <AsyncDropdown
                  defaultOptions={[]}
                  src={`surgicalProcedure.${SURGICAL_PROCEDURE_DIAGNOSES_LOCATION}`}
                  store={1}
                  disabled={!isEditing}
                  required={
                    !isEmptyObject(
                      values,
                      validListSurgical[SURGICAL_PROCEDURE_DIAGNOSES_LOCATION],
                      false
                    )
                  }
                />
              </div>
              <div className="grid-4-3rd">
                <TextInputLabel
                  src={`surgicalProcedure.${DIAGNOSES_BY_NAME}`}
                  disabled={!isEditing}
                />
              </div>
              <div className="grid-4-4th">
                <TextInputLabel
                  src={`surgicalProcedure.${DIAGNOSES_BY_ID}`}
                  disabled={!isEditing}
                />
              </div>
            </div>

            {/*/////////////////////////////////////////////// ROW 5 ///////////////////////////////////////////////*/}
            <DiagnosesTable
              disabled={!isEditing}
              name={SURGICAL_PROCEDURE_DIAGNOSES}
            />

            {/*/////////////////////////////////////////////// ROW 6 ///////////////////////////////////////////////*/}
            <div className="container__row">
              <div className="container__col-12">
                <TextInputLabel
                  src={`surgicalProcedure.${MUTATION_HISTOLOGICAL_DIAGNOSIS}`}
                  disabled={!isEditing}
                />
              </div>
            </div>
            {/*/////////////////////////////////////////////// ROW 7 ///////////////////////////////////////////////*/}
            <SectionHeader src="surgicalProcedure" name="pTNMClassification" />
            <div className="container__row">
              <div className="grid-4-1st">
                <DropdownFormik
                  src={`surgicalProcedure.${CANCER_PTNM_T_ID}`}
                  dropDown={screening[CANCER_PTNM_T_ID]}
                  disabled={!isEditing}
                />
              </div>
              <div className="grid-4-2nd">
                <DropdownFormik
                  src={`surgicalProcedure.${CANCER_PTNM_N_ID}`}
                  dropDown={screening[CANCER_PTNM_N_ID]}
                  disabled={!isEditing}
                />
              </div>
              <div className="grid-4-3rd">
                <DropdownFormik
                  dropDown={screening[CANCER_PTNM_M_ID]}
                  src={`surgicalProcedure.${CANCER_PTNM_M_ID}`}
                  disabled={!isEditing}
                />
              </div>
              <div className="grid-4-4th">
                <DropdownFormik
                  src={`surgicalProcedure.${CANCER_PTNM_GRADUS_ID}`}
                  dropDown={screening[CANCER_PTNM_GRADUS_ID]}
                  disabled={
                    !isEditing || values[SURGICAL_PROCEDURE_PRIMARY_ID] === 3
                  }
                />
              </div>
            </div>
            <div className="container__row space-between ">
              <Button
                type="submit"
                disabled={
                  !isEditing ||
                  screening.isLoading ||
                  isEmptyObject(values, excludeValues)
                }
                className={styles.formButton}
                noSpinner={!isEditing || isEmptyObject(values, excludeValues)}
              >
                {t('control.save')}
              </Button>
              <Button
                onClick={() => {
                  resetForm();
                  dispatch(
                    setSubmitForm(
                      Forms.SCREENING_LOCK,
                      screeningUnlock(screening.screeningId),
                      t
                    )
                  );
                }}
                noSpinner={!isEditing}
                disabled={!isEditing || screening.isLoading}
                className={styles.formButton}
              >
                {t('control.quit')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Scopy;
