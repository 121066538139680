import { Language } from './state';
import { ActionTypes, LanguageActionTypes, setLanguage } from './actions';

export const initialLanguageState: Language = {
  language: 'fi',
};

export const LanguageReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: Language = initialLanguageState,
  action: LanguageActionTypes
): Language => {
  switch (action.type) {
    case ActionTypes.setLanguage:
      return {
        ...state,
        language: action.payload,
      };
    default:
      return state;
  }
};
