import { AnyAction, CombinedState, combineReducers } from 'redux';
import {
  AnnouncementsReducer,
  initialAnnouncementState,
} from './announcements/reducer';
import { AnnouncementsState } from './announcements/state';
import { initialLanguageState, LanguageReducer } from './language/reducer';
import { Language } from './language/state';
import { initialScreeningState, ScreeningReducer } from './screening/reducer';
import { Screening } from './screening/state';
import {
  initialSearchKeysState,
  SearchKeysReducer,
} from './searchKeys/reducer';
import { SearchKeys } from './searchKeys/state';
import { initialTabState, TabReducer } from './tabs/reducer';
import { Tab } from './tabs/state';
import { initialUsersState, UserReducer } from './user/reducer';
import { CurrentUser } from './user/state';
export interface RootState {
  tab: Tab;
  user: CurrentUser;
  screening: Screening;
  language: Language;
  searchKeys: SearchKeys;
  announcements: AnnouncementsState;
}

export const initialRootState = {
  searchKeys: initialSearchKeysState,
  tab: initialTabState,
  user: initialUsersState,
  screening: initialScreeningState,
  language: initialLanguageState,
  announcements: initialAnnouncementState,
};

export const appReducer = combineReducers<RootState>({
  searchKeys: SearchKeysReducer,
  tab: TabReducer,
  user: UserReducer,
  screening: ScreeningReducer,
  language: LanguageReducer,
  announcements: AnnouncementsReducer,
});

export const rootReducer = (
  state: CombinedState<RootState> | undefined,
  action: AnyAction
) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'LOGOUT_USER') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
