import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { STATUS, STATUS_LIST } from '../../constants/commonKeys';
import { RootState } from '../../state/rootReducer';
import Select from 'react-select';
import { CustomOption, CustomValue } from './screeningStatusHelper';
import styles from './screeningStatus.module.scss';
import { Forms } from '../../state/screening/state';
import { setSubmitForm } from '../../state/screening/actions';
import { formsMutationList } from '../../services/queries/formsQueryHelper';

export const ScreeningStatus: React.FC = () => {
  const screening = useSelector((state: RootState) => state.screening);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const translateOptions = screening[STATUS_LIST]?.map((el) => ({
    label: t(`${STATUS}.${el.langValue}`),
    value: el.value,
    langValue: el.langValue,
  }));

  return (
    <>
      <div>
        <label className={styles.label}>
          <span>{t(`personFrom.${STATUS}`)}</span>
        </label>
      </div>
      <Select
        options={translateOptions}
        components={{ Option: CustomOption, SingleValue: CustomValue }}
        onChange={(val) => {
          if (!val || !+val.value || +val.value === screening[STATUS]) {
            return;
          }
          dispatch(
            setSubmitForm(
              Forms.STATUS,
              formsMutationList[Forms.STATUS](
                screening.screeningId,
                screening[STATUS],
                +val.value
              ),
              t
            )
          );
        }}
        value={translateOptions?.find((el) => +el.value === screening[STATUS])}
        placeholder={t('forms.select')}
      />
    </>
  );
};
