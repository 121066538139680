import React from 'react';
import { useHistory } from 'react-router-dom';
import { SignoutCallbackComponent } from 'redux-oidc';
import userManager from '../../utils/userManager';

const SingoutOidc: React.FC = () => {
  const history = useHistory();
  return (
    <SignoutCallbackComponent
      userManager={userManager}
      successCallback={() => {
        return history.push('/');
      }}
      errorCallback={() => {}}
    >
      <div />
    </SignoutCallbackComponent>
  );
};

export default SingoutOidc;
