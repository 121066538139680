import Axios, { AxiosResponse } from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IAnnouncement } from '../../models/IAnnouncement';
import { AnnouncementsState } from './state';
import defineLanguage from '../../utils/defineLanguage';
import userManager from '../../utils/userManager';

export enum ActionTypes {
  FetchingSuccessful = 'FETCHING_ANNOUNCEMENTS_SUCCESSFUL',
  FetchingError = 'FETCHING_ANNOUNCEMENTS_ERROR',
  Fetching = 'FETCHING_ANNOUNCEMENTS',
  Infoclosed = 'INFO_CLOSED',
  Warningclosed = 'WARNING_CLOSED',
}

interface FetchingSuccessfulAction extends Action {
  type: ActionTypes.FetchingSuccessful;
  payload: IAnnouncement[];
}
interface InfoBoxClosedAction extends Action {
  type: ActionTypes.Infoclosed;
}
interface WarningBoxClosedAction extends Action {
  type: ActionTypes.Warningclosed;
}
interface FetchingSuccessfulAction extends Action {
  type: ActionTypes.FetchingSuccessful;
  payload: IAnnouncement[];
}

interface FetchingErrorAction extends Action {
  type: ActionTypes.FetchingError;
  payload: string;
}

interface FetchingAction extends Action {
  type: ActionTypes.Fetching;
}

export type AnnouncementActionTypes =
  | FetchingSuccessfulAction
  | FetchingErrorAction
  | WarningBoxClosedAction
  | InfoBoxClosedAction
  | FetchingAction;

type AnnouncementsThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AnnouncementsState,
  unknown,
  AnnouncementActionTypes
>;

export const boxClosed = (warning: boolean) => ({
  type: warning ? ActionTypes.Warningclosed : ActionTypes.Infoclosed,
});

export const FetchAnnouncementData = (): AnnouncementsThunk => async (
  dispatch,
  getState
) => {
  try {
    const state = getState();
    const user = await userManager.getUser();
    if (!state.fetching && !state.haveData && user) {
      dispatch({
        type: ActionTypes.Fetching,
      });
      const language = defineLanguage();
      const params = `?locale=${language === 'sv' ? 'sv-SE' : 'fi-FI'}`;

      Axios.get(`${process.env.REACT_APP_API_URL}/announcements${params}`, {
        headers: {
          Authorization: `${user.token_type} ${user.access_token}`,
        },
      }).then((res: AxiosResponse<any>) => {
        const announcements: IAnnouncement[] = res.data;
        if (announcements.length) {
          dispatch({
            type: ActionTypes.FetchingSuccessful,
            payload: announcements,
          });
        }
      });
    }
  } catch (e) {
    dispatch({
      type: ActionTypes.FetchingError,
      payload: 'Error fetching announcements!',
    });
  }
};
