import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../state/rootReducer';
import { setLoadingValue } from '../../state/screening/actions';
import wasmLoader from '../../utils/wasmLoader';

const NotFoundPage: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { hasLocations, isLoading } = useSelector(
    (state: RootState) => state.screening
  );

  useEffect(() => {
    if (!hasLocations) {
      dispatch(setLoadingValue(true));
      wasmLoader(dispatch, t)
        .then(() => {
            dispatch(setLoadingValue(false));
        })
        .catch((er) => {
          dispatch(setLoadingValue(false));
          console.error(er);
        })
        .finally(()=>dispatch(setLoadingValue(false)));
    }
  }, []);

  return <></>;
};

export default NotFoundPage;
