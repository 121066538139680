export const needDownload = (
  obj: any,
  key: string,
  table: string,
  nochange: boolean = false,
  langValue = 'code',
  value = 'databaseId'
) => {
  if (
    Array.isArray(obj[key]) &&
    obj[key].length > 0 &&
    (obj[key][0].value === undefined || obj[key][0].value !== 'null')
  ) {
    return '';
  }
  if (nochange) {
    return `${key}: ${table}`;
  } else {
    return `${key}: codeTable(type: ${table}) {
            value: ${value}
            langValue: ${langValue}
            }`;
  }
};
