export const SCOPY_NOT_PERFORMED_REASON = 'colonoscopyNotDoneReasonId';
export const PREVIOUS_YEAR = 'previousColonoscopyYear';
export const OTHER_REASONS = 'colonoscopyNotDoneOtherReason';
export const SCOPY_DIAGNOSES = 'colonoscopyDiagnoses';
export const BOWEL_EMPTY_SUCCESS_ID = 'bowelEmptyingSuccessId';
export const COMPLICATION_IDS = 'colonoscopyComplicationIds';
export const DECISION_DATE = 'colonoscopyDecisionDate';
export const SCOPY_DECISION_BY_ID = 'colonoscopyDecisionDoneById';
export const DECISION_BY_NAME = 'colonoscopyDecisionDoneByName';
export const DECISION_LOCATION = 'colonoscopyDecisionLocationId';
export const DECISION_ID = 'colonoscopyDecisionId';
export const SCOPY_FINDING_IDS = 'colonoscopyFindingIds';
export const SCOPY_LIMITATION_IDS = 'colonoscopyLimitationIds';
export const SCOPY_PROCEDURE_IDS = 'colonoscopyProcedureIds';
export const SCOPY_REACHED_ID = 'colonoscopyReachId';
export const SCOPY_REFERRAL_LOCATION = 'colonoscopyReferralLocationId';
export const SCOPY_RETRIEVAL_MIN = 'colonoscopyRetrievalTimeMin';
export const DIAGNOSES_DATE = 'diagnosisDate';
export const DIAGNOSES_LOCATION = 'diagnosisLocationId';
export const HAD_INNER_TURN_DONE = 'hadInnerTurnDone';
export const HAD_INTERVAENOUS_PREMEDICATION = 'hadIntravenousPremedication';
export const SCOPY_DATE = 'colonoscopyDate';
export const SCOPY_LOCATION_ID = 'colonoscopyLocationId';
export const SCOPY_DONE_BY_ID = 'colonoscopyDoneById';
export const SCOPY_DONE_BY_NAME = 'colonoscopyDoneByName';
export const FOLLOW_UP_DECISION_ID_COLONSCOPY = 'followUpDecisionIdColonoscopy';
export const SCOPY_OTHER_LIMITS = 'colonoscopyLimitationOther';
export const SCOPY_OTHER_FINDINGS = 'colonoscopyOtherFindingDescription';
