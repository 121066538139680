import React from 'react';
import Dropdown from '../../components/dropdown/dropdown';
import { useTranslation } from 'react-i18next';
import { IOption } from '../../models/IOption';
import { useField } from 'formik';
import TooltipLabel from '../../components/tooltipLabel/tooltipLabel';

interface IDropDownProps {
  src: string;
  dropDown: IOption[];
  disabled?: boolean;
  labelClass?: string;
  required?: boolean;
  isMulti?: boolean;
  optionSrc?: string;
  customValue?: any;
  clearOption?: boolean;
  onChange?: (op: IOption | IOption[]) => boolean | number;
}

const DropdownFormik: React.FC<IDropDownProps> = ({
  src,
  disabled,
  isMulti,
  dropDown,
  optionSrc,
  labelClass,
  required,
  customValue,
  clearOption,
  onChange,
}) => {
  const { t } = useTranslation();
  const [form, name] = src.split('.');
  const [{ value }] = useField(name);

  const optionsTranslate = (options: IOption[]) => {
    if (!options) {
      return [];
    }
    return options
      .filter((el) => {
        if (isMulti && Array.isArray(value))
          return !value?.includes(JSON.parse(el.value));
        if (!isMulti && !Array.isArray(value))
          return value !== JSON.parse(el.value);
        return true;
      })
      .map((option) => ({
        value: '' + option.value,
        label: t(`${optionSrc || name}.${option.langValue}`),
      }));
  };

  return (
    <div data-testid={name}>
      <TooltipLabel
        label={form}
        name={name}
        labelClassName={labelClass}
        required={required}
      />
      <Dropdown
        options={optionsTranslate(dropDown)}
        name={name}
        onChange={(option) => {
          if (onChange) {
            return onChange(option);
          }
          if (isMulti && Array.isArray(option))
            return option.map((el) => JSON.parse(el.value));
          if (!isMulti && !Array.isArray(option))
            return JSON.parse(option.value);
        }}
        clearOption={clearOption}
        value={dropDown
          .filter((el) => {
            const currentVal = customValue || value;
            if (isMulti && Array.isArray(currentVal))
              return currentVal?.includes(JSON.parse(el.value));
            if (!isMulti && !Array.isArray(currentVal))
              return currentVal === JSON.parse(el.value);
            return false;
          })
          .map((el) => ({
            value: el.value,
            label: t(`${optionSrc ? optionSrc : name}.${el.langValue}`),
          }))}
        disabled={disabled}
        isMulti={isMulti}
      />
    </div>
  );
};

DropdownFormik.defaultProps = {
  disabled: false,
  clearOption: true,
};

export default DropdownFormik;
