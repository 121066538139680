import {
  ROLE_IDS,
  MODIFIED_AT,
  MODIFIED_BY,
  MUNICIPALITY_IDS,
  PERMISSION_STATUS,
  END_DATE,
  USER_ID,
  START_DATE,
  NAME_ID_HAG,
  COMMENT,
  CREATED_AT,
  EMAIL,
  IS_DELETED,
  IS_DISABLED,
  ORGANIZATION,
  PHONE_NUMBER,
} from '../constants/adminTabel';
import { FIRSTNAME, LASTNAME } from '../constants/commonKeys';

export type RolesId = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
export interface IUser {
  [FIRSTNAME]: string | null;
  [LASTNAME]: string | null;
  [ROLE_IDS]: RolesId[] | null;
  [MODIFIED_AT]: string | null;
  [MODIFIED_BY]: string | null;
  [MUNICIPALITY_IDS]: number[] | null;
  [NAME_ID_HAG]: string | null;
  [PERMISSION_STATUS]: boolean;
  [START_DATE]: string | null;
  [END_DATE]: string | null;
  [USER_ID]: string | null;
  [COMMENT]: string | null;
  [EMAIL]: string | null;
  [CREATED_AT]: string | null;
  [IS_DELETED]: boolean | null;
  [IS_DISABLED]: boolean | null;
  [PHONE_NUMBER]: string | null;
  [ORGANIZATION]: string | null;
}

// TODO add null to all
export const initialUserForm = {
  [FIRSTNAME]: null,
  [LASTNAME]: null,
  [ROLE_IDS]: [],
  [MODIFIED_AT]: null,
  [MODIFIED_BY]: null,
  [MUNICIPALITY_IDS]: null,
  [NAME_ID_HAG]: null,
  [PERMISSION_STATUS]: false,
  [START_DATE]: null,
  [END_DATE]: null,
  [USER_ID]: null,
  [COMMENT]: null,
  [EMAIL]: null,
  [CREATED_AT]: null,
  [IS_DELETED]: null,
  [IS_DISABLED]: null,
  [PHONE_NUMBER]: null,
  [ORGANIZATION]: null,
};
