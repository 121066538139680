import moment from 'moment';
import { TFunction } from 'react-i18next';
import { Dispatch } from 'redux';
import * as Yup from 'yup';
import {
  ADITIONAL_INFO,
  IS_INERRUPTED,
  NUMBER_POLYP_ID,
} from '../../constants/commonKeys';
import {
  FOLLOW_UP_BY_ID,
  FOLLOW_UP_BY_NAME,
  FOLLOW_UP_DATE,
  FOLLOW_UP_DECISION_ID,
  FOLLOW_UP_LOCATION,
  FOLLOW_UP_REFERRAL_LOCATION,
} from '../../constants/followUp';
import {
  RADIOLOGIST_ID,
  RADIOLOGIST_NAME,
  RADIOLOGIST_STATMENT,
  RADIOLOGIST_STATMENT_DATE,
  RADIOLOGIST_STATMENT_LOCATION,
  TTGRAPHY_BY_ID,
  TTGRAPHY_BY_NAME,
  TTGRAPHY_DATE,
  TTGRAPHY_FINDING_IDS,
  TTGRAPHY_LOCATION,
  TTGRAPHY_OTHER_FINDINGS,
} from '../../constants/ttgraphyFromKeys';
import {
  validationNumberValues,
  validListGraphy,
} from '../../constantsLists/validationLists';
import { IColongraphyFrom } from '../../models/ITTGaphyFrom';
import { formsMutationList } from '../../services/queries/formsQueryHelper';
import {
  setLoadingValue,
  setScreeningData,
  setSubmitForm,
} from '../../state/screening/actions';
import { Forms } from '../../state/screening/state';
import { formCleaning } from '../../utils/formCleaning';
import { isEmptyObject } from '../../utils/isEmptyObject';

export const validateColongraphy = (t: TFunction) =>
  Yup.object().shape({
    [TTGRAPHY_DATE]: Yup.date()
      .required(t('forms.requiredField'))
      .test(TTGRAPHY_DATE, t('forms.futureData'), (val) => {
        if (!val) return true;
        return moment(val).isSameOrBefore(moment(), 'day');
      }),
    [TTGRAPHY_LOCATION]: Yup.number()
      .required(t('forms.requiredLocation'))
      .typeError(t('forms.requiredLocation')),
    [TTGRAPHY_BY_NAME]: Yup.string()
      .nullable()
      .max(
        validationNumberValues[TTGRAPHY_BY_NAME].max,
        t('forms.mustBeStringMax', {
          maxCharacter: validationNumberValues[TTGRAPHY_BY_NAME].max,
        })
      )
      .when(IS_INERRUPTED, {
        is: true,
        then: Yup.string().required(t('forms.requiredField')),
      }),
    [TTGRAPHY_BY_ID]: Yup.string()
      .nullable()
      .max(
        validationNumberValues[TTGRAPHY_BY_ID].max,
        t('forms.mustBeStringMax', {
          maxCharacter: validationNumberValues[TTGRAPHY_BY_ID].max,
        })
      ),
    [RADIOLOGIST_NAME]: Yup.string()
      .nullable()
      .max(
        validationNumberValues[RADIOLOGIST_NAME].max,
        t('forms.mustBeStringMax', {
          maxCharacter: validationNumberValues[RADIOLOGIST_NAME].max,
        })
      ),
    [RADIOLOGIST_ID]: Yup.string()
      .nullable()
      .max(
        validationNumberValues[RADIOLOGIST_ID].max,
        t('forms.mustBeStringMax', {
          maxCharacter: validationNumberValues[RADIOLOGIST_ID].max,
        })
      ),
    [RADIOLOGIST_STATMENT_DATE]: Yup.date()
      .nullable()

      .test(
        RADIOLOGIST_STATMENT_DATE,
        t('forms.requiredField'),
        function (val) {
          const values: IColongraphyFrom = this.parent;
          return !(
            !val &&
            !values[IS_INERRUPTED] &&
            !isEmptyObject(
              values,
              validListGraphy[RADIOLOGIST_STATMENT_DATE],
              false
            )
          );
        }
      )
      .test(
        RADIOLOGIST_STATMENT_DATE,
        t('forms.needMoreGraphy'),
        function (val) {
          const values: IColongraphyFrom = this.parent;
          return !(
            !!val &&
            !values[IS_INERRUPTED] &&
            isEmptyObject(values, validListGraphy.graphyStatmentMin, false)
          );
        }
      )
      .test(RADIOLOGIST_STATMENT_DATE, t('forms.futureData'), (val) => {
        if (!val) return true;
        return moment(val).isSameOrBefore(moment(), 'day');
      }),
    [RADIOLOGIST_STATMENT_LOCATION]: Yup.number()
      .nullable()
      .test(
        RADIOLOGIST_STATMENT_LOCATION,
        t('forms.requiredLocation'),
        function (val) {
          const values: IColongraphyFrom = this.parent;
          return !(
            !val &&
            val !== 0 &&
            !values[IS_INERRUPTED] &&
            !isEmptyObject(
              values,
              validListGraphy[RADIOLOGIST_STATMENT_LOCATION],
              false
            )
          );
        }
      )
      .test(
        RADIOLOGIST_STATMENT_LOCATION,
        t('forms.needMoreGraphy'),
        function (val) {
          const values: IColongraphyFrom = this.parent;
          return !(
            !!val &&
            val !== 0 &&
            !values[IS_INERRUPTED] &&
            isEmptyObject(values, validListGraphy.graphyStatmentMin, false)
          );
        }
      ),
    [FOLLOW_UP_DATE]: Yup.date()
      .nullable()

      .test(FOLLOW_UP_DATE, t('forms.requiredField'), function (val) {
        const values: IColongraphyFrom = this.parent;
        return !(
          !val &&
          !values[IS_INERRUPTED] &&
          !isEmptyObject(values, validListGraphy.followUpDisabled, false) &&
          !isEmptyObject(values, validListGraphy[FOLLOW_UP_DATE], false)
        );
      })
      .test(FOLLOW_UP_DATE, t('forms.futureData'), (val) => {
        if (!val) return true;
        return moment(val).isSameOrBefore(moment(), 'day');
      }),
    [FOLLOW_UP_LOCATION]: Yup.number()
      .nullable()
      .test(FOLLOW_UP_LOCATION, t('forms.requiredLocation'), function (val) {
        const values: IColongraphyFrom = this.parent;
        return !(
          !val &&
          val !== 0 &&
          !values[IS_INERRUPTED] &&
          !isEmptyObject(values, validListGraphy.followUpDisabled, false) &&
          !isEmptyObject(values, validListGraphy[FOLLOW_UP_LOCATION], false)
        );
      }),

    [FOLLOW_UP_DECISION_ID]: Yup.number()
      .nullable()
      .test(FOLLOW_UP_DECISION_ID, t('forms.requiredField'), function (val) {
        const values: IColongraphyFrom = this.parent;
        return !(
          !val &&
          !values[IS_INERRUPTED] &&
          !isEmptyObject(values, validListGraphy.followUpDisabled, false) &&
          !isEmptyObject(values, validListGraphy[FOLLOW_UP_DECISION_ID], false)
        );
      }),
    [NUMBER_POLYP_ID]: Yup.number()
      .nullable()
      .when(TTGRAPHY_FINDING_IDS, {
        is: (val) => val?.includes(3),
        then: Yup.number().required(t('forms.requiredField')),
      }),
    [TTGRAPHY_OTHER_FINDINGS]: Yup.string()
      .nullable()
      .max(
        validationNumberValues[TTGRAPHY_OTHER_FINDINGS].max,
        t('forms.mustBeStringMax', {
          maxCharacter: validationNumberValues[TTGRAPHY_OTHER_FINDINGS].max,
        })
      ),
    [RADIOLOGIST_STATMENT]: Yup.string()
      .nullable()
      .max(
        validationNumberValues[RADIOLOGIST_STATMENT].max,
        t('forms.mustBeStringMax', {
          maxCharacter: validationNumberValues[RADIOLOGIST_STATMENT].max,
        })
      ),
    [ADITIONAL_INFO]: Yup.string()
      .nullable()
      .max(
        validationNumberValues[ADITIONAL_INFO].max,
        t('forms.mustBeStringMax', {
          maxCharacter: validationNumberValues[ADITIONAL_INFO].max,
        })
      ),
    [FOLLOW_UP_BY_ID]: Yup.string()
      .nullable()
      .max(
        validationNumberValues[FOLLOW_UP_BY_ID].max,
        t('forms.mustBeStringMax', {
          maxCharacter: validationNumberValues[FOLLOW_UP_BY_ID].max,
        })
      ),
    [FOLLOW_UP_BY_NAME]: Yup.string()
      .nullable()
      .max(
        validationNumberValues[FOLLOW_UP_BY_NAME].max,
        t('forms.mustBeStringMax', {
          maxCharacter: validationNumberValues[FOLLOW_UP_BY_NAME].max,
        })
      ),
    [FOLLOW_UP_REFERRAL_LOCATION]: Yup.string()
      .nullable()
      .when(FOLLOW_UP_DECISION_ID, {
        is: (val) => val && val !== 5 && val !== 1,
        then: Yup.string().required(t('forms.requiredLocation')),
      }),
  });

export const colonographySubmit = async (
  form: IColongraphyFrom,
  t: TFunction,
  dispatch: Dispatch<any>,
  resetForm: () => void
) => {
  dispatch(setLoadingValue(true));
  dispatch(setScreeningData({ [Forms.TTGRAPHY]: form }));
  let cleanedForm = form;
  // clean if interrupted
  if (cleanedForm[IS_INERRUPTED]) {
    cleanedForm = formCleaning(cleanedForm, validListGraphy.formInerrupted);
  }
  // if the interruption is cancelled we clean ttTTGraphyDoneByName and ttTTGraphyDoneById
  if (!cleanedForm[IS_INERRUPTED]) {
    cleanedForm = formCleaning(
      cleanedForm,
      [TTGRAPHY_BY_ID, TTGRAPHY_BY_NAME],
      true
    );
  }
  // we clean Followup if it was filled and then disabled since there weren't the needed data
  if (isEmptyObject(cleanedForm, validListGraphy.followUpDisabled, false)) {
    cleanedForm = formCleaning(cleanedForm, validListGraphy.followUp, true);
  }
  // if ttTTGraphyFindingIds array doesn't have polyppi we clean numberOfPolypsId
  if (!cleanedForm[TTGRAPHY_FINDING_IDS]?.includes(3)) {
    cleanedForm = formCleaning(cleanedForm, [NUMBER_POLYP_ID], true);
  }
  // if colonoscopyFindingId array doesn't have Muu we clean it SCOPY_OTHER_FINDINGS
  if (!cleanedForm[TTGRAPHY_FINDING_IDS]?.includes(7)) {
    cleanedForm = formCleaning(cleanedForm, [TTGRAPHY_OTHER_FINDINGS], true);
  }
  // if followUpDecisionId's value is 1 or 5 we clean followUpReferralLocationId
  if (
    cleanedForm[FOLLOW_UP_DECISION_ID] === 1 ||
    cleanedForm[FOLLOW_UP_DECISION_ID] === 5
  ) {
    cleanedForm = formCleaning(
      cleanedForm,
      [FOLLOW_UP_REFERRAL_LOCATION],
      true
    );
  }

  dispatch(
    setSubmitForm(
      Forms.TTGRAPHY,
      formsMutationList[Forms.TTGRAPHY](cleanedForm),
      t,
      resetForm
    )
  );
};
