import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../components/button/button';
import {
  IS_LOCKED,
  LOCKED_BY_CURRENT_USER,
  LOCKED_BY_NAME,
} from '../../constants/lockKeys';
import { screeningLock } from '../../services/queries/screeningLock';
import { RootState } from '../../state/rootReducer';
import { setSubmitForm } from '../../state/screening/actions';
import { Forms } from '../../state/screening/state';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import styles from './editingBar.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IEditingBarProps {
  hideEditButton?: boolean;
  children?: React.ReactElement;
}

const EditingBar: React.FC<IEditingBarProps> = ({
  children,
  hideEditButton = false,
}) => {
  const { t } = useTranslation();
  const { screening, tab } = useSelector((state: RootState) => state);
  const isEditing = screening.screeningLock[LOCKED_BY_CURRENT_USER];
  const dispatch = useDispatch();

  const editTab = async () => {
    if (screening.screeningId) {
      dispatch(
        setSubmitForm(
          Forms.SCREENING_LOCK,
          screeningLock(screening.screeningId, tab.currentTab),
          t
        )
      );
    }
  };

  return (
    <div className="container__row rrr align-center">
      <div className="grid-2-1st">
        {children || <span>&nbsp;</span>}
      </div>
      <div className="grid-2-2nd">
        {!screening.isLatestScreening && 
          <span className='warning-text'>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            &nbsp;&nbsp; <b>Huom.!</b> Tämä ei ole henkilön viimeisin seulonta.
          </span>
        }
        <div className={styles.buttons}>
          {!hideEditButton && (
            <Button
              disabled={
                isEditing ||
                screening.isLoading ||
                (screening.screeningLock[IS_LOCKED] &&
                  !screening.screeningLock[LOCKED_BY_CURRENT_USER])
              }
              noSpinner={
                (!screening.isLoading) ||
                isEditing ||
                (screening.screeningLock[IS_LOCKED] &&
                  !screening.screeningLock[LOCKED_BY_CURRENT_USER])
              }
              onClick={editTab}
              className={styles.button}
            >
              {screening.screeningLock[IS_LOCKED] &&
              !screening.screeningLock[LOCKED_BY_CURRENT_USER]
                ? t('control.lockedFor', {
                    name: screening.screeningLock[LOCKED_BY_NAME],
                  })
                : t('control.edit')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
export default EditingBar;
