import axios from 'axios';
import { TFunction } from 'react-i18next';
import { toast } from 'react-toastify';
import { redirectAuthFail } from '../utils/redirectAuthFail';
import userManager from '../utils/userManager';

export const DownloadPaperform = async (
  t: TFunction,
  controlId: number
): Promise<void | null> => {
  const user = await userManager.getUser();
  if (!user) {
    redirectAuthFail(t);
    return null;
  }
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/files/preinfo/${controlId}`,
      {
        headers: { Authorization: `${user.token_type} ${user.access_token}` },
        responseType: 'blob',
      }
    );
    var file = new Blob([res.data], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  } catch (er) {
    toast.error(t('errors.preliminaryTab'));
    return null;
  }
};
