import React from 'react';
import Layout from '../../features/layout/layout';

const NotFoundPage: React.FC = () => {
  return (
    <Layout>
      <div>404 not found</div>
    </Layout>
  );
};

export default NotFoundPage;
