import axios from 'axios';
import { TFunction } from 'react-i18next';
import { toast } from 'react-toastify';
import { SCREENING_ID } from '../constants/commonKeys';
import { Forms, ScreeningState } from '../state/screening/state';
import { Tabs } from '../state/tabs/state';
import { flattenObj } from '../utils/flatternObject';
import { redirectAuthFail } from '../utils/redirectAuthFail';
import userManager from '../utils/userManager';
import { errorsHandler, lockMessageHelper } from './FormServiceHelper';
import { formQuery } from './queries/formFetchQuery';
import { CheckTabAvailability } from './tabAvailabilityService';

export const FetchFromData = async (
  tab: Tabs,
  id: number,
  screening: ScreeningState,
  t: TFunction
): Promise<Partial<ScreeningState> | null> => {
  const user = await userManager.getUser();
  if (!user) {
    redirectAuthFail(t);
    return null;
  }
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/graph`,
      {
        query: formQuery(screening, id, tab),
        operationName: `Fetch${tab}Data`,
      },
      { headers: { Authorization: `${user.token_type} ${user.access_token}` } }
    );
    const resData = res?.data?.data;
    // check if errors
    if (Array.isArray(res.data.errors)) {
      toast.error(t(`errors.${tab}`));
      return null;
    }
    return flattenObj(resData, [
      Forms.SCREENING_LOCK,
      Forms.PERSON_LOCK,
      Forms.ANALYSIS_RESULT,
      Forms.PRELIMINARY,
      Forms.SCOPY,
      Forms.TTGRAPHY,
      Forms.SURGICAL_PROCEDURE,
      Forms.PERSON,
    ]);
  } catch (er) {
    toast.error(t(`errors.${tab}`));
    return null;
  }
};

export const SubmitFormData = async (
  formName: Forms,
  query: string,
  t: TFunction,
  screeningId?: number,
  onError?: (errors: any[]) => void
): Promise<Partial<ScreeningState> | null> => {
  const user = await userManager.getUser();

  if (!user) {
    redirectAuthFail(t);
    return null;
  }
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/graph`,
      {
        query,
        operationName: `Submit${formName}`,
      },
      { headers: { Authorization: `${user.token_type} ${user.access_token}` } }
    );
    let resData = res?.data?.data;
    // check if errors
    if (Array.isArray(res.data.errors)) {
      if (onError) {
        onError(res.data.errors);
        return null;
      } else {
        return errorsHandler(res.data.errors, formName, t);
      }
    }

    if (formName === Forms.SCREENING_LOCK || formName === Forms.PERSON_LOCK) {
      const newData = flattenObj(resData, [
        Forms.SCREENING_LOCK,
        Forms.PERSON_LOCK,
        Forms.PRELIMINARY,
        Forms.SCOPY,
        Forms.TTGRAPHY,
        Forms.SURGICAL_PROCEDURE,
        Forms.PERSON,
      ]);
      if (newData[formName]) {
        lockMessageHelper(newData[formName], t);
        return newData;
      } else {
        lockMessageHelper(newData, t);
        return { [formName]: newData };
      }
    }
    toast.success(t(`success.${formName}`));

    if (formName === Forms.STATUS || formName === Forms.HANDLER) {
      return resData;
    }
    resData = { [formName]: flattenObj(resData) };

    if (
      [
        Forms.SCOPY,
        Forms.PRELIMINARY,
        Forms.TTGRAPHY,
        Forms.SURGICAL_PROCEDURE,
      ].includes(formName)
    ) {
      const availability = await CheckTabAvailability(
        resData[formName]?.[SCREENING_ID] || screeningId,
        t
      );
      if (availability) {
        resData = { ...resData, ...flattenObj(availability) };
      }
    }

    return resData;
  } catch (er) {
    toast.error(t(`errors.${formName}`));
    return null;
  }
};
