import React from 'react';
import axios from 'axios';
import { ICellRendererParams } from 'ag-grid-community';
import { useTranslation, TFunction } from 'react-i18next';
import { toast } from 'react-toastify';
import userManager from '../../utils/userManager';
import styles from './linkCellRenderer.module.scss';
import { redirectAuthFail } from '../../utils/redirectAuthFail';

export const DownloadPdf = async (
  t: TFunction,
  filename: string
): Promise<void | null> => {
  const user = await userManager.getUser();
  if (!user) {
    redirectAuthFail(t);
    return null;
  }
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/files/failedImports/${filename}`,
      {
        headers: { Authorization: `${user.token_type} ${user.access_token}` },
        responseType: 'blob',
      }
    );
    var file = new Blob([res.data], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  } catch (er) {
    toast.error(t('errors.preliminaryTab'));
    return null;
  }
};

const LinkCellRenderer: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const { t } = useTranslation();
  return (
    <button
      type="button"
      className={styles.linkButton}
      onClick={() => DownloadPdf(t, props.data.filename)}
    >
      {props.data.filename}
    </button>
  );
};

export default LinkCellRenderer;
