import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

const FocusScrollError = () => {
  const { errors, isSubmitting, isValidating } = useFormikContext();

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      const names = Object.keys(errors);
      if (names.length > 0) {
        const selector = `[data-testid=${names[0]}]`;
        const errorElement = document.querySelector(selector) as HTMLElement;
        if (errorElement) {
          window.focus();
          errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          errorElement.focus({ preventScroll: true });
        }
      }
    }
  }, [errors, isSubmitting, isValidating]);

  return null;
};

export default FocusScrollError;
